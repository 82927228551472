<template>
    <section
        class="grid items-center border-b border-neutral-500 bg-neutral-100"
        :style="{
            'grid-template-columns': 'subgrid',
            'grid-column-start': 'span 7'
        }"
    >
        <div class="self-stretch border-r border-neutral-500" />
        <div class="pl-8">
            {{ $t('AUDIENCE.WAVE', 'Wave') }}{{ index + 1 }} {{ $t('AUDIENCE.WAVE_REMINDER', 'Reminder') }} - {{ dayjs(reminder.waveSentAt).format('YYYY.MM.DD. HH:mm') }}
        </div>
        <div>{{ getReminderTypeDisplayName(reminder.reminderType) }}</div>
        <div>
            <TypeIcon
                :type="reminder.type"
                :solid="!!reminder.lastSubmittedAt || reminder.completedCount > 0"
            />
        </div>
        <div>-</div>
        <div>{{ reminder.completedCount }} / {{ reminder.recipientCount }} ({{ Math.round(reminder.completedCount / reminder.recipientCount * 100) }}%)</div>
        <div>
            <Action
                :isReminder="true"
                :isScheduled="false"
                @click="action($event)"
            />
        </div>
        <wave-metrics-popup
            v-if="waveMetricsPopupOpen"
            :campaign="campaign"
            :wave="{ ...reminder, index }"
            @close="waveMetricsPopupOpen = false;"
        />
    </section>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import TypeIcon from './CampaignTypeIcon.vue';
import Action from './WaveListItemAction.vue';
import WaveMetricsPopup from '@/dashboard/campaign-metrics/components/WaveMetricsPopup/WaveMetricsPopup.vue';
import dayjs from 'dayjs';
import { Campaign, getReminderTypeDisplayName, Wave } from '@/campaign/types';
import { WaveAction } from './CampaignList.vue';

export default defineComponent({
    components: { TypeIcon, Action, WaveMetricsPopup },
    props: {
        campaign: { type: Object as () => Campaign, required: true },
        index: { type: Number, required: true },
        reminder: { type: Object as () => Wave, required: true },
    },
    emits: ['wave-action'],
    setup(props, context) {
        const waveMetricsPopupOpen = ref(false);

        function action(action) {
            if (action == 'metrics') {
                waveMetricsPopupOpen.value = true;
                return;
            }

            const payload: WaveAction = {
                type: action,
                wave: {
                    id: props.reminder.id,
                    campaignId: props.reminder.campaignId,
                    type: props.reminder.type,
                    isRecurring: false,
                    reminderType: props.reminder.reminderType!,
                }
            };
            context.emit('wave-action', payload);
        }

        return { action, waveMetricsPopupOpen };
    },
    methods: { dayjs, getReminderTypeDisplayName },
});
</script>

<style scoped>
section.grid > div {
    @apply px-2 py-4
}
</style>
