<template>
    <div class="group relative inline-block">
        <slot>
            <icon icon="question-circle" />
        </slot>
        <div
            class="z-tooltip absolute bg-white shadow rounded p-2 text-sm hidden group-hover:block z-10"
            :style="{
                bottom: 'calc(100% + 5px)',
                left: '50%',
                transform: 'translateX(-50%)',
                width: 'max-content',
                maxWidth: width + 'px'
            }"
        >
            <slot name="tooltip">
                <span v-html="tooltip" />
            </slot>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '@/components/ui/Icon.vue';

export default defineComponent({
    components: {
        Icon,
    },
    props: {
        tooltip: { type: String, default: '' },
        width: { type: Number, default: 450 }
    },
    setup() {

    },
});
</script>

<style scoped>
    .z-tooltip:after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border: solid 5px transparent;
        border-top-color: white;
    }
</style>
