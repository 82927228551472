<template>
    <section
        class="grid items-center border-b border-neutral-500 text-neutral-800"
        :style="{
            'grid-template-columns': 'subgrid',
            'grid-column-start': 'span 7'
        }"
    >
        <div class="self-stretch border-r border-neutral-500" />
        <div class="pl-8">
            {{ $t('AUDIENCE.SCHEDULED', 'Scheduled') }} {{ $t('AUDIENCE.WAVE', 'Wave') }} - {{ dayjs(scheduledCampaign.nextSendAt).format('YYYY.MM.DD. HH:mm') }}
        </div>
        <div>{{ recipients }}</div>
        <div>
            <CampaignTypeIcon
                type="scheduled-for-later"
            />
        </div>
        <div>-</div>
        <div>-</div>
        <div>
            <Action
                :isReminder="false"
                :isScheduled="true"
                @click="action($event)"
            />
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CampaignTypeIcon from './CampaignTypeIcon.vue';
import Action from './WaveListItemAction.vue';
import { Schedule } from '@/campaign/types';
import dayjs from 'dayjs';
import { WaveAction } from './CampaignList.vue';

export default defineComponent({
    components: { CampaignTypeIcon, Action },
    props: {
        scheduledCampaign: { type: Object as () => Schedule, required: true },
        recipients: { type: String, required: true },
    },
    setup(props, context) {
        function action(action) {
            const payload: WaveAction = {
                type: action,
                wave: {
                    id: props.scheduledCampaign.id!.toString(),
                    campaignId: props.scheduledCampaign.campaignId,
                    type: 'scheduled',
                    isRecurring: props.scheduledCampaign.scheduleType == 'recurring'
                }
            };
            context.emit('wave-action', payload);
        }

        return { action };
    },
    methods: { dayjs },
});
</script>

<style scoped>
section.grid > div {
    @apply px-2 py-4
}
</style>
