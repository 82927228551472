<template>
    <table
        style="border-collapse: unset;"
        :style="outerTableStyle"
    >
        <tr v-if="styleOptions.showTitle">
            <td
                id="titlePlaceholderScale"
                :style="headerStyle"
                colspan="2"
            />
        </tr>
        <tr>
            <td
                colspan="2"
            >
                <table :style="bodyTableStyle">
                    <tr>
                        <td
                            v-for="(option, i) in options"
                            :key="option"
                            :style="getTdStyle(i+1)"
                            :data-zurvey-id="'option-'+i"
                        >
                            <a
                                target="_blank"
                                :style="anchorStyle"
                                :disabled="!isLive"
                                :href="url + '/' + uniqueName + '?' + params + element.guid + '=' + option"
                            >
                                {{ option }}
                            </a>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr
            v-if="styleOptions.showLabels && element.label_type !== 'none' && (element.ranges[0].range || element.ranges[1].range)"
            :style="'width: 100%; font-size: 14px; line-height: 16px; color: ' + textColor + '; font-family: ' + answer_font_name + ', Sans-Serif; font-weight: ' + answer_font_weight"
        >
            <td>
                <scale-label-icon
                    v-if="element.range_start_icon"
                    :type="element.range_start_icon.split('_')[0]"
                    :icon-style="element.range_start_icon.split('_')[1]"
                />
                <div v-else>
                    {{ element.ranges[0].range }}
                </div>
            </td>
            <td style="text-align: right;">
                <scale-label-icon
                    v-if="element.range_end_icon"
                    style="float: right;"
                    :type="element.range_end_icon.split('_')[0]"
                    :icon-style="element.range_end_icon.split('_')[1]"
                />
                <div v-else>
                    {{ element.ranges[1].range }}
                </div>
            </td>
        </tr>
        <tr v-if="styleOptions.branding">
            <td
                colspan="2"
                style="vertical-align: bottom; line-height: 16px;"
            >
                <table
                    :style="'color: #9fa7bc; width: 100%; margin-top: 10px; font-size: 12px; line-height: 16px; font-family: ' + answer_font_name + ', Sans-Serif;'"
                >
                    <tr>
                        <td style="width: 20px;">
                            <img
                                src="https://s3-eu-central-1.amazonaws.com/neticle-images/2019/10/zurvey_16.png"
                                style="margin-bottom: 0; max-width: unset;"
                            >
                        </td>
                        <td>
                            <a
                                href="https://zurvey.io"
                                style="text-decoration: none; cursor: pointer;color: #9fa7bc;"
                            >{{ $t('EMBEDDED.POWERED_BY', 'powered by') }} Zurvey.io</a>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</template>

<script lang="ts">
import range from 'lodash/range';
import globals from '@/utils/globals';
import { computed, nextTick, onMounted, onUpdated, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers/dist';
import ScaleLabelIcon from '@/survey/widget/components/elements/ScaleLabelIcon.vue';
import { StyleValue } from 'vue/types/jsx';

export default {
    components: { ScaleLabelIcon },
    props: {
        element: { type: Object, default: () => ({}) },
        isLive: { type: Boolean, default: false },
        styleOptions: { type: Object, default: () => ({}) },
        useLinkPlaceholder: { type: Boolean, default: false },
        urlParams: { type: Array as () => Array<{ name: string }>, default: () => ([]) }
    },
    setup(props) {
        const { useState, useGetters } = createNamespacedHelpers('survey');
        const { uniqueName, primary_color, background_color, text_color, answer_font_name, answer_font_weight, title_font_name, title_font_weight } = useState([
            'uniqueName', 'primary_color', 'background_color', 'text_color', 'answer_font_name', 'answer_font_weight', 'title_font_name', 'title_font_weight',
        ]);
        const { hasActiveLanguages } = useGetters(['hasActiveLanguages']);
        const url = ref(globals.getFillerBaseUrl());
        const params = computed(() => {
            if (props.useLinkPlaceholder) return '@@PARAMS@@';
            let params = hasActiveLanguages.value ? 'lang={{LANG}}&' : '';
            const paramCount = props.urlParams.length;
            if (paramCount > 0) {
                props.urlParams.forEach(p => {
                    params = params + p.name + '={{' + p.name.toUpperCase() + '}}&';
                });
            }
            return params;
        });
        const textColor = computed(() => {
            return props.styleOptions.textColor || text_color.value;
        });
        const options = computed(() => {
            return range(parseInt(props.element.ranges[0].weight), parseInt(props.element.ranges[1].weight) + 1);
        });
        const outerTableStyle = computed(() => {
            return {
                color: props.styleOptions.textColor || text_color.value,
                padding: props.styleOptions.marginSize + 'px',
                border: props.styleOptions.borderColor ? '1px solid' + props.styleOptions.borderColor : 'unset',
                backgroundColor: props.styleOptions.backgroundColor || background_color.value,
            };
        });

        const anchorStyle = computed(() => {
            return {
                display: 'inline-block',
                width: '100%',
                height: '100%',
                lineHeight: '32px',
                verticalAlign: 'middle',
                textDecoration: 'none',
                cursor: 'pointer',
                color: textColor.value,
                padding: props.styleOptions.iconSpacing ? '0' : '0 8px'
            };
        });

        const bodyTableStyle = computed<StyleValue>(() => {
            return {
                height: '32px',
                margin: '0',
                borderCollapse: 'separate',
                borderSpacing: '0',
                backgroundColor: props.styleOptions.scaleButtonColor || props.styleOptions.backgroundColor || background_color.value,
                textAlign: 'center',
                border: '1px solid' + (props.styleOptions.scaleBorderColor || primary_color.value),
                borderRadius: '4px',
                verticalAlign: 'middle',
                fontFamily: answer_font_name.value + ', Sans-Serif',
                fontWeight: answer_font_weight.value,
                tableLayout: 'fixed',
            };
        });

        const headerStyle = computed(() => {
            return {
                fontSize: '14px',
                lineHeight: '16px',
                color: textColor.value,
                fontFamily: title_font_name.value + ', Sans-Serif',
                fontWeight: title_font_weight.value,
                padding: '0 0 10px'
            };
        });

        function getTdStyle(option) {
            return {
                width: props.styleOptions.iconSpacing ? (props.styleOptions.iconSpacing + 16 + 'px') : 'unset',
                padding: '0',
                borderRight: (option === options.value.length ? '0' : '1px') + ' solid' + (props.styleOptions.scaleBorderColor || primary_color.value),
            };
        }

        const updateTitle = async () => {
            const titlePlaceholder = document.getElementById('titlePlaceholderScale');
            await nextTick();

            if (titlePlaceholder) {
                titlePlaceholder.innerHTML = props.element.title;
                const title = titlePlaceholder.getElementsByTagName( 'p' )[0];
                if (title) {
                    title.style.margin = '0';
                }
            }
        };

        onMounted(() => {
            updateTitle();
        });

        onUpdated(() => {
            updateTitle();
        });

        return {
            headerStyle,
            bodyTableStyle,
            anchorStyle,
            outerTableStyle,
            options,
            url,
            uniqueName,
            params,
            textColor,
            answer_font_name,
            answer_font_weight,
            getTdStyle
        };
    }
};
</script>
