<template>
    <popup
        v-if="visible && !isDatasetAlertingLoading"
        :title="title"
        :is-closable="true"
        @close="$emit('close')"
    >
        <div class="w-128 text-sm">
            <div
                class="mb-2"
                v-html="deleteText"
            />
            <info-box
                v-if="connectedAlerts.length > 0"
                type="warning"
                class="mb-3"
            >
                {{ alertDimensionWarningIntro }}
                <ul class="list-disc pl-4 my-2">
                    <li
                        v-for="(alert, index) in connectedAlerts"
                        :key="index"
                    >
                        {{ alert }}
                    </li>
                </ul>
                {{ $t('HOME_DATASET_ALERT_DIMENSION_DELETE_WARNING_OUTRO', 'The deletion of this question results in the deletion of alert if it contains a question as a threshold dimension or modifies alert settings if it is used as a filter.') }} <br>
                {{ $t('HOME.DATASET_DELETE_TEXT', 'This action cannot be undone, proceed with caution.') }}
            </info-box>
            <div class="flex justify-center">
                <z-button
                    variant="secondary"
                    @click="$emit('close')"
                >
                    {{ $t('GLOBAL.CANCEL') }}
                </z-button>
                <z-button
                    data-test-id="delete-button"
                    destructive
                    @click="$emit('delete')"
                >
                    {{ $t('BUTTONS.DELETE') }}
                </z-button>
            </div>
        </div>
    </popup>
</template>

<script lang="ts">
import {computed, onMounted, defineComponent, ref} from 'vue';
import Popup from '@/components/ui/Popup.vue';
import { toaster } from '@/utils/toaster';
import i18next from 'i18next';
import {setLoading} from '@/utils/loading';
import InfoBox from '@/components/ui/InfoBox.vue';
import { getAlertNamesForDimension } from '@/dataset-alerting/api/datasetAlertApi';
import { DatasetTypeEnum } from '@/datasets/store/types';


export default defineComponent({
    name: 'DeleteDimensionPopup',
    components: {InfoBox, Popup,  },
    props: {
        datasetId: { type: String, required: true },
        datasetType: { type: Number as () => DatasetTypeEnum, required: true },
        dimensionId: { type: String, required: true },
        title: { type: String, required: true },
        defaultWarning: { type: String, default: '' },
        visible: { type: Boolean, default: false}
    },
    setup(props, ctx) {
        const isDatasetAlertingLoading = ref(true);
        const connectedAlerts = ref<string[]>([]);

        const deleteText = computed(() =>
            connectedAlerts.value.length === 0 ?
                i18next.t('HOME.DATASET_DELETE_TEXT', 'This action cannot be undone, proceed with caution.') :
                props.defaultWarning
        );

        const dimensionBasedOnType = computed(() => {
            switch (props.datasetType) {
            case DatasetTypeEnum.SURVEY:
                return i18next.t('GENERAL.SURVEY_QUESTION', 'survey question');
            case DatasetTypeEnum.API:
                return i18next.t('GENERAL.API_DIMENSION', 'API dimension');
            default:
                return i18next.t('HOME_DATASET_ALERT_CRITERIA_ALERT_DIMENSION', 'Dimension');
            }
        });
        const alertDimensionWarningIntro = computed(() => i18next.t('HOME_DATASET_ALERT_DIMENSION_DELETE_WARNING_INTRO', {
            dimension: dimensionBasedOnType.value,
            defaultValue: 'This {{ dimension }} is applied as a threshold or filter dimension in one or multiple alerts.',
        }));

        onMounted(async () => {
            connectedAlerts.value = await getAlertNamesForDimension(props.datasetId, props.dimensionId);
            isDatasetAlertingLoading.value = false;
            if (connectedAlerts.value.length === 0) {
                setLoading(true);
                try {
                    if(connectedAlerts.value.length === 0 && !props.visible) {
                        ctx.emit('delete');
                    }
                } catch (e) {
                    toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                    Sentry.captureException(e);
                }
                setLoading(false);
            } else if (!props.visible) {
                ctx.emit('delete');
            }
        });

        return {
            deleteText,
            connectedAlerts,
            alertDimensionWarningIntro,
            isDatasetAlertingLoading
        };
    }
});
</script>

