<template>
    <button
        class="p-1 text-xs rounded outline-none"
        :class="disabled ? 'bg-neutral-300 text-neutral-600 cursor-not-allowed' : 'hover:bg-primary-700 bg-neutral-400 text-black hover:text-bg'"
        @click="disabled ? null : $emit('click')"
    >
        <slot />
    </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        disabled: { type: Boolean, default: false }
    },
});
</script>

