<template>
    <div class="embeddable-questions-wrapper">
        <div class="mb-4">
            <z-select
                :model-value="selectedQuestion.guid"
                data-test-id="embed-select"
                name="embeddable-questions"
                class="w-2/3 ml-2"
                :label="$t('SURVEYFORMS.PUBLISH_QUESTION_TO_EMBED_SELECT', 'Select the initial question')"
                @update:modelValue="setSelectedQuestion($event)"
            >
                <option
                    v-for="question in questions"
                    :key="question.guid"
                    :value="question.guid"
                >
                    {{ striptags(question.title) }}
                </option>
            </z-select>
        </div>
        <div class="w-full overflow-auto">
            <div>
                <label class="text-sm ml-2">
                    {{ $t('SURVEYFORMS.PUBLISH_WIDGET_PREVIEW', 'Widget preview') }}:
                </label>
                <span
                    class="text-primary-700 float-right mr-4 text-xs cursor-pointer"
                    @click="copyRichText()"
                >
                    <icon icon="copy" />
                    {{ $t('SURVEYFORMS.PUBLISH_COPY_WIDGET', 'Copy widget') }}
                </span>
            </div>
            <div
                ref="widget"
                class="m-3"
            >
                <component
                    :is="component"
                    :data-test-id="component"
                    :element="selectedQuestion"
                    :is-live="true"
                    :style-options="customizationSettings"
                    :url-params="urlParameters"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import striptags from 'striptags';
import EmbeddableScale from '@/survey/widget/components/elements/EmbeddableScale.vue';
import EmbeddableRating from '@/survey/widget/components/elements/EmbeddableRating.vue';
import EmbeddableSingleChoice from '@/survey/widget/components/elements/EmbeddableSingleChoice.vue';
import EmbeddableButtons from '@/survey/widget/components/elements/EmbeddableButtons.vue';
import EmbeddableVerticalButtons from '@/survey/widget/components/elements/EmbeddableVerticalButtons.vue';
import elementTypes from '@/survey/store/utils/elementTypeEnum.js';
import ZSelect from '@/components/ui/Select.vue';
import Icon from '@/components/ui/Icon.vue';
import i18next from 'i18next';
import { toaster } from '@/utils/toaster';
import { createNamespacedHelpers } from 'vuex-composition-helpers/dist';

export default defineComponent({
    components: { EmbeddableScale, EmbeddableRating, EmbeddableSingleChoice, EmbeddableButtons, EmbeddableVerticalButtons, ZSelect, Icon },
    props: {
        questions: { type: Array as () => ({guid: string, type: number, display_type: string, display_direction: string, title: string }[]), default: () => [] },
        customizationSettings: { type: Object, default: () => {} },
    },
    setup(props, ctx) {
        const  { useState }  = createNamespacedHelpers('survey');
        const { urlParameters } = useState(['urlParameters']);
        const selectedQuestion = ref<{guid: string, type: number, display_type: string, display_direction: string }>(props.questions[0]);
        const widget = ref();
        function copyRichText() {
            const element = widget.value.innerHTML;
            const listener = function(ev) {
                ev.preventDefault();
                ev.clipboardData.setData('text/html', element);
                ev.clipboardData.setData('text/plain', element);
            };
            document.addEventListener('copy', listener);
            document.execCommand('copy');
            document.removeEventListener('copy', listener);
            toaster.success(i18next.t('SURVEYFORMS.PUBLISH_COPY_ELEMENT_SUCCESS', 'Widget successfully copied.'));
        }

        function setSelectedQuestion(guid) {
            selectedQuestion.value = props.questions.find(q => q.guid === guid)!;
            ctx.emit('selectedQuestionChanged', selectedQuestion.value.type);
        }

        const component = computed(() => {
            if (selectedQuestion.value.type === elementTypes.RADIO) {
                return 'embeddable-single-choice';
            }
            if (selectedQuestion.value.type === elementTypes.RATING) {
                return 'embeddable-rating';
            }
            if ([elementTypes.CSAT, elementTypes.CES_5_SCALE, elementTypes.CES_7_SCALE].includes(selectedQuestion.value.type)) {
                return 'embeddable-buttons';
            }
            if (selectedQuestion.value.type !== elementTypes.SCALE && selectedQuestion.value.type !== elementTypes.NPS) {
                Sentry.captureException(new Error('Invalid element selected for embed: ' + selectedQuestion.value.type));
                return '';
            }
            if (selectedQuestion.value.display_type !== 'value') {
                return 'embeddable-scale';
            }
            if (selectedQuestion.value.display_direction === 'vertical') {
                return 'embeddable-vertical-buttons';
            }
            return 'embeddable-buttons';
        });

        return {
            widget,
            selectedQuestion,
            component,
            elementTypes,
            copyRichText,
            setSelectedQuestion,
            urlParameters,
            striptags,
        };
    }
});
</script>
