<template>
    <div class="shadow rounded bg-white p-4">
        <div
            class="loading-rectangle"
            :class="innerClass"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        innerClass: { type: String, default: '' }
    }
});
</script>

<style scoped>
.loading-rectangle {
    background: linear-gradient(to right, var(--color-neutral-200) 40%, var(--color-neutral-600) 50%, var(--color-neutral-200) 60%);
    background-size: 200%;
    animation: move 2s linear infinite;
}
@keyframes move {
    0% {
        background-position: 300%;
    }
    100% {
        background-position: 100%;
    }
}
</style>
