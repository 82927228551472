<template>
    <div class="h-9 rounded relative bg-neutral-200 flex items-center">
        <input
            ref="copyContainer"
            type="hidden"
            :value="textToCopy"
        >
        <div
            v-if="!textToCopy.includes('&para')"
            class="url cursor-default truncate flex-grow px-3"
            v-html="textToDisplay || textToCopy"
        />
        <div
            v-else
            class="url cursor-default truncate flex-grow px-3"
            v-text="textToCopy"
        />
        <div
            class="bg-primary-700 text-bg h-full flex items-center justify-center px-2 rounded-tr rounded-br cursor-pointer"
            @click="copyUrl"
        >
            <z-icon icon="copy" />
        </div>
    </div>
</template>

<script lang="ts">
import { ref } from 'vue';
import ZIcon from '@/components/ui/Icon.vue';
import i18next from 'i18next';
import { toaster } from '@/utils/toaster';

export default {
    name: 'CopyContainer',
    components: { ZIcon },
    props: {
        // v-html converts &para to ¶
        textToCopy: { type: String, default: '' },
        textToDisplay: { type: String || null, default: null }
    },
    setup() {
        const copyContainer = ref();
        function copyUrl() {
            copyContainer.value.setAttribute('type', 'text');
            copyContainer.value.select();
            document.execCommand('copy');
            copyContainer.value.setAttribute('type', 'hidden');
            toaster.success(i18next.t('SURVEYFORMS.PUBLISH_COPY_SUCCESS'));
        }
        return {
            copyContainer,
            copyUrl
        };
    }
};
</script>
