<template>
    <div class="flex flex-col space-y-4">
        <main
            class="flex-grow h-1 flex justify-center"
        >
            <template v-if="selectedTemplate">
                <z-scrollbar class="h-full w-1/2">
                    <email-customization-preview
                        :html-body="selectedTemplate.customizationOptions.htmlBody"
                        :has-button="!selectedTemplate.customizationOptions.embeddedQuestionGuid"
                        :button-text="selectedTemplate.customizationOptions.actionButtonText"
                        :sender-name="selectedTemplate.customizationOptions.senderName"
                        :embedded-code="selectedTemplate.customizationOptions.embeddedCode"
                    />
                </z-scrollbar>
                <z-scrollbar class="h-full w-1/2 border-l border-neutral-500">
                    <div class="text-default p-4 space-y-4">
                        <section class="grid grid-cols-2 gap-4">
                            <div class="font-semibold capitalize">
                                {{ $t('AUDIENCE.CAMPAIGN_POPUP_CAMPAIGN_NAME_INPUT') }}
                            </div>
                            <div>
                                {{ modelValue.name }}
                            </div>
                            <div class="font-semibold capitalize">
                                {{ reminderWave === null ? $t('AUDIENCE.POPUP_TAB_RECIPIENTS') : $t('AUDIENCE.POPUP_TAB_ORIGINAL_RECIPIENTS', 'Original Recipients') }}
                            </div>
                            <div>
                                {{ recipient }}
                            </div>
                            <template v-if="reminderWave !== null">
                                <div class="font-semibold capitalize">
                                    {{ $t('AUDIENCE.POPUP_TAB_REMINDER_RECIPIENTS', 'Reminder Recipients') }}
                                </div>
                                <div>
                                    {{ reminderRecipients }}
                                    <z-icon
                                        class="cursor-pointer text-primary-700"
                                        icon="download"
                                        @click="exportReminderRecipients"
                                    />
                                </div>
                            </template>
                            <div class="col-span-2">
                                <z-toggle
                                    :model-value="modelValue.sendOnlyNewMembers"
                                    @update:modelValue="toggleSendOnlyNewMembers($event)"
                                >
                                    <span class="mr-1">{{ $t('AUDIENCE.TOGGLE_SEND_TO_NEW_MEMBERS', 'Send only to new members') }}</span>
                                    <z-tooltip :tooltip="$t('AUDIENCE.SUMMARY_TAB.SEND_ONLY_NEW_MEMBERS_TOOLTIP', 'This feature ensures that survey campaign is exclusively sent to audience members added to the audience or segment after the last send. This means only newly populated members will receive the invitations, optimizing outreach to fresh audience members.')" />
                                </z-toggle>
                            </div>
                            <div class="font-semibold capitalize">
                                {{ $t('SURVEYFORMS.EMAIL_TEMPLATE_NAME') }}
                            </div>
                            <div>
                                {{ selectedTemplate.name }}
                            </div>
                        </section>
                        <section
                            v-if="selectedTemplate.variables.length > 0"
                            class="space-y-4"
                        >
                            <div class="space-x-1">
                                <span class="font-semibold capitalize">{{ $t('AUDIENCE.SUMMARY_TAB.APPLIED_VARIABLES', 'Applied Variables') }}</span>
                                <z-tooltip
                                    :tooltip="$t('AUDIENCE.SUMMARY_TAB.APPLIED_VARIABLES_TOOLTIP', 'See the audience dimensions listed below, which have been added to the email template as variables.')"
                                    :width="250"
                                />
                            </div>
                            <ul class="space-y-2">
                                <li
                                    v-for="variable in selectedTemplate.variables"
                                    :key="variable"
                                >
                                    {{ variable }}
                                </li>
                            </ul>
                        </section>
                        <section
                            v-if="urlParameters.length > 0"
                            class="space-y-4"
                        >
                            <div class="space-x-1">
                                <span class="font-semibold capitalize">{{ $t('AUDIENCE.SUMMARY_TAB.APPLIED_URL_PARAMETERS', 'Applied URL parameters') }}</span>
                                <z-tooltip
                                    :tooltip="$t('AUDIENCE.SUMMARY_TAB.APPLIED_URL_PARAMETERS_TOOLTIP', 'Check which dimensions\' data will populate your URL parameters based on your configuration.')"
                                    :width="250"
                                />
                            </div>
                            <div class="inline-grid grid-cols-2 gap-2">
                                <div class="font-semibold">
                                    {{ $t('AUDIENCE.DIMENSION') }}
                                </div>
                                <div class="font-semibold">
                                    {{ $t('SURVEYFORMS.PUBLISH_URL_PARAMETER') }}
                                </div>
                                <template
                                    v-for="param in urlParameters"
                                >
                                    <div :key="'d-' + param.key">
                                        {{ param.dimension }}
                                    </div>
                                    <div :key="'p-' + param.key">
                                        {{ param.parameter }}
                                    </div>
                                </template>
                            </div>
                        </section>
                    </div>
                </z-scrollbar>
            </template>
            <template v-else>
                <div class="flex items-center justify-center">
                    <z-loading-animation />
                </div>
            </template>
        </main>
        <div class="mx-auto space-x-2">
            <z-button
                variant="outline"
                @click="$emit('show-prev')"
            >
                {{ $t('AUDIENCE.BUTTON_BACK', 'Go Back') }}
            </z-button>
            <z-button
                :loading="saving"
                @click="handleNext()"
            >
                {{
                    savedSendOnlyNewMembers === modelValue.sendOnlyNewMembers
                        ? $t('AUDIENCE.BUTTON_GO_TO_SENDING', 'Go to sending options')
                        : $t('AUDIENCE.BUTTON_SAVE_AND_GO_TO_SENDING', 'Save and go to sending options')
                }}
            </z-button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { getSurveyEmailTemplates } from '@/survey/api';

import EmailCustomizationPreview from '@/survey/components/publish/email/EmailCustomizationPreview.vue';

import ZButton from '@/common/components/design-system/Button.vue';
import ZScrollbar from '@/components/ui/Scrollbar.vue';
import ZToggle from '@/common/components/design-system/Toggle.vue';
import ZTooltip from '@/components/ui/Tooltip.vue';
import ZLoadingAnimation from '@/components/ui/LoadingAnimation.vue';
import ZIcon from '@/components/ui/Icon.vue';

import { useCampaigns } from '@/campaign';
import { Campaign, VariableType, getReminderTypeDisplayName } from '@/campaign/types';

import { actions as campaignMetricsActions } from '@/dashboard/campaign-metrics/store';

import { useNamespacedState } from 'vuex-composition-helpers';

import { toaster } from '@/utils/toaster';

import i18next from 'i18next';
import { Audience } from '@/audience/types';

export default defineComponent({
    name: 'CampaignSummary',
    components: {
        ZButton,
        ZScrollbar,
        EmailCustomizationPreview,
        ZToggle,
        ZTooltip,
        ZLoadingAnimation,
        ZIcon,
    },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue',
    },
    props: {
        modelValue: { type: Object as () => Campaign, required: true },
        audiences: { type: Array as () => Audience[], required: true }
    },
    emits: ['show-prev', 'show-next', 'update:modelValue', 'campaign-saved'],
    setup(props, context) {
        const savedSendOnlyNewMembers = props.modelValue.sendOnlyNewMembers;

        const campaignsStore = useCampaigns();
        const selectedTemplate = ref();

        onMounted(async () => {
            try {
                selectedTemplate.value = (await getSurveyEmailTemplates()).find(t => t.id === props.modelValue.emailTemplateId);
            }
            catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG'));
            }
        });

        function toggleSendOnlyNewMembers(sendOnlyNewMembers: boolean) {
            context.emit('update:modelValue', {
                ...props.modelValue,
                sendOnlyNewMembers,
            });
        }

        const saving = ref(false);

        async function handleNext() {
            try {
                if (savedSendOnlyNewMembers !== props.modelValue.sendOnlyNewMembers) {
                    saving.value = true;
                    const savedCampaign = await campaignsStore.updateExistingCampaign(props.modelValue);
                    toaster.success(i18next.t('AUDIENCE.CAMPAIGN_UPDATED', 'Campaign updated.'));
                    context.emit('campaign-saved', savedCampaign);
                }
                context.emit('show-next');
            }
            catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG'));
                Sentry.captureException(e);
            }
            finally {
                saving.value = false;
            }
        }

        const state = useNamespacedState('survey', ['urlParameters', 'datasetId']);

        const urlParameters = computed(() => {
            const params = props.modelValue.variables ? props.modelValue.variables.filter(v => v.type === VariableType.UrlParameter) : [];

            return params.map(param => {
                const urlParameter = state.urlParameters.value.find(p => p.guid === param.urlUid);

                return {
                    key: param.fieldUid,
                    dimension: param.name,
                    parameter: urlParameter.name,
                };
            });
        });

        const recipient = computed(() => {
            const audience = props.audiences.find(a => a.uid === props.modelValue.audienceId);

            if (audience && props.modelValue.segmentId) {
                const segment = audience?.segments?.find(s => s.id === props.modelValue.segmentId);

                if (segment && audience.name && segment.name) {
                    return `${audience.name} - ${segment.name} (${segment.memberCount || 0} ${i18next.t('AUDIENCE.MEMBERS', 'members')})`;
                }
                else return '-';
            }
            else if (audience && audience.name) {
                return `${audience.name} (${audience.members || 0} ${i18next.t('AUDIENCE.MEMBERS', 'members')})`;
            }

            return '-';
        });

        const reminderWave = computed(() => campaignsStore.state.reminderWave);

        const reminderRecipients = computed(() => {
            if (!reminderWave.value) {
                return '-';
            }

            const count = campaignsStore.state.reminderRecipientCount;
            const type = getReminderTypeDisplayName(reminderWave.value.reminderType);
            return `${type} (${count} ${i18next.t('AUDIENCE.MEMBERS', 'members')})`;
        });

        const exportReminderRecipients = async (): Promise<void> => {
            if (!reminderWave.value) {
                return;
            }

            try {
                await campaignMetricsActions.exportRawData({
                    datasetId: state.datasetId.value,
                    campaignId: reminderWave.value.campaignId,
                    waveId: reminderWave.value.waveId,
                    metricType: reminderWave.value.reminderType || undefined
                });
                toaster.success(i18next.t('AUDIENCE.CAMPAIGN_EXPORT_UNDER_GENERATION', 'Your export is under generation and will be sent in email.'));
            }
            catch (e) {
                // @ts-ignore
                if (e.response && e.response.status === 409) {
                    // it's still under generation but user doesn't have to know
                    toaster.success(i18next.t('AUDIENCE.CAMPAIGN_EXPORT_UNDER_GENERATION', 'Your export is under generation and will be sent in email.'));
                } else {
                    toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG'));
                    Sentry.captureException(e);
                }
            }
        };

        return {
            savedSendOnlyNewMembers,
            selectedTemplate,
            toggleSendOnlyNewMembers,
            handleNext,
            urlParameters,
            saving,
            recipient,
            reminderRecipients,
            reminderWave,
            exportReminderRecipients
        };
    }
});
</script>
