<template>
    <div class="relative rounded shadow p-4">
        <high-charts
            ref="chartRef"
            :options="chartOptions"
        />
        <div class="absolute top-0 right-0 mr-3 mt-3">
            <z-icon
                icon="download"
                class="cursor-pointer"
                @click="downloadChart"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import ZIcon from '@/components/ui/Icon.vue';
import { Chart } from 'highcharts-vue';
import { state } from '../../store';
import useUserStore from '@/user/store';
import { getLegend } from '../../utils';
import { createColorScale } from '@/utils/color';
import i18next from 'i18next';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import { MappedWave } from './CampaignMetricsPopup.vue';
Exporting(Highcharts);
OfflineExporting(Highcharts);

export default defineComponent({
    components: { 'high-charts': Chart, ZIcon },
    props: {
        waves: { type: Array as () => Array<MappedWave>, required: true },
    },
    setup(props) {
        const userStore = useUserStore();
        const chartRef = ref();

        const colors = computed(() => createColorScale(userStore.state.primaryColor, 4).reverse());

        const metrics = ['bounced', 'opened', 'clicked', 'completed'];

        const data = computed(() => {
            return props.campaign.waves!.map(wave => ({
                bounced: state.countDistribution[wave.id]?.bounced || 0,
                opened: state.countDistribution[wave.id]?.opened || 0,
                clicked: state.countDistribution[wave.id]?.clicked || 0,
                completed: state.countDistribution[wave.id]?.completed || 0,
                recipients: state.countDistribution[wave.id]?.recipients || 0,
            }));
        });

        const chartOptions = computed(() => ({
            exporting: { enabled: false },
            credits: { enabled: false },
            chart: {
                type: 'bar',
                backgroundColor: null,
                // need a margin so the 100% label doesn't get cut off
                marginRight: 20
            },
            plotOptions: {
                series: {
                    groupPadding: 0
                }
            },
            title: {
                text: i18next.t('CAMPAIGN_METRICS.METRICS_PER_WAVE', 'Metrics per wave'),
                align: 'left',
                style: {
                    'font-size': '16px',
                    'font-weight': 'bold'
                },
            },
            tooltip: {
                pointFormatter(this: any) {
                    return `<span style="color:${this.series.color}">\u25CF</span> ${this.series.name}: <b>${+this.y.toFixed(2)}%</b>`;
                },
            },
            xAxis: {
                categories: props.waves.map(wave => wave.name),
                lineColor: '#F0F0F0',
                plotLines: props.waves.map(() => '').concat('').map((_, index) => ({
                    color: '#F0F0F0',
                    width: 1,
                    value: index - 0.5
                })),
            },
            yAxis: {
                max: 100,
                labels: {
                    format: '{text}%',
                },
                title: undefined
            },
            series: metrics.map((metric, index) => {
                return {
                    name: getLegend(metric),
                    color: colors.value[index],
                    data: data.value.map(wave => ({ y: wave[metric] / wave.recipients * 100 }))
                };
            }),
        }));

        const downloadChart = () => {
            chartRef.value
                .chart
                .exportChartLocal({
                    type: 'image/png',
                    filename: i18next.t('CAMPAIGN_METRICS.METRICS_PER_WAVE', 'Metrics per wave')
                });
        };

        return { chartRef, chartOptions, downloadChart };
    },
});
</script>
