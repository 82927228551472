<template>
    <popup
        :title="$t('PUBLISH.ENCODED_URLS_READY_POPUP_TITLE', 'Encoded URLs are ready')"
        is-closable
        dont-capitalize-title
        @close="$emit('close')"
    >
        <div class="w-96 space-y-8">
            <p class="text-xs">
                {{ $t('PUBLISH.ENCODED_URLS_READY_POPUP_BODY', 'You can download the encoded URLs in an xlsx file below. Please be aware if you close this popup you have to upload your template with URL parameter inputs again.') }}
            </p>
            <div class="flex justify-center">
                <z-button @click="download">
                    {{ $t('PUBLISH.ENCODED_URLS_READY_POPUP_DOWNLOAD', 'Download Encoded URLs') }}
                </z-button>
            </div>
        </div>
    </popup>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import Popup from '@/components/ui/Popup.vue';
import { useNamespacedState } from 'vuex-composition-helpers';

import { saveAs } from 'file-saver';

export default defineComponent({
    components: {
        Popup
    },
    props: {
        name: { type: String, required: true },
    },
    setup(props) {
        const state = useNamespacedState('survey/encodedUrl', ['response']);

        async function download(): Promise<void> {
            saveAs(state.response.value, props.name + ' Encoded URLs.xlsx');
        }

        return { download };
    },
});
</script>
