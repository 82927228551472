<template>
    <div class="w-1/2 ml-auto mr-auto">
        <div
            class="flex p-2 items-center"
        >
            <span class="w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_BG_COLOR', 'Background color') }}</span>
            <color-picker
                :model-value="customizationSettings.backgroundColor"
                class="pl-6 w-1/2"
                transparent-option
                data-test-id="background-color"
                @update:modelValue="$emit('settingsChanged', {type: 'backgroundColor', value: $event})"
            />
        </div>
        <div
            class="flex p-2 items-center"
        >
            <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_TEXT_COLOR', 'Text color') }}</span>
            <color-picker
                :model-value="customizationSettings.textColor"
                class="pl-6 w-1/2"
                @update:modelValue="$emit('settingsChanged', {type: 'textColor', value: $event})"
            />
        </div>
        <div
            class="flex p-2 items-center"
        >
            <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_BORDER_COLOR', 'Border color') }}</span>
            <color-picker
                :model-value="customizationSettings.borderColor"
                class="pl-6 w-1/2"
                transparent-option
                data-test-id="border-color"
                @update:modelValue="$emit('settingsChanged', {type: 'borderColor', value: $event})"
            />
        </div>
        <div
            v-if="[elementTypeEnum.SCALE, elementTypeEnum.NPS, elementTypeEnum.CSAT, elementTypeEnum.CES_5_SCALE, elementTypeEnum.CES_7_SCALE].includes(type)"
            class="flex p-2 items-center"
        >
            <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_SCALE_BORDER', 'Scale border color') }}</span>
            <color-picker
                :model-value="customizationSettings.scaleBorderColor"
                class="pl-6 w-1/2"
                @update:modelValue="$emit('settingsChanged', {type: 'scaleBorderColor', value: $event})"
            />
        </div>
        <div
            v-if="type === elementTypeEnum.RATING"
            class="flex p-2 items-center"
        >
            <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_ICON_COLOR', 'Icon color') }}</span>
            <color-picker
                :model-value="customizationSettings.iconColor"
                class="pl-6 w-1/2"
                data-test-id="icon-color"
                @update:modelValue="$emit('settingsChanged', {type: 'iconColor', value: $event})"
            />
        </div>
        <div
            v-if="[elementTypeEnum.SCALE, elementTypeEnum.NPS, elementTypeEnum.CSAT, elementTypeEnum.CES_5_SCALE, elementTypeEnum.CES_7_SCALE].includes(type)"
            class="flex p-2 items-center"
        >
            <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_SCALE_BUTTON_COLOR', 'Scale button color') }}</span>
            <color-picker
                :model-value="customizationSettings.scaleButtonColor"
                class="pl-6 w-1/2"
                @update:modelValue="$emit('settingsChanged', {type: 'scaleButtonColor', value: $event})"
            />
        </div>
        <div
            class="flex p-2 items-center"
        >
            <span class="w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_MARGIN', 'Margin') }}</span>
            <span class="pl-6 w-1/2">
                <z-slider
                    :model-value="customizationSettings.marginSize"
                    :min="0"
                    :max="16"
                    @update:modelValue="$emit('settingsChanged', {type: 'marginSize', value: $event})"
                />
            </span>
        </div>
        <div
            v-if="type === elementTypeEnum.RATING"
            class="flex p-2 items-center"
            data-test-id="static-icon-size"
        >
            <span class="w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_ICON_SIZE', 'Icon size') }}</span>
            <span
                class="pl-6 w-1/2"
            >
                <z-slider
                    :model-value="customizationSettings.iconSize"
                    :min="26"
                    :max="48"
                    @update:modelValue="$emit('settingsChanged', {type: 'iconSize', value: $event})"
                />
            </span>
        </div>
        <div
            v-if="[elementTypeEnum.RATING, elementTypeEnum.SCALE, elementTypeEnum.NPS, elementTypeEnum.CSAT, elementTypeEnum.CES_5_SCALE, elementTypeEnum.CES_7_SCALE].includes(type)"
            class="flex p-2 items-center"
            data-test-id="static-icon-spacing"
        >
            <span class="w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_ICON_SPACING', 'Icon spacing') }}</span>
            <span class="pl-6 w-1/2">
                <z-slider
                    :model-value="customizationSettings.iconSpacing"
                    :min="8"
                    :max="40"
                    @update:modelValue="$emit('settingsChanged', {type: 'iconSpacing', value: $event})"
                />
            </span>
        </div>
        <div
            class="flex p-2 items-center"
        >
            <span class="w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_SHOW_TITLE', 'Show title') }}</span>
            <span class="pl-6 w-1/2">
                <z-toggle
                    :model-value="customizationSettings.showTitle"
                    data-test-id="show-title-toggle"
                    @update:modelValue="$emit('settingsChanged', {type: 'showTitle', value: $event})"
                />
            </span>
        </div>
        <div
            v-if="[elementTypeEnum.RATING, elementTypeEnum.SCALE, elementTypeEnum.NPS, elementTypeEnum.CSAT, elementTypeEnum.CES_5_SCALE, elementTypeEnum.CES_7_SCALE].includes(type)"
            class="flex p-2 items-center"
        >
            <span class="w-1/2">
                {{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_SHOW_LABELS', 'Show labels') }}
            </span>
            <span class="pl-6 w-1/2">
                <z-toggle
                    :model-value="customizationSettings.showLabels"
                    data-test-id="show-labels-toggle"
                    @update:modelValue="$emit('settingsChanged', {type: 'showLabels', value: $event})"
                />
            </span>
        </div>
        <div
            v-if="type === elementTypeEnum.RATING"
            class="flex p-2 items-center"
        >
            <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_RATING_STYLE', 'Rating style') }}</span>
            <z-select
                :model-value="customizationSettings.ratingStyle"
                class="pl-6 w-1/2"
                @update:modelValue="$emit('settingsChanged', {type: 'ratingStyle', value: $event})"
            >
                <option
                    v-for="(ratingType, index) in ratingStyleOptions"
                    :key="index"
                    :value="ratingType.id"
                >
                    {{ ratingType.text }}
                </option>
            </z-select>
        </div>
        <div
            v-if="canUseWhitelabel"
            class="flex p-2 items-center"
        >
            <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_ZURVEY_REMOVE_BRANDING', 'Remove zurvey branding') }}</span>
            <div class="w-1/2">
                <z-toggle
                    :model-value="!customizationSettings.branding"
                    data-test-id="customization-branding"
                    class="pl-6"
                    @update:modelValue="$emit('settingsChanged', {type: 'branding', value: !$event})"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import ColorPicker from '@/components/ui/ColorPicker.vue';
import ZSlider from '@/components/ui/Slider.vue';
import ZSelect from '@/components/ui/Select.vue';
import i18next from 'i18next';
import elementTypeEnum from '@/survey/store/utils/elementTypeEnum';
import planFeatureTypeEnum from '@/domain/planFeatureTypeEnum';
import useSubscriptionStore from '@/subscription/store';
export default defineComponent({
    components: { ColorPicker, ZSlider, ZSelect },
    props: {
        customizationSettings: { type: Object, default: () => {} },
        type: { type: Number, required: true }
    },
    setup() {
        const { canUseFeature } = useSubscriptionStore().getters;
        const canUseWhitelabel = computed(() => canUseFeature.value(planFeatureTypeEnum.WHITELABEL));
        const ratingStyleOptions = ref([
            { id: 'solid', text: i18next.t('SURVEYFORMS.PUBLISH_EMBED_CUSTOMIZATION_RATING_SOLID', 'Solid') },
            { id: 'regular', text: i18next.t('SURVEYFORMS.PUBLISH_EMBED_CUSTOMIZATION_RATING_REG', 'Regular') },
            { id: 'light', text: i18next.t('SURVEYFORMS.PUBLISH_EMBED_CUSTOMIZATION_RATING_LIGHT', 'Light') }
        ]);
        const directionOptions = ref([
            { id: 'top', text: i18next.t('SURVEYFORMS.PUBLISH_EMBED_CUSTOMIZATION_DIRECTION_TOP', 'Top') },
            { id: 'right', text: i18next.t('SURVEYFORMS.PUBLISH_EMBED_CUSTOMIZATION_DIRECTION_RIGHT', 'Right') },
            { id: 'left', text: i18next.t('SURVEYFORMS.PUBLISH_EMBED_CUSTOMIZATION_DIRECTION_LEFT', 'Left') },
            { id: 'bottom', text: i18next.t('SURVEYFORMS.PUBLISH_EMBED_CUSTOMIZATION_DIRECTION_BOTTOM', 'Bottom') }
        ]);
        return {
            close,
            ratingStyleOptions,
            directionOptions,
            elementTypeEnum,
            canUseWhitelabel
        };
    }
});
</script>
