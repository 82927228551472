import i18next from 'i18next';

export function getRateName(metric: string): string {
    switch (metric) {
        case 'sent': return i18next.t('CAMPAIGN_METRICS.CHART_TITLE.SENT', 'Emails Sent');
        case 'bounced': return i18next.t('CAMPAIGN_METRICS.CHART_TITLE.BOUNCED', 'Bounce Rate');
        case 'opened': return i18next.t('CAMPAIGN_METRICS.CHART_TITLE.OPENED', 'Open Rate');
        case 'clicked': return i18next.t('CAMPAIGN_METRICS.CHART_TITLE.CLICKED', 'Click Through Rate');
        case 'completed': return i18next.t('CAMPAIGN_METRICS.CHART_TITLE.COMPLETED', 'Completion Rate');
        default: throw new Error('wrong metric');
    }
}

export function getLegend(metric: string): string {
    switch (metric) {
        case 'bounced': return i18next.t('CAMPAIGN_METRICS.CHART_LEGEND.BOUNCED', 'Bounced');
        case 'not-bounced': return i18next.t('CAMPAIGN_METRICS.CHART_LEGEND.NOT_BOUNCED', 'Delivered');
        case 'opened': return i18next.t('CAMPAIGN_METRICS.CHART_LEGEND.OPENED', 'Opened');
        case 'not-opened': return i18next.t('CAMPAIGN_METRICS.CHART_LEGEND.NOT_OPENED', 'Not opened');
        case 'clicked': return i18next.t('CAMPAIGN_METRICS.CHART_LEGEND.CLICKED', 'Clicked');
        case 'not-clicked': return i18next.t('CAMPAIGN_METRICS.CHART_LEGEND.NOT_CLICKED', 'Not clicked');
        case 'completed': return i18next.t('CAMPAIGN_METRICS.CHART_LEGEND.COMPLETED', 'Completed');
        case 'not-completed': return i18next.t('CAMPAIGN_METRICS.CHART_LEGEND.NOT_COMPLETED', 'Not completed');
        default: throw new Error('wrong metric');
    }
}

import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
dayjs.extend(minMax);
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);

export function getDates(originalDates: string[]) {
    if (originalDates.length < 2) {
        return { format: 'day', dates: originalDates, parseFormat: 'YYYY-MM-DD' };
    }

    const days = originalDates.map(day => dayjs(day));

    const minDate = dayjs.min(...days);
    const maxDate = dayjs.max(...days);

    let diff = maxDate!.diff(minDate, 'day');
    let format = 'day';
    let dates: string[] = [];

    if (diff > 40) {
        format = 'isoWeek';
        diff = maxDate!.diff(minDate, 'week');
    }

    if (diff > 40) {
        format = 'month';
        diff = maxDate!.diff(minDate, 'month');
    }

    if (format == 'day') {
        dates = new Array(diff + 1).fill(1).map((_, index) => minDate!.add(index, 'day').format('YYYY-MM-DD'));
    }
    else if (format == 'isoWeek') {
        dates = new Array(diff + 1).fill(1).map((_, index) => minDate!.add(index, 'week').format('YYYY, W'));
    }
    else {
        dates = new Array(diff + 1).fill(1).map((_, index) => minDate!.add(index, 'month').format('YYYY, M'));
    }

    let parseFormat;
    if (format == 'day') parseFormat = 'YYYY-MM-DD';
    if (format == 'isoWeek') parseFormat = 'YYYY, W';
    if (format == 'month') parseFormat = 'YYYY, M';

    return {
        parseFormat,
        format,
        dates
    };
}

import { OperatorTypeEnum } from '@/dashboard/common/types/filterInterface';

export function filterCampaigns(campaigns, filters) {
    const campaignFilter = filters.find(f => f.dimensionId == 'campaign_id');

    if (campaignFilter) {
        return campaigns.filter(c => {
            switch (campaignFilter.operator) {
                case OperatorTypeEnum.eq:
                    return c.id == campaignFilter.value;
                case OperatorTypeEnum.oneOf:
                    return (campaignFilter.value as Array<string>).includes(c.id!);
                case OperatorTypeEnum.neq:
                    return c.id != campaignFilter.value;
                case OperatorTypeEnum.noneOf:
                    return !(campaignFilter.value as Array<string>).includes(c.id!);
                default:
                    throw new Error('wrong filter operator');
            }
        });
    }

    const waveFilter = filters.find(f => f.dimensionId == 'wave_id');

    if (waveFilter) {
        return campaigns.filter(c => {
            switch (waveFilter.operator) {
                case OperatorTypeEnum.oneOf:
                    return c.waves!.some(w => (waveFilter.value as Array<string>).includes(w.id));
                case OperatorTypeEnum.noneOf:
                    return !c.waves!.some(w => (waveFilter.value as Array<string>).includes(w.id));
                default:
                    throw new Error('wrong filter operator');
            }
        });
    }

    return campaigns;
}
