import { api } from '@/api';
import { CountDistribution, DateDistribution } from '../store/types';
import { Filter } from '@/dashboard/common/types/filterInterface';

interface campaignEventsPayload {
    datasetId: string
    campaignId?: string
    waveId?: string
    metricType?: string
    filters?: Filter[]
}

export const exportRawData = async ({ datasetId, campaignId, waveId, metricType, filters }: campaignEventsPayload) => {
    const params = new URLSearchParams({ datasetId });
    if (campaignId) params.set('campaignId', campaignId);
    if (waveId) params.set('waveId', waveId);
    if (metricType) params.set('metricType', metricType);

    const { data } = await api.post('/campaigns/events/export?' + params.toString(), { filters });

    return data;
};

export const getCountDistribution = async ({ datasetId, campaignId, waveId, filters }: campaignEventsPayload & { filters: Array<Filter> }): Promise<CountDistribution> => {
    const params = new URLSearchParams({ datasetId });
    if (campaignId) params.set('campaignId', campaignId);
    if (waveId) params.set('waveId', waveId);

    const { data } = await api.post('campaigns/events/count-distribution?' + params.toString(), { filters });

    return (Array.isArray(data) && data.length === 0) ? {} : data;
};

export const getDateDistribution = async ({ datasetId, campaignId, waveId, filters }: campaignEventsPayload & { filters: Array<Filter> }): Promise<DateDistribution> => {
    const params = new URLSearchParams({ datasetId });
    if (campaignId) params.set('campaignId', campaignId.toString());
    if (waveId) params.set('waveId', waveId.toString());

    const { data } = await api.post('campaigns/events/date-distribution?' + params.toString(), { filters });

    return (Array.isArray(data) && data.length === 0) ? {} : data;
};
