<template>
    <tooltip>
        <template #default>
            <z-icon
                icon="info-circle"
            />
        </template>
        <template #tooltip>
            <slot />
        </template>
    </tooltip>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ZIcon from '@/components/ui/Icon.vue';
import Tooltip from '@/components/ui/Tooltip.vue';

export default defineComponent({
    components: { ZIcon, Tooltip },
});
</script>
