import { reactive } from 'vue';
import {DisplayTypeEnum, EmbedState, TriggerTypeEnum} from '@/survey/embed/store/types';
import makeActions from '@/survey/embed/store/embedActions';
import makeGetters from '@/survey/embed/store/embedGetters';


export const embedState = reactive<EmbedState>({
    surveyEmbedSettings: [],
    hasUnsavedChanges: false,
    activeSettings: {
        name: '',
        domain: '',
        type: DisplayTypeEnum.POPUP,
        displaySettings: {
            backgroundColor: '#ffffff',
            textColor: '#212529',
            primaryColor: '#1cc6ba',
            showTitle: false,
            title: '',
            borderRadius: '4',
            shadow: false,
            backgroundOverlay: false,
            closeOnSubmit: false,
            removeBranding: false,
            removePrivacyPolicy: false
        },
        urls: [],
        querySelector: '',
        triggers: [{
            type: TriggerTypeEnum.ON_PAGE_VISIT
        }],
        targets: [],
        urlParameters: [],
        delay: 0,
    },
    selectedId: null,
    isActive: true,
    defaultSettings: {
        name: '',
        domain: '',
        type: DisplayTypeEnum.POPUP,
        displaySettings: {
            backgroundColor: '#ffffff',
            textColor: '#212529',
            primaryColor: '#1cc6ba',
            showTitle: false,
            title: '',
            borderRadius: '4',
            shadow: false,
            backgroundOverlay: false,
            closeOnSubmit: false,
            removeBranding: false,
            removePrivacyPolicy: false
        },
        urls: [],
        querySelector: '',
        triggers: [{
            type: TriggerTypeEnum.ON_PAGE_VISIT
        }],
        targets: [],
        urlParameters: [],
        delay: 0,
    }
});

const actions = makeActions(embedState);
const getters = makeGetters(embedState);

export default function useEmbedStore() {
    return {
        state: embedState,
        actions,
        getters
    };
}
