<script lang="ts">
import type { Component } from 'vue';
import type { Campaign } from '@/campaign/types';
import type { PropType, Ref } from 'vue';
import type { AudienceWithSegments } from '@/audience/types';

import { ref, computed, reactive, defineComponent, toRefs } from 'vue';

import PopupSubviewRecipients from '@/survey/components/publish/campaign/popup/views/recipients/Default.vue';
import PopupSubviewSegmentList from '@/survey/components/publish/campaign/popup/views/recipients/SegmentList.vue';
import PopupSubviewAudienceList from '@/survey/components/publish/campaign/popup/views/recipients/AudienceList.vue';
import PopupSubviewReminderFor from '@/survey/components/publish/campaign/popup/views/recipients/ReminderFor.vue';

import { Variable, VariableType, ReminderType } from '@/campaign/types';
import { useCampaigns } from '@/campaign';

export interface NextPayload {
    segmentUid: string | null
    audienceUid: string

    variables?: Variable[]
}

export default defineComponent({
    name: 'Recipients',

    model: {
        prop: 'modelValue',
        event: 'update:modelValue',
    },

    props: {
        modelValue: {
            type: Object as PropType<Campaign>,
            required: true
        },

        audiences: {
            type: Array as PropType<AudienceWithSegments[]>,
            required: true,
        },

        goBackButtonClicked: {
            type: Boolean,
            default: false,
        },

        isEdit: { type: Boolean, default: false },
        isReminder: { type: Boolean, default: false },
    },

    emits: ['update:modelValue', 'show-next', 'set-go-back-to-default'],
    setup: (props, { emit }) => {
        const { isPopupViewOnly, reminderWave } = toRefs(useCampaigns().state);
        const campaign = reactive({ ...props.modelValue });
        const campaignName = computed({
            get: () => campaign.name,
            set: (name) => {
                campaign.name = name;
                emit('update:modelValue', campaign);
            },
        });

        const subview: Ref<Component> = ref(PopupSubviewRecipients);

        function showCampaignNameEditor() {
            subview.value = PopupSubviewRecipients;
        }

        function showNext({ segmentUid, audienceUid, variables }: NextPayload): void {
            campaign.segmentId = segmentUid;
            campaign.audienceId = audienceUid;
            if (variables) {
                campaign.variables = variables;
            }
            emit('update:modelValue', campaign);
            emit('show-next');
        }


        if (
            (isPopupViewOnly.value || props.isEdit) &&
            (props.audiences.length === 1 && !campaign.segmentId || props.audiences.find(a => a.uid === campaign.audienceId)?.segments.length === 1)
        ) {
            showCampaignNameEditor();
        } else if (props.isReminder) {
            subview.value = PopupSubviewReminderFor;
        } else if (campaign.segmentId) {
            handlePagination(PopupSubviewSegmentList);
        } else if (campaign.audienceId) {
            handlePagination(PopupSubviewAudienceList);
        }

        function handlePagination(view) {
            const hasOneAudienceOrOneSegment = props.audiences.length === 1 && view === PopupSubviewAudienceList ||
                props.audiences.length === 1 && props.audiences[0].segments.length === 1 && view === PopupSubviewSegmentList;
            if (props.goBackButtonClicked && hasOneAudienceOrOneSegment) {
                showCampaignNameEditor();
                emit('set-go-back-to-default');
            } else if (!props.goBackButtonClicked && hasOneAudienceOrOneSegment) {
                const audience = props.audiences[0];
                // Only auto-select segment if user clicked on the segment box, otherwise only audience should be auto-selected
                const segment = view === PopupSubviewSegmentList && props.audiences[0].segments.length ? props.audiences[0].segments[0] : null;
                showNext({
                    segmentUid: segment ? segment.id! : null,
                    audienceUid: audience.uid,
                    variables: props.modelValue.variables!.length > 0 ?
                        props.modelValue.variables! :
                        audience.fields.map(f => ({
                            urlUid: null,
                            type: VariableType.Variable,
                            name: f.name,
                            fieldUid: f.id,
                        }))
                });
            } else {
                subview.value = view;
            }
        }

        const setReminderType = async (type: ReminderType) => {
            const newReminderWave = reminderWave.value;
            newReminderWave!.reminderType = type;
            useCampaigns().setReminderWave(newReminderWave);

            emit('show-next');
        };

        return {
            subview,
            campaignName,

            showSegmentSelection: () => {
                if (campaignName.value) {
                    handlePagination(PopupSubviewSegmentList);
                }
            },

            showAudienceSelection: () => {
                if (campaignName.value) {
                    handlePagination(PopupSubviewAudienceList);
                }
            },

            showNext,

            showPrev: () => {
                if (subview.value !== PopupSubviewRecipients) {
                    subview.value = PopupSubviewRecipients;
                }
            },

            setReminderType,
        };
    }
});
</script>
<template>
    <component
        :is="subview"

        v-model="campaignName"

        :campaign="modelValue"
        :audiences="audiences"

        @show-next="showNext"
        @show-prev="showPrev"
        @show-segment-list="showSegmentSelection"
        @show-audience-list="showAudienceSelection"
        @set-reminder-type="setReminderType"
    />
</template>
