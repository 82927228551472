<template>
    <div class="p-2">
        <widget-types
            v-if="widgetQuestions.length > 0"
            ref="widgetTypes"
            :questions="widgetQuestions"
            :customization-settings="customizationSettings"
            @selectedQuestionChanged="changeType"
        />
        <accordion
            data-test-id="customization-part"
            :text="$t('SURVEYFORMS.PUBLISH_EMBED_SETTINGS_CUSTOMIZATION', 'Customization')"
        >
            <widget-customization
                :customization-settings="customizationSettings"
                :type="selectedQuestionType"
                @settingsChanged="changeCustomizationSettings($event)"
            />
        </accordion>
        <div
            v-if="widgetQuestions.length > 0"
            class="text-center mt-2"
        >
            <z-button
                @click="getCode()"
            >
                {{ $t('SURVEYFORMS.PUBLISH_EMBED_SETTINGS_GET_CODE', 'Get code') }}
            </z-button>
        </div>
        <popup
            v-if="getCodePopupVisible"
            is-closable
            :title="$t('SURVEYFORMS.PUBLISH_EMBED_SETTINGS_CODE_SNIPPET', 'Code snippet')"
            @close="getCodePopupVisible = false"
        >
            <code-popup :embed-code="embedCode" />
        </popup>
    </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import WidgetTypes from '@/survey/widget/components/WidgetTypes.vue';
import WidgetCustomization from '@/survey/widget/components/WidgetCustomization.vue';
import Popup from '@/components/ui/Popup.vue';
import CodePopup from '@/survey/widget/components/CodePopup.vue';
import Accordion from '@/components/ui/Accordion.vue';
import {createNamespacedHelpers} from 'vuex-composition-helpers/dist';
import elementTypes from '@/survey/store/utils/elementTypeEnum';
const  { useGetters, useState }  = createNamespacedHelpers('survey');


export default defineComponent({
    components: {WidgetTypes, WidgetCustomization, Popup, CodePopup, Accordion,  },
    setup() {
        const { embeddableElements: widgetQuestions } = useGetters(['embeddableElements']);
        const { text_color, primary_color } = useState(['text_color', 'primary_color']);
        const getCodePopupVisible = ref(false);
        const embedCode = ref('');
        const selectedQuestionType = ref(widgetQuestions.value[0].type);
        const customizationSettings = ref({
            color: primary_color,
            backgroundColor: null,
            textColor: text_color,
            branding: true,
            borderColor: null,
            scaleBorderColor: primary_color,
            iconColor: primary_color,
            ratingStyle: 'solid',
            scaleButtonColor: '#F2F3F8',
            iconSize: 26,
            iconSpacing: 8,
            showTitle: true,
            marginSize: 0,
            showLabels: true,
        });
        const widgetTypes = ref();

        function changeType(newType) {
            selectedQuestionType.value = newType;
            if ([elementTypes.CSAT, elementTypes.SCALE, elementTypes.CES_5_SCALE, elementTypes.CES_7_SCALE].includes(newType)) {
                customizationSettings.value = { ...customizationSettings.value, showLabels: true };
            } else {
                customizationSettings.value = { ...customizationSettings.value, showLabels: false };
            }
        }

        async function changeCustomizationSettings (newSetting) {
            customizationSettings.value = {
                ...customizationSettings.value,
                [newSetting.type]: newSetting.value
            };
        }

        const getCode = () => {
            embedCode.value = widgetTypes.value.$refs['widget'].innerHTML;
            getCodePopupVisible.value = true;
        };

        return {
            widgetTypes,
            widgetQuestions,
            getCodePopupVisible,
            customizationSettings,
            selectedQuestionType,
            embedCode,
            changeCustomizationSettings,
            changeType,
            getCode,
        };
    }
});

</script>

