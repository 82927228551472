<template>
    <div class="flex flex-col text-default space-y-4">
        <scrollbar class="flex-grow flex-shrink">
            <main class="space-y-4">
                <div
                    class="space-y-4"
                    v-html="text1"
                />
                <div class="flex space-x-2 items-center">
                    <span>{{ $t('AUDIENCE.CAMPAIGN_ID', 'Campaign ID') }}:</span>
                    <copy-url-container
                        :url="campaign.id || 'id not found'"
                    />
                </div>
                <div
                    class="space-y-2"
                    v-html="text2"
                />
                <div class="flex">
                    <copy-url-container
                        method="GET"
                        :url="'api.zurvey.io/v1/campaigns/' + campaign.id"
                    />
                </div>
                <div
                    class="space-y-2"
                    v-html="text3"
                />
                <div class="flex">
                    <copy-url-container
                        method="POST"
                        :url="'api.zurvey.io/v1/campaigns/' + campaign.id + '/trigger'"
                    />
                </div>
                <div>
                    {{ $t('AUDIENCE.TRIGGER_API_SETTINGS_TEXT4', 'Make sure the campaign is activated (the toggle is switched on in the campaign list) before triggering!') }}
                </div>
            </main>
        </scrollbar>
        <div class="self-center space-x-2">
            <z-button
                variant="outline"
                @click="$emit('show-prev')"
            >
                {{ $t('AUDIENCE.BUTTON_BACK', 'Go Back') }}
            </z-button>
            <z-button
                @click="$emit('close')"
            >
                {{ $t('AUDIENCE.TRIGGER_API_SETTINGS_ALL_SET_EXIT', 'All set, exit') }}
            </z-button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

import CopyUrlContainer from '@/components/ui/CopyUrlContainter.vue';
import ZButton from '@/common/components/design-system/Button.vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';

import { Campaign } from '@/campaign/types';

import i18next from 'i18next';

export default defineComponent({
    components: {
        CopyUrlContainer,
        ZButton,
        Scrollbar,
    },
    props: {
        campaign: { type: Object as () => Campaign, required: true },
    },
    setup() {
        const text1 = computed(() => i18next.t('AUDIENCE.TRIGGER_API_SETTINGS_TEXT1', {
            tokenUrl: '/settings/apitokens',
            docUrl: 'https://api.zurvey.io/v1/docs',
            defaultValue: `
            <h1>Trigger campaign via API</h1>
            <p>Find here all the necessary information and credentials, which are needed to trigger campaign sending via API.</p>
            <ol><li>Authentication and Identification</li></ol>
            <p>All API actions in Zurvey.io are authenticated using bearer tokens. To create and manage your tokens check <a href="{{tokenUrl}}">Settings/API tokens</a>.<p>
            <p>Utilize the Campaign ID for trigger action. Check our <a href="{{docUrl}}">API documentation</a> for request and response samples.</p>
            `,
        }));

        const text2 = computed(() => i18next.t('AUDIENCE.TRIGGER_API_SETTINGS_TEXT2', {
            defaultValue: `
            <ol><li>Retrieve Campaign Details</li></ol>
            <p>Obtain Campaign Details including metrics like <i>Last Submitted at</i> date, or <i>Completion rate</i>.<p>
            `,
        }));

        const text3 = computed(() => i18next.t('AUDIENCE.TRIGGER_API_SETTINGS_TEXT3', {
            defaultValue: `
            <ol><li>Trigger Campaigns</li></ol>
            <p>Trigger campaign sending using the dedicated endpoint below<p>
            `,
        }));

        return { text1, text2, text3 };
    }
});
</script>

<style scoped lang="less">
/deep/h1 {
    font-weight: bold;
}
main {
  counter-reset: count;
}
/deep/ol li {
  counter-increment: count;
}
/deep/ol ::marker {
  content: counter(count) ". ";
}
/deep/ol {
    list-style-type: decimal;
    font-weight: bold;
    padding-left: 1.5em;
}
/deep/ul {
    list-style-type: disc;
    padding-left: 1.5em;
}
/deep/a {
    text-decoration: underline;
}
</style>
