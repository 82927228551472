<template>
    <section
        class="grid items-center border-b border-neutral-500 bg-neutral-100 text-neutral-800"
        :style="{
            'grid-template-columns': 'subgrid',
            'grid-column-start': 'span 7'
        }"
    >
        <div class="self-stretch border-r border-neutral-500" />
        <div class="pl-8">
            {{ $t('AUDIENCE.SCHEDULED', 'Scheduled') }}
            {{ $t('AUDIENCE.WAVE', 'Wave') }}{{ index + 1 }}
            {{ $t('AUDIENCE.WAVE_REMINDER', 'Reminder') }}
            -
            {{ dayjs(schedule.nextSendAt).format('YYYY.MM.DD. HH:mm') }}
        </div>
        <div>{{ getReminderTypeDisplayName(schedule.reminderType) }}</div>
        <div>
            <TypeIcon
                type="scheduled-for-later"
            />
        </div>
        <div>-</div>
        <div>-</div>
        <div>
            <Action
                :isReminder="true"
                :isScheduled="true"
                @click="action($event)"
            />
        </div>
    </section>
</template>

<script lang=ts>
import { defineComponent } from 'vue';
import TypeIcon from './CampaignTypeIcon.vue';
import Action from './WaveListItemAction.vue';
import { getReminderTypeDisplayName, Schedule } from '@/campaign/types';
import dayjs from 'dayjs';
import { WaveAction } from './CampaignList.vue';

export default defineComponent({
    components: { TypeIcon, Action },
    props: {
        index: { type: Number, required: true },
        schedule: { type: Object as () => Schedule, required: true },
    },
    emits: ['wave-action'],
    setup(props, context) {
        function action(action) {
            const payload: WaveAction = {
                type: action,
                wave: {
                    id: props.schedule.parentWaveId!,
                    campaignId: props.schedule.campaignId,
                    type: 'scheduled_reminder',
                    isRecurring: false,
                    reminderType: props.schedule.reminderType!
                },
            };
            context.emit('wave-action', payload);
        }

        return { action };
    },
    methods: { dayjs, getReminderTypeDisplayName },
});
</script>

<style scoped>
section.grid > div {
    @apply px-2 py-4
}
</style>
