<script lang="ts">
import type { Campaign } from '@/campaign/types';
import type { PropType } from 'vue';
import type { AudienceWithSegments } from '@/audience/types';

import { defineComponent } from 'vue';

import ZIcon from '@/components/ui/Icon.vue';

/* eslint-disable */
export interface EmitCallback <T = never> {
    (event: string, payload?: T|string): void;
}

export interface Button {
    icon: string;
    title: string;
    summary: string;
    footnote?: string;
    action: (emit: EmitCallback) => void;
    isDisabled: (campaign: Campaign, audiences: AudienceWithSegments[]) => boolean;
}
/* eslint-enable */

export default defineComponent({
    name: 'ButtonList',

    components: { ZIcon },

    props: {
        campaign: {
            type: Object as PropType<Campaign>,
            required: false,
            default: () => null,
        },

        audiences: {
            type: Array as PropType<AudienceWithSegments[]>,
            required: false,
            default: () => [],
        },

        buttons: {
            type: Array as PropType<Button[]>,
            required: true,
        },

        selectedButtonIndex: {
            type: Number,
            required: true,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
        loading: { type: Boolean, default: false },
    },

    emits: ['select-index'],
});
</script>
<template>
    <section class="flex flex-row mt-4">
        <template v-if="loading">
            <div
                v-for="(_, index) in buttons"
                :key="index"
                class="w-full bg-neutral-200 mx-2 h-64 animate-pulse"
            />
        </template>
        <template v-else>
            <button
                v-for="(button, index) in buttons"
                :key="index"
                :disabled="disabled || button.isDisabled(campaign, audiences)"
                class="relative overflow-hidden flex flex-col p-4 mx-2 w-xs items-center rounded border outline-none disabled:text-neutral-700 disabled:pointer-events-none"
                :class="[
                    (selectedButtonIndex === index)
                        ? 'border-primary-700 bg-active'
                        : 'border-neutral-500 hover:border-primary-700'
                ]"
                @click="$emit('select-index', index)"
            >
                <figure class="flex flex-col items-center h-full">
                    <z-icon
                        type="fat"
                        :icon="button.icon"
                        class="text-5xl font-hairline"
                        :class="{ 'text-primary-700': !disabled && !button.isDisabled(campaign, audiences) }"
                    />
                    <span class="mt-2 font-bold">
                        {{ button.title }}
                    </span>
                    <figcaption class="flex flex-col justify-between h-full text-left font-hairline">
                        <span class="mt-2 text-sm">
                            {{ button.summary }}
                        </span>
                        <span
                            v-if="button.footnote"
                            class="mt-4 text-xs text-neutral-700"
                        >
                            {{ button.footnote }}
                        </span>
                    </figcaption>
                </figure>
            </button>
        </template>
    </section>
</template>
<style scoped lang="postcss">
/*
NOTE(adam): The primary-100 is too colorful to be used for the background. If we
were to save CSS color variables in the form of  `--variable-name: BA, DA, 55;`,
we could use the `rgba(var(--variable-name), .5);` trick, and even construct a
composable class in tailwind.config.
*/
.bg-active::before {
    content: '';
    opacity: .12;
    @apply block absolute w-full h-full inset-0 bg-primary-700 pointer-events-none;
}
</style>
