import * as XLSX from 'xlsx';

interface ExcelWriterData {
    fileName: string,
    headers: string[] | { [key: string]: string[] },
    data: {
        [sheetName: string]: any[]
    }
}

export default ({ fileName, headers, data }: ExcelWriterData): void => {
    const workbook = XLSX.utils.book_new();

    for (const name in data) {
        const sheetHeaders = Array.isArray(headers) ? headers : headers[name];

        const workSheet = XLSX.utils.aoa_to_sheet([sheetHeaders, ...data[name]]);

        XLSX.utils.book_append_sheet(workbook, workSheet, name);
    }

    /* output format determined by filename */

    XLSX.writeFile(workbook, fileName);
    /* at this point, the file will have been downloaded */
};
