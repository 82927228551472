<script lang="ts">
import type { PropType } from 'vue';

import { FontAwesomeIcon } from '@/utils/icons';
import { ref, watch, computed, defineComponent } from 'vue';

import i18next from 'i18next';

// NOTE(adam): We can use the user's language here if we want. By default, it
// will use the browser's language.
const collator = Intl.Collator();
const ascending = (left: unknown, right: unknown): number => {
    // NOTE(adam): When the two values are of different types, we will
    // cast them to strings - this could be confusing when sorting objects
    // with overwritten Symbol.toStringTags, but will still yield the most
    // predictable results without having to handle an explosive number of
    // cases.
    if (typeof left !== typeof right) {
        return collator.compare('' + left, '' + right);
    }

    if (typeof left === 'string') {
        return collator.compare(left, right as string);
    }

    if (typeof left === 'number') {
        return left - (right as number);
    }

    if (typeof left === 'bigint') {
        return Number(left - (right as bigint));
    }

    if (typeof left === 'boolean') {
        return left ? 1 : -1;
    }

    if (typeof left === 'object') {
        if (Array.isArray(left)) {
            return left.length - (right as any[]).length;
        }

        // NOTE(adam): By the same token as above, we will cast objects to strings to compare them.
        return collator.compare('' + left, '' + right);
    }

    if (typeof left === 'function') {
        // NOTE(adam): Sorting by function name, and when they are the same
        // (eg. "anonymous"), by their arity.
        return collator.compare(left.name, (right as Function).name) || left.length - (right as Function).length;
    }

    if (typeof left === 'symbol') {
        return collator.compare(left.description ?? '', (right as symbol).description ?? '');
    }

    return 0;
};

const sortDataSource = <T extends {}> (dataSource: T[], sortBy: string, sortDirection: SortDirection): T[] => {
    const sorted = dataSource.sort((left, right) => ascending(left[sortBy], right[sortBy]));
    // PERF(adam): Fortunately, `reverse()` sorts in-place, so using it is an
    // acceptable compromise compared to having to re-map the result of
    // `collator.compare()` to achieve descending order.
    return (sortDirection === SortDirection.Ascending)
        ? sorted
        : sorted.reverse();
};

// NOTE(adam): I disabled eslint because it ges confused, and thinks that the
// enum fields are not used, when they clearly are, multiple times.
export enum SortDirection {
    // eslint-disable-next-line
    Ascending,
    // eslint-disable-next-line
    Descending,
}

const toggleSortDirection = (direction: SortDirection) =>
    (direction === SortDirection.Descending)
        ? SortDirection.Ascending
        : SortDirection.Descending;

const WATCH_IMMEDIATE = { immediate: true };

export default defineComponent({
    name: 'DataTable',

    components: { FontAwesomeIcon },

    model: {
        prop: 'modelValue',
        event: 'update:modelValue',
    },

    props: {
        /**
         * A property to tell the datatable which property to use as rendering key.
         */
        useKey: {
            type: String as PropType<string>,
            default: '',
        },

        dataSource: {
            type: Array as PropType<object[]>,
            required: true,
        },

        searchBy: {
            type: String as PropType<string>,
            required: true,
        },

        sortBy: {
            type: String as PropType<string>,
            default: '',
        },

        sortDirection: {
            type: Number as PropType<SortDirection>,
            default: SortDirection.Ascending,
        },

        labels: {
            type: Array as PropType<string[]>,
            required: true,
        },

        columnKeys: {
            type: Array as PropType<string[]>,
            required: true,
        },

        modelValue: {
            type: Object,
            default: null,
        },

        /* eslint-disable */
        formatters: {
            type: Object as PropType<Record<string, ((row: any, key: any) => string)>>,
            default: () => ({}),
        },
        /* eslint-disable */

        deletes: {
            type: Boolean,
            default: false,
        },

        searchLabel: {
            type: String,
            required: false,
        },

        searchPlaceholder: {
            type: String,
            default: () => i18next.t('DATATABLE.SEARCH_PLACEHOLDER', 'Type to search (minimum 3 characters)...'),
        },

        columnWidths: {
            type: Array as PropType<string[]>,
            default: null,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
        sticky: { type: Boolean, default: false },
    },

    emits: ['update:modelValue', 'delete'],

    setup: (props) => {
        const searchTerm = ref('');
        const searchBy = props.searchBy;

        let lastSortBy = props.sortBy || props.columnKeys[0];
        let lastSortDirection = props.sortDirection;

        const sortedDataSource = ref<object[]>([]);
        const updateDataSource = (dataSource: object[]): void => {
            // PERF(adam): We want to play nice, and not mutate the original
            // data (hence the `.slice()` call), but moving forward, every
            // operation will mutate the `sordedDataSource` array.
            sortedDataSource.value = sortDataSource(dataSource.slice(), lastSortBy, lastSortDirection);
        };

        watch(() => props.dataSource, updateDataSource, WATCH_IMMEDIATE);

        const sortingField = ref(lastSortBy);
        const sortingDirection = ref(lastSortDirection);

        const data = computed(() => {
            const term = searchTerm.value.toLowerCase();

            if (term.length < 3) {
                return sortedDataSource.value;
            }

            return sortedDataSource.value.filter((data) => {
                return data[searchBy].toLowerCase().includes(term);
            });
        });

        const showSearchbar = computed(() => props.dataSource.length > 1 && !props.disabled);

        return {
            data,
            searchTerm,

            sortingField,
            sortingDirection,

            toggleSortDirection,

            sort: (sortBy: string, sortDirection = SortDirection.Ascending) => {
                if (lastSortBy === sortBy) {
                    if (lastSortDirection === sortDirection) return;

                    // NOTE(adam): Since the SortDirection enum is binary, and
                    // the property to sort by is the same, we can spare the
                    // sort operation and just reverse the array.
                    sortedDataSource.value = sortedDataSource.value.reverse();
                }
                else {
                    sortedDataSource.value = sortDataSource(sortedDataSource.value, sortBy, sortDirection = SortDirection.Ascending);
                }

                sortingField.value     = lastSortBy        = sortBy;
                sortingDirection.value = lastSortDirection = sortDirection;
            },
            showSearchbar,
        };
    }
});
</script>
<template>
    <div class="flex flex-col">
        <section
            class="flex flex-row w-full pb-4"
            :class="{
                'sticky top-0 bg-white z-10': sticky,
            }"
        >
            <slot name="before-search" />
            <section
                v-if="showSearchbar"
                class="flex flex-col w-full"
            >
                <label
                v-if="searchLabel"
                class="font-bold text-sm"
                >
                    {{ searchLabel }}
                </label>
                <z-input
                    v-model="searchTerm"
                    class="w-full"
                    :placeholder="searchPlaceholder"
                >
                    <template #startIcon>
                        <font-awesome-icon
                            :icon="['fal', 'search']"
                            class="fa-icons search"
                        />
                    </template>
                </z-input>
            </section>
            <slot name="after-search" />
        </section>
        <table class="table-auto select-none">
            <thead class="border-b">
                <slot name="headers">
                    <tr>
                        <th
                            v-for="(label, index) in labels"
                            :key="label"
                            class="px-4 py-2 text-sm text-left cursor-pointer select-none"
                            :class="[!!columnWidths && columnWidths[index] ? columnWidths[index] : '', { 'sticky bg-white z-10': sticky && showSearchbar, }]"
                            :style="{
                                top: sticky && showSearchbar ? '48px' : undefined
                            }"
                            @click="!disabled && sort(columnKeys[index], toggleSortDirection(sortingDirection))"
                        >
                            <div class="flex flex-row justify-between items-center">
                                <span class="inline-block">{{ label }}</span>

                                <section class="w-1">
                                    <font-awesome-icon
                                        v-show="sortingField === columnKeys[index] && sortingDirection === 0"
                                        :icon="['fa', 'sort-up']"
                                        class="fa-icons sort-up"
                                    />
                                    <font-awesome-icon
                                        v-show="sortingField === columnKeys[index] && sortingDirection === 1"
                                        :icon="['fa', 'sort-down']"
                                        class="fa-icons sort-down"
                                    />
                                </section>
                            </div>
                        </th>
                        <th
                            v-if="deletes"
                            :class="{
                                'sticky bg-white z-10': sticky && showSearchbar
                            }"
                            :style="{
                                top: sticky && showSearchbar ? '48px' : undefined
                            }"
                        ></th>
                    </tr>
                </slot>
            </thead>
            <slot :data="data">
                <tbody>
                    <tr
                        v-for="row in data"
                        :key="useKey ? row[useKey] : row[columnKeys[0]]"
                        class="relative border-b border-neutral-500"
                        :class="{
                            'bg-active border-b border-primary-700': modelValue === row,
                            'cursor-default text-neutral-800' : disabled,
                            'cursor-pointer' : !disabled
                        }"

                        @click="!disabled && $emit('update:modelValue', row === modelValue ? null : row)"
                    >
                        <td
                            v-for="columnKey in columnKeys"
                            :key="columnKey"
                            class="px-4 py-2 text-sm text-left capitalize truncate"
                        >
                            <!--
                                TODO(adam): Update to Vite to be able to use the "??" operator - this now renders '-'
                                for `0` incorrectly.
                            -->
                            {{
                                (columnKey in formatters)
                                    ? formatters[columnKey](row, columnKey)
                                    : row[columnKey] || '-'
                            }}
                        </td>
                        <td v-if="deletes">
                            <font-awesome-icon
                                :icon="['fal', 'trash']"
                                class="fa-icons trash text-red-500 cursor-pointer"
                                :class="disabled ? 'text-red-800' : 'text-red-500'"
                                @click.stop="!disabled && $emit('delete', row)"
                            />
                        </td>
                    </tr>
                    <tr v-if="!data.length">
                        <td
                            :colspan="labels.length"
                            class="px-4 py-2 text-sm text-center capitalize truncate text-neutral-700"
                        >
                            {{ $t('DATATABLE.NO_DATA', 'There is no data to show.') }}
                        </td>
                    </tr>
                </tbody>
            </slot>
        </table>
    </div>
</template>
<style scoped lang="postcss">
/*
NOTE(adam): The primary-100 is too colorful to be used for the background. If we
were to save CSS color variables in the form of  `--variable-name: BA, DA, 55;`,
we could use the `rgba(var(--variable-name), .5);` trick, and even construct a
composable class in tailwind.config.
*/
.bg-active::after {
    content: '';
    opacity: .12;
    margin-top: 1px;
    @apply block absolute w-full h-full inset-0 bg-primary-700;
}
</style>
