<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'EmptyCampaignList',
    components: {

    },
});
</script>
<template>
    <div class="flex flex-col items-center w-1/2 mx-auto mt-4">
        <img
            class="m-auto mb-2"
            src="@/assets/images/empty_dog.png"
            style="width: 290px; max-height: unset; height: 290px;"
        >
        <z-button @click="$emit('open-popup')">
            {{ $t('AUDIENCE.BUTTON_CREATE_NEW_CAMPAIGN', 'Create new campaign') }}
        </z-button>
        <section class="mt-2 text-center text-sm font-light text-neutral-700">
            {{ $t('AUDIENCE.EMPTY_CAMPAIGN_LIST_DESCRIPTION', 'You have no campaigns created yet.') }}
        </section>
    </div>
</template>
