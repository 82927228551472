<template>
    <popup
        has-backdrop
        is-closable
        sticky-header
        :title="campaign.name + ' - ' + $t('CAMPAIGN_METRICS.POPUP_TITLE.METRICS', 'Metrics')"
        @close="$emit('close')"
    >
        <div class="w-5xl space-y-4">
            <template v-if="loading">
                <Loading
                    v-for="index in 2"
                    :key="index"
                    inner-class="h-80"
                />
            </template>
            <template v-else-if="noData">
                <NoData />
            </template>
            <template v-else>
                <metric-rates
                    campaign-metrics
                    :waves="waves"
                />
                <metrics-by-wave :waves="waves" />
            </template>
        </div>
        <template #popupActions>
            <PopupExportButton
                :disabled="noData"
                class="border-r border-neutral-500 pr-2 mr-2"
                @click="exportData()"
            />
        </template>
    </popup>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, onUnmounted, ref, computed } from 'vue';
import Popup from '@/components/ui/Popup.vue';
import PopupExportButton from '../PopupExportButton.vue';
import Loading from '../Loading.vue';
import MetricRates from '../charts/MetricRates.vue';
import MetricsByWave from './MetricsByWave.vue';
import { actions, noData } from '../../store/index';
import { toaster } from '@/utils/toaster';
import i18next from 'i18next';
import { useRoute } from 'vue-router/composables';
import NoData from '../NoData.vue';
import { Campaign } from '@/campaign/types';

export interface MappedWave {
    id: string,
    name: string,
}

export default defineComponent({
    components: { Popup, PopupExportButton, Loading, MetricRates, MetricsByWave, NoData },
    props: {
        campaign: { type: Object as () => Campaign, required: true },
    },
    setup(props, context) {
        const route = useRoute();
        const loading = ref(false);

        onBeforeMount(async () => {
            try {
                loading.value = true;
                await actions.loadChartData({ datasetId: route.params.id, campaignId: props.campaign.id });
            }
            catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG'));
                context.emit('close');
            }
            finally {
                loading.value = false;
            }
        });

        onUnmounted(() => actions.resetCampaignMetricsState());

        const exportData = async () => {
            try {
                await actions.exportRawData({ datasetId: route.params.id, campaignId: props.campaign.id });
                toaster.success(i18next.t('AUDIENCE.CAMPAIGN_EXPORT_UNDER_GENERATION', 'Your export is under generation and will be sent in email.'));
            }
            catch (e: any) {
                if (e.response && e.response.status == 409) {
                    toaster.success(i18next.t('AUDIENCE.CAMPAIGN_EXPORT_UNDER_GENERATION', 'Your export is under generation and will be sent in email.'));
                }
                else {
                    Sentry.captureException(e);
                    toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG'));
                }
            }
        };

        const waves = computed<MappedWave[]>(() => {
            if (!props.campaign.waves) return [];

            const mappedWaves = props.campaign.waves
                .filter(wave => !wave.parentWaveId)
                .map((wave, index) => ({ id: wave.id, name: i18next.t('AUDIENCE.WAVE', 'Wave') + (index + 1) }));

            props.campaign.waves
                .filter(wave => wave.parentWaveId)
                .forEach(wave => {
                    const parentWaveIndex = mappedWaves.findIndex(p => +p.id == wave.parentWaveId);

                    mappedWaves.splice(parentWaveIndex + 1, 0, {
                        id: wave.id,
                        name: mappedWaves[parentWaveIndex].name + ' ' + i18next.t('AUDIENCE.WAVE_REMINDER', 'Reminder')
                    });
                });

            return mappedWaves;
        });

        return { loading, exportData, noData, waves };
    }
});
</script>
