<template>
    <div>
        <svg
            :width="width"
            :height="height"
            viewBox="0 0 210 138"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mt-1 mb-1 mr-auto ml-auto"
        >
            <rect
                x="0.5"
                y="0.5"
                width="209"
                height="137"
                rx="7.5"
                :fill="embedType === 'popup' ? 'var(--color-primary-700)': '#F2F3F8'"
                stroke="#D8DCE8"
            />
            <g filter="url(#filter0_d)">
                <rect
                    x="55"
                    y="29"
                    width="100"
                    height="80"
                    rx="4"
                    fill="white"
                />
            </g>
            <path
                d="M70.0586 64.6016L68.7891 67.1992L65.918 67.6094C65.4102 67.6875 65.2148 68.3125 65.5859 68.6836L67.6367 70.6953L67.1484 73.5273C67.0703 74.0352 67.6172 74.4258 68.0664 74.1914L70.625 72.8438L73.1641 74.1914C73.6133 74.4258 74.1602 74.0352 74.082 73.5273L73.5938 70.6953L75.6445 68.6836C76.0156 68.3125 75.8203 67.6875 75.3125 67.6094L72.4609 67.1992L71.1719 64.6016C70.957 64.1523 70.293 64.1328 70.0586 64.6016Z"
                :fill="embedType === 'popup' ? 'var(--color-primary-700)': '#9FA7BC'"
            />
            <path
                d="M87.0586 64.6016L85.7891 67.1992L82.918 67.6094C82.4102 67.6875 82.2148 68.3125 82.5859 68.6836L84.6367 70.6953L84.1484 73.5273C84.0703 74.0352 84.6172 74.4258 85.0664 74.1914L87.625 72.8438L90.1641 74.1914C90.6133 74.4258 91.1602 74.0352 91.082 73.5273L90.5938 70.6953L92.6445 68.6836C93.0156 68.3125 92.8203 67.6875 92.3125 67.6094L89.4609 67.1992L88.1719 64.6016C87.957 64.1523 87.293 64.1328 87.0586 64.6016Z"
                :fill="embedType === 'popup' ? 'var(--color-primary-700)': '#9FA7BC'"
            />
            <path
                d="M104.059 64.6016L102.789 67.1992L99.918 67.6094C99.4102 67.6875 99.2148 68.3125 99.5859 68.6836L101.637 70.6953L101.148 73.5273C101.07 74.0352 101.617 74.4258 102.066 74.1914L104.625 72.8438L107.164 74.1914C107.613 74.4258 108.16 74.0352 108.082 73.5273L107.594 70.6953L109.645 68.6836C110.016 68.3125 109.82 67.6875 109.312 67.6094L106.461 67.1992L105.172 64.6016C104.957 64.1523 104.293 64.1328 104.059 64.6016Z"
                :fill="embedType === 'popup' ? 'var(--color-primary-700)': '#9FA7BC'"
            />
            <path
                d="M121.059 64.6016L119.789 67.1992L116.918 67.6094C116.41 67.6875 116.215 68.3125 116.586 68.6836L118.637 70.6953L118.148 73.5273C118.07 74.0352 118.617 74.4258 119.066 74.1914L121.625 72.8438L124.164 74.1914C124.613 74.4258 125.16 74.0352 125.082 73.5273L124.594 70.6953L126.645 68.6836C127.016 68.3125 126.82 67.6875 126.312 67.6094L123.461 67.1992L122.172 64.6016C121.957 64.1523 121.293 64.1328 121.059 64.6016Z"
                fill="#D8DCE8"
            />
            <path
                d="M138.059 64.6016L136.789 67.1992L133.918 67.6094C133.41 67.6875 133.215 68.3125 133.586 68.6836L135.637 70.6953L135.148 73.5273C135.07 74.0352 135.617 74.4258 136.066 74.1914L138.625 72.8438L141.164 74.1914C141.613 74.4258 142.16 74.0352 142.082 73.5273L141.594 70.6953L143.645 68.6836C144.016 68.3125 143.82 67.6875 143.312 67.6094L140.461 67.1992L139.172 64.6016C138.957 64.1523 138.293 64.1328 138.059 64.6016Z"
                fill="#D8DCE8"
            />
            <rect
                x="65"
                y="56"
                width="24"
                height="3"
                fill="#424454"
            />
            <rect
                x="92"
                y="95"
                width="26"
                height="8"
                rx="4"
                :fill="embedType === 'popup' ? 'var(--color-primary-700)': '#9FA7BC'"
            />
            <rect
                x="99"
                y="98"
                width="12"
                height="2"
                fill="white"
            />
            <defs>
                <filter
                    id="filter0_d"
                    x="41"
                    y="19"
                    width="128"
                    height="108"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.513726 0 0 0 0 0.572549 0 0 0 0 0.647059 0 0 0 0.45 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        embedType: { type: String, default: null },
        width: { type: Number, default: 210 },
        height: { type: Number, default: 138 },
    },
};
</script>

<style scoped>

</style>
