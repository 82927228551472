<template>
    <div class="text-default relative">
        <div
            class="p-2 text-white flex items-center select-none space-x-2"
            :class="{
                'bg-primary-700 cursor-pointer': !disabled,
                'bg-neutral-300 text-neutral-600 cursor-not-allowed': disabled,
                'rounded-b': !open || (open && direction === 'up'),
                'rounded-t': !open || (open && direction === 'down'),
            }"
            @click="!disabled && (open = !open)"
        >
            <div class="flex-grow truncate">
                {{ selectedLabel }}
            </div>
            <div>
                <icon :icon="open ? 'chevron-up' : 'chevron-down'" />
            </div>
        </div>
        <div
            v-if="open"
            v-on-clickaway="() => open = false"
            class="absolute bg-white z-10 border border-primary-700 w-full"
            :class="{
                'rounded-b border-t-0': direction === 'down',
                'rounded-t border-b-0': direction === 'up'
            }"
            :style="{
                bottom: direction === 'up' && '100%'
            }"
        >
            <div
                v-for="option in options"
                :key="option.value"
                class="p-2 hover:bg-primary-300 cursor-pointer last:rounded-b truncate"
                @click="$emit('input', option.value); open = false"
            >
                {{ option.label }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, defineComponent, computed } from 'vue';
import { directive as onClickaway } from 'vue-clickaway2';
import Icon from '@/components/ui/Icon.vue';

export default defineComponent({
    name: 'DropdownButton',
    components: { Icon },
    directives: { onClickaway },
    props: {
        value: { type: [String, Number], required: true },
        options: { type: Array as () => Array<{ value: string|number, label: string}>, required: true },
        disabled: { type: Boolean, default: false },
        direction: { type: String as () => 'up'|'down', default: 'down' }
    },
    emits: ['input'],
    setup(props) {
        const open = ref(false);
        const selectedLabel = computed(() => props.options.find(o => o.value === props.value)?.label);

        return { open, selectedLabel };
    }
});
</script>
