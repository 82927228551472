<template>
    <div>
        <label
            v-if="$slots.label || label"
            class="text-default block w-full mb-2 text-black"
        >
            <slot name="label">
                {{ label }}
            </slot>
        </label>
        <div v-if="$slots.tags">
            <slot name="tags" />
        </div>
        <div class="flex items-center relative">
            <span class="absolute mx-2">
                <slot name="icon" />
            </span>
            <input
                v-if="!$slots.result"
                ref="input"
                :data-test-id="dataTestId"
                :value="modelValue"
                :type="isDateAndTime ? 'datetime-local' : 'date'"
                :placeholder="placeholder"
                v-bind="$attrs"
                class="block flex-1 max-w-full rounded p-1.5 text-black text-default placeholder-neutral-700 disabled:opacity-75 disabled:cursor-not-allowed outline-none border"
                :class="{ 'border-red-500': !!errorMessage, 'pl-6': !!$slots.icon, 'border-neutral-800': !isActive && !isHovered }"
                :style="boxStyle"
                :step="isDateAndTime ? 1 : null"
                :min="min"
                @blur="input"
                @mouseover="isHovered = true"
                @mouseleave="isHovered = false"
                @focus="isActive = true"
                @focusout="isActive = false"
                v-on="listeners"
            >
            <div
                v-else
                class="block flex-1 max-w-full rounded p-2 text-black text-default placeholder-neutral-700 disabled:opacity-75 disabled:cursor-not-allowed outline-none border"
                :class="{ 'border-red-500': !!errorMessage, 'pl-6': !!$slots.icon, 'border-neutral-800': !isActive && !isHovered }"
            >
                <slot name="result" />
            </div>
        </div>
        <div
            v-if="isOptional"
            class="text-default text-neutral-700 text-right"
        >
            ({{ $t('GLOBAL.OPTIONAL', 'optional') }})
        </div>
        <span
            v-if="$slots.errorMessage || errorMessage"
            class="text-default text-red-500"
        >
            <slot name="errorMessage">
                {{ errorMessage }}
            </slot>
        </span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getBackgroundColor, getPrimaryColor, getTextColor } from '@/utils/theme';

export default defineComponent({
    model: {
        prop: 'modelValue',
        event: 'update:modelValue'
    },
    props:  {
        label: { type: String, default: null },
        placeholder: { type: String, default: null },
        errorMessage: { type: String, default: null },
        isOptional: { type: Boolean, default: false },
        modelValue: { type: [String,Number], default: null },
        textColor: { type: String, default: getTextColor },
        primaryColor: { type: String, default: getPrimaryColor },
        backgroundColor: { type: String, default: getBackgroundColor },
        dataTestId: { type: String, default: null },
        isDateAndTime: { type: Boolean, default: false },
        min: { type: String, default: undefined }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            isHovered: false,
            isActive: false
        };
    },
    computed: {
        listeners() {
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            const { input, ...listeners } = this.$listeners;
            return listeners;
        },
        boxStyle() {
            if  (this.isActive || this.isHovered) {
                return {
                    'background-color': this.backgroundColor,
                    'color': this.textColor,
                    'border-color': this.textColor // default N1100 black
                };
            }
            return {
                'background-color': this.backgroundColor,
                'color': this.textColor
            };
        },
    },
    methods: {
        input(event) {
            this.$emit('update:modelValue', event.target.value);
        },
        focus() {
            // @ts-ignore
            this.$refs.input.focus();
        }
    },
});
</script>
