<template>
    <div>
        <info-box
            v-if="urlParameters.length > 0"
            style="width: 600px;"
            class="text-sm mb-3"
        >
            {{ $t('SURVEYFORMS.PUBLISH_COPY_SOURCE_CODE_PARAM_INFO', 'The code snippet of this HTML widget contains the URL parameters added to this survey. We applied placeholders for URL parameter values, make them easily replaceable with your own data. Placeholder values are in between \{\{\}\}, you can find them in the following format &urlParamName=\{\{urlParamName\}\}. \{\{\}\} are only used for highlighting, should not be applied in actual use. ') }}
        </info-box>
        <div v-if="embedCode !== ''">
            <scrollbar
                ref="embedVisibleCodeContainer"
                class="block bg-neutral-1000 text-white rounded mb-2 p-2 text-sm"
                style="width: 600px; max-height: 120px;"
                stop-propagation
            >
                {{ embedCode }}
            </scrollbar>
        </div>
        <input
            ref="embedCodeContainer"
            type="hidden"
            :value="embedCode"
        >
        <span
            class="copy-text text-primary-700 text-xs cursor-pointer"
            @click="copyEmbedCode"
        >
            <span class="copy-icon">
                <icon icon="copy" />
            </span> {{ $t('SURVEYFORMS.PUBLISH_COPY_SOURCE_CODE', 'Copy source code') }}
        </span>
        <span
            class="copy-text text-primary-700 cursor-pointer text-xs"
            @click="downloadHtml"
        >
            <span class="copy-icon ">
                <icon icon="download" />
            </span> {{ $t('SURVEYFORMS.PUBLISH_EMBED_DOWNLOAD_AS_HTML', 'Download as HTML') }}
        </span>
    </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';
import { toaster } from '@/utils/toaster';
import i18next from 'i18next';
import Icon from '@/components/ui/Icon.vue';
import InfoBox from '@/components/ui/InfoBox.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers/dist';

export default defineComponent({
    components: {
        InfoBox,
        Scrollbar,
        Icon
    },
    props: {
        embedCode: { type: String, default: null },
    },
    setup(props) {
        const  { useState }  = createNamespacedHelpers('survey');
        const { urlParameters } = useState(['urlParameters']);

        const embedCodeContainer = ref();

        function copyEmbedCode() {
            embedCodeContainer.value.setAttribute('type', 'text');
            embedCodeContainer.value.select();
            document.execCommand('copy');
            embedCodeContainer.value.setAttribute('type', 'hidden');
            toaster.success(i18next.t('SURVEYFORMS.PUBLISH_COPY_CODE_SUCCESS', 'Embed code successfully copied.'));
        }
        function downloadHtml() {
            const element = document.createElement('a');
            element.setAttribute('href', 'data:text/htm;charset=utf-8,' + encodeURIComponent(props.embedCode));
            element.setAttribute('download', 'zurvey-embed-widget.htm');

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
        return {
            embedCodeContainer,
            copyEmbedCode,
            downloadHtml,
            urlParameters
        };
    }
});
</script>
