<template>
    <div>
        <svg
            :width="width"
            :height="height"
            viewBox="0 0 210 138"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mt-1 mb-1 mr-auto ml-auto"
        >
            <rect
                x="0.5"
                y="0.5"
                width="209"
                height="137"
                rx="7.5"
                :fill="embedType === 'slider' ? 'var(--color-primary-700)': '#F2F3F8'"
                stroke="#D8DCE8"
            />
            <g filter="url(#filter2_d)">
                <rect
                    x="136"
                    y="29"
                    width="67"
                    height="80"
                    rx="4"
                    fill="white"
                />
            </g>
            <path
                d="M144.047 63.2812L143.031 65.3594L140.734 65.6875C140.328 65.75 140.172 66.25 140.469 66.5469L142.109 68.1562L141.719 70.4219C141.656 70.8281 142.094 71.1406 142.453 70.9531L144.5 69.875L146.531 70.9531C146.891 71.1406 147.328 70.8281 147.266 70.4219L146.875 68.1562L148.516 66.5469C148.812 66.25 148.656 65.75 148.25 65.6875L145.969 65.3594L144.938 63.2812C144.766 62.9219 144.234 62.9062 144.047 63.2812Z"
                :fill="embedType === 'slider' ? 'var(--color-primary-700)': '#9FA7BC'"
            />
            <path
                d="M156.797 63.2812L155.781 65.3594L153.484 65.6875C153.078 65.75 152.922 66.25 153.219 66.5469L154.859 68.1562L154.469 70.4219C154.406 70.8281 154.844 71.1406 155.203 70.9531L157.25 69.875L159.281 70.9531C159.641 71.1406 160.078 70.8281 160.016 70.4219L159.625 68.1562L161.266 66.5469C161.562 66.25 161.406 65.75 161 65.6875L158.719 65.3594L157.688 63.2812C157.516 62.9219 156.984 62.9062 156.797 63.2812Z"
                :fill="embedType === 'slider' ? 'var(--color-primary-700)': '#9FA7BC'"
            />
            <path
                d="M169.547 63.2812L168.531 65.3594L166.234 65.6875C165.828 65.75 165.672 66.25 165.969 66.5469L167.609 68.1562L167.219 70.4219C167.156 70.8281 167.594 71.1406 167.953 70.9531L170 69.875L172.031 70.9531C172.391 71.1406 172.828 70.8281 172.766 70.4219L172.375 68.1562L174.016 66.5469C174.312 66.25 174.156 65.75 173.75 65.6875L171.469 65.3594L170.438 63.2812C170.266 62.9219 169.734 62.9062 169.547 63.2812Z"
                :fill="embedType === 'slider' ? 'var(--color-primary-700)': '#9FA7BC'"
            />
            <path
                d="M182.297 63.2812L181.281 65.3594L178.984 65.6875C178.578 65.75 178.422 66.25 178.719 66.5469L180.359 68.1562L179.969 70.4219C179.906 70.8281 180.344 71.1406 180.703 70.9531L182.75 69.875L184.781 70.9531C185.141 71.1406 185.578 70.8281 185.516 70.4219L185.125 68.1562L186.766 66.5469C187.062 66.25 186.906 65.75 186.5 65.6875L184.219 65.3594L183.188 63.2812C183.016 62.9219 182.484 62.9062 182.297 63.2812Z"
                fill="#D8DCE8"
            />
            <path
                d="M195.047 63.2812L194.031 65.3594L191.734 65.6875C191.328 65.75 191.172 66.25 191.469 66.5469L193.109 68.1562L192.719 70.4219C192.656 70.8281 193.094 71.1406 193.453 70.9531L195.5 69.875L197.531 70.9531C197.891 71.1406 198.328 70.8281 198.266 70.4219L197.875 68.1562L199.516 66.5469C199.812 66.25 199.656 65.75 199.25 65.6875L196.969 65.3594L195.938 63.2812C195.766 62.9219 195.234 62.9062 195.047 63.2812Z"
                fill="#D8DCE8"
            />
            <rect
                x="140"
                y="53"
                width="18"
                height="3"
                fill="#424454"
            />
            <rect
                x="160.25"
                y="93"
                width="19.5"
                height="8"
                rx="4"
                :fill="embedType === 'slider' ? 'var(--color-primary-700)': '#9FA7BC'"
            />
            <rect
                x="165.5"
                y="96"
                width="9"
                height="2"
                fill="white"
            />
            <defs>
                <filter
                    id="filter2_d"
                    x="122"
                    y="19"
                    width="95"
                    height="108"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.513726 0 0 0 0 0.572549 0 0 0 0 0.647059 0 0 0 0.45 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        embedType: { type: String, default: null },
        width: { type: Number, default: 210 },
        height: { type: Number, default: 138 },
    },
};
</script>

<style scoped>

</style>
