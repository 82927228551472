<template>
    <div class="p-4 gap-4 flex flex-col">
        <EmailQuotaInfoBox />
        <template v-if="isRecurring">
            <h1 class="font-bold">
                {{ $t('AUDIENCE.RECURRING_SCHEDULE.TITLE', 'Set recurring scheduled sending') }}
            </h1>
            <div class="w-160 whitespace-pre-line leading-4 text-sm">
                {{ $t('AUDIENCE.RECURRING_SCHEDULE.DESCRIPTION', 'Choose a recurring interval to send your campaign and ensure it reaches your audience at the same time consistently.\nTo activate recurring scheduled send, ensure the toggle is switched on. If not, the schedule will remain as a draft, and the campaign wave will not be sent.\nAll times are calculated based on your time zone.') }}
            </div>
            <RecurringSchedule
                v-for="(schedule, index) in schedules"
                :key="schedule.id || schedule.tempid"
                :settings="schedule.scheduleSettings"
                @update-settings="updateSchedule(index, $event)"
            />
        </template>
        <template v-else>
            <h1 class="font-bold">
                {{ $t('AUDIENCE.EXACT_DATES.TITLE', 'Set an exact date or build a custom timeline') }}
            </h1>
            <div class="w-160 whitespace-pre-line leading-4 text-sm">
                {{ $t('AUDIENCE.EXACT_DATES.DESCRIPTION', 'Create a custom timeline of scheduled sends to consistently reach your audience.\nEach row represents a send, where you can set the exact date and time.\nTo activate a scheduled send, ensure the toggle is switched on. If not, the schedule will remain as a draft, and the campaign wave will not be sent. All times are calculated based on your time zone.\nPlease note: Here, you can manage upcoming schedules only. Previous sends are not visible in this view. To see previous sends, check the Campaigns List on the Publish page.') }}
            </div>
            <ExactDateSchedule
                v-for="(schedule, index) in schedules"
                :key="schedule.id || schedule.tempid"
                :settings="schedule.scheduleSettings"
                :isFirst="index == 0"
                @update-settings="updateSchedule(index, $event)"
                @delete="deleteSchedule(index)"
            />
            <div class="w-112">
                <ZButton
                    variant="secondary"
                    class="mx-auto block"
                    @click="addNewSchedule()"
                >
                    <ZIcon
                        icon="plus"
                        class="mr-2"
                    />
                    {{ $t('AUDIENCE.ADD_NEW_SEND_DATE', 'Add new send date') }}
                </ZButton>
            </div>
        </template>
        <div class="mt-auto mx-auto flex gap-2">
            <ZButton
                variant="outline"
                @click="$emit('show-prev')"
            >
                {{ $t('AUDIENCE.BUTTON_BACK', 'Go Back') }}
            </ZButton>
            <SaveButton
                :disabled="disableButton"
                :isRecurring="isRecurring"
                :allSchedulesAreNotActive="schedules.every(s => !s.scheduleSettings.isActive)"
                :notActiveCampaign="!modelValue.isActive"
                @save="saveSchedules()"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue';
import RecurringSchedule from './scheduled-sending/RecurringSchedule.vue';
import ExactDateSchedule from './scheduled-sending/ExactDateSchedule.vue';
import ZIcon from '@/components/ui/Icon.vue';
import SaveButton from './scheduled-sending/SaveButton.vue';
import EmailQuotaInfoBox from '../../EmailQuotaInfoBox.vue';

import { Campaign } from '@/campaign/types';
import { setLoading } from '@/utils/loading';
import { toaster } from '@/utils/toaster';
import * as api from '@/campaign/api';

import i18next from 'i18next';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);

export default defineComponent({
    components: { RecurringSchedule, ExactDateSchedule, ZIcon, SaveButton, EmailQuotaInfoBox },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue'
    },
    props: {
        modelValue: { type: Object as () => Campaign, required: true },
        isRecurring: { type: Boolean, default: false },
        isReminder: { type: Boolean, default: false },
        reminderWave: { type: Object, default: () => ({}) },
    },
    emits: ['show-prev', 'close'],
    setup(props, { emit }) {
        const newSchedule = ref(false);
        const schedulesToUpdate = ref<number[]>([]);
        const schedulesToDelete = ref<number[]>([]);

        const disableButton = computed(() =>
            (!newSchedule.value
            && schedulesToUpdate.value.length == 0
            && schedulesToDelete.value.length == 0)
            || schedules.value.some(s => {
                if (s.scheduleType != 'specific') return false;

                const date = dayjs()
                    .year(s.scheduleSettings.year!)
                    // months begin at 0
                    .month(s.scheduleSettings.month! - 1)
                    .date(s.scheduleSettings.day!)
                    .hour(s.scheduleSettings.hours)
                    .minute(s.scheduleSettings.minutes);

                return date.isBefore(dayjs(), 'minute');
            })
        );

        const schedules = ref(
            props.modelValue
                .schedules
                .filter(s => props.isReminder ? s.parentWaveId == props.reminderWave.waveId : !s.parentWaveId)
                .filter(s => props.isReminder ? s.reminderType == props.reminderWave.reminderType : true)
                .filter(s => props.isRecurring ? s.scheduleType == 'recurring' : s.scheduleType == 'specific')
        );

        function updateSchedule(index, settings) {
            if (schedules.value[index].id) {
                schedulesToUpdate.value.push(schedules.value[index].id);
            }
            else {
                newSchedule.value = true;
            }

            schedules.value = schedules.value.map((schedule, i) => {
                if (index == i) {
                    return {
                        ...schedule,
                        scheduleSettings: settings,
                    };
                }
                return schedule;
            });
        }

        async function saveSchedules() {
            try {
                setLoading(true);

                if (props.isRecurring) {
                    const { id, ...body } = schedules.value[0];

                    if (!id) {
                        await api.createRecurringSchedule(body);
                        toaster.success(i18next.t('AUDIENCE.RECURRING_SCHEDULE_CREATED', 'Recurring schedule succesfully created'));
                    }
                    else {
                        await api.updateRecurringSchedule(id, body);
                        toaster.success(i18next.t('AUDIENCE.RECURRING_SCHEDULE_UPDATED', 'Recurring schedule succesfully updated'));
                    }
                }
                else {
                    const schedulesToCreate = schedules.value.filter(schedule => !schedule.id);
                    const promises: Promise<void>[] = [];

                    if (schedulesToCreate.length) {
                        promises.push(api.createExactDateSchedules(schedulesToCreate));
                    }
                    if (schedulesToUpdate.value.length) {
                        promises.push(api.updateExactDateSchedules(schedules.value.filter(schedule => schedule.id && schedulesToUpdate.value.includes(schedule.id))));
                    }
                    if (schedulesToDelete.value.length) {
                        promises.push(api.deleteExactDateSchedules(schedulesToDelete.value));
                    }

                    await Promise.all(promises);
                    toaster.success(i18next.t('AUDIENCE.EXACT_DATE_SCHEDULES_SAVED', 'Exact date schedule(s) succesfully saved'));
                }

                emit('close');
            }
            catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG'));
            }
            finally {
                setLoading(false);
            }
        }

        function addNewSchedule() {
            const today = dayjs();

            schedules.value.push({
                tempid: Math.random(),
                campaignId: props.modelValue.id!,
                datasetId: props.modelValue.datasetId,
                scheduleType: props.isRecurring ? 'recurring' : 'specific',
                scheduleSettings: {
                    year: props.isRecurring ? null : today.year(),
                    month: props.isRecurring ? null : today.month() + 1,
                    day: props.isRecurring ? today.day() + 1 : today.date(),
                    hours: 0,
                    minutes: 0,
                    timeZone: dayjs.tz.guess(),
                    periodType: props.isRecurring ? 'monthly' : null,
                    isLast: false,
                    isActive: false,
                },
                reminderType: props.isReminder ? props.reminderWave.reminderType : null,
                parentWaveId: props.isReminder ? props.reminderWave.waveId : null,
                nextSendAt: null,
                createdAt: null,
                updatedAt: null,
            });
        }

        function deleteSchedule(index) {
            if (schedules.value[index].id) {
                schedulesToDelete.value.push(schedules.value[index].id);
            }
            schedules.value.splice(index, 1);
        }

        onMounted(() => {
            if (schedules.value.length == 0) {
                addNewSchedule();
            }
        });

        return { disableButton, schedules, updateSchedule, saveSchedules, addNewSchedule, deleteSchedule };
    }
});
</script>
