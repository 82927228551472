import {Filter} from '@/dashboard/common/types/filterInterface';
import {Campaign} from '@/campaign/types';
import { CsvDelimiterEnum } from '@/excel-analysis/store';

export interface AudienceState {
    loading: boolean,
    audiences: Audience[], // home page
    audience: Audience|null, // currently opened
    upload: UploadedAudienceState,
    members: Member[],
    selectedMembers: string[],
    page: number,
    segment: AudienceSegmentsState,
    export: ExportAudienceState,
    audiencesWithSegments: AudienceWithSegments[],
    campaigns: Campaign[]
}

export interface AudienceSegmentsState {
    segments: Segment[],
    membersPage: number,
    uid: string|null,
}

export interface ExportAudienceState {
    exportPopupOpen: boolean,
    exportMode: ExportMode|null,
    exports: Array<Export>|null,
}

export interface Audience {
    uid?: string,
    userGroupId?: number,
    name: string,
    isPopulated?: boolean,
    hasEmail?: boolean,
    hasPhone?: boolean,
    members?: number,
    segmentCount?: number,
    fields?: Field[],
    connectedDatasetIds?: string[],
    connectedCampaigns?: Campaign[],
    dictionaries?: any[],
    runningJobs?: any[],
    creatorId?: number,
    editorId?: number,
    createdAt?: string,
    updatedAt?: string
    segments?: Segment[]
}

export interface Field {
    id: string,
    type: fieldTypeEnum|null,
    name: string,
    isUnique: boolean,
    mappings: Mapping[],
    changeable?: boolean
}

export interface Mapping {
    datasetId: string,
    dimensionId: string,
}

export interface UploadedAudienceState {
    file: uploadedFile|null,
    columnMapping: {[key: number]: string|null},
    delimiter: CsvDelimiterEnum|null
}

export interface uploadedFile {
    name: string,
    sheets: any,
    activeSheet: any,
    file: any
}

export interface Sheet {
    columnData: any[],
    columnNames: Array<string|null>,
    columnTypes: Array<string|null>,
    headerRows: number,
    rows: number,
}

export enum fieldTypeEnum {
    EMAIL = 'email',
    PHONE = 'phone',
    ID = 'id',
    TEXT = 'text',
    CATEGORY = 'category',
    NUMBER = 'number',
    DATE = 'date'
}

export interface Member {
    id: string;
    recordCount: number;
    fields: {
        [fieldId: string]: string|number;
    };
    createdAt: number;
    updatedAt: number;
}

export interface Segment {
    name: string,
    filters: Filter[],
    id?: string,
    type?: segmentTypeEnum,
    memberCount?: number,
    members?: Member[],
    connectedCampaigns?: Campaign[],
    runningJobs?: any[],
    creatorId?: number,
    editorId?: number,
    createdAt?: string,
    updatedAt?: string
}

export enum segmentTypeEnum {
    DYNAMIC = 'DYNAMIC',
    STATIC = 'STATIC'
}

export type ExportMode = 'audience'|'segment'|'selected';

export interface Export {
    audience: {
        name: string,
        uid: string,
    },
    createdAt: string,
    creator: string,
    creatorId: number,
    deletedAt: string|null,
    finishedAt: string|null,
    id: number,
    memberIds: number[]|null,
    path: string,
    segment: any|null,
    userGroupId: number,
}

export interface AudienceWithSegments {
    uid: string
    name: string
    hasEmail?: boolean
    hasPhone?: boolean
    fields: Field[]
    segments: Segment[]
    members: number
    createdAt: string
    updatedAt: null | string
}
