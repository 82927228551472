import {api} from '@/api/index.js';
import {Audience, AudienceWithSegments, Export, Field, Member, Segment} from '@/audience/types';
import {Campaign} from '@/campaign/types';
import contentDisposition from 'content-disposition';
import { CsvDelimiterEnum } from '@/excel-analysis/store';

export const getAudiences = async (): Promise<Audience[]> => {
    const { data } = await api.get('/audiences');
    return data;
};

export const getAudience = async (uid): Promise<Audience> => {
    const { data } = await api.get('/audiences/' + uid);
    return data;
};

export const fetchAudiencesWithSegments = async (campaignType: 'sms' | 'email' = 'email'): Promise<AudienceWithSegments[]> => {
    let response: any;

    /* eslint-disable */
    switch (campaignType) {
        case 'sms':   throw new Error('SMS campaigns are not supported yet!');
        case 'email': response = await api.get('/audiences/audienceWithSegmentsByUserGroupId?hasEmail=true'); break;
    }
    /* eslint-enable */

    return response.data;
};

export const createAudienceFromFile = async (name: string, fields: Field[], headerRows: number, columnMapping: {[key: number]: string|null}, sheetIndex: number, file, delimiter: CsvDelimiterEnum | null): Promise<Audience> => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('fields', JSON.stringify(fields));
    formData.append('columnMapping', JSON.stringify(columnMapping));
    formData.append('sheetIndex', sheetIndex.toString());
    formData.append('headerRows', headerRows.toString());
    formData.append('file', file);
    if(delimiter) {
        formData.append('delimiter', delimiter);
    }
    const { data } = await api.post('/audiences', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
    return data;
};

export const createAudienceFromApi = async (name: string, fields: Field[]) => {
    const { data } = await api.post('/audiences', {
        name,
        fields: JSON.stringify(fields),
    });

    return data;
};

export const appendAudienceFromFile = async (
    id: string,
    {
        headerRows,
        columnMapping,
        sheetIndex,
        file,
        delimiter
    }: { headerRows: number, columnMapping: { [key: number]: string | null }, sheetIndex: number, file: File, delimiter: CsvDelimiterEnum | null }
): Promise<any> => {
    const formData = new FormData();

    formData.append('headerRows', headerRows.toString());
    formData.append('columnMapping', JSON.stringify(columnMapping));
    formData.append('sheetIndex', sheetIndex.toString());
    formData.append('file', file);
    if(delimiter) {
        formData.append('delimiter', delimiter);
    }

    const { data } = await api.post('/audiences/' + id + '/append', formData);

    return data;
};

export const updateAudience = async (audience: Audience): Promise<Audience> => {
    const { data } = await api.put('/audiences/' + audience.uid, {
        name: audience.name,
        fields: audience.fields || []
    });
    return data;
};

export const deleteAudience = async (uid) => {
    await api.delete('/audiences/' + uid);
};

export const getConnectedCampaignsByAudienceId = async (audienceId: string): Promise<Campaign[]> => {
    //const { data } = await api.get('/campaigns/byAudienceId/' + audienceId);
    return []; //TODO: Return properly when the endpoint exists
};

/* MEMBERS */

export const getMembers = async (uid: string, offset = 0, fieldId = '', fieldValue = ''): Promise<Member[]> => {
    const { data } = await api.get(`/audiences/${uid}/members?offset=${offset}&maxSize=${50}&fieldId=${fieldId}&fieldValue=${fieldValue}`);

    return data;
};

export const countMembers = async (audienceId: string | null, segmentId: string | null, fromDate: number): Promise<number> => {
    let path = '?';
    if (segmentId) {
        path = path + 'segmentId=' + segmentId + '&';
    }
    path = path + 'membersFromDate=' + fromDate;
    const { data } = await api.get(`/audiences/${audienceId}/countMembers${path}`);
    return data;
};

export const deleteMembers = async (uid: string, membersToDelete: string[]): Promise<void> => {
    await api.post(`/audiences/${uid}/members/delete`, { membersToDelete });
};

export const editMember = async (uid: string, memberId: string, fields): Promise<void> => {
    await api.put(`/audiences/${uid}/members/${memberId}`, { fields });
};

/* SEGMENTS */

export const createDynamicSegment = async (uid: string, segment: Segment): Promise<string> => {
    const { data } = await api.post(`/audiences/${uid}/segments`, {
        name: segment.name,
        filters: segment.filters
    });
    return data;
};

export const editDynamicSegment = async (uid: string, segment: Segment) => {
    await api.put(`/audiences/${uid}/segments/${segment.id}`, {
        name: segment.name,
        filters: segment.filters
    });
};

export const getSegments = async (uid: string): Promise<Segment[]> => {
    const { data } = await api.get(`/audiences/${uid}/segments`);
    return data;
};
export const deleteSegment = async (uid: string, segmentId: string) => {
    await api.delete(`/audiences/${uid}/segments/${segmentId}`);
};
export const getMembersBySegmentId = async (uid: string, segmentId: string, offset = 0 ): Promise<Member[]> => {
    const { data } = await api.get(`/audiences/${uid}/segments/${segmentId}/members?offset=${offset}`);
    return data;
};

export const createAudienceExport = async (audienceId: string, options: { segmentId?: string, memberIds?: string[] }): Promise<void> => {
    await api.post(`/audiences/${audienceId}/exports`, options);
};

export const getAudienceExports = async (audienceId: string): Promise<Export[]> => {
    const { data } = await api.get(`/audiences/${audienceId}/exports`);

    return data.exports;
};

export const deleteExport = async (audienceId: string, exportId: number): Promise<void> => {
    await api.delete(`/audiences/${audienceId}/exports/${exportId}`);
};

export const downloadExport = async (audienceId: string, exportId: number) => {
    const { data, headers } =  await api.get(`/audiences/${audienceId}/exports/${exportId}/download`, {
        responseType: 'blob'
    });

    const { parameters } = contentDisposition.parse(headers['content-disposition']);

    return { data, filename: parameters.filename };
};
