import {EmbedSettings, EmbedState} from '@/survey/embed/store/types';
import useUserStore from '@/user/store';
import store from '@/store';
import {createSurveyEmbedSettings, deleteSurveyEmbedSettings, getSurveyEmbedSettings, updateSurveyEmbedSettings} from '@/survey/api';

export default function makeActions(state: EmbedState) {
    const actions = {
        setSurveyEmbedSettings: (embedSettings: EmbedSettings[]) => {
            state.surveyEmbedSettings = embedSettings || [];
        },
        init: async (datasetId: number) => {
            // set default values here
            // @ts-ignore
            const { text_color: textColor, background_color: backgroundColor, primary_color: primaryColor, title, bgImage } = store.state.survey;

            state.activeSettings = {
                ...state.activeSettings,
                displaySettings: {
                    ...state.activeSettings.displaySettings,
                    textColor,
                    backgroundColor: (bgImage && bgImage.panelSettings) ? bgImage.panelSettings.bgColor : backgroundColor,
                    primaryColor,
                    title
                }
            };

            state.defaultSettings = {
                ...state.defaultSettings,
                displaySettings: {
                    ...state.defaultSettings.displaySettings,
                    textColor,
                    backgroundColor: bgImage && bgImage.panelSettings ? bgImage.panelSettings.bgColor : backgroundColor,
                    primaryColor,
                    title
                }
            };
            const { state: user } = useUserStore();
            const settings = await getSurveyEmbedSettings(user.groupId, datasetId);
            actions.setSurveyEmbedSettings(settings || []);
        },
        loadSurveyEmbedSettings: async () => {
            const { state: user } = useUserStore();
            // @ts-ignore
            const datasetId = store.state.survey.datasetId;
            const settings = await getSurveyEmbedSettings(user.groupId, datasetId);
            actions.setSurveyEmbedSettings(settings || []);
        },
        createNewSettings: async (preview = false, draft = false) => {
            // @ts-ignore
            const datasetId = store.state.survey.datasetId;
            const newEmbedSettings: EmbedSettings = await createSurveyEmbedSettings({
                datasetId: datasetId,
                liveSettings: draft ? [] : (preview ? [] : state.activeSettings),
                nextSettings: draft ? [] : state.activeSettings,
                draftSettings: state.activeSettings
            });
            state.surveyEmbedSettings.push(newEmbedSettings);
            state.selectedId = newEmbedSettings.id;
        },
        copy: async (surveyEmbedSettings) => {
            const newEmbedSettings: EmbedSettings = await createSurveyEmbedSettings(surveyEmbedSettings);
            state.surveyEmbedSettings.push(newEmbedSettings);
        },
        deleteSettings: async (id) => {
            const { state: user } = useUserStore();
            // @ts-ignore
            const datasetId = store.state.survey.datasetId;
            await deleteSurveyEmbedSettings(id);
            state.surveyEmbedSettings = await getSurveyEmbedSettings(user.groupId, datasetId);
        },
        updateEmbedSettings: async (preview = false, draft = false) => {
            const { state: user } = useUserStore();
            // @ts-ignore
            const datasetId = store.state.survey.datasetId;
            const selectedSettings = state.surveyEmbedSettings.find(s => s.id === state.selectedId);
            await updateSurveyEmbedSettings({
                ...selectedSettings,
                nextSettings: draft ? {...selectedSettings!.nextSettings} : state.activeSettings,
                liveSettings: preview || draft ? {...selectedSettings!.liveSettings} : state.activeSettings,
                draftSettings: state.activeSettings
            });
            state.surveyEmbedSettings = await getSurveyEmbedSettings(user.groupId, datasetId);
        },
        setUrls: (urls: string[]) => {
            state.activeSettings = {...state.activeSettings, urls: urls};
        },
        setActiveSettings: (settings, isActive = null, id = null) => {
            state.activeSettings = { ...state.defaultSettings, ...settings };
            state.selectedId = id ?? state.selectedId;
            state.isActive = isActive ?? state.isActive;
        },
        resetActiveSettings: () => {
            state.activeSettings = state.defaultSettings;
            state.selectedId = null;
            state.hasUnsavedChanges = false;
        },
        changeSettingsOptions: (type: string, newSetting) => {
            state.activeSettings = {...state.activeSettings, [type]: newSetting };
        },
        setHasUnsavedChanges: (value: boolean) => {
            state.hasUnsavedChanges = value;
        },
        returnToPrevState: async () => {
            const { state: user } = useUserStore();
            // @ts-ignore
            const datasetId = store.state.survey.datasetId;
            const selectedSettings = state.surveyEmbedSettings.find(s => s.id === state.selectedId);
            await updateSurveyEmbedSettings({
                ...selectedSettings,
                nextSettings: selectedSettings!.liveSettings,
                draftSettings: selectedSettings!.liveSettings,
            });
            state.surveyEmbedSettings = await getSurveyEmbedSettings(user.groupId, datasetId);
        },
        setActivity: async (selectedId: number) => {
            const selectedSettings = state.surveyEmbedSettings.find(s => s.id === selectedId)!;
            const notPublishedYet = !selectedSettings.isActive && Array.isArray(selectedSettings.liveSettings) && selectedSettings.liveSettings.length === 0;
            await updateSurveyEmbedSettings({
                ...selectedSettings,
                isActive: !selectedSettings.isActive,
                liveSettings: notPublishedYet ? selectedSettings.draftSettings : selectedSettings.liveSettings
            });
            const { state: user } = useUserStore();
            const { survey } = store.state as any;
            const datasetId = survey.datasetId;
            state.surveyEmbedSettings = await getSurveyEmbedSettings(user.groupId, datasetId);
        },
        syncUrlParameters: async (parameters: {guid: string, name: string}[]) => {
            // check if there are embed settings where url param names should be updated
            const embedsWithUrlParams = state.surveyEmbedSettings.filter((embedSetting: EmbedSettings) => {
                return embedSetting.draftSettings.urlParameters.length > 0 ||
                (!Array.isArray(embedSetting.nextSettings) && embedSetting.nextSettings.urlParameters?.length > 0) ||
                (!Array.isArray(embedSetting.liveSettings) && embedSetting.liveSettings.urlParameters?.length > 0);
            });

            embedsWithUrlParams.forEach(embedSetting => {
                // check if there is an url param that has different name in url params than in embed settings
                let embedSettingUpdated = false;

                ['draftSettings', 'liveSettings', 'nextSettings'].forEach((type) => {
                    if (!Array.isArray(embedSetting[type]) && embedSetting[type].urlParameters.length > 0) {
                        embedSetting[type].urlParameters.forEach((embedParam, index) => {
                            const urlParam = parameters.find(p => p.guid == embedParam.urlParam.guid);
                            if (!urlParam) {
                                // remove from embed settings too
                                embedSetting[type].urlParameters.splice(index, 1);
                                embedSettingUpdated = true;
                            } else if (urlParam.name !== embedParam.urlParam.name) {
                                // update name in embed settings too
                                embedParam.urlParam.name = urlParam.name;
                                embedSettingUpdated = true;
                            }
                        });
                    }
                });

                if (embedSettingUpdated) {
                    updateSurveyEmbedSettings(embedSetting);
                }
            });
        }
    };
    return actions;
}
