<template>
    <nav
        class="grid border-b border-neutral-700"
        :style="{
            'grid-template-columns': 'subgrid',
            'grid-column-start': 'span 7',
        }"
    >
        <div />
        <div @click="sort('campaign')">
            <span class="relative">
                {{ $t('AUDIENCE.TABLE_HEAD_CAMPAIGN', 'Campaign') }}
                <z-icon
                    v-if="modelValue.sortBy === 'campaign'"
                    :icon="modelValue.ascending ? 'chevron-down' : 'chevron-up'"
                    class="chevron"
                />
            </span>
        </div>
        <div @click="sort('recipients')">
            <span class="relative">
                {{ $t('AUDIENCE.TABLE_HEAD_RECIPIENTS', 'Recipients') }}
                <z-icon
                    v-if="modelValue.sortBy === 'recipients'"
                    :icon="modelValue.ascending ? 'chevron-down' : 'chevron-up'"
                    class="chevron"
                />
            </span>
        </div>
        <div @click="sort('sending')">
            <span class="relative">
                {{ $t('AUDIENCE.TABLE_HEAD_SENDING', 'Sending') }}
                <z-icon
                    v-if="modelValue.sortBy === 'sending'"
                    :icon="modelValue.ascending ? 'chevron-down' : 'chevron-up'"
                    class="chevron"
                />
            </span>
        </div>
        <div @click="sort('lastSubmittedAt')">
            <span class="relative">
                {{ $t('AUDIENCE.TABLE_HEAD_LAST_SUBMITTED_AT', 'Last submitted at') }}
                <z-icon
                    v-if="modelValue.sortBy === 'lastSubmittedAt'"
                    :icon="modelValue.ascending ? 'chevron-down' : 'chevron-up'"
                    class="chevron"
                />
            </span>
        </div>
        <div @click="sort('completion')">
            <span class="relative">
                {{ $t('AUDIENCE.TABLE_HEAD_COMPLETION', 'Completion') }}
                <z-icon
                    v-if="modelValue.sortBy === 'completion'"
                    :icon="modelValue.ascending ? 'chevron-down' : 'chevron-up'"
                    class="chevron"
                />
            </span>
        </div>
        <div />
    </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import ZIcon from '@/components/ui/Icon.vue';

export default defineComponent({
    components: {
        ZIcon,
    },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue'
    },
    props: {
        modelValue: { type: Object as () => ({ sortBy: string|null, ascending: boolean }), default: () => ({ sortBy: null, ascending: true }) },
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        const sort = (col) => {
            context.emit('update:modelValue', {
                sortBy: col,
                ascending: props.modelValue.sortBy === col ? !props.modelValue.ascending : true
            });
        };

        return { sort };
    }
});
</script>

<style scoped>
nav.grid > div {
    text-align: left;
    padding: 1em 0.5em;
    cursor: pointer;
    user-select: none;
    text-wrap: nowrap;
    font-weight: bold;
}

.chevron {
    position: absolute;
    top: 50%;
    left: calc(100% + 0.25em);
    transform: translateY(-50%);
}

</style>
