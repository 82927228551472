<template>
    <div
        class="grid"
        :style="{
            'grid-template-columns': 'subgrid',
            'grid-column-start': 'span 7',
        }"
    >
        <!-- wave list stuff -->
        <section
            class="grid items-center border-b border-neutral-500"
            :style="{
                'grid-template-columns': 'subgrid',
                'grid-column-start': 'span 7',
            }"
        >
            <div class="self-stretch border-r border-neutral-500" />
            <div class="flex flex-no-wrap items-center space-x-2">
                <div class="w-4">
                    <Icon
                        v-if="hasWaves"
                        :icon="open ? 'chevron-up' : 'chevron-down'"
                        class="cursor-pointer select-none"
                        @click="open = !open"
                    />
                </div>
                <div>
                    {{ $t('AUDIENCE.WAVE', 'Wave') }}{{ wave.index + 1 }} - {{ dayjs(wave.created_at).format('YYYY.MM.DD. HH:mm') }}
                    <b v-if="wave.reminders.length > 0">({{ wave.reminders.length }})</b>
                </div>
            </div>
            <div>{{ wave.recipients }}</div>
            <div>
                <TypeIcon
                    :type="getWaveIconType(wave.type)"
                    :solid="!!wave.lastSubmittedAt || wave.completedCount > 0"
                />
            </div>
            <div>{{ wave.lastSubmittedAt ? dayjs().format('YYYY.MM.DD. HH:mm') : '-' }}</div>
            <div>{{ wave.completedCount }} / {{ wave.recipientCount }} ({{ Math.round(wave.completedCount / wave.recipientCount * 100) }}%)</div>
            <div>
                <Action
                    v-if="!!wave.waveSentAt"
                    :isReminder="false"
                    :isScheduled="false"
                    @click="action($event)"
                />
            </div>
        </section>
        <template v-if="open">
            <!-- reminder waves stuff -->
            <ReminderWave
                v-for="reminder in wave.reminders"
                :key="reminder.id"
                :index="wave.index"
                :reminder="reminder"
                :campaign="campaign"
                @wave-action="$emit('wave-action', $event)"
            />
            <!-- scheduled reminder waves stuff -->
            <ScheduledReminderWave
                v-for="schedule in wave.schedules"
                :key="schedule.id"
                :index="wave.index"
                :schedule="schedule"
                @wave-action="$emit('wave-action', $event)"
            />
        </template>
        <!-- popup -->
        <wave-metrics-popup
            v-if="waveMetricsPopupOpen"
            :campaign="campaign"
            :wave="wave"
            @close="waveMetricsPopupOpen = false;"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import Icon from '@/components/ui/Icon.vue';
import TypeIcon from './CampaignTypeIcon.vue';
import Action from './WaveListItemAction.vue';
import ReminderWave from './ReminderWave.vue';
import ScheduledReminderWave from './ScheduledReminderWave.vue';
import WaveMetricsPopup from '@/dashboard/campaign-metrics/components/WaveMetricsPopup/WaveMetricsPopup.vue';
import { MappedWave } from './CampaignListItem.vue';
import getWaveIconType from '../getWaveIconType';
import dayjs from 'dayjs';
import { Campaign } from '@/campaign/types';
import { WaveAction } from './CampaignList.vue';

export default defineComponent({
    components: { Icon, TypeIcon, Action, ReminderWave, ScheduledReminderWave, WaveMetricsPopup },
    props: {
        campaign: { type: Object as () => Campaign, required: true },
        wave: { type: Object as () => MappedWave, required: true },
    },
    emits: ['wave-action'],
    setup(props, context) {
        const open = ref(false);
        const waveMetricsPopupOpen = ref(false);

        function action(action) {
            if (action == 'metrics') {
                waveMetricsPopupOpen.value = true;
                return;
            }
            const payload: WaveAction = {
                type: action,
                wave: {
                    id: props.wave.id,
                    campaignId: props.wave.campaignId,
                    type: props.wave.type,
                    isRecurring: false,
                }
            };

            context.emit('wave-action', payload);
        }

        const hasWaves = computed(() => props.wave.reminders.length > 0 || props.wave.schedules.length > 0);

        return { open, hasWaves, waveMetricsPopupOpen, action };
    },
    methods: { getWaveIconType, dayjs }
});
</script>

<style scoped>
section.grid > div {
    @apply py-4 px-2
}
</style>
