<template>
    <div
        class="logo-part-wrapper"
        @mouseenter="$emit('show-hover', true)"
        @mouseleave="$emit('show-hover', false)"
    >
        <img
            v-if="url"
            :src="url"
            alt="Logo"
            class="logo-wrapper"
        >
        <zurvey-logo
            v-else
            class="logo-wrapper"
        />
    </div>
</template>

<script>
import ZurveyLogo from '../ui/Logo';

export default {
    components: {
        'zurvey-logo': ZurveyLogo,
    },
    props: {
        url: { type: String, default: null },
        position: { type: String, default: 'left'},
        size: { type: String, default: 'normal'},
    },
};
</script>

<style scoped lang="less">
    .logo-part-wrapper {
        display: flex;
        justify-content: center;
    }

    img,svg {
        max-width: 100%;
        max-height: 200px;
        margin-bottom: 10px;
        object-fit: contain;
    }

    @media (min-width: 480px) {
        img {
            max-width: 400px;
        }
    }
    svg {
        width: 200px;
    }
</style>
