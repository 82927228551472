<template>
    <v-swatches
        :value="modelValue"
        show-fallback
        fallback-ok-text="Apply"
        popover-x="left"
        :swatches="colors"
        fallback-ok-class="bg-primary-700 hover:bg-primary-500 rounded-full px-6 py-1 text-default font-normal max-h-96"
        @input="$emit('update:modelValue', $event)"
    >
        <template #trigger>
            <div
                class="border border-neutral-700 hover:border-neutral-900 rounded p-1.5 text-default flex items-center justify-between w-full"
                data-test-trigger
            >
                <span>
                    {{ modelValue ? modelValue : 'Transparent' }}
                </span>
                <span
                    class="rounded-full ml-4"
                    :style="{ width: '18px', height: '18px', backgroundColor: modelValue }"
                />
            </div>
        </template>
    </v-swatches>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import 'vue-swatches/dist/vue-swatches.css';
import VSwatches from 'vue-swatches';

export default defineComponent({
    components: {
        VSwatches
    },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue'
    },
    props: {
        modelValue: { type: String, default: '#212529' },
        transparentOption: { type: Boolean, default: false }
    },
    emits: ['update:modelValue'],
    computed: {
        colors() {
            const colors = [
                '#1FBC9C', '#1CA085', '#2ECC70', '#27AF60',
                '#3398DB', '#2980B9', '#A463BF', '#8E43AD',
                '#3D556E', '#222F3D', '#F2C511', '#F39C19',
                '#E84B3C', '#C0382B', '#DDE6E8', '#BDC3C8'
            ];

            if (this.transparentOption) {
                colors.push('');
            }

            return colors;
        }
    }
});
</script>

<style>
    .vue-swatches__trigger__wrapper {
        display: block;
    }
</style>
