<template>
    <div>
        <p class="text-default">
            {{ $t('SURVEYFORMS.PUBLISH_EMBED_TARGETS_INFO', 'Targeting users is a useful tool for collecting feedback from representative sample. This can be achieved easily by using filtering rules. Select users by different meta information, first time or returning user, or more advanced variable-based filtering.') }}
        </p>
        <div
            v-for="(target, index) in activeSettings.targets"
            :key="index"
            class="flex ml-1"
        >
            <target
                class="w-11/12"
                :target="target"
                :index="index"
                @change="changeTarget($event, index)"
            />
            <div class="flex justify-center flex-col px-5 w-1/12 pl-9">
                <z-icon
                    class="text-red-700 right-0 cursor-pointer"
                    icon="trash"
                    @click="deleteTarget(index)"
                />
            </div>
        </div>
        <div
            class="font-bold text-center cursor-pointer mt-3 mb-6 text-sm text-primary-700"
            @click="addNewTarget()"
        >
            <z-icon
                icon="plus"
            />
            {{ $t('SURVEYFORMS_ADD_NEW_EMBED_TARGET', 'Add a new target') }}
        </div>
    </div>
</template>

<script lang="ts">
import ZIcon from '@/components/ui/Icon.vue';
import { defineComponent, toRefs } from 'vue';
import useEmbedStore from '@/survey/embed/store/embedStore';
import { OperatorTypeEnum, TargetTypeEnum } from '@/survey/embed/store/types';
import Target from '@/survey/embed/components/Target.vue';

export default defineComponent({
    components: { ZIcon, Target },
    setup() {
        const embedStore = useEmbedStore();
        const { activeSettings } = toRefs(embedStore.state);
        const { changeSettingsOptions } = embedStore.actions;


        function addNewTarget() {
            let targets = [...activeSettings.value.targets];
            targets.push({ type: TargetTypeEnum.DEVICE, operator: OperatorTypeEnum.ONE_OF, value: [] });
            changeSettingsOptions('targets', targets);
        }

        function changeTarget(target, index) {
            let targets = [...activeSettings.value.targets];
            targets.splice(index, 1, target);
            changeSettingsOptions('targets', targets);
        }

        function deleteTarget(index) {
            let targets = [...activeSettings.value.targets];
            targets = targets.filter((t,i) => i !== index);
            changeSettingsOptions('targets', targets);
        }

        return {
            activeSettings,
            addNewTarget,
            changeTarget,
            deleteTarget
        };
    }
});
</script>
