<script lang="ts">
import type { Campaign } from '@/campaign/types';
import { TabIndex } from '@/survey/components/publish/campaign/popup/CreateNewCampaignPopup.vue';

import { toaster } from '@/utils/toaster';
import { useCampaigns } from '@/campaign';
import { ref, computed, defineComponent, onMounted, toRefs, onUnmounted } from 'vue';
import { setLoading } from '@/utils/loading/';

import i18next from 'i18next';
import useAudienceStore from '@/audience/store';
import useSubscriptionStore from '@/subscription/store';
import planFeatureTypeEnum from '@/domain/planFeatureTypeEnum';
import ViewStateContactUs from '@/survey/components/publish/campaign/states/ContactUs.vue';
import ViewStateContactUsForCampaignEmails from './states/ContactUsForCampaignEmails.vue';
import CampaignList from './Campaigns/CampaignList.vue';
import ViewStateNoAudienceFound from '@/survey/components/publish/campaign/states/EmptyAudienceList.vue';
import ViewStateNoCampaignsFound from '@/survey/components/publish/campaign/states/EmptyCampaignList.vue';
import CreateNewCampaignPopup from '@/survey/components/publish/campaign/popup/CreateNewCampaignPopup.vue';
import LoadingSpinner from '@/common/components/design-system/LoadingSpinner.vue';

import { AudienceWithSegments } from '@/audience/types';
import { useSurveyEmailTemplates } from '@/survey/api';
import EmailQuotaInfoBox from './EmailQuotaInfoBox.vue';
import { useRoute } from 'vue-router/composables';

export interface OpenPopupPayload {
    tab?: TabIndex;
    campaign?: Campaign;
    waveId?: number;
    isEdit?: boolean;
    viewMode?: boolean;
    isReminder?: boolean;
    isRecurring?: boolean;
    reminderType?: string;
}

export default defineComponent({
    name: 'CampaignEditor',

    components: { CreateNewCampaignPopup, EmailQuotaInfoBox, LoadingSpinner },
    emits: ['popup-closed', 'update-campaigns'],
    setup: () => {
        const {
            state,
            setPopupViewOnlyMode,
            setReminderWave,
            getCampaignsByDatasetId,
            setCampaignsForDatasetId,
        } = useCampaigns();
        const { listAudiencesWithSegments, actions } = useAudienceStore();
        const { audiencesWithSegments } = toRefs(useAudienceStore().state);

        // NOTE(adam): It seems that survey email templates are bound to the
        // user group id, so I can load them here. This should solve the race
        // condition issue encountered in the new campaign popup.
        void useSurveyEmailTemplates().findSurveyEmailTemplates();
        const { campaignsForDatasetId: campaigns, firstLoadingCampaigns } = toRefs(state);

        const hasValidAudience = computed(() => audiencesWithSegments.value.some((audience: AudienceWithSegments) => audience.hasEmail));

        const { canUseFeature } = useSubscriptionStore().getters;
        const isAudiencePlanFeatureEnabled = computed(() => canUseFeature.value(planFeatureTypeEnum.AUDIENCE));
        const canUseCampaignEmails = computed(() => canUseFeature.value(planFeatureTypeEnum.CAMPAIGN_EMAILS));

        // NOTE(adam): We needed to move the campaigns state variable out from
        // the "store", because there are multiple ways to query campaigns.
        const isEditAction = ref(false);
        const viewOnlyMode = ref(false);

        const isReminderAction = ref(false);
        const reminderWaveParentId = ref<number | null>(null);
        const reminderWave = computed(() => {
            if (!reminderWaveParentId.value) {
                return null;
            }
            const campaign = campaigns.value.find(campaign => campaign.waves!.some(wave => +wave.id == reminderWaveParentId.value));
            if (!campaign) {
                return null;
            }
            const waveIndex = campaign.waves!.findIndex(wave => +wave.id == reminderWaveParentId.value);
            const wave = campaign.waves![waveIndex];
            return {
                waveId: reminderWaveParentId.value,
                index: waveIndex,
                campaignId: wave.campaignId,
                audienceId: wave.audienceId,
                segmentId: wave.segmentId,
                reminderType: null
            };
        });


        const loadingAudience = ref(false);

        onMounted(async () => {
            setLoading(true);
            loadingAudience.value = true;

            if (isAudiencePlanFeatureEnabled.value && !audiencesWithSegments.value.length) {
                try {
                    await listAudiencesWithSegments();
                } catch (e) {
                    toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                }
            }

            setLoading(false);
            loadingAudience.value = false;
        });

        onUnmounted(() => {
            actions.reset();
        });

        const viewState = computed(() => {
            if (!isAudiencePlanFeatureEnabled.value) {
                return ViewStateContactUs;
            }

            if (!canUseCampaignEmails.value) {
                return ViewStateContactUsForCampaignEmails;
            }

            if (!hasValidAudience.value) {
                return ViewStateNoAudienceFound;
            }

            if (!campaigns.value.length) {
                return ViewStateNoCampaignsFound;
            }

            return CampaignList;
        });

        const isPopupOpen = ref(false);
        const selectedTabIndex = ref(0);
        const editableCampaign = ref<Campaign | null>(null);

        const updateEditableCampaign = (campaign: Campaign) => {
            editableCampaign.value = { ...campaign };
        };

        const isRecurringAction = ref(false);

        function updateCampaigns(newCampaigns) {
            setCampaignsForDatasetId(newCampaigns);
        }

        async function loadCampaigns() {
            await getCampaignsByDatasetId(useRoute().params.id);
        }

        return {
            viewState,
            audiences: audiencesWithSegments,

            isPopupOpen,
            selectedTabIndex,
            editableCampaign,
            isEditAction,
            viewOnlyMode,
            isReminderAction,
            reminderWaveParentId,
            reminderWave,
            isRecurringAction,

            openPopup: ({ tab, campaign, waveId, isEdit, viewMode, isReminder, isRecurring, reminderType }: OpenPopupPayload = {}) => {
                selectedTabIndex.value = tab ?? TabIndex.Recipients;
                editableCampaign.value = campaign ?? null;
                if (isEdit) {
                    isEditAction.value = isEdit;
                }

                if (viewMode) {
                    setPopupViewOnlyMode(viewMode);
                }

                if (isReminder) {
                    isReminderAction.value = isReminder;
                    reminderWaveParentId.value = waveId ?? null;
                    setReminderWave({ ...reminderWave.value, reminderType: reminderType ?? reminderWave.value?.reminderType  });
                }
                if (tab == TabIndex.Schedule) {
                    isRecurringAction.value = isRecurring || false;
                }

                isPopupOpen.value = true;
            },

            closePopup: () => {
                setLoading(true);
                isPopupOpen.value = false;
                selectedTabIndex.value = TabIndex.Recipients;
                editableCampaign.value = null;
                loadCampaigns();
                isReminderAction.value = false;
                isRecurringAction.value = false;
                setLoading(false);
            },
            updateEditableCampaign,
            loadingAudience,
            updateCampaigns,
            campaigns,
            firstLoadingCampaigns,
        };
    }
});
</script>

<template>
    <div
        v-if="!loadingAudience && !firstLoadingCampaigns"
        class="px-2 space-y-2 mx-2 py-6"
    >
        <strong v-if="campaigns.length > 0">
            {{ $t('AUDIENCE.TITLE_CAMPAIGNS', 'Campaigns') }}
        </strong>
        <EmailQuotaInfoBox />
        <component
            :is="viewState"
            :campaigns="campaigns"
            :audiences="audiences"
            @open-popup="openPopup"
            @update-campaigns="updateCampaigns($event)"
        />
        <create-new-campaign-popup
            v-if="isPopupOpen"
            :audiences="audiences"
            :campaign-to-edit="editableCampaign"
            :selected-tab-index="selectedTabIndex"
            :is-edit="isEditAction"
            :is-reminder="isReminderAction"
            :is-recurring="isRecurringAction"
            @campaign-saved="updateEditableCampaign"
            @close-popup="closePopup"
        />
    </div>
    <div
        v-else
        class="flex justify-center items-center h-64"
    >
        <LoadingSpinner class="h-16 w-16" />
    </div>
</template>
