<template>
    <popup
        :title="$t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_REMOVE_PRIVACY_POLICY_WARNING_TITLE', 'Removing Privacy Policy From Footer')"
        :is-closable="false"
    >
        <div class="text-small w-96">
            <div class="mb-4 p-2 bg-red-100 border border-red-500 rounded leading-normal">
                {{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_REMOVE_PRIVACY_POLICY_WARNING_LONG_TEXT', defaultLongText) }}
            </div>
            <z-checkbox
                v-model="isAccepted"
                data-test-id="checkbox"
            >
                <span class="text-small">
                    {{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_REMOVE_PRIVACY_POLICY_WARNING_CHECKBOX', defaultCheckboxText) }}
                </span>
            </z-checkbox>
        </div>
        <template #footer>
            <div class="text-center mt-4">
                <z-button
                    data-test-id="left-button"
                    variant="outline"
                    class="mr-4"
                    @click="$emit('cancel')"
                >
                    {{ $t('GLOBAL.CANCEL') }}
                </z-button>
                <z-button
                    data-test-id="right-button"
                    variant="primary"
                    :disabled="!isAccepted"
                    @click="handleSubmit"
                >
                    {{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_REMOVE_PRIVACY_POLICY_BUTTON', 'Remove Privacy Policy') }}
                </z-button>
            </div>
        </template>
    </popup>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';
import Popup from '@/components/ui/Popup.vue';


export default defineComponent({
    components: {Popup, },
    setup(_, ctx) {
        const isAccepted = ref(false);
        const defaultLongText = '"Personal data" means any information relating to an identified or identifiable natural person; an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.';
        const defaultCheckboxText = 'By removing the Privacy Policy from footer, I agree not to collect personal data in the survey and not to process data by the Zurvey tools.';

        const handleSubmit = () => {
            if (isAccepted.value) {
                ctx.emit('ok');
            }
        };
        return { isAccepted, handleSubmit, defaultLongText, defaultCheckboxText };
    },
});
</script>
