<template>
    <div class="text-default space-y-2">
        <div>
            {{ $t('SURVEYFORMS.SURVEY_REDIRECT_ACTION', 'Survey Redirect Action') }} <tooltip :tooltip="$t('SURVEYFORMS.SURVEY', 'Customize how respondents are directed to your survey. Choose to display a customizable button with a label of your choice, or embed a single interaction question (e.g., rating, scale) directly into the email. If no suitable question is available, the button remains your only option.')" />
        </div>
        <div class="flex items-center space-x-2">
            <div
                class="whitespace-no-wrap"
            >
                {{ $t('SURVEYFORMS.EMAIL_BUTTON_ACTION', 'Action') }}:
            </div>
            <dropdown-button
                v-if="dropdownValue"
                class="w-40 flex-none"
                :value="dropdownValue"
                :options="dropdownOptions"
                :disabled="dropdownOptions.length <= 1"
                direction="up"
                @input="handleDropdown($event)"
            />
            <div
                class="whitespace-no-wrap"
            >
                {{ $t('SURVEYFORMS.EMAIL_BUTTON_ACTION_LABEL', 'Button Label') }}:
            </div>
            <z-input
                v-model="buttonText"
                class="flex-grow"
                :disabled="!hasButton || disabled"
                data-test-id="button-text"
                @update:modelValue="$emit('button-updated', $event)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { QuestionElement } from '@/survey/api/types';
import i18next from 'i18next';
import Tooltip from '@/components/ui/Tooltip.vue';
import DropdownButton from '@/components/ui/DropdownButton.vue';
import striptags from 'striptags';

import { createNamespacedHelpers } from 'vuex-composition-helpers/dist';
const { useGetters: useSurveyGetters } = createNamespacedHelpers('survey');

export default defineComponent({
    components: { Tooltip, DropdownButton },
    props: {
        defaultButtonText: { type: String, default: null },
        defaultEmbeddedQuestionGuid: { type: String, default: null },
        disabled: { type: Boolean, default: false }
    },
    setup (props, ctx) {
        const hasButton = ref(!!props.defaultButtonText);
        const showActionDropDown = ref(false);
        const buttonText = ref(props.defaultButtonText);
        const embeddedQuestion = ref<QuestionElement|null>(null);

        const { embeddableElements } = useSurveyGetters(['embeddableElements']);

        onMounted(() => {
            if (props.defaultEmbeddedQuestionGuid) {
                const question = embeddableElements.value.find(q => q.guid === props.defaultEmbeddedQuestionGuid);
                if (question) {
                    selectQuestion(question);
                } else {
                    selectButton();
                }
            }
        });

        const stripTags = (text: string) => {
            return text.replace(/(<([^>]+)>)/gi, '');
        };

        const selectQuestion = (question: QuestionElement) => {
            hasButton.value = false;
            embeddedQuestion.value = question;
            showActionDropDown.value = false;
            ctx.emit('question-updated', question);
        };

        const selectButton = () => {
            hasButton.value = true;
            buttonText.value = props.defaultButtonText || i18next.t('SURVEYFORMS.EMAIL_BUTTON');
            embeddedQuestion.value = null;
            showActionDropDown.value = false;
            ctx.emit('button-updated', buttonText.value);
        };

        const dropdownValue = computed(() => {
            if (hasButton.value) {
                return 'button';
            }
            else {
                return embeddedQuestion.value?.guid;
            }
        });

        const dropdownOptions = computed(() => {
            const questions = embeddableElements.value.map(q => ({ value: q.guid, label: striptags(q.title) }));

            return [
                { value: 'button', label: 'Button' },
                ...questions,
            ];
        });

        const handleDropdown = (event) => {
            if (event === 'button') {
                selectButton();
            }
            else {
                selectQuestion(embeddableElements.value.find(q => q.guid === event)!);
            }
        };

        return {
            hasButton, buttonText, showActionDropDown,
            embeddedQuestion, embeddableElements,
            selectQuestion, selectButton, stripTags,
            dropdownValue, dropdownOptions, handleDropdown
        };
    },
});
</script>
