<script lang="ts">
import type { Campaign } from '@/campaign/types';
import type { PropType } from 'vue';
import type { AudienceWithSegments } from '@/audience/types';

import {ref, defineComponent, toRefs} from 'vue';

import DataTable, { SortDirection } from '@/survey/components/publish/campaign/states/DataTable.vue';
import {VariableType} from '@/campaign/types';
import {useCampaigns} from '@/campaign';


// NOTE(adam): We can include the user's locale in the formatter, but for now,
// we use the OS's settings.
const formatter = Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
});

const numberFormatter = Intl.NumberFormat('default', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

const formatNumber = (audience: AudienceWithSegments, property: keyof AudienceWithSegments): string => {
    return numberFormatter.format(audience[property] as number);
};

const formatDateTime = (audience: AudienceWithSegments, property: keyof AudienceWithSegments): string => {
    return (!audience[property])
        ? '-'
        : formatter.format(Date.parse(audience[property] as string)); // NOTE(adam): AudienceWithSegments timestamps are in seconds.
};

function audienceById (this: string, audience: AudienceWithSegments): boolean {
    return this == audience.uid;
}

export default defineComponent({
    name: 'AudienceList',

    components: { DataTable },

    props: {
        campaign: {
            required: true,
            type: Object as PropType<Campaign>,
        },

        audiences: {
            type: Array as PropType<AudienceWithSegments[]>,
            required: true,
        }
    },

    setup: ({ campaign, audiences }, { emit }) => {
        const { isPopupViewOnly } = toRefs(useCampaigns().state);
        const selectedAudience = ref<AudienceWithSegments | null>(null);
        const selectedAudienceIndex = ref<number>((audiences.length) ? 0 : -1);

        (!campaign.audienceId)
            ? selectedAudience.value = null
            : selectedAudience.value = audiences.find(audienceById, campaign.audienceId) ?? null;

        function updateCampaignAndShowNext() {
            if (selectedAudience.value) {
                emit('show-next', {
                    segmentUid: null,
                    audienceUid: selectedAudience.value.uid,
                    variables: campaign.variables!.length === 0 ? selectedAudience.value?.fields.map(f => ({
                        urlUid: null,
                        type: VariableType.Variable,
                        name: f.name,
                        fieldUid: f.id,
                    })) : campaign.variables
                });
            }
        }

        return {
            formatNumber,
            SortDirection,
            formatDateTime,
            selectedAudience,
            selectedAudienceIndex,
            updateCampaignAndShowNext,
            isPopupViewOnly
        };
    }
});
</script>
<template>
    <div class="flex flex-col">
        <data-table
            v-model="selectedAudience"
            :disabled="isPopupViewOnly"
            :labels="[
                $t('AUDIENCE.TABLE_HEAD_AUDIENCE_NAME', 'Audience Name'),
                $t('AUDIENCE.TABLE_HEAD_AUDIENCE_MERMBER_COUNT', 'Audience Members'),
                $t('AUDIENCE.TABLE_HEAD_AUDIENCE_CREATED_AT', 'Date of Creation'),
                $t('AUDIENCE.TABLE_HEAD_AUDIENCE_UPDATED_AT', 'Last Updated'),
            ]"
            :column-widths="['w-1/3']"

            :formatters="{
                memberCount: formatNumber,
                createdAt: formatDateTime,
                updatedAt: formatDateTime,
            }"

            use-key="id"
            search-by="name"
            :data-source="audiences"
            :column-keys="['name', 'members', 'createdAt', 'updatedAt']"
            sort-by="createdAt"
            :sort-direction="SortDirection.Descending"
        />
        <section class="flex flex-row mx-auto mt-auto">
            <button
                v-if="!isPopupViewOnly"
                class="mx-1 px-3 py-1 rounded border border-primary-700 text-primary-700 text-sm"
                @click="$emit('show-prev')"
            >
                {{ $t('AUDIENCE.BUTTON_BACK', 'Go Back') }}
            </button>
            <z-button
                class="mx-1"
                :disabled="!selectedAudience || isPopupViewOnly"
                @click="updateCampaignAndShowNext"
            >
                {{ $t('AUDIENCE.BUTTON_SELECT_AUDIENCE', 'Select Audience') }}
            </z-button>
        </section>
    </div>
</template>
