<template>
    <div>
        <p class="text-default">
            {{ $t('SURVEYFORMS.PUBLISH_EMBED_URL_PARAMS_INFO', 'URL Parameters are a two-way channel of information. You can predefine them or get values from them based on URL queries, Cookies, Local storage, GTM or Zurvey variables or Constants. To create a URL parameter, you can do so in the URL tab.') }}
        </p>
        <info-box
            v-if="is_multilang"
            type="info"
            class="text-default my-4 space-y-2"
        >
            <p>{{ $t('SURVEY_BUILDER.TRANSLATIONS.LANGUAGE_CODES_INFOBOX', 'For the \'lang\' URL Parameter, your active translations and their corresponding codes are:') }}</p>
            <ul class="list-disc ml-6">
                <li
                    v-for="lang in activeLanguages"
                    :key="lang.code"
                >
                    {{ lang.name }} - {{ lang.code }}
                </li>
            </ul>
        </info-box>
        <div
            v-for="(urlParameter, index) in activeSettings.urlParameters"
            :key="index"
            class="flex my-2"
        >
            <z-select
                :model-value="urlParameter.urlParam.guid"
                class="w-1/3 mr-2"
                @update:modelValue="changeParam(index, {type: 'urlParam', value: {guid: $event, name: urlParameters.find(u => u.guid === $event).name}})"
            >
                <option
                    v-if="is_multilang"
                    value="lang"
                >
                    lang
                </option>
                <option
                    v-for="param in urlParameters"
                    :key="param.guid"
                    :value="param.guid"
                >
                    {{ param.name }}
                </option>
            </z-select>
            <div class="my-1 mx-2">
                {{ $t('SURVEYFORMS_EMBED_URL_PARAM_SOURCE_FROM', 'from') }}:
            </div>
            <z-select
                :model-value="urlParameter.source"
                class="w-1/3 mr-2"
                @update:modelValue="changeParam(index,{type: 'source', value: $event})"
            >
                <option
                    v-for="source in sourceTypes"
                    :key="source.value"
                    :value="source.value"
                >
                    {{ source.text }}
                </option>
            </z-select>
            <z-input
                class="w-1/3"
                :model-value="urlParameter.value"
                data-test-id="url-param"
                @update:modelValue="changeParam(index, {type: 'value', value: $event})"
            />
            <z-icon
                class="text-red-700 float-right my-2 ml-4 mr-1 cursor-pointer"
                icon="trash"
                @click="deleteParameter(index)"
            />
        </div>
        <div
            class="font-bold text-center cursor-pointer mt-3 mb-6 text-sm text-primary-700"
            @click="addParameter()"
        >
            <z-icon
                icon="plus"
            />
            {{ $t('SURVEYFORMS_ADD_NEW_EMBED_URL_PARAM_SOURCE', 'Add URL parameter source') }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, computed } from 'vue';
import { useNamespacedState } from 'vuex-composition-helpers';

import ZSelect from '@/components/ui/Select.vue';
import ZIcon from '@/components/ui/Icon.vue';
import InfoBox from '@/components/ui/InfoBox.vue';

import useEmbedStore from '@/survey/embed/store/embedStore';
import { SourceTypeEnum, SourceTypeText } from '@/survey/embed/store/types';
import { getDisplayNameFromNickname, getNicknameFromId, getDisplayName } from '@/domain/analysisLanguageEnum';

export default defineComponent({
    components: {
        ZIcon,
        ZSelect,
        InfoBox,
    },
    setup() {
        const { urlParameters, is_multilang, translations, language_id } = useNamespacedState('survey', ['urlParameters', 'is_multilang', 'translations', 'language_id']);

        const embedStore = useEmbedStore();
        const { activeSettings } = toRefs(embedStore.state);
        const { changeSettingsOptions } = embedStore.actions;

        const sourceTypes = ref([
            { value: SourceTypeEnum.URL_QUERY, text: SourceTypeText[SourceTypeEnum.URL_QUERY]() },
            { value: SourceTypeEnum.COOKIE, text: SourceTypeText[SourceTypeEnum.COOKIE]() },
            { value: SourceTypeEnum.LOCAL_STORAGE, text: SourceTypeText[SourceTypeEnum.LOCAL_STORAGE]() },
            { value: SourceTypeEnum.GTM, text: SourceTypeText[SourceTypeEnum.GTM]() },
            { value: SourceTypeEnum.ZURVEY, text: SourceTypeText[SourceTypeEnum.ZURVEY]() },
            { value: SourceTypeEnum.CONSTANT, text: SourceTypeText[SourceTypeEnum.CONSTANT]() },
        ]);
        function changeParam(index, settings) {
            let urlParametersSettings = [...activeSettings.value.urlParameters];
            urlParametersSettings[index] = { ...urlParametersSettings[index], [settings.type]: settings.value };
            changeSettingsOptions('urlParameters', urlParametersSettings);
        }

        function deleteParameter(index) {
            let urlParametersSettings = [...activeSettings.value.urlParameters];
            urlParametersSettings = urlParametersSettings.filter((t,i) => i !== index);
            changeSettingsOptions('urlParameters', urlParametersSettings);
        }

        function addParameter() {
            const urlParametersSettings = [...activeSettings.value.urlParameters];

            // this is only possible if multi_lang is true
            if (urlParameters.value.length === 0) {
                urlParametersSettings.push({
                    urlParam: { guid: 'lang', name: 'lang' },
                    source: SourceTypeEnum.URL_QUERY,
                    value: ''
                });
            }
            else {
                urlParametersSettings.push({
                    urlParam: { guid: urlParameters.value[0].guid, name: urlParameters.value[0].name },
                    source: SourceTypeEnum.URL_QUERY,
                    value: ''
                });
            }

            changeSettingsOptions('urlParameters', urlParametersSettings);
        }

        const activeLanguages = computed(() => {
            const languages = [
                {
                    code: getNicknameFromId(language_id.value),
                    name: getDisplayName(language_id.value),
                }
            ].concat(
                translations.value.activeLanguages
                    .map(lang => (
                        {
                            code: lang,
                            name: getDisplayNameFromNickname(lang),
                        }
                    ))
            );

            return languages;
        });

        return {
            activeSettings,
            urlParameters,
            changeParam,
            sourceTypes,
            deleteParameter,
            addParameter,
            is_multilang,
            activeLanguages,
        };
    }
});
</script>
