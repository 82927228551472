<template>
    <div class="w-112 space-y-2">
        <div class="flex items-end gap-2 relative">
            <ZToggle
                :modelValue="settings.isActive"
                class="relative transform -translate-y-2"
                @update:modelValue="updateSchedule('isActive', $event)"
            />
            <DateInput
                :modelValue="date"
                :label="isFirst ? $t('AUDIENCE.SEND_DATE', 'Send date') : undefined"
                :min="dayjs().format('YYYY-MM-DD')"
                class="flex-grow"
                @change="date = $event.target.value"
            />
            <ZSelect
                :modelValue="settings.hours.toString()"
                :label="isFirst ? $t('AUDIENCE.HOURS', 'Hours') : undefined"
                :options="hourOptions"
                class="w-16"
                @update:modelValue="updateSchedule('hours', +$event)"
            />
            <ZSelect
                :modelValue="settings.minutes.toString()"
                :label="isFirst ? $t('AUDIENCE.MINUTEs', 'Minutes') : undefined"
                :options="minuteOptions"
                class="w-16"
                @update:modelValue="updateSchedule('minutes', +$event)"
            />
            <ZIcon
                v-if="!isFirst"
                class="absolute cursor-pointer text-red-700 right-0 transform -translate-y-2 translate-x-full pl-2"
                icon="trash"
                @click="$emit('delete')"
            />
            <div
                v-if="showTimeZoneWarning"
                class="absolute right-0 bottom-0 transform translate-x-full -translate-y-1"
            >
                <MoreInfo class="ml-2">
                    {{ $t('AUDIENCE.DIFFERENT_TIMEZONE', 'You are in a different timezone than the original time was set') }}
                </MoreInfo>
            </div>
        </div>
        <div
            v-if="isPast"
            class="text-red-700 text-xs pl-10"
        >
            {{ $t('AUDIENCE.PAST_DATE_SELECTED', 'Selected date is in the past, choose a future date.') }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import DateInput from '@/components/ui/DateInput.vue';
import ZSelect from '@/components/ui/Select.vue';
import ZIcon from '@/components/ui/Icon.vue';
import MoreInfo from '@/components/ui/MoreInfo.vue';
import { ScheduleSettings } from '@/campaign/types';
import dayjs from 'dayjs';

export default defineComponent({
    components: { DateInput, ZSelect, ZIcon, MoreInfo },
    props: {
        settings: { type: Object as () => ScheduleSettings, required: true },
        isFirst: { type: Boolean, default: false },
    },
    emits: ['update-settings', 'delete'],
    setup(props, { emit }) {

        function updateSchedule(setting, value) {
            emit('update-settings', {
                ...props.settings,
                [setting]: value
            });
        }

        const date = computed({
            get: () => dayjs().year(props.settings.year!).month(props.settings.month! - 1).date(props.settings.day!).format('YYYY-MM-DD'),
            set: (val) => {
                if (!val) {
                    emit('update-settings', {
                        ...props.settings,
                        year: dayjs().year(),
                        month: dayjs().month() + 1,
                        day: dayjs().date()
                    });
                }
                else {
                    emit('update-settings', {
                        ...props.settings,
                        year: dayjs(val).year(),
                        month: dayjs(val).month() + 1,
                        day: dayjs(val).date(),
                    });
                }

            }
        });

        const isPast = computed(() => {
            return dayjs()
                .year(props.settings.year!)
                .month(props.settings.month! - 1)
                .date(props.settings.day!)
                .hour(props.settings.hours)
                .minute(props.settings.minutes)
                .isBefore(dayjs(), 'minute');
        });

        const showTimeZoneWarning = computed(() => props.settings.timeZone != dayjs.tz.guess());

        return { updateSchedule, date, isPast, dayjs, showTimeZoneWarning };
    },
    computed: {
        hourOptions() {
            return Array(24).fill(true).map((_, index) => index.toString());
        },
        minuteOptions() {
            return Array(60).fill(true).map((_, index) => index.toString());
        }
    }
});
</script>
