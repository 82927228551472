<template>
    <z-icon
        v-if="icon"
        :icon="icon"
        :type="solid ? 'fas' : 'fal'"
        :class="[color]"
    />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import ZIcon from '@/components/ui/Icon.vue';

export default defineComponent({
    components: {
        ZIcon,
    },
    props: {
        type: { type: String, required: true },
        solid: { type: Boolean, default: false },
        quotaError: { type: Boolean, default: false },
    },
    setup(props) {
        const icon = computed(() => {
            if (props.quotaError && props.type == 'scheduled-for-later') {
                return 'calendar-xmark';
            }
            switch (props.type) {
                case 'api':
                    return 'code-branch';
                case 'manual':
                case 'reminder':
                    return 'paper-plane';
                case 'scheduled':
                case 'scheduled_reminder':
                    return 'calendar';
                case 'scheduled-for-later':
                    return 'calendar-clock';
                default:
                    return null;
            }
        });

        const color = computed(() => {
            switch (icon.value) {
                case 'calendar-xmark':
                    return 'text-red-500';
                case 'calendar-clock':
                    return 'text-neutral-800';
                default:
                    return 'text-primary-700';
            }
        });

        return { icon, color };
    }
});
</script>
