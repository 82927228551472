<template>
    <popup
        :title="$t('SURVEYFORMS.PUBLISH_EMBED_PREVIEW_POPUP_TITLE', 'Enter the preview website')"
        :is-closable="true"
        data-test-id="embed-preview-popup"
        width-class="w-128"
        @close="$emit('close')"
    >
        <form @submit.prevent="handleSubmit">
            <div class="text-sm mb-4">
                {{ $t('SURVEYFORMS.PUBLISH_EMBED_PREVIEW_POPUP_DESC', 'Enter a URL to your site to preview your embedded survey.') }}
            </div>
            <div class="flex justify-between">
                <z-input
                    v-model="targetUrl"
                    :placeholder="$t('SETTINGS.SURVEY_TRACKING_CODE_SCAN_PLACEHOLDER', 'Your website URL e.g. https://zurvey.io')"
                    :disabled="isScanningInProgress"
                    data-test-id="target-url"
                    class="w-full"
                />
                <connectedness-marker
                    v-if="connected || notConnected"
                    :is-disconnected="notConnected"
                />
            </div>
            <div class="text-sm text-neutral-900 mt-2">
                {{ $t('SURVEYFORMS.PUBLISH_EMBED_PREVIEW_POPUP_WARNING', 'Include http:// or https:// in the URL.') }}
            </div>
            <info-box
                v-if="connected"
                class="mt-4 text-small"
                data-test-id="connected-infobox"
            >
                <div v-html="connectedInfoBoxText" />
            </info-box>
            <info-box
                v-if="notConnected"
                class="mt-4 text-small"
                type="warning"
                data-test-id="not-connected-infobox"
            >
                <div v-html="notConnectedInfoBoxText" />
            </info-box>
            <info-box
                v-if="showWarningMsg"
                type="warning"
                class="mt-4"
                data-test-id="warning-infobox"
            >
                {{ $t('SURVEYFORMS.PUBLISH_EMBED_PREVIEW_FAILS', 'The website is not available or blocks preview mode.') }}
            </info-box>
            <div
                v-else
                class="text-center mt-4"
            >
                <z-button
                    v-if="!connected && !notConnected"
                    :disabled="!isValidUrl"
                    :loading="isScanningInProgress"
                    data-test-id="preview-connect-button"
                    type="submit"
                >
                    {{ $t('SURVEYFORMS.PUBLISH_EMBED_PREVIEW_POPUP_CONNECT', 'CONNECT') }}
                </z-button>
                <z-button
                    v-else
                    @click="startPreview"
                >
                    {{ $t('BUTTONS.PREVIEW', 'Preview') }}
                </z-button>
            </div>
        </form>
    </popup>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue';
import Popup from '@/components/ui/Popup.vue';
import { toaster } from '@/utils/toaster';
import api from '@/api';
import InfoBox from '@/components/ui/InfoBox.vue';
import useUserStore from '@/user/store';
import i18next from 'i18next';
import ConnectednessMarker from '@/survey/embed/components/ConnectednessMarker.vue';
import useSubscriptionStore from '@/subscription/store';

export default defineComponent({
    components: {
        Popup,
        InfoBox,
        ConnectednessMarker,
    },
    props: {
        id: { type: Number, required: true },
        domain: { type: String, default: '' }
    },
    setup(props) {
        const { state: subscriptionState } = useSubscriptionStore();
        const userStore = useUserStore();
        const targetUrl = ref(props.domain);
        const isValidUrl = computed(() => {
            // eslint-disable-next-line no-useless-escape
            const urlRegex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
            return urlRegex.test(targetUrl.value);
        });

        const startPreview = () => {
            if (!connected.value && !notConnected.value) {
                return;
            }

            try {
                let fullUrl;

                if (connected.value) {
                    const concatChar = targetUrl.value.includes('?') ? '&' : '?';
                    fullUrl = targetUrl.value +
                        concatChar + 'zurveyPreviewMode=' + props.id +
                        '&lang=' + userStore.state.langCode;
                }
                else {
                    fullUrl = '/embed-preview?url=' + targetUrl.value +
                        '&gi=' + subscriptionState.sid +
                        '&zurveyPreviewMode=' + props.id +
                        '&notConnectedMode=true' +
                        '&lang=' + userStore.state.langCode;
                }

                window.open(
                    fullUrl,
                    'winname',
                    'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1280,height=1024'
                );
            } catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
            }
        };

        const connected = ref(false);
        const notConnected = ref(false);
        const isScanningInProgress = ref(false);
        const showWarningMsg = ref(false);

        async function handleSubmit() {
            connected.value = false;
            notConnected.value = false;
            isScanningInProgress.value = true;
            try {
                const isTrackingCodeInstalled = await api.scanWebsite(targetUrl.value);
                if (isTrackingCodeInstalled) {
                    connected.value = true;
                } else {
                    notConnected.value = true;
                }
            } catch (e) {
                showWarningMsg.value = true;
                Sentry.captureException(e);
            }
            isScanningInProgress.value = false;
        }

        watch(targetUrl, () => {
            connected.value = false;
            notConnected.value = false;
            showWarningMsg.value = false;
        });

        const connectedInfoBoxText = i18next.t('SURVEYFORMS.PUBLISH_EMBED_PREVIEW_CONNECTED_INFOBOX', 'This website is connected with Zurvey.io. During preview, you can test the embed with the exception of Target functions.');
        const notConnectedInfoBoxText = i18next.t('SURVEYFORMS.PUBLISH_EMBED_PREVIEW_NOT_CONNECTED_INFOBOX', 'This website is not connected with Zurvey.io. During preview, some functionalities will not be available.<br><br>Including:<br><ul class="list-disc pl-4"><li>Triggers</li><li>Targeting</li><li>In-App embed</li></ul>');

        return {
            isValidUrl,
            targetUrl,
            handleSubmit,
            connected,
            notConnected,
            startPreview,
            connectedInfoBoxText,
            notConnectedInfoBoxText,
            isScanningInProgress,
            showWarningMsg,
        };
    }
});
</script>

