<template>
    <div class="w-36 relative cursor-pointer text-default">
        <div
            v-if="isOpen"
            v-on-clickaway="() => isOpen = false"
            class="w-36 mx-auto absolute z-10 shadow text-default rounded"
            style="bottom: 32px"
        >
            <div
                v-for="(variableName, index) in variables"
                :key="index"
                class="w-full p-1.5 bg-white hover:bg-neutral-100"
                :class="isActionUsed && variableName === 'Action' ? 'text-neutral-700' : 'text-black'"
                @click="copyVariable(variableName)"
            >
                {{ variableName }}
            </div>
        </div>
        <div
            class="px-6 py-1.5 flex rounded text-center"
            :class="colors"
        >
            <div class="w-full px-2">
                {{ $t('SURVEYFORMS.PUBLISH_TEMPLATE_VARIABLES', 'Variables') }}
            </div>
            <icon
                icon="chevron-down"
                class="float-right w-6"
                :class="colors"
                @click="!disabled && (isOpen = !isOpen)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import Icon from '@/components/ui/Icon.vue';
import {ref, defineComponent, computed} from 'vue';
import { directive as onClickaway } from 'vue-clickaway2';
import i18next from 'i18next';
import { toaster } from '@/utils/toaster';

export default defineComponent({
    name: 'DropdownButton',
    components: { Icon },
    directives: { onClickaway },
    props: {
        isDark: { type: Boolean, default: false},
        variables: { type: Array as () => Array<string>, default: () => ([])},
        isActionUsed: { type: Boolean, default: true},
        disabled: { type: Boolean, default: false},
    },
    setup(props) {
        const isOpen = ref(false);

        const colors = computed(() => {
            let bgColor = props.disabled ? 'bg-neutral-800' : 'bg-primary-700';
            let cursor = props.disabled ? 'cursor-default' : 'cursor-pointer';
            let textColor = props.isDark ? 'text-white' : 'text-black';
            return bgColor + ' ' + textColor + ' ' + cursor;
        });

        const copyVariable = (variableName: string) => {
            if (props.isActionUsed && variableName === 'Action')  return;
            navigator.clipboard.writeText('{{' + variableName + '}}');
            toaster.success(i18next.t('SURVEYFORMS.EMAIL_VARIABLE_COPIED', 'Variable is copied to clipboard'));
            isOpen.value = false;
        };

        return { isOpen, colors, copyVariable };
    }
});
</script>

