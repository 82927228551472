import { RouteConfig } from 'vue-router';
import { setLoading } from '@/utils/loading';
import { downloadExportById } from '@/exports/api/exportApi';
import useHomeDatasetsStore from '@/datasets/store';
const { setIsArchiveView, setOpenPermissionPopup } = useHomeDatasetsStore().actions;

const Home = () => import('../components/Home.vue');
const FilterBar = () => import('@/home/components/FilterBar/FilterBar.vue');

const routes: RouteConfig[] = [
    {
        path: '/',
        name: 'home',
        components: {
            default: Home,
            nav: FilterBar,
        },
        beforeEnter: async (to, from, next) => {
            if (to.query.downloadExport) {
                setLoading(true);
                await downloadExportById(to.query.downloadExport);
                setLoading(false);
            }
            if (to.query.permissions && typeof to.query.permissions === 'string') {
                setOpenPermissionPopup(to.query.permissions);
            }
            setIsArchiveView(false);
            next();
        }
    },
    {
        path: '/archive',
        components: {
            default: Home,
            nav: FilterBar,
        },
        beforeEnter: async (to, from, next) => {
            Sentry.addBreadcrumb({
                message: 'Go to archive list',
                level: 'info'
            });
            setIsArchiveView(true);
            next();
        }
    }
];

export default routes;
