<template>
    <div>
        <z-input
            v-model.trim="searchQuery"
            :placeholder="$t('DATATABLE.SEARCH_PLACEHOLDER', 'Type to search (minimum 3 characters)...')"
        />
        <div
            class="grid"
            :style="{ 'grid-template-columns': 'min-content auto auto min-content min-content min-content min-content' }"
        >
            <list-header
                v-model="sorting"
            />
            <list-item
                v-for="campaign in sortedAndFilteredCampaigns"
                :key="campaign.id"
                :campaign="campaign"
                @action="handleAction"
                @wave-action="handleWaveAction"
                @set-is-active="setIsActive(campaign.id, $event)"
            />
        </div>
        <z-button
            class="block mx-auto mt-8"
            @click="$emit('open-popup')"
        >
            {{ $t('AUDIENCE.BUTTON_CREATE_NEW_CAMPAIGN', 'Create new campaign') }}
        </z-button>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import ListHeader from './CampaignListHeader.vue';
import ListItem from './CampaignListItem.vue';
import { useCampaigns } from '@/campaign';
import { Campaign, Wave } from '@/campaign/types';
import { AudienceWithSegments } from '@/audience/types';
import getWaveIconType from '../getWaveIconType';
import { toaster } from '@/utils/toaster';
import i18next from 'i18next';
import { TabIndex } from '../popup/CreateNewCampaignPopup.vue';
import confirmPopup from '@/utils/confirmPopup';
import { OpenPopupPayload } from '../CampaignEditor.vue';

export type MappedCampaign = Campaign & { waves: Wave[], sending: Array<{ type: string, active: boolean }> }

export interface CampaignAction {
    type: string,
    id: string,
}

export interface WaveAction {
    type: string,
    wave: {
        id: string,
        campaignId: string,
        type: string,
        isRecurring: boolean,
        reminderType: string,
    },
}

export default defineComponent({
    components: { ListHeader, ListItem },
    props: {
        campaigns: { type: Array as () => Array<Campaign>, required: true },
        audiences: { type: Array as () => Array<AudienceWithSegments>, required: true },
    },
    emits: ['open-popup', 'update-campaigns'],
    setup(props, context) {
        const searchQuery = ref('');
        const sorting = ref<{ sortBy: 'campaign'|'recipients'|'sending'|'lastSubmittedAt'|'completion'|null, ascending: boolean }>({ sortBy: null, ascending: true });

        const sortedAndFilteredCampaigns = computed<MappedCampaign[]>(() => {
            return props.campaigns
                .filter(c => {
                    if (searchQuery.value.trim().length < 3) return true;

                    return c.name.toLowerCase().includes(searchQuery.value.trim().toLowerCase());
                })
                .map(c => {
                    const audience = props.audiences.find(a => a.uid == c.audienceId);
                    const recipients = c.segmentId ? audience?.segments.find(s => s.id == c.segmentId)?.name : audience?.name;
                    const sending = c.waves?.reduce<{ type: string, active: boolean }[]>((acc, wave) => {
                        const type = getWaveIconType(wave.type);
                        const active = !!wave.lastSubmittedAt || wave.completedCount > 0;

                        if (!acc.some(a => a.type == type && a.active == active )) {
                            acc.push({ type, active });
                        }
                        return acc;
                    }, []) || [];

                    return {
                        ...c,
                        recipients,
                        sending,
                        waves: c.waves?.map(w => {
                            return {
                                ...w,
                                recipients: w.segmentId ? audience?.segments.find(s => s.id == w.segmentId)?.name : audience?.name
                            };
                        }) || [],
                    };
                })
                .sort((c1, c2) => {
                    if (sorting.value.sortBy == null) return 0;

                    switch (sorting.value.sortBy) {
                        case 'campaign':
                        case 'recipients':
                        case 'lastSubmittedAt':
                            return c1[sorting.value.sortBy] - c2[sorting.value.sortBy];
                        case 'sending':
                            return 0;
                        case 'completion':
                            return (c1.completedCount || 0)/(c1.recipientCount || 0) - (c2.completedCount || 0)/(c2.recipientCount || 0);
                    }
                })
                .sort(() => sorting.value.ascending ? -1 : 1);
        });

        const { updateExistingCampaign, removeCampaign } = useCampaigns();

        const deleteCampaign = async (campaign: Campaign) => {
            const confirmed = await confirmPopup.destruction(
                i18next.t('AUDIENCE.POPUP_DELETE_CAMPAIGN_TITLE', 'Deleting campaign'),
                i18next.t('AUDIENCE.POPUP_DELETE_CAMPAIGN_DESCRIPTION', {
                    defaultValue: 'Are you sure to delete "{{ campaignName }}"?',
                    campaignName: campaign.name,
                }),
                i18next.t('BUTTONS.DELETE', 'Delete'),
                i18next.t('GLOBAL.CANCEL', 'Cancel')
            );

            if (confirmed) {
                try {
                    await removeCampaign(campaign.id!);
                    toaster.success(i18next.t('AUDIENCE.CAMPAIGN_DELETE_SUCCEEDED', {
                        defaultValue: 'Campaign "{{ campaignName }}" was deleted successfully!',
                        campaignName: campaign.name,
                    }));
                }
                catch (e) {
                    Sentry.captureException(e);
                    toaster.error(i18next.t('AUDIENCE.CAMPAIGN_DELETE_FAILED', {
                        defaultValue: 'Could not delete campaign "{{ campaignName }}"!',
                        campaignName: campaign.name,
                    }));
                }
            }
        };

        function handleAction(action: CampaignAction) {
            let tab;

            switch (action.type) {
                case 'edit':
                    tab = TabIndex.Recipients;
                    break;
                case 'customization':
                    tab = TabIndex.EmailCustomization;
                    break;
                case 'summary':
                    tab = TabIndex.Summary;
                    break;
                case 'sending':
                    tab = TabIndex.Sending;
                    break;
                case 'delete':
                    deleteCampaign(props.campaigns.find(c => c.id === action.id)!);
                    return;
            }

            context.emit('open-popup', {
                tab,
                campaign: props.campaigns.find(c => c.id === action.id),
                isEdit: action.type === 'edit'
            });
        }

        function handleWaveAction(action: WaveAction) {
            const payload: OpenPopupPayload = {
                tab: (action.type == 'schedule' || action.wave.type == 'scheduled_reminder') ? TabIndex.Schedule : undefined,
                campaign: props.campaigns.find(c => c.id === action.wave.campaignId),
                waveId: +action.wave.id,
                isReminder: (action.type == 'reminder' || action.wave.type == 'scheduled_reminder') ? true : action.wave.type == 'reminder',
                isRecurring: action.wave.isRecurring,
                reminderType: action.wave.reminderType,
            };
            context.emit('open-popup', payload);
        }

        async function setIsActive(id, isActive) {
            try {
                const campaign = {
                    ...props.campaigns.find(c => c.id === id)!,
                    isActive
                };
                await updateExistingCampaign(campaign);
                if (isActive) {
                    toaster.success(i18next.t('AUDIENCE.CAMPAIGN_ACTIVATED', 'Campaign activated'));
                }
                else {
                    toaster.success(i18next.t('AUDIENCE.CAMPAIGN_DEACTIVATED', 'Campaign deactivated'));
                }
                const newCampaigns = props.campaigns.map(c => c.id == id ? campaign : c);

                context.emit('update-campaigns', newCampaigns);
            }
            catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG'));
            }
        }

        return {
            searchQuery, sorting, sortedAndFilteredCampaigns, handleAction, handleWaveAction, setIsActive,
        };
    }
});
</script>
