<template>
    <div>
        <div v-if="!showSettingsOption">
            <div
                v-if="isDisconnected"
                data-test-id="disconnectivity-warning"
                class="bg-yellow-100 border border-yellow-500 rounded p-2 m-4"
            >
                {{ $t('SURVEYFORMS.PUBLISH_EMBED_DISCONNECTED_WARNING', 'Your website is not connected with Zurvey.io. Please make sure the tracking code is installed properly. You can set it up in the Settings') }} / <router-link
                    class="font-bold"
                    to="/settings/survey-tracking"
                >
                    {{ $t('SETTINGS.SURVEY_TRACKING', 'Survey tracking code') }}
                </router-link>
            </div>
            <div
                v-if="embedSettings.length === 0"
                class="m-2"
            >
                <img
                    src="@/assets/images/empty_dog.png"
                    class="m-auto"
                    style="width: 290px; max-height: unset; height: 290px;"
                >
                <div class="my-2 flex justify-center">
                    {{ $t('SURVEYFORMS.PUBLISH_EMBED_SETTINGS_EMPTY', 'You have no embed settings yet.') }}
                </div>
            </div>
            <embed-settings-list
                v-else
                :embed-settings="embedSettings"
                :is-disconnected="isDisconnected"
                @select="showSettingsOption = true"
            />
            <div class="text-center mt-2">
                <z-button
                    class="text-sm"
                    data-test-id="create-embed-settings"
                    @click="showSettingsOption = true"
                >
                    {{
                        embedSettings.length === 0 ?
                            $t('SURVEYFORMS.PUBLISH_EMBED_EMPTY_BUTTON', 'Set up your first embedded survey') :
                            $t('SURVEYFORMS.PUBLISH_EMBED_NOT_EMPTY_BUTTON', 'Set up embedded survey')
                    }}
                </z-button>
            </div>
        </div>
        <embed-settings
            v-else
            @close="close()"
        />
    </div>
</template>

<script lang="ts">
import EmbedSettingsList from '@/survey/embed/components/EmbedSettingsList.vue';
import EmbedSettings from '@/survey/embed/components/EmbedSettings.vue';
import { defineComponent, onUnmounted, ref, toRefs, computed, onMounted } from 'vue';
import useEmbedStore from '@/survey/embed/store/embedStore';
import useSubscriptionStore from '@/subscription/store';
import dayjs from 'dayjs';

export default defineComponent({
    components: { EmbedSettingsList, EmbedSettings, },
    setup(_,ctx) {
        const embedStore = useEmbedStore();
        const { surveyEmbedSettings } = toRefs(embedStore.state);
        const { resetActiveSettings, loadSurveyEmbedSettings } = embedStore.actions;
        const showSettingsOption = ref(false);

        function close() {
            showSettingsOption.value = false;
            ctx.emit('settingsClosed');
            resetActiveSettings();
        }
        onUnmounted(() => {
            resetActiveSettings();
        });

        onMounted(() => {
            loadSurveyEmbedSettings();
        });

        const { state } = useSubscriptionStore();
        const { domains } = toRefs(state);
        const isDisconnected = computed(() => Object.keys(domains.value).length === 0 || !Object.values(domains.value).some(v => v > dayjs().subtract(1, 'day').unix()) );

        return {
            embedSettings: surveyEmbedSettings,
            showSettingsOption,
            close,
            isDisconnected,
        };
    }
});

</script>

