<template>
    <div class="relative rounded shadow p-4">
        <template v-if="empty">
            <div class="font-bold">
                {{ title }}
            </div>
            <div class="flex justify-center">
                <img
                    src="@/assets/images/empty_state-01.png"
                    class="max-h-64"
                >
            </div>
        </template>
        <template v-else>
            <chart
                ref="chartRef"
                :options="chartOptions"
            />
        </template>
        <div class="absolute top-0 right-0 mr-3 mt-3">
            <PopupExportButton
                :disabled="empty"
                @click="downloadChart"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { Chart } from 'highcharts-vue';
import PopupExportButton from '../PopupExportButton.vue';

import { getDates, getLegend } from '../../utils/';
import { state } from '../../store';
import { userState } from '@/user/store';
import { createColorScale } from '@/utils/color';

import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
Exporting(Highcharts);
OfflineExporting(Highcharts);
import i18next from 'i18next';
import dayjs from 'dayjs';

export default defineComponent({
    components: { Chart, PopupExportButton },
    props: {
        name: { type: String, required: true }
    },
    setup(props) {
        const chartRef = ref();

        const colors = computed(() => createColorScale(userState.primaryColor, 4).reverse());

        const downloadChart = () => {
            chartRef.value
                .chart
                .exportChartLocal({
                    type: 'image/png',
                    filename: title.value
                });
        };

        const dates = computed(() => getDates(Object.keys(state.dateDistribution)));

        const data = computed(() => {
            return dates.value.dates.map((date) => {
                const rates = {
                    bounced: 0,
                    opened: 0,
                    clicked: 0,
                    completed: 0,
                };

                Object.entries(state.dateDistribution).forEach(([d, r]) => {
                    if (dayjs(d).format(dates.value.parseFormat) == date) {
                        rates.bounced += Object.values(r)[0].bounced;
                        rates.opened += Object.values(r)[0].opened;
                        rates.clicked += Object.values(r)[0].clicked;
                        rates.completed += Object.values(r)[0].completed;
                    }
                });

                return rates;
            }, {});
        });

        const title = computed(() => `${props.name} - ${i18next.t('CAMPAIGN_METRICS.METRICS_BY_TIME', 'Metrics by time')}`);
        const empty = computed(() => data.value.every(date => Object.values(date).every(m => !m)));

        const chartOptions = computed(() => ({
            chart: {
                type: 'column',
                backgroundColor: null,
            },
            exporting: { enabled: false },
            title: {
                text: title.value,
                align: 'left',
                style: {
                    'font-weight': 'bold',
                    'font-size': '16px',
                }
            },
            xAxis: {
                categories: dates.value.dates
            },
            yAxis: {
                title: {
                    text: undefined
                }
            },
            credits: {
                enabled: false,
            },
            colors: colors.value,
            series: ['bounced', 'opened', 'clicked', 'completed'].map(metric => ({
                name: getLegend(metric),
                data: data.value.map(el => el[metric])
            })),
        }));

        return { chartRef, downloadChart, chartOptions, empty, title };
    }
});
</script>
