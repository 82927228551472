<script lang="ts">
import { toaster } from '@/utils/toaster';
import { defineComponent } from 'vue';

import api from '@/api';
import i18next from 'i18next';
import useUserStore from '@/user/store';


export default defineComponent({
    name: 'ContactUs',
    components: {

    },
    setup: () => {
        const { groupId } = useUserStore().state;

        return {
            sendContactRequest: async () => {
                const response = await api.requestUpgrade(groupId, 'audience');
                if (response === 'success') {
                    toaster.success(i18next.t('DASHBOARD.UPGRADE_REQUEST_SUCCESS', 'Thank you! Your account manager will be in touch soon.'));
                }
                else {
                    toaster.error(i18next.t('DASHBOARD.EMAIL_FAILURE', 'Something went wrong, please try again.'));
                }
            }
        };
    }
});
</script>
<template>
    <div class="flex flex-col items-center w-1/2 mx-auto mt-4">
        <z-button @click="sendContactRequest">
            {{ $t('AUDIENCE.BUTTON_CONTACT_US', 'Contact us') }}
        </z-button>
        <section class="mt-2 text-center text-sm font-light text-neutral-700">
            {{ $t('AUDIENCE.DISABLED_DESCRIPTION', 'Audience feature is not acitvated in your group, to create campaigns please contact your account manager') }}
        </section>
    </div>
</template>
