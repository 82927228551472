<template>
    <div>
        <div
            class="w-1/2 ml-auto mr-auto"
        >
            <div
                v-if="popupType !== DisplayTypeEnum.IN_PAGE"
                class="flex p-2 items-center"
            >
                <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_SHOW_POPUP_TITLE', 'Show popup title') }}</span>
                <div class="w-1/2 text-right">
                    <z-toggle
                        :model-value="activeSettings.displaySettings.showTitle"
                        data-test-id="customization-show-title"
                        class="-mr-2"
                        @update:modelValue="changeDisplaySettings({type: 'showTitle', value: $event})"
                    />
                </div>
            </div>
            <div
                v-if="popupType !== DisplayTypeEnum.IN_PAGE && activeSettings.displaySettings.showTitle"
                class="flex p-2 items-center"
            >
                <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_POP_TITLE', 'Popup title') }}</span>
                <z-input
                    :model-value="activeSettings.displaySettings.title"
                    class="w-1/2 pl-6"
                    data-test-id="title"
                    @update:modelValue="changeDisplaySettings({type: 'title', value: $event})"
                />
            </div>
            <div
                class="flex p-2 items-center"
            >
                <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_BACKGROUND_COLOR', 'Background color') }}</span>
                <color-picker
                    :model-value="activeSettings.displaySettings.backgroundColor"
                    data-test-id="backgroundColor"
                    class="pl-6 w-1/2"
                    @update:modelValue="changeDisplaySettings({type: 'backgroundColor', value: $event})"
                />
            </div>
            <div
                v-if="popupType === DisplayTypeEnum.FULL_PAGE && bgImage && bgImage.panelSettings"
                class="flex p-2 items-center"
            >
                <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_PANEL_COLOR', 'Panel color') }}</span>
                <color-picker
                    :model-value="activeSettings.displaySettings.panelColor"
                    data-test-id="panelColor"
                    class="pl-6 w-1/2"
                    @update:modelValue="changeDisplaySettings({type: 'panelColor', value: $event})"
                />
            </div>
            <div
                class="flex p-2 items-center"
            >
                <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_TEXT_COLOR', 'Text color') }}</span>
                <color-picker
                    :modelValue="activeSettings.displaySettings.textColor"
                    data-test-id="textColor"
                    class="pl-6 w-1/2"
                    @update:modelValue="changeDisplaySettings({type: 'textColor', value: $event})"
                />
            </div>
            <div
                class="flex p-2 items-center"
            >
                <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_PRIMARY_COLOR', 'Primary color') }}</span>
                <color-picker
                    :modelValue="activeSettings.displaySettings.primaryColor"
                    data-test-id="primaryColor"
                    class="pl-6 w-1/2"
                    @update:modelValue="changeDisplaySettings({type: 'primaryColor', value: $event})"
                />
            </div>
            <div
                v-if="popupType === DisplayTypeEnum.FULL_PAGE || popupType === DisplayTypeEnum.SLIDER"
                class="flex p-2 items-center"
            >
                <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_MARGIN', 'Margin') }}</span>
                <z-input
                    :model-value="activeSettings.displaySettings.margin"
                    class="w-1/2 pl-6"
                    type="number"
                    @update:modelValue="changeDisplaySettings({type: 'margin', value: $event})"
                >
                    <template #endIcon>
                        <span class="text-neutral-800 -ml-12 z-10">
                            px
                        </span>
                    </template>
                </z-input>
            </div>
            <div
                v-if="popupType !== DisplayTypeEnum.IN_PAGE"
                class="flex p-2 items-center"
            >
                <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_BORDER_RADIUS', 'Border radius') }}</span>
                <z-input
                    :model-value="activeSettings.displaySettings.borderRadius"
                    class="w-1/2 pl-6"
                    data-test-id="borderRadius"
                    type="number"
                    @update:modelValue="changeDisplaySettings({type: 'borderRadius', value: $event})"
                >
                    <template #endIcon>
                        <span class="text-neutral-800 -ml-12 z-10">
                            px
                        </span>
                    </template>
                </z-input>
            </div>
            <div
                v-if="popupType === DisplayTypeEnum.SIDE_TAB"
                class="flex p-2 items-center"
            >
                <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_BUTTON_TEXT', 'Button text') }}</span>
                <z-input
                    :model-value="activeSettings.displaySettings.buttonText"
                    data-test-id="customization-button-text"
                    class="w-1/2 pl-6"
                    @update:modelValue="changeDisplaySettings( {type: 'buttonText', value: $event})"
                />
            </div>
            <div
                v-if="popupType === DisplayTypeEnum.SLIDER"
                class="flex p-2 items-center"
            >
                <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_DIRECTION', 'Direction') }}</span>
                <z-select
                    :model-value="activeSettings.displaySettings.direction"
                    class="pl-6 w-1/2"
                    @update:modelValue="changeDisplaySettings({type: 'direction', value: $event})"
                >
                    <option
                        v-for="(type, index) in directionOptions"
                        :key="index"
                        :value="type.id"
                    >
                        {{ type.text }}
                    </option>
                </z-select>
            </div>
            <div
                v-if="popupType !== DisplayTypeEnum.IN_PAGE"
                class="flex p-2 items-center"
            >
                <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_SHADOW', 'Shadow') }}</span>
                <div class="w-1/2 text-right">
                    <z-toggle
                        :model-value="activeSettings.displaySettings.shadow"
                        data-test-id="customization-shadow"
                        class="-mr-2"
                        @update:modelValue="changeDisplaySettings({type: 'shadow', value: $event})"
                    />
                </div>
            </div>
            <div
                v-if="popupType !== DisplayTypeEnum.IN_PAGE"
                class="flex p-2 items-center"
            >
                <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_BG_OVERLAY', 'Background overlay') }}</span>
                <div class="w-1/2 text-right">
                    <z-toggle
                        :model-value="activeSettings.displaySettings.backgroundOverlay"
                        data-test-id="customization-bo"
                        class="-mr-2"
                        @update:modelValue="changeDisplaySettings({type: 'backgroundOverlay', value: $event})"
                    />
                </div>
            </div>
            <div
                v-if="popupType === DisplayTypeEnum.FULL_PAGE && bgImage"
                class="flex p-2 items-center"
            >
                <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_BG_IMAGE', 'Show background image') }}</span>
                <div class="w-1/2 text-right">
                    <z-toggle
                        :model-value="activeSettings.displaySettings.backgroundImage"
                        data-test-id="customization-bg-image"
                        class="-mr-2"
                        @update:modelValue="changeDisplaySettings({type: 'backgroundImage', value: $event})"
                    />
                </div>
            </div>
            <div
                v-if="popupType !== DisplayTypeEnum.IN_PAGE"
                class="flex p-2 items-center"
            >
                <span class="w-1/2">{{ $t('SURVEYFORMS.PUBLISH_EMBED_SETTINGS_AUTOCLOSE', 'Close automatically after submitted') }}</span>
                <div class="w-1/2 text-right">
                    <z-toggle
                        :model-value="activeSettings.displaySettings.closeOnSubmit"
                        data-test-id="close-on-submit"
                        class="-mr-2"
                        @update:modelValue="changeDisplaySettings({type: 'closeOnSubmit', value: $event})"
                    />
                </div>
            </div>
            <div
                v-if="canUseWhitelabel && popupType !== DisplayTypeEnum.FULL_PAGE"
                class="flex p-2 items-center"
            >
                <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_ZURVEY_REMOVE_BRANDING', 'Remove zurvey branding') }}</span>
                <div class="w-1/2 text-right">
                    <z-toggle
                        :model-value="activeSettings.displaySettings.removeBranding"
                        data-test-id="customization-branding"
                        class="-mr-2"
                        @update:modelValue="changeDisplaySettings({type: 'removeBranding', value: $event})"
                    />
                </div>
            </div>
            <div
                v-if="popupType !== DisplayTypeEnum.FULL_PAGE"
                class="flex p-2 items-center"
            >
                <span class="float-left w-1/2">{{ $t('SURVEYFORMS.PUBLISH_CUSTOMIZATION_ZURVEY_REMOVE_PRIVACY', 'Remove Privacy Policy') }}</span>
                <div class="w-1/2 text-right">
                    <z-toggle
                        :model-value="activeSettings.displaySettings.removePrivacyPolicy"
                        data-test-id="customization-privacy"
                        class="-mr-2"
                        @update:modelValue="changeDisplaySettings({type: 'removePrivacyPolicy', value: $event})"
                    />
                </div>
            </div>
        </div>
        <embed-remove-privacy-popup
            v-if="activeSettings.displaySettings.removePrivacyPolicy && !readPrivacyWarning && shouldShowPP"
            data-test-id="remove-privacy-policy-popup"
            @ok="readPrivacyWarning = true"
            @cancel="changeDisplaySettings({type: 'removePrivacyPolicy', value: false})"
        />
    </div>
</template>

<script>
import ColorPicker from '@/components/ui/ColorPicker.vue';
import ZSelect from '@/components/ui/Select';
import planFeatureTypeEnum from '@/domain/planFeatureTypeEnum';
import EmbedRemovePrivacyPopup from '@/components/surveyBuilder/EmbedRemovePrivacyPopup';
import { computed, defineComponent, ref, toRefs } from 'vue';
import useSubscriptionStore from '@/subscription/store';
import i18next from 'i18next';
import useEmbedStore from '@/survey/embed/store/embedStore.ts';
import { DisplayTypeEnum } from '@/survey/embed/store/types';
import { useNamespacedState } from 'vuex-composition-helpers';

export default defineComponent({
    components: {
        EmbedRemovePrivacyPopup,
        ZSelect,
        ColorPicker,
    },
    props: {
        popupType: { type: String, default: null },
        isCustomizationOpened: { type: Boolean, default: false },
    },
    setup(_, { emit }) {
        const { canUseFeature } = useSubscriptionStore().getters;
        const embedStore = useEmbedStore();
        const { activeSettings } = toRefs(embedStore.state);
        const { changeSettingsOptions } = embedStore.actions;
        const { bgImage } = useNamespacedState('survey', ['bgImage']);
        const canUseWhitelabel = computed(() => canUseFeature.value(planFeatureTypeEnum.WHITELABEL));
        const shouldShowPP = ref(false);
        const readPrivacyWarning = ref(false);

        const directionOptions = ref([
            { id: 'top', text: i18next.t('SURVEYFORMS.PUBLISH_EMBED_CUSTOMIZATION_DIRECTION_TOP', 'Top') },
            { id: 'right', text: i18next.t('SURVEYFORMS.PUBLISH_EMBED_CUSTOMIZATION_DIRECTION_RIGHT', 'Right') },
            { id: 'left', text: i18next.t('SURVEYFORMS.PUBLISH_EMBED_CUSTOMIZATION_DIRECTION_LEFT', 'Left') },
            { id: 'bottom', text: i18next.t('SURVEYFORMS.PUBLISH_EMBED_CUSTOMIZATION_DIRECTION_BOTTOM', 'Bottom') }
        ]);

        function changeDisplaySettings(newSetting) {
            changeSettingsOptions('displaySettings', {
                ...activeSettings.value.displaySettings,
                [newSetting.type]: newSetting.value
            });
            if (newSetting.type === 'removePrivacyPolicy') {
                shouldShowPP.value = true;
            }
            emit('customization-changed');
        }

        return {
            canUseWhitelabel,
            directionOptions,
            readPrivacyWarning,
            changeDisplaySettings,
            activeSettings,
            shouldShowPP,
            DisplayTypeEnum,
            bgImage
        };
    },
});
</script>
