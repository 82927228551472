<template>
    <div class="w-112 pl-8 space-y-2">
        <div class="relative">
            <ZSelect
                :modelValue="settings.periodType || undefined"
                :label="$t('HOME_SCHEDULED_EXPORT_POPUP_DATE_PERIOD', 'Date period')"
                :options="periodOptions"
                @update:modelValue="updateSchedule('periodType', $event)"
            />
            <ZToggle
                :modelValue="settings.isActive"
                class="absolute left-0 bottom-0 transform -translate-x-full -translate-y-2"
                @update:modelValue="updateSchedule('isActive', $event)"
            />
        </div>
        <div class="flex gap-2 relative">
            <ZSelect
                v-if="settings.periodType != 'daily'"
                :modelValue="settings.day"
                :label="daySelectLabel"
                class="flex-grow"
                :options="dayOptions"
                :disabled="settings.isLast"
                @update:modelValue="updateSchedule('day', +$event)"
            />
            <ZSelect
                :modelValue="settings.hours.toString()"
                :label="$t('AUDIENCE.HOURS', 'Hours')"
                class="w-16"
                :options="hourOptions"
                @update:modelValue="updateSchedule('hours', +$event)"
            />
            <ZSelect
                :modelValue="settings.minutes.toString()"
                :label="$t('AUDIENCE.MINUTEs', 'Minutes')"
                class="w-16"
                :options="minuteOptions"
                @update:modelValue="updateSchedule('minutes', +$event)"
            />
            <div
                v-if="showTimeZoneWarning"
                class="absolute right-0 bottom-0 transform translate-x-full -translate-y-1"
            >
                <MoreInfo class="ml-2">
                    {{ $t('AUDIENCE.DIFFERENT_TIMEZONE', 'You are in a different timezone than the original time was set') }}
                </MoreInfo>
            </div>
        </div>
        <div
            v-if="settings.day && settings.day > 28 && !settings.isLast"
            class="text-xs"
        >
            {{ $t('AUDIENCE.NOT_EXISTING_SELECTED_DATE_INFO', 'If the selected date does not exist in a given month, the campaign will be sent on the last day of that month') }}
        </div>
        <div v-if="settings.periodType == 'monthly'">
            <ZToggle
                :modelValue="settings.isLast"
                :labelText="$t('HOME_SCHEDULED_EXPORT_POPUP_LAST_DAY_OF_MONTH', 'Last day of month')"
                @update:modelValue="updateSchedule('isLast', $event)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import ZSelect from '@/components/ui/Select.vue';
import MoreInfo from '@/components/ui/MoreInfo.vue';
import { ScheduleSettings } from '@/campaign/types';
import dayjs from 'dayjs';

export default defineComponent({
    components: { ZSelect, MoreInfo },
    props: {
        settings: { type: Object as () => ScheduleSettings, required: true }
    },
    emits: ['update-settings'],
    setup(props, { emit }) {

        function updateSchedule(setting, value) {
            if (setting == 'periodType') {
                emit('update-settings', {
                    ...props.settings,
                    day: value == 'daily' ? undefined : 1,
                    isLast: false,
                    [setting]: value,
                });
            }
            else {
                emit('update-settings', {
                    ...props.settings,
                    [setting]: value
                });
            }
        }

        const showTimeZoneWarning = computed(() => props.settings.timeZone != dayjs.tz.guess());

        return { updateSchedule, showTimeZoneWarning };
    },
    computed: {
        periodOptions() {
            return [
                { text: this.$t('HOME_SCHEDULED_EXPORT_POPUP_DATE_PERIOD_MONTHLY', 'Monthly'), value: 'monthly' },
                { text: this.$t('HOME_SCHEDULED_EXPORT_POPUP_DATE_PERIOD_WEEKLY', 'Weekly'), value: 'weekly' },
                { text: this.$t('HOME_SCHEDULED_EXPORT_POPUP_DATE_PERIOD_DAILY', 'Daily'), value: 'daily' },
            ];
        },
        daySelectLabel() {
            return this.settings.periodType == 'monthly' ?
                this.$t('HOME_SCHEDULED_EXPORT_POPUP_NUMBER_OF_DAY', 'Number of day') :
                this.$t('HOME_SCHEDULED_EXPORT_POPUP_ON_THIS_DAY', 'On this day');
        },
        dayOptions() {
            switch (this.settings.periodType) {
                case 'monthly':
                    return Array(31).fill(true).map((_, index) => ({ text: (index + 1).toString(), value: index + 1 }));
                case 'weekly':
                    return [
                        { text: this.$t('HOME_SCHEDULED_EXPORT_POPUP_PERIOD_DAILY_1', 'Monday'), value: 1 },
                        { text: this.$t('HOME_SCHEDULED_EXPORT_POPUP_PERIOD_DAILY_2', 'Tuesday'), value: 2 },
                        { text: this.$t('HOME_SCHEDULED_EXPORT_POPUP_PERIOD_DAILY_3', 'Wednesday'), value: 3 },
                        { text: this.$t('HOME_SCHEDULED_EXPORT_POPUP_PERIOD_DAILY_4', 'Thursday'), value: 4 },
                        { text: this.$t('HOME_SCHEDULED_EXPORT_POPUP_PERIOD_DAILY_5', 'Friday'), value: 5 },
                        { text: this.$t('HOME_SCHEDULED_EXPORT_POPUP_PERIOD_DAILY_6', 'Saturday'), value: 6 },
                        { text: this.$t('HOME_SCHEDULED_EXPORT_POPUP_PERIOD_DAILY_7', 'Sunday'), value: 7 },
                    ];
                case 'daily':
                default:
                    return [];
            }
        },
        hourOptions() {
            return Array(24).fill(true).map((_, index) => index.toString());
        },
        minuteOptions() {
            return Array(60).fill(true).map((_, index) => index.toString());
        }
    }
});
</script>
