<template>
    <div class="grid grid-cols-2 grid-rows-2">
        <div
            v-for="(options,index) in chartOptions"
            :key="index"
            class="odd:border-r border-neutral-500"
            :class="{
                'border-b': index < 2,
                'pt-4': index > 1
            }"
        >
            <high-charts
                ref="chartRefs"
                :options="options"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { Chart } from 'highcharts-vue';

import { state } from '../../store';
import { getRateName, getLegend } from '../../utils';
import useCommonStore from '@/dashboard/common/store/commonStore';
import useUserStore from '@/user/store';
import { createColorScale } from '@/utils/color';
import { setLoading } from '@/utils/loading';
import { toaster } from '@/utils/toaster';
import exportCharts from '@/dashboard/common/utils/exportMultipleCharts';

import i18next from 'i18next';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';

Exporting(Highcharts);
OfflineExporting(Highcharts);

import { filterCampaigns } from '../../utils';

export default defineComponent({
    components: { 'high-charts': Chart },
    setup() {
        const chartRefs = ref();
        const { state: commonState } = useCommonStore();
        const filteredCampaigns = computed(() => filterCampaigns(commonState.campaigns, state.filters));
        const { state: userState } = useUserStore();

        const colors = computed(() => createColorScale(userState.primaryColor, filteredCampaigns.value.length + 2));

        const metrics = ['bounced', 'opened', 'clicked', 'completed'];

        const data = computed<{ [metric: string]: { [campaign: string]: number } }>(() => [...metrics, 'recipients'].reduce((acc, metric) => {
            const count = {
                all: Object.values(state.countDistribution).reduce((total, campaign) => total + campaign[metric], 0)
            };

            Object.entries(state.countDistribution).forEach(([campaign, distribution]) => {
                count[campaign] = distribution[metric];
            });

            acc[metric] = count;

            return acc;
        }, {}));

        const chartOptions = computed(() => metrics.map((metric, index) => ({
            exporting: {
                enabled: false,
            },
            chart: {
                type: 'pie',
                backgroundColor: null,
                events: {
                    render(this: any) {
                        if (!this.customLegend) {
                            this.customLegend = this.renderer.g('custom-legend').add();

                            [metric, `not-${metric}`].forEach((type, index) => {
                                const icon = this.renderer
                                    .circle(12, 7 + index * 24, 6)
                                    .css({
                                        color: index != 0 ? colors.value[0] : colors.value[colors.value.length - 1]
                                    });
                                icon.add(this.customLegend);

                                const text = this.renderer
                                    .text(getLegend(type))
                                    .attr({
                                        x: 22,
                                        y: index * 24,
                                        'alignment-baseline': 'hanging'
                                    })
                                    .css({
                                        'font-size': '12px',
                                        'cursor': 'pointer'
                                    })
                                    .on('mouseover', () => {
                                        this.series[0].points.forEach(p => {
                                            if (p.type == type) {
                                                p.setState('select');
                                            }
                                            else {
                                                p.setState('inactive');
                                            }
                                        });
                                        this.series[1].points.forEach(p => {
                                            if (p.type == type) {
                                                p.setState('select');
                                            }
                                            else {
                                                p.setState('inactive');
                                            }
                                        });
                                    })
                                    .on('mouseout', () => {
                                        this.series[0].points.forEach(p => p.setState('normal'));
                                        this.series[1].points.forEach(p => p.setState('normal'));
                                    })
                                    .on('click', () => {
                                        // hide from inner circle
                                        this.series[1].points.forEach(p => p.type == type && p.setVisible(!p.visible));
                                        // hide from outer circle
                                        this.series[0].points.forEach(p => p.type == type && p.setVisible(!p.visible));

                                        const isVisible = this.series[0].points.find(p => p.type == type).visible;
                                        icon.css({ opacity: isVisible ? 1 : 0.2 });
                                        text.css({ opacity: isVisible ? 1 : 0.2, 'text-decoration-line': isVisible ? 'none' : 'line-through' });
                                    });

                                text.add(this.customLegend);
                            });
                            filteredCampaigns.value.forEach((campaign, index) => {
                                const icon = this.renderer
                                    .circle(12, 7 + (index + 2) * 24, 6)
                                    .css({
                                        color: colors.value[index + 1]
                                    });
                                icon.add(this.customLegend);

                                const text = this.renderer
                                    .text(campaign.name)
                                    .attr({
                                        x: 22,
                                        y: (index + 2) * 24,
                                        'alignment-baseline': 'hanging'
                                    })
                                    .css({
                                        'font-size': '12px',
                                        'cursor': 'pointer'
                                    })
                                    .on('mouseover', () => {
                                        this.series[0].points.forEach(p => {
                                            if (p.id == campaign.id) {
                                                p.setState('select');
                                            }
                                            else {
                                                p.setState('inactive');
                                            }
                                        });
                                        this.series[1].points.forEach(p => p.setState('inactive'));
                                    })
                                    .on('mouseout', () => {
                                        this.series[0].points.forEach(p => p.setState('normal'));
                                        this.series[1].points.forEach(p => p.setState('normal'));
                                    })
                                    .on('click', () => {
                                        this.series[0].points.forEach(p => {
                                            if (p.id == campaign.id) {
                                                // hide from outer circle
                                                p.setVisible(!p.visible);
                                                // modify inner circle
                                                const inner = this.series[1].points.find(innerPoint => innerPoint.type == p.type);
                                                if (inner) {
                                                    inner.update(p.visible ? inner.y + p.y : inner.y - p.y);
                                                }
                                            }
                                        });

                                        const isVisible = this.series[0].points.find(p => p.id == campaign.id).visible;
                                        icon.css({ 'opacity': isVisible ? 1 : 0.2 });
                                        text.css({ 'opacity': isVisible ? 1 : 0.2, 'text-decoration-line': isVisible ? 'none' : 'line-through' });
                                    });

                                text.add(this.customLegend);
                            });
                        }

                        const x = this.plotLeft + this.plotWidth / 2 - this.plotWidth * 0.1 + this.plotHeight / 2 + 20;
                        const y = this.plotTop + this.plotHeight / 2 - this.customLegend.element.getBoundingClientRect().height / 2;

                        this.customLegend.css({ transform: `translate(${x}px, ${y}px)` });
                    }
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    dataLabels: { enabled: false },
                    states: {
                        hover: {
                            halo: null
                        },
                    },
                    center: ['40%', '50%']
                }
            },
            title: {
                text: getRateName(metric),
                style: {
                    'color': 'var(--color-neutral-800)',
                    'font-size': '14px',
                },
            },
            credits: { enabled: false },
            legend: {
                enabled: false,
                align: 'right',
                verticalAlign: 'center',
                layout: 'vertical',
            },
            tooltip: {
                formatter(this: any) {
                    return `<b>${getLegend(this.point.type)}</b><br>${this.point.name}: ${this.y}`;
                }
            },
            series: [
                // outer ring, per campaign data
                {
                    size: '100%',
                    innerSize: '70%',
                    data: [
                        ...filteredCampaigns.value.map((c, index) => ({ id: c.id, name: c.name, type: metric, y: data.value[metric][c.id!], color: colors.value[index + 1] })),
                        ...filteredCampaigns.value.map((c, index) => ({ id: c.id, name: c.name, type: `not-${metric}`, y: data.value.recipients[c.id!] - data.value[metric][c.id!], color: colors.value[index + 1] })),
                    ],
                    point: {
                        events: {
                            mouseOver(this: any) {
                                this.series.points.forEach(p => {
                                    if (p.id == this.id) {
                                        p.setState('select');
                                    }
                                    else {
                                        p.setState('inactive');
                                    }
                                });
                            },
                            mouseOut(this: any) {
                                this.series.points.forEach(p => {
                                    p.setState('normal');
                                });
                            }
                        },
                    },
                },
                // inner ring, all data
                {
                    size: '70%',
                    innerSize: '60%',
                    data: [
                        { type: metric, name: i18next.t('GLOBAL.ALL'), y: data.value[metric].all, color: colors.value[colors.value.length -1] },
                        { type: `not-${metric}`, name: i18next.t('GLOBAL.ALL'), y: data.value.recipients.all - data.value[metric].all, color: colors.value[0] },
                    ],
                    point: {
                        events: {
                            mouseOver(this: any) {
                                // couldn't find a better way to reach all series data
                                chartRefs.value[index].chart.series.forEach(series => {
                                    series.points.forEach(p => {
                                        if (p.type == this.type) {
                                            p.setState('select');
                                        }
                                        else {
                                            p.setState('inactive');
                                        }
                                    });
                                });
                            },
                            mouseOut(this: any) {
                                chartRefs.value[index].chart.series.forEach(series => {
                                    series.points.forEach(p => {
                                        p.setState('normal');
                                    });
                                });
                            }
                        },
                    },
                },
            ],
        })));

        const downloadCharts = async () => {
            try {
                setLoading(true);
                await exportCharts('metrics-by-campaign', chartRefs.value);
            }
            catch (e) {
                Sentry.captureException(e);
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG'));
            }
            finally {
                setLoading(false);
            }
        };

        return { chartRefs, chartOptions, data, downloadCharts };
    }
});
</script>
