<template>
    <SelectableButton>
        <Icon
            class="text-5xl"
            :class="{
                'text-yellow-500': !hasEnoughEmailQuota,
                'text-primary-700': hasEnoughEmailQuota
            }"
            icon="calendar"
            type="fat"
        />
        <h1
            v-if="isWaveReminder"
            class="font-bold"
        >
            {{ $t('AUDIENCE.SCHEDULE_REMINDER', 'Schedule Reminder') }}
        </h1>
        <h1
            v-else
            class="font-bold"
        >
            {{ $t('AUDIENCE.SCHEDULE_SENDING', 'Schedule sending') }}
        </h1>
        <div class="flex-grow text-default text-left">
            {{ $t('AUDIENCE.SCHEDULED_SENDING_SUMMARY', 'Plan your campaign delivery. Choose the best time for your recipients to receive their surveys.') }}
        </div>
        <z-button
            v-if="isWaveReminder"
            @click.stop="$emit('set-schedule', false)"
        >
            {{ $t('AUDIENCE.SCHEDULE', 'Schedule') }}
        </z-button>
        <div
            v-else
            class="relative"
        >
            <z-button
                @click.stop="open = !open;"
            >
                {{ $t('AUDIENCE.SCHEDULUNG_OPTIONS', 'Scheduling Options') }}
                <Icon
                    :icon="open ? 'chevron-up' : 'chevron-down'"
                    class="ml-2"
                />
            </z-button>
            <div
                v-if="open"
                ref="dropdown"
                v-on-clickaway="() => open = false"
                class="absolute bg-white rounded shadow z-20 w-full text-left mt-2 text-default"
            >
                <div
                    class="cursor-pointer hover:bg-primary-700 hover:text-white p-2"
                    @click="$emit('set-schedule', false)"
                >
                    {{ $t('AUDIENCE.SET_EXACT_DATE', 'Set an exact date') }}
                </div>
                <div
                    class="cursor-pointer hover:bg-primary-700 hover:text-white p-2"
                    @click="$emit('set-schedule', true)"
                >
                    {{ $t('AUDIENCE.RECURRING_SENDS', 'Recurring sends') }}
                </div>
            </div>
        </div>
    </SelectableButton>
</template>

<script lang="ts">
import { defineComponent, ref, watch, nextTick } from 'vue';
import { directive } from 'vue-clickaway2';
import SelectableButton from '@/survey/components/publish/campaign/popup/views/common/SelectableButton.vue';
import Icon from '@/components/ui/Icon.vue';

export default defineComponent({
    components: { SelectableButton, Icon },
    directives: {
        onClickaway: directive,
    },
    props: {
        hasEnoughEmailQuota: { type: Boolean, required: true },
        isWaveReminder: { type: Boolean, default: false },
    },
    emits: ['set-schedule'],
    setup() {
        const open = ref(false);
        const dropdown = ref<HTMLDivElement>();

        watch(open, async (val) => {
            if (val) {
                await nextTick();
                dropdown.value?.scrollIntoView();
            }
        });

        return { open, dropdown };
    }
});
</script>
