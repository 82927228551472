<template>
    <table
        style="border: none; border-collapse: unset;"
        :style="outerTableStyle"
    >
        <tr v-if="styleOptions.showTitle">
            <td
                id="titlePlaceholderRating"
                :style="'padding: 0 0 5px; font-size: 14px; line-height: 16px; color: ' + styleOptions.textColor + '; font-family: ' + title_font_name + ', Sans-Serif; font-weight: ' + title_font_weight"
                colspan="5"
            />
        </tr>
        <tr>
            <td>
                <table>
                    <tr>
                        <td
                            v-for="index in 5"
                            :key="index"
                            :style="getIconStyle(index)"
                        >
                            <a
                                target="_blank"
                                :disabled="!isLive"
                                style="display: inline-block; width: 100%; height: 100%; cursor: pointer; vertical-align: bottom;"
                                :data-zurvey-id="'option-'+index"
                                :href="url + '/' + uniqueName + '?' + params + element.guid + '=' + index"
                            >
                                <img
                                    :src="getEmbedIconUrl(index)"
                                    :height="styleOptions.iconSize || 32"
                                    :width="styleOptions.iconSize || 32"
                                    style="border:0; margin-bottom: 0"
                                >
                            </a>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr
            v-if="!noLabels && styleOptions.showLabels"
            :style="`color: ${styleOptions.textColor}; font-family: '${answer_font_name}', Sans-Serif;`"
        >
            <td
                v-for="(label, index) in element.rating_labels"
                v-show="!onlyFirstAndLastLabel || index === 0 || index === 2 || index === 4"
                :key="index"
                :colspan="(index === 0 || index === 4) && onlyFirstAndLastLabel ? 2 : 1"
                :style="getLabelStyle(index)"
            >
                {{ label }}
            </td>
        </tr>
        <tr
            v-if="styleOptions.branding"
            :style="'color: #9fa7bc; width: 100%; margin-top: 10px; font-size: 12px; line-height: 16px; font-family: ' + answer_font_name + ', Sans-Serif;'"
        >
            <td
                colspan="5"
                style="vertical-align: bottom; line-height: 16px; padding: 0;"
            >
                <table
                    :style="'color: #9fa7bc; width: 100%; margin-top: 10px; font-size: 12px; line-height: 16px; font-family: ' + answer_font_name + ', Sans-Serif;'"
                >
                    <tr>
                        <td style="width: 20px;">
                            <img
                                src="https://s3-eu-central-1.amazonaws.com/neticle-images/2019/10/zurvey_16.png"
                                style="margin-bottom: 0; max-width: unset;"
                            >
                        </td>
                        <td>
                            <a
                                href="https://zurvey.io"
                                style="text-decoration: none; cursor: pointer;color: #9fa7bc;"
                            >{{ $t('EMBEDDED.POWERED_BY', 'powered by') }} Zurvey.io</a>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</template>

<script lang="ts">
import globals from '@/utils/globals';
import { createNamespacedHelpers } from 'vuex-composition-helpers/dist';
import { computed, defineComponent, ref, onMounted, nextTick, onUpdated } from 'vue';
import { StyleValue } from 'vue/types/jsx';

export default defineComponent({
    props: {
        element: { type: Object, default: () => ({}) },
        isLive: { type: Boolean, default: false },
        styleOptions: { type: Object, default: () => ({}) },
        useLinkPlaceholder: { type: Boolean, default: false },
        urlParams: { type: Array as () => Array<{ name: string }>, default: () => ([]) }
    },
    setup(props) {
        const { useState, useGetters } = createNamespacedHelpers('survey');
        const { uniqueName, text_color, answer_font_name, title_font_name, title_font_weight } = useState(['uniqueName', 'text_color', 'answer_font_name', 'title_font_name', 'title_font_weight']);
        const { hasActiveLanguages } = useGetters(['hasActiveLanguages']);
        const url = ref(globals.getFillerBaseUrl());
        const params = computed(() => {
            if (props.useLinkPlaceholder) return '@@PARAMS@@';
            let params = hasActiveLanguages.value ? 'lang={{LANG}}&' : '';
            const paramCount = props.urlParams.length;
            if (paramCount > 0) {
                props.urlParams.forEach(p => {
                    params = params + p.name + '={{' + p.name.toUpperCase() + '}}&';
                });
            }
            return params;
        });

        const outerTableStyle = computed(() => {
            return {
                color: props.styleOptions.textColor || text_color.value,
                padding: props.styleOptions.marginSize + 'px',
                border: props.styleOptions.borderColor ? '1px solid' + props.styleOptions.borderColor : 'unset',
                backgroundColor: props.styleOptions.backgroundColor,
            };
        });
        const onlyFirstAndLastLabel = computed(() => !noLabels.value && !props.element.rating_labels[1] && !props.element.rating_labels[2] && !props.element.rating_labels[3]);
        const noLabels = computed(() => !(props.element.rating_labels && props.element.rating_labels.length > 0));

        function getIconStyle(index) {
            if ((index === 1 || index === 5) && (noLabels.value || onlyFirstAndLastLabel.value ) ) {
                return {
                    width: props.styleOptions.iconSize + props.styleOptions.iconSpacing / 2 + 'px',
                    'padding-left': index === 1 ? 0 : props.styleOptions.iconSpacing / 2 + 'px',
                    'padding-right': index === 5 ? 0 : props.styleOptions.iconSpacing / 2 + 'px',
                };
            }
            return {
                width: props.styleOptions.iconSize + props.styleOptions.iconSpacing + 'px',
                padding: '0 ' + props.styleOptions.iconSpacing / 2 + 'px',
            };
        }

        const iconStyle = computed(() => props.styleOptions.ratingStyle ? props.styleOptions.ratingStyle : props.element.display_type.split('_')[1]);
        const iconType = computed(() => props.element.display_type.split('_')[0]);

        const iconColor = computed(() => props.element.colors?.[0] || props.styleOptions.iconColor);

        function getEmbedIconUrl(index) {
            return globals.getApiBaseUrl() + '/filler/embed_icons/' + iconStyle.value + '/' +  iconType.value + (props.element.display_type.split('_')[0] === 'smile' ? '-' + index  : '') + '/' + iconColor.value.replace('#', '') + '.png';
        }

        function getLabelStyle(index): StyleValue {
            if ((index === 0 || index === 4) && onlyFirstAndLastLabel.value) {
                return {
                    width: props.styleOptions.iconSize + props.styleOptions.iconSpacing / 2 + 'px',
                    'text-align': index === 0 ? 'left' : 'right',
                };
            }

            return {
                width: props.styleOptions.iconSize + props.styleOptions.iconSpacing + 'px',
                'text-align': 'center',
            };
        }

        const updateTitle = async () => {
            let titlePlaceholder = document.getElementById('titlePlaceholderRating');
            await nextTick();

            if (titlePlaceholder) {
                titlePlaceholder.innerHTML = props.element.title;
                const title = titlePlaceholder.getElementsByTagName( 'p' )[0];
                title.style.margin = '0';
            }
        };

        onMounted(() => {
            updateTitle();
        });

        onUpdated(() => {
            updateTitle();
        });

        return {
            outerTableStyle,
            title_font_name,
            title_font_weight,
            getIconStyle,
            url,
            uniqueName,
            params,
            getEmbedIconUrl,
            noLabels,
            onlyFirstAndLastLabel,
            answer_font_name,
            getLabelStyle
        };
    }
});
</script>
