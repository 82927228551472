<template>
    <div class="py-3">
        <div class="flex">
            <z-select
                :model-value="target.type"
                class="w-1/3 mr-1"
                @update:modelValue="exchangeTarget($event)"
            >
                <option
                    v-for="targetType in targetTypes"
                    :key="targetType.value"
                    :value="targetType.value"
                >
                    {{ targetType.text }}
                </option>
            </z-select>
            <z-input
                v-if="[TargetTypeEnum.ZURVEY, TargetTypeEnum.GTM, TargetTypeEnum.COOKIE].includes(target.type)"
                class="w-1/2 mr-1"
                :model-value="target.variable"
                data-test-id="variable"
                @update:modelValue="changeTarget({type: 'variable', value: $event})"
            />
            <z-select
                v-if="target.operator"
                :model-value="target.operator"
                class="w-1/6 mr-1"
                @update:modelValue="changeTarget({type: 'operator', value: $event})"
            >
                <option
                    v-for="operator in operators"
                    :key="operator.value"
                    :value="operator.value"
                >
                    {{ operator.text }}
                </option>
            </z-select>
            <multi-select
                v-if="[TargetTypeEnum.DEVICE, TargetTypeEnum.OS, TargetTypeEnum.BROWSER].includes(target.type)"
                class="w-1/2"
                :model-value="target.value"
                :options="selectOptions"
                @update:modelValue="changeTarget({type: 'value', value: $event})"
            />
            <div
                v-if="[TargetTypeEnum.TIME, TargetTypeEnum.VISITOR].includes(target.type)"
                class="w-2/3 flex"
            >
                <z-select
                    v-if="[TargetTypeEnum.TIME, TargetTypeEnum.VISITOR].includes(target.type)"
                    :model-value="target.value"
                    class="w-full"
                    @update:modelValue="changeTarget({type: 'value', value: $event})"
                >
                    <option
                        v-for="singleSelectOption in selectOptions"
                        :key="singleSelectOption.value"
                        :value="singleSelectOption.value"
                    >
                        {{ singleSelectOption.text }}
                    </option>
                </z-select>
                <more-info
                    v-if="target.type === TargetTypeEnum.TIME"
                    class="m-1"
                >
                    {{ $t('SURVEY_EMBED_TARGET_TIME_INFO', 'Time refers to only display the Embedded Survey to users once per period, per session (user/browser).') }}
                </more-info>
            </div>
            <z-input
                v-if="target.type === TargetTypeEnum.VISITOR_PERCENTAGE"
                type="number"
                data-test-id="visitor-percentage"
                class="w-2/3"
                :model-value="target.value"
                @update:modelValue="changeTarget({type: 'value', value: $event})"
            />
        </div>
        <tags-input-field
            v-if="[TargetTypeEnum.ZURVEY, TargetTypeEnum.GTM, TargetTypeEnum.COOKIE].includes(target.type)"
            class="w-full"
            :class="{'mt-2': target.value.length === 0}"
            :tag-list="target.value"
            @input="modifyTags(true, $event)"
            @removeTag="modifyTags(false, $event)"
        />
    </div>
</template>

<script lang="ts">
import ZSelect from '@/components/ui/Select.vue';
import { defineComponent, ref, computed } from 'vue';
import {
    OperatorTypeEnum,
    OperatorTypeText,
    TargetTypeEnum,
    TargetTypeText,
    VisitorTypeEnum,
    VisitorTypeText,
    DeviceTypeEnum,
    DeviceTypeText,
    OSTypeEnum,
    BrowserTypeEnum,
    TimeTypeEnum,
    TimeTypeText
} from '@/survey/embed/store/types';
import MultiSelect from '@/components/ui/MultiSelect.vue';
import TagsInputField from '@/components/ui/TagsInputField.vue';
import i18next from 'i18next';
import MoreInfo from '@/components/ui/MoreInfo.vue';
export default defineComponent({
    components: { MoreInfo, TagsInputField, MultiSelect, ZSelect },
    props: {
        target: { type: Object, required: true },
    },
    setup(props, ctx) {
        const targetTypes = ref([
            { value: TargetTypeEnum.DEVICE, text: TargetTypeText[TargetTypeEnum.DEVICE]() },
            { value: TargetTypeEnum.OS, text: TargetTypeText[TargetTypeEnum.OS]() },
            { value: TargetTypeEnum.BROWSER, text: TargetTypeText[TargetTypeEnum.BROWSER]() },
            { value: TargetTypeEnum.VISITOR, text: TargetTypeText[TargetTypeEnum.VISITOR]() },
            { value: TargetTypeEnum.VISITOR_PERCENTAGE, text: TargetTypeText[TargetTypeEnum.VISITOR_PERCENTAGE]() },
            { value: TargetTypeEnum.TIME, text: TargetTypeText[TargetTypeEnum.TIME]() },
            { value: TargetTypeEnum.COOKIE, text: TargetTypeText[TargetTypeEnum.COOKIE]() },
            { value: TargetTypeEnum.GTM, text: TargetTypeText[TargetTypeEnum.GTM]() },
            { value: TargetTypeEnum.ZURVEY, text: TargetTypeText[TargetTypeEnum.ZURVEY]() },
        ]);

        const multipleChoiceOperators = ref([
            { value: OperatorTypeEnum.ONE_OF, text: OperatorTypeText[OperatorTypeEnum.ONE_OF]() },
            { value: OperatorTypeEnum.NONE_OF, text: OperatorTypeText[OperatorTypeEnum.NONE_OF]() },
        ]);

        const keyValueOperators = ref([
            { value: OperatorTypeEnum.EQ, text: OperatorTypeText[OperatorTypeEnum.EQ]() },
            { value: OperatorTypeEnum.CONTAINS, text: OperatorTypeText[OperatorTypeEnum.CONTAINS]() },
            { value: OperatorTypeEnum.NOT_EQ, text: OperatorTypeText[OperatorTypeEnum.NOT_EQ]() },
            { value: OperatorTypeEnum.NOT_CONTAINS, text: OperatorTypeText[OperatorTypeEnum.NOT_CONTAINS]() },
        ]);

        const visitorTypes = ref([
            { value: VisitorTypeEnum.NEW, text: VisitorTypeText[VisitorTypeEnum.NEW]() },
            { value: VisitorTypeEnum.RETURNING, text: VisitorTypeText[VisitorTypeEnum.RETURNING]() }
        ]);

        const deviceTypes = ref([
            { value: DeviceTypeEnum.DESKTOP, text: DeviceTypeText[DeviceTypeEnum.DESKTOP]() },
            { value: DeviceTypeEnum.MOBILE, text: DeviceTypeText[DeviceTypeEnum.MOBILE]() },
            { value: DeviceTypeEnum.TABLET, text: DeviceTypeText[DeviceTypeEnum.TABLET]() },
        ]);

        const OSTypes = ref([
            { value: OSTypeEnum.ANDROID, text: OSTypeEnum.ANDROID },
            { value: OSTypeEnum.IOS, text: OSTypeEnum.IOS },
            { value: OSTypeEnum.LINUX, text: OSTypeEnum.LINUX },
            { value: OSTypeEnum.OSX, text: OSTypeEnum.OSX },
            { value: OSTypeEnum.WINDOWS, text: OSTypeEnum.WINDOWS },
            { value: OSTypeEnum.OTHER, text: i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_CHECKBOX_OTHER_TEXT') },
        ]);

        const BrowserTypes = ref([
            { value: BrowserTypeEnum.CHROME, text: BrowserTypeEnum.CHROME },
            { value: BrowserTypeEnum.FIREFOX, text: BrowserTypeEnum.FIREFOX },
            { value: BrowserTypeEnum.SAFARI, text: BrowserTypeEnum.SAFARI },
            { value: BrowserTypeEnum.INTERNET_EXPLORER, text: BrowserTypeEnum.INTERNET_EXPLORER },
            { value: BrowserTypeEnum.EDGE, text: BrowserTypeEnum.EDGE },
            { value: BrowserTypeEnum.OTHER, text: i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_CHECKBOX_OTHER_TEXT') },
        ]);

        const TimeTypes = ref([
            { value: TimeTypeEnum.ONCE_DAY, text: TimeTypeText[TimeTypeEnum.ONCE_DAY]() },
            { value: TimeTypeEnum.ONCE_WEEK, text: TimeTypeText[TimeTypeEnum.ONCE_WEEK]() },
            { value: TimeTypeEnum.ONCE_MONTH, text: TimeTypeText[TimeTypeEnum.ONCE_MONTH]() },
            { value: TimeTypeEnum.ONCE_SESSION, text: TimeTypeText[TimeTypeEnum.ONCE_SESSION]() }
        ]);

        const operators = computed (() => [TargetTypeEnum.DEVICE, TargetTypeEnum.OS, TargetTypeEnum.BROWSER].includes(props.target.type) ? multipleChoiceOperators.value : keyValueOperators.value);

        const selectOptions = computed (() => {
            switch (props.target.type) {
            case TargetTypeEnum.DEVICE:
                return deviceTypes.value;
            case TargetTypeEnum.OS:
                return OSTypes.value;
            case TargetTypeEnum.VISITOR:
                return visitorTypes.value;
            case TargetTypeEnum.TIME:
                return TimeTypes.value;
            default:
                return BrowserTypes.value;
            }
        });

        function changeTarget(newSetting) {
            const newTarget = { ...props.target, [newSetting.type]: newSetting.value };
            ctx.emit('change', newTarget);
        }

        function modifyTags(isAddable, value) {
            if (isAddable && value.length > 0) {
                const newValue = { type: 'value', value: [...props.target.value].concat(value) };
                changeTarget(newValue);
            } else if (!isAddable && value.length > 0) {
                const newValue = { type: 'value', value: [...props.target.value].filter(t => t !== value) };
                changeTarget(newValue);
            }
        }

        function exchangeTarget(type) {
            switch (type) {
            case TargetTypeEnum.DEVICE:
                ctx.emit('change', { type: TargetTypeEnum.DEVICE, operator: OperatorTypeEnum.ONE_OF, value: [] });
                break;
            case TargetTypeEnum.OS:
                ctx.emit('change', { type: TargetTypeEnum.OS, operator: OperatorTypeEnum.ONE_OF, value: [] });
                break;
            case TargetTypeEnum.BROWSER:
                ctx.emit('change', { type: TargetTypeEnum.BROWSER, operator: OperatorTypeEnum.ONE_OF, value: [] });
                break;
            case TargetTypeEnum.VISITOR:
                ctx.emit('change', { type: TargetTypeEnum.VISITOR, value: VisitorTypeEnum.NEW });
                break;
            case TargetTypeEnum.VISITOR_PERCENTAGE:
                ctx.emit('change', { type: TargetTypeEnum.VISITOR_PERCENTAGE, value: '' });
                break;
            case TargetTypeEnum.TIME:
                ctx.emit('change', { type: TargetTypeEnum.TIME, value: TimeTypeEnum.ONCE_DAY });
                break;
            case TargetTypeEnum.COOKIE:
                ctx.emit('change', { type: TargetTypeEnum.COOKIE, value: [], variable: '', operator: OperatorTypeEnum.EQ });
                break;
            case TargetTypeEnum.GTM:
                ctx.emit('change', { type: TargetTypeEnum.GTM, value: [], variable: '', operator: OperatorTypeEnum.EQ });
                break;
            case TargetTypeEnum.ZURVEY:
                ctx.emit('change', { type: TargetTypeEnum.ZURVEY, value: [], variable: '', operator: OperatorTypeEnum.EQ });
                break;
            }
        }

        return {
            targetTypes,
            operators,
            TargetTypeEnum,
            selectOptions,
            exchangeTarget,
            changeTarget,
            modifyTags
        };
    }
});
</script>

