<template>
    <div
        class="z-slider"
        :class="{'colored': isColorSlider}"
    >
        <vue-slider
            v-model="defaultValue"
            contained
            :height="isColorSlider ? 8 : 4"
            :size="isColorSlider ? 18 : 14"
            :min="min"
            :max="max"
            :tooltip="tooltipType"
            :process-style="{ backgroundColor: processBackground }"
            :tooltip-style="{ backgroundColor: primaryColor, borderColor: primaryColor }"
            :rail-style="railBackground"
            :dot-options="dotOptions"
            :direction="isReversed ? 'rtl' : 'ltr'"
            :interval="isDecimal ? (decimalPrecision === '1' ? 0.1 : 0.01) : 1"
            @change="updateValue"
            @drag-end="unfocus"
            @drag-start="focus"
        >
            <!-- sliderHasDefaultValue is null if it's not within a question element but e.g. style setting and value can be 0 too-->
            <template
                v-if="modelValue === null && sliderHasDefaultValue === false"
                #tooltip
            >
                <div class="default-zurvey-tooltip bg-neutral-600 text-neutral-1000 text-sm rounded z-50 whitespace-no-wrap p-1">
                    {{ $t('SURVEYFORMS.SCALE_SLIDER_SELECT_VALUE', 'Select a value') }}
                </div>
            </template>
        </vue-slider>
        <!-- this is only for cypress e2e tests -->
        <input
            class="hidden"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
        >
    </div>
</template>

<script lang="ts">
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import { computed, defineComponent, ref, onMounted } from 'vue';
import { getBackgroundColor, getPrimaryColor } from '@/utils/theme';
import { hex2rgb, getHexFromGradientBasedOnValue } from '@/utils/color';

export default defineComponent({
    components: {
        VueSlider,
    },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue'
    },
    props: {
        primaryColor: { type: String, default: getPrimaryColor },
        backgroundColor: { type: String, default: getBackgroundColor },
        gradientColors: { type: Array, default: () => ([]) },
        min: { type: Number, required: true },
        max: { type: Number, required: true },
        modelValue: { type: Number, default: null },
        startingPoint: { type: Number, default: null },
        isReversed: { type: Boolean, default: false },
        isDecimal: { type: Boolean, default: false },
        decimalPrecision: { type: String, default: null },
        sliderHasDefaultValue: { type: Boolean, default: null },
        hideValue: { type: Boolean, default: false },
        isNps: { type: Boolean, default: false },
    },
    emits: ['update:modelValue'],
    setup(props, ctx) {
        const slider = ref();

        const defaultValue = computed({
            get() {
                // 0 is a valid value too
                if (props.modelValue !== null) return props.modelValue;
                return props.startingPoint;
            },
            set(val) {
                ctx.emit('update:modelValue', val);
            }
        });

        const tooltipType = computed(() => {
            // If sliderHasDefaultValue is null then it's not within a question element but e.g. style setting, so value should be shown on hover
            if (props.sliderHasDefaultValue === null) return 'active';
            // If slider has no default value and no value, show "Select value" tooltip
            if (props.modelValue === null && props.sliderHasDefaultValue === false) return 'always';
            // If slider has value but hide value is on, don't show anything
            if (props.modelValue !== null && props.hideValue) return 'none';
            // Otherwise show value on hover
            return 'active';
        });

        const isColorSlider = computed(() => {
            // Currently coloured slider only works with three colors
            return props.gradientColors.length === 3;
        });

        const railBackground = computed(() => {
            if (!isColorSlider.value) {
                return null;
            }

            const leftColor = props.gradientColors[props.isReversed ? 2 : 0];
            const middleColor = props.gradientColors[1];
            const rightColor = props.gradientColors[props.isReversed ? 0 : 2];

            if (props.isNps) {
                if (props.isReversed) {
                    if (props.min === 1) {
                        return { background: `linear-gradient(90deg, ${leftColor} 12%, ${middleColor} 20% 36%, ${rightColor} 43%)` };
                    }
                    return { background: `linear-gradient(90deg, ${leftColor} 12%, ${middleColor} 18% 32%, ${rightColor} 38%)` };
                }

                if (props.min === 1) {
                    return { background: `linear-gradient(90deg, ${leftColor} 57%, ${middleColor} 65% 80%, ${rightColor} 88%)` };
                }
                return { background: `linear-gradient(90deg, ${leftColor} 58%, ${middleColor} 66% 82%, ${rightColor} 90%)` };
            }

            return { background: 'linear-gradient(90deg, ' + leftColor + ' 0%, ' + middleColor + ' 50%, ' + rightColor + ' 100%)' };
        });

        const processBackground = computed(() => {
            if (!isColorSlider.value) {
                return (props.modelValue !== null || props.sliderHasDefaultValue) ? props.primaryColor : '#ccc';
            }
            return 'transparent';
        });

        const dotColor = ref(props.primaryColor);
        const dotOptions = computed(() => {
            if (!isColorSlider.value) {
                return {
                    focusStyle: { backgroundColor: props.primaryColor },
                    style: { backgroundColor: props.backgroundColor, border: `2px solid ${props.primaryColor}`, boxShadow: 'none' }
                };
            }
            return {
                focusStyle: { backgroundColor: dotColor.value, border: '2px solid ' + dotColor.value, boxShadow: '0 2px 9px #8392A566', scale: 1.3 },
                style: { backgroundColor: dotColor.value, border: '1px solid ' + dotColor.value, boxShadow: '0 2px 9px #8392A566', transition: 'all 0.5s ease' }
            };
        });

        const rgbGradient = computed(() => {
            if (!isColorSlider.value) {
                return [];
            }
            // @ts-ignore
            const leftColor: string = props.gradientColors[props.isReversed ? 2 : 0];
            const leftValue: number = props.isReversed ? props.max : props.min;
            // @ts-ignore
            const middleColor: string = props.gradientColors[1];
            const middleValue: number = (props.max - props.min) / 2;
            // @ts-ignore
            const rightColor: string = props.gradientColors[props.isReversed ? 0 : 2];
            const rightValue: number = props.isReversed ? props.min : props.max;
            return [
                [ leftValue, hex2rgb(leftColor) ],
                [ middleValue, hex2rgb(middleColor) ],
                [ rightValue, hex2rgb(rightColor) ]
            ];
        });

        const setDotColorBasedOnValue = (value) => {
            dotColor.value = getHexFromGradientBasedOnValue(rgbGradient.value, parseFloat(value), props.max);
        };

        const updateValue = (value) => {
            ctx.emit('update:modelValue', value);
            if (isColorSlider.value) {
                setDotColorBasedOnValue(value);
            }
        };

        onMounted(() => {
            if (!isColorSlider.value) {
                return;
            }
            if (props.modelValue !== null) {
                setDotColorBasedOnValue(props.modelValue);
            } else if (props.startingPoint !== null) {
                setDotColorBasedOnValue(props.startingPoint);
            } else {
                // If there is no value, nor default starting point, dot should take the left end's color
                // @ts-ignore
                dotColor.value = props.gradientColors[props.isReversed ? 2 : 0];
            }
        });

        const isFocused = ref(false);

        const focus = () => {
            isFocused.value = true;
        };

        const unfocus = () => {
            isFocused.value = false;
            // When releasing node, don't focus on node anymore
            slider.value.blur();
        };

        return {
            slider,
            defaultValue,
            tooltipType,
            isColorSlider,
            railBackground,
            processBackground,
            dotOptions,
            updateValue,
            unfocus,
            focus
        };
    }
});
</script>
<style scoped lang="less">
div.z-slider {
    .default-zurvey-tooltip:after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border: solid 7px transparent;
        border-top-color: var(--color-neutral-600);
    }
}
</style>
