<template>
    <div class="pl-2">
        <p>
            {{ $t('SURVEYFORMS.PUBLISH_EMBED_TRIGGERS_INFO', 'Triggers represent events that fire embedded microsurveys. Triggers are used to listen for these events, and they control when a microsurvey is fired.') }}
        </p>
        <z-toggle
            data-test-id="on-page-visit"
            class="my-3"
            :model-value="activeSettings.triggers.some(t => t.type === TriggerTypeEnum.ON_PAGE_VISIT)"
            @update:modelValue="changeTriggerTypes(TriggerTypeEnum.ON_PAGE_VISIT, $event)"
        >
            {{ $t('SURVEY.PUBLISH_EMBED_TRIGGER_PAGE', 'On page visit') }}
            <more-info class="ml-1">
                {{ $t('SURVEY.PUBLISH_EMBED_TRIGGER_PAGE_INFO', 'The embedded microsurvey appears when the page defined above is loaded.') }}
            </more-info>
        </z-toggle>
        <z-toggle
            data-test-id="on-click"
            class="my-3"
            :model-value="activeSettings.triggers.some(t => t.type === TriggerTypeEnum.ON_CLICK)"
            @update:modelValue="changeTriggerTypes(TriggerTypeEnum.ON_CLICK, $event)"
        >
            {{ $t('SURVEY.PUBLISH_EMBED_TRIGGER_CLICK', 'Click') }}
            <more-info class="ml-1">
                {{ $t('SURVEY.PUBLISH_EMBED_TRIGGER_CLICK_INFO', 'Define an HTML class or id here, if your visitor clicks on these elements, the embedded microsurvey will show up.') }}
            </more-info>
        </z-toggle>
        <z-input
            v-if="activeSettings.triggers.some(t => t.type === TriggerTypeEnum.ON_CLICK)"
            class="w-1/3"
            :placeholder="$t('SURVEY.PUBLISH_EMBED_TRIGGER_CLICK_TARGET_REFERENCE', 'Target reference e.g. #id or .class-name')"
            :model-value="activeSettings.triggers.find(t => t.type === TriggerTypeEnum.ON_CLICK).value"
            data-test-id="on-click-text"
            @update:modelValue="changeTriggerValues(TriggerTypeEnum.ON_CLICK, $event)"
        />
        <z-toggle
            data-test-id="on-scroll"
            class="my-3"
            :model-value="activeSettings.triggers.some(t => t.type === TriggerTypeEnum.ON_SCROLL)"
            @update:modelValue="changeTriggerTypes(TriggerTypeEnum.ON_SCROLL, $event)"
        >
            {{ $t('SURVEY.PUBLISH_EMBED_TRIGGER_SCROLL', 'Scroll') }}
            <more-info class="ml-1">
                {{ $t('SURVEY.PUBLISH_EMBED_TRIGGER_SCROLL_INFO', 'If the user reaches the defined percentage of the page, the embedded microsurvey will show up.') }}
            </more-info>
        </z-toggle>
        <div
            v-if="activeSettings.triggers.some(t => t.type === TriggerTypeEnum.ON_SCROLL)"
            class="flex items-center"
        >
            <z-input
                class="w-1/3"
                type="number"
                :model-value="activeSettings.triggers.find(t => t.type === TriggerTypeEnum.ON_SCROLL).value"
                data-test-id="on-scroll-text"
                :min="0"
                @update:modelValue="changeTriggerValues(TriggerTypeEnum.ON_SCROLL, $event)"
            >
                <template #endIcon>
                    <span>%</span>
                </template>
            </z-input>
        </div>
        <z-toggle
            data-test-id="on-idle"
            class="my-3"
            :model-value="activeSettings.triggers.some(t => t.type === TriggerTypeEnum.ON_IDLING_USER)"
            @update:modelValue="changeTriggerTypes(TriggerTypeEnum.ON_IDLING_USER, $event)"
        >
            {{ $t('SURVEY.PUBLISH_EMBED_TRIGGER_IDLING_USER', 'Idling user') }}
            <more-info class="ml-1">
                {{ $t('SURVEY.PUBLISH_EMBED_TRIGGER_IDLING_USER_INFO', 'When a user seems to have gotten lost, ask for feedback. Generally, this is a 60 second period when the user hasn\'t clicked, just moved around the page.') }}
            </more-info>
        </z-toggle>
        <z-toggle
            data-test-id="on-exit"
            class="my-3"
            :model-value="activeSettings.triggers.some(t => t.type === TriggerTypeEnum.ON_EXIT)"
            @update:modelValue="changeTriggerTypes(TriggerTypeEnum.ON_EXIT, $event)"
        >
            {{ $t('SURVEY.PUBLISH_EMBED_TRIGGER_EXIT', 'Exit') }}
            <more-info class="ml-1">
                {{ $t('SURVEY.PUBLISH_EMBED_TRIGGER_EXIT_INFO', 'When the user\'s cursor left your page, the embedded microsurvey will show up.') }}
            </more-info>
        </z-toggle>
        <z-toggle
            data-test-id="on-gtm"
            class="my-3"
            :model-value="activeSettings.triggers.some(t => t.type === TriggerTypeEnum.ON_GTM_EVENT)"
            @update:modelValue="changeTriggerTypes(TriggerTypeEnum.ON_GTM_EVENT, $event)"
        >
            {{ $t('SURVEY.PUBLISH_EMBED_TRIGGER_GTM_EVENT', 'GTM event') }}
            <more-info class="ml-1">
                {{ $t('SURVEY.PUBLISH_EMBED_TRIGGER_GTM_EVENT_INFO', 'Input any Google Tag Manager event and if it triggers, the embedded microsurvey will show up.') }}
            </more-info>
        </z-toggle>
        <z-textarea
            v-if="activeSettings.triggers.some(t => t.type === TriggerTypeEnum.ON_GTM_EVENT)"
            class="w-1/3"
            :placeholder="$t('SURVEY.PUBLISH_EMBED_EVENT_NAME', 'Event name')"
            :model-value="activeSettings.triggers.find(t => t.type === TriggerTypeEnum.ON_GTM_EVENT).value"
            data-test-id="on-gtm-text"
            @update:modelValue="changeTriggerValues(TriggerTypeEnum.ON_GTM_EVENT, $event)"
        />
        <z-toggle
            data-test-id="on-zurvey"
            class="my-3"
            :model-value="activeSettings.triggers.some(t => t.type === TriggerTypeEnum.ON_ZURVEY_EVENT)"
            :label-text="$t('SURVEY.PUBLISH_EMBED_TRIGGER_ZURVEY_EVENT', 'Zurvey event')"
            @update:modelValue="changeTriggerTypes(TriggerTypeEnum.ON_ZURVEY_EVENT, $event)"
        >
            {{ $t('SURVEY.PUBLISH_EMBED_TRIGGER_ZURVEY_EVENT', 'Zurvey event') }}
            <more-info class="ml-1">
                <div>{{ $t('SURVEY.PUBLISH_EMBED_TRIGGER_ZURVEY_EVENT_INFO', 'Events that can be used for triggering a survey. Code example:') }}</div>
                <code class="bg-neutral-500 text-small inline-block mt-1 p-1">
                    {{ "window.zurvey('send', '\{\{eventName\}\}', '\{\{eventValue\}\}')" }}
                </code>
            </more-info>
        </z-toggle>
        <z-textarea
            v-if="activeSettings.triggers.some(t => t.type === TriggerTypeEnum.ON_ZURVEY_EVENT)"
            class="w-1/3"
            data-test-id="on-zurvey-text"
            :placeholder="$t('SURVEY.PUBLISH_EMBED_EVENT_NAME', 'Event name')"
            :model-value="activeSettings.triggers.find(t => t.type === TriggerTypeEnum.ON_ZURVEY_EVENT).value"
            @update:modelValue="changeTriggerValues(TriggerTypeEnum.ON_ZURVEY_EVENT, $event)"
        />
        <z-input
            type="number"
            step="0.1"
            class="w-56 mb-4"
            :model-value="activeSettings.delay || 0"
            :disabled="activeSettings.triggers.length === 0"
            @update:modelValue="changeTriggerDelay"
        >
            <template #label>
                <label class="-ml-4">
                    {{ $t('SURVEY.PUBLISH_EMBED_TRIGGER_DELAY', 'Trigger delay') }}
                    <more-info class="ml-1">
                        {{ $t('SURVEY.PUBLISH_EMBED_TRIGGER_DELAY_INFO', 'The time in seconds the popup appears after the trigger fires. If 0, there is no delay.') }}
                    </more-info>
                </label>
            </template>
            <template #endIcon>
                <span>{{ $t('GLOBAL.SECONDS', 'seconds') }}</span>
            </template>
        </z-input>
    </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import { TriggerTypeEnum } from '@/survey/embed/store/types';
import useEmbedStore from '@/survey/embed/store/embedStore';
import MoreInfo from '@/components/ui/MoreInfo.vue';

export default defineComponent({
    components: { MoreInfo },
    setup() {
        const embedStore = useEmbedStore();
        const { activeSettings } = toRefs(embedStore.state);
        const { changeSettingsOptions } = embedStore.actions;

        function changeTriggerTypes(type: TriggerTypeEnum, value: boolean) {
            let triggers = [...activeSettings.value.triggers];
            const typesWithValue = [TriggerTypeEnum.ON_ZURVEY_EVENT, TriggerTypeEnum.ON_GTM_EVENT, TriggerTypeEnum.ON_SCROLL, TriggerTypeEnum.ON_CLICK];
            if (value) {
                triggers.push(typesWithValue.includes(type) ? { type: type, value: '' } : { type: type });
            } else {
                triggers = triggers.filter(t => t.type !== type);
            }
            changeSettingsOptions('triggers', triggers);
        }
        function changeTriggerValues(type: TriggerTypeEnum, value: string) {
            const triggers = activeSettings.value.triggers.map(t => t.type === type ? { ...t, value: value } : t);
            changeSettingsOptions('triggers', triggers);
        }

        function changeTriggerDelay(value) {
            let delay = value ? parseFloat(value) : 0;
            if (delay < 0) delay = 0;
            changeSettingsOptions('delay', delay);
        }

        return {
            TriggerTypeEnum,
            activeSettings,
            changeTriggerTypes,
            changeTriggerValues,
            changeTriggerDelay,
        };
    }
});
</script>
