<template>
    <div class="flex">
        <div
            v-for="({ rate, recipients }, metric) in data"
            :key="metric"
            class="flex-grow border-r last:border-0 border-neutral-500"
        >
            <wave-rate
                ref="chartRefs"
                :rate="rate"
                :recipients="recipients"
                :metric="metric"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { state } from '../../store';
import WaveRate from './WaveRate.vue';
import exportCharts from '@/dashboard/common/utils/exportMultipleCharts';

import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
Exporting(Highcharts);
OfflineExporting(Highcharts);

export default defineComponent({
    components: { WaveRate },
    setup() {
        const chartRefs = ref();
        const metrics = ['bounced', 'opened', 'clicked', 'completed'];

        const data = computed(() => metrics.reduce((rates, metric) => {
            return {
                ...rates,
                [metric]: {
                    rate: Object.values(state.countDistribution)[0][metric],
                    recipients: Object.values(state.countDistribution)[0].recipients
                }
            };
        }, {}));

        const downloadCharts = () => {
            exportCharts('wave-rates', chartRefs.value.map(ref => ref.$refs.chartRef), 200);
        };

        return { data, chartRefs, downloadCharts };
    }
});
</script>
