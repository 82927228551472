<template>
    <div
        class="collector-editor bg-bg text-black mx-4 py-6"
        data-test-id="collector-editor"
    >
        <div class="collector-header">
            <div class="collector-link-holder flex">
                <z-toggle
                    v-if="!shouldUpgrade"
                    v-model="isActive"
                    :disabled="isArchived"
                    class="mt-3 w-6"
                    data-test-id="collector-switch"
                />
                <div
                    v-if="shouldUpgrade"
                    class="upgradeBox"
                    data-test-id="upgradeBox"
                >
                    <z-icon
                        icon="caret-circle-up"
                        type="fas"
                        class="text-primary-700"
                    />
                    <span class="text-neutral-800">
                        {{ upgradeTexts[0] }} <br>
                        {{ upgradeTexts[1] }}
                    </span>
                </div>
                <div
                    v-else-if="isActive"
                    class="flex-grow flex"
                    data-test-id="collector-url"
                >
                    <copy-container
                        class="flex-grow"
                        style="width: 836px"
                        :text-to-copy="urlToCopy"
                        :text-to-display="url"
                    />
                </div>
                <div
                    v-else
                    class="not-active text-neutral-700"
                    data-test-id="collector-inactive-text"
                >
                    {{ $t('SURVEY.NOT_ACTIVE', 'Your survey is not activated.') }}
                </div>
            </div>
        </div>
        <share-survey
            class="my-4"
            :title="strippedTitle"
            :unique-name="uniqueName"
            :langs="multilangCodes"
            :url-params="urlParameters"
        />
        <accordion
            v-if="isActive && !shouldUpgrade"
            data-test-id="customize-url"
            :text="$t('SURVEY.CUSTOMIZE_URL', 'Customise URL')"
            @changed="isOpen = !isOpen"
        >
            <url-customization
                class="mb-4"
                @uniqueNameChanged="updateUniqueName($event)"
            />
        </accordion>
        <accordion
            v-if="isActive && multilangCodes.length > 0"
            data-test-id="multi-lang-urls"
            :text="$t('SURVEY.LANGUAGE_CODE_URLS', 'Language code URLs')"
            @changed="isOpen = !isOpen"
        >
            <lang-code-urls
                :base-url="urlToCopy"
                :benchmark-lang-id="language_id"
                :lang-codes="multilangCodes"
            />
        </accordion>
    </div>
</template>

<script>
import globals from '@/utils/globals';
import { mapState } from 'vuex';
import ZIcon from '@/components/ui/Icon.vue';
import planFeatureTypeEnum from '@/domain/planFeatureTypeEnum';
import UrlCustomization from './UrlCustomization.vue';
import CopyContainer from './CopyContainer.vue';
import Accordion from '@/components/ui/Accordion.vue';
import LangCodeUrls from '@/survey/components/publish/LangCodeUrls';
import useSubscriptionStore from '@/subscription/store';
import { computed } from 'vue';

import { toaster } from '@/utils/toaster';
import { createNamespacedHelpers } from 'vuex-composition-helpers/dist';
import ShareSurvey from './Share/ShareSurvey.vue';
import striptags from 'striptags';
import confirmPopup from '@/utils/confirmPopup';

export default {
    components: {
        CopyContainer,
        Accordion,
        ZIcon,
        UrlCustomization,
        LangCodeUrls,
        ShareSurvey,
    },
    setup() {
        const { canUseFeature } = useSubscriptionStore().getters;
        const isSurveyEnabled = computed(() => canUseFeature.value(planFeatureTypeEnum.SURVEY, 1));
        const isSurveyResponseEnabled = computed(() => canUseFeature.value(planFeatureTypeEnum.SURVEY_RESPONSE, 1));

        const  { useState }  = createNamespacedHelpers('survey');
        const { is_multilang, translations, language_id, isArchived } = useState(['is_multilang', 'translations', 'language_id', 'isArchived']);
        const multilangCodes = computed(() => is_multilang.value && translations.value.activeLanguages && Array.isArray(translations.value.activeLanguages) ? translations.value.activeLanguages : []);

        return { isSurveyEnabled, isSurveyResponseEnabled, toaster, multilangCodes, language_id, isArchived };
    },
    data() {
        return {
            isOpen: false,
            originalUniqueName: null,
            currentUniqueName: null,
        };
    },
    computed: {
        ...mapState('survey', [
            'title', 'uniqueName', 'urlParameters', 'status', 'id',
        ]),
        strippedTitle() {
            return striptags(this.title);
        },
        isActive: {
            async set(status) {
                if (!status && await confirmPopup.default(
                    this.$t('SURVEY.INACTIVATION_TITLE', { defaultValue: 'Survey Inactivation - {{name}}', name: striptags(this.title) }),
                    this.$t('SURVEY.INACTIVATION_BODY', 'Please note that if you inactivate this survey:<br><br><ul><li>It will no longer be available to respondents.</li><li>If embedded in a webpage or mobile application, it will not be displayed.</li><li>All upcoming scheduled campaigns and reminders will be paused automatically. However, if you reactivate the survey later, these scheduled sendings will resume automatically.</li></ul>'),
                    this.$t('SURVEY.INACTIVATE_BUTTON', 'Inactivate survey'),
                    null,
                    true
                )) {
                    this.changeStatus(status);
                }
                else if (status && await confirmPopup.default(
                    this.$t('SURVEY.ACTIVATION_TITLE', { defaultValue: 'Survey Activation - {{name}}', name: striptags(this.title) }),
                    this.$t('SURVEY.ACTIVATION_BODY', 'Please note that if you activate this survey:<br><br><ul><li>It will immediately become available to respondents.</li><li>If embedded in a webpage or mobile application, it will be displayed based on the defined trigger.</li><li>All upcoming scheduled campaigns and reminders that were paused will resume automatically.</li></ul>'),
                    this.$t('SURVEY.ACTIVATE_BUTTON', 'Activate survey'),
                    null,
                    true
                )) {
                    this.changeStatus(status);
                }
            },
            get() {
                return parseInt(this.status) === 1 || this.status === true;
            }
        },
        shouldUpgrade() {
            // We have survey limit issue if the survey is inactive and cant add anymore active survey
            const surveyLimitIssue = !this.isActive && !this.isSurveyEnabled;
            // We have response issue, if we cant add new survey responses
            const responseLimitIssue = !this.isSurveyResponseEnabled;
            // If we have any issue, we should upgrade
            return surveyLimitIssue || responseLimitIssue;
        },
        upgradeTexts() {
            if (!this.isSurveyEnabled) {
                return [
                    this.$t('SURVEY.UPGRADE_MESSAGE_1', 'You have reached your maximum available active surveys.'),
                    this.$t('SURVEY.UPGRADE_MESSAGE_2', 'If you want to keep adding new ones, consider upgrading.'),
                ];
            }
            return [
                this.$t('SURVEY.RESPONSE_UPGRADE_MESSAGE_1', 'You have reached your maximum survey responses for this period.'),
                this.$t('SURVEY.RESPONSE_UPGRADE_MESSAGE_2', 'If you need your surveys to be available, consider upgrading.'),
            ];
        },
        url() {
            return `${globals.getFillerBaseUrl()}/${this.currentUniqueName}${this.getQuery('basic')}`;
        },
        urlToCopy() {
            return `${globals.getFillerBaseUrl()}/${this.uniqueName}${this.getQuery('copy')}`;
        },
        urlParametersNames() {
            return this.urlParameters.map(param => param.name);
        }
    },
    watch: {
        uniqueName(newValue) {
            this.originalUniqueName = newValue;
            this.currentUniqueName = newValue;
        },
    },
    mounted() {
        this.currentUniqueName = this.uniqueName;
    },
    methods: {
        getQuery(type) {
            let query = '';
            if (this.urlParametersNames && this.urlParametersNames.length > 0) {
                query = this.urlParametersNames
                    .filter(param => !!param)
                    .reduce((query, param) => {
                        const pre = query === '' ? '?' : '&';
                        const parameter = type === 'copy' ? `${param}=${param.toUpperCase()}` : `${param}=<span class="param-value">${param.toUpperCase()}</span>`;
                        return query + pre + parameter;
                    }, '');
            }
            return query;
        },
        async changeStatus(status) {
            await this.$store.dispatch('survey/changeStatus', status);
            this.toaster.success('Survey successfully ' + (status ? 'activated' : 'inactivated'));
        },
        updateUniqueName(newName) {
            this.currentUniqueName = newName;
        }
    }
};
</script>

<style lang="less" scoped>
    .collector-editor {
        border-radius: 4px;

        .collector-header {

            .upgradeBox {
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;

                svg {
                    width: 24px;
                    height: 24px;
                    margin: 0 8px;
                }
                span {
                    font-size: 12px;
                    display: block;
                }
            }

            .not-active {
                font-size: 18px;
                font-style: italic;
                padding-top: 4px;
            }

            .filled-part {
                width: 70px;
                margin-right: 10px;
            }
            .created-part {
                width: 140px;
                margin-right: 20px;
            }
        }
    }
</style>
