export enum dimensionTypeId {
    DONT_USE=1,
    VERBATIM=2,
    ID=3,
    DATE=4,
    /** @deprecated **/
    TIME=5,
    CATEGORY=6,
    /** @deprecated **/
    AUTHOR=7,
    MULTI_VALUE=8,
    DELETE=9,
    NUMBER=10,
    GROUPED_MULTI_VALUE=11,
    GROUPED_NUMBER=12,
    GROUPED_CATEGORY=13,
    FILE_PATH=14,
    NPS=15,
    CSAT=16,

    URL= 18,
    CES_5_SCALE= 19,
    CES_7_SCALE= 20,
    NON_UNIQUE_ID= 21,

    AUDIENCE_MEMBER_ID = 101,
    CAMPAIGN_ID = 102,
    WAVE_ID = 103,

    // conversation input types
    CONVERSATION_ID = 200,
    AGENT_ID = 201,
    AGENT_NAME = 202,
    CUSTOMER_ID = 203,
    CUSTOMER_NAME = 204,
    ROLE = 205,
    AGENT_TYPE = 206,
}
