<template>
    <button
        :disabled="disabled"
        class="w-64 relative flex flex-col p-4 items-center rounded border outline-none disabled:text-neutral-700 disabled:pointer-events-none space-y-6 cursor-default"
        :class="{
            'border-primary-700 bg-active': selected && !warning,
            'border-neutral-500 hover:border-primary-700': !selected && !warning,
            'bg-yellow-100 border border-yellow-500': warning,
        }"
        @click="$emit('click')"
    >
        <slot />
    </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        disabled: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
        warning: { type: Boolean, default: false },
    },
});
</script>
