<template>
    <div class="email-template-customization flex flex-col space-y-4 h-1">
        <div class="flex-grow flex-shrink flex flex-col">
            <div
                class="flex justify-between flex-grow flex-shrink h-1"
            >
                <scrollbar
                    class="w-1/2 border-r border-neutral-500"
                >
                    <email-customization-preview
                        ref="preview"
                        :html-body="emailCode"
                        :has-button="hasButton"
                        :button-text="buttonText"
                        :sender-name="senderName"
                        :embedded-question="embeddedQuestion || undefined"
                    />
                </scrollbar>
                <div
                    class="right-side px-4 space-y-2 w-1/2 flex flex-col h-full"
                >
                    <div
                        class="grid gap-4 items-start"
                        :style="{
                            'grid-template-columns': 'max-content auto',
                        }"
                    >
                        <label
                            for="templateName"
                            class="leading-8"
                        >
                            {{ $t('SURVEYFORMS.EMAIL_TEMPLATE_NAME', 'Template name') }}
                        </label>
                        <z-input
                            id="templateName"
                            v-model="templateName"
                            :disabled="isViewOnlyMode"
                            data-test-id="template-name"
                            :error-message="templateError"
                        />
                        <label
                            for="senderName"
                            class="leading-8"
                        >
                            {{ $t('SURVEYFORMS.EMAIL_SUBJECT_SENDER_NAME', 'Name of the sender') }}
                        </label>
                        <z-input
                            id="senderName"
                            v-model="senderName"
                            :disabled="isViewOnlyMode"
                            data-test-id="sender-name"
                        />
                        <label
                            for="subjectText"
                            class="leading-8"
                        >
                            {{ $t('SURVEYFORMS.EMAIL_SUBJECT_TITLE') }}
                        </label>
                        <z-input
                            id="subjectText"
                            v-model="subjectText"
                            data-test-id="subject-text"
                            :disabled="isViewOnlyMode"
                            :error-message="subjectError"
                        />
                    </div>
                    <html-text-editor
                        class="flex-grow flex-shrink h-1"
                        :value="emailCode"
                        :disabled="isViewOnlyMode"
                        @changed="emailCode = $event"
                    >
                        <email-variable-selector
                            :disabled="isViewOnlyMode"
                            :is-dark="isDark"
                            :variables="optionalVariables"
                            :is-action-used="isActionUsed"
                        />
                    </html-text-editor>
                    <info-box
                        v-if="!!variableError"
                        type="error"
                    >
                        <span
                            class="text-sm"
                            v-html="variableError"
                        />
                    </info-box>
                    <email-action-selector
                        :default-button-text="defaultButtonText"
                        :default-embedded-question-guid="defaultEmbeddedQuestionGuid"
                        :disabled="isViewOnlyMode"
                        @button-updated="updateButtonText($event)"
                        @question-updated="updateQuestion($event)"
                    />
                </div>
            </div>
        </div>
        <div
            class="flex justify-center space-x-4"
        >
            <z-button
                v-if="!isViewOnlyMode"
                variant="outline"
                @click="$emit('show-prev')"
            >
                {{ $t('AUDIENCE.BUTTON_BACK', 'Go back') }}
            </z-button>
            <z-button
                data-test-id="send-button"
                :disabled="!canSend || isViewOnlyMode || htmlToLong"
                :loading="savingCampaign"
                @click="saveAndContinue"
            >
                {{ $t('SURVEYFORMS.CAMPAIGN_SAVE_AND_CONTINUE', 'Save and Continue') }}
            </z-button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, nextTick } from 'vue';
import HtmlTextEditor from '@/components/ui/HtmlTextEditor.vue';
import EmailCustomizationPreview from '@/survey/components/publish/email/EmailCustomizationPreview.vue';
import InfoBox from '@/components/ui/InfoBox.vue';
import EmailVariableSelector from '@/survey/components/publish/email/EmailVariableSelector.vue';
import EmailActionSelector from '@/survey/components/publish/email/EmailActionSelector.vue';
import { NewSurveyEmailTemplate, QuestionElement } from '@/survey/api/types';
import i18next from 'i18next';
import useUserStore from '@/user/store';
import { isHexDark } from '@/utils/color';
import { useRoute } from 'vue-router/composables';

import Scrollbar from '@/components/ui/Scrollbar.vue';

export default defineComponent({
    components: {
        EmailCustomizationPreview,
        HtmlTextEditor,
        InfoBox,
        EmailVariableSelector,
        EmailActionSelector,
        Scrollbar,
    },
    props: {
        templateId: { type: Number, default: 0 },
        defaultName: { type: String, default: null },
        defaultSubject: { type: String, required: true },
        defaultSender: { type: String, required: true },
        defaultEmailCode: { type: String, required: true },
        defaultButtonText: { type: String, default: null },
        defaultEmbeddedQuestionGuid: { type: String, default: null },
        variables: { type: Array as () => string[], default: () => ([]) },
        isViewOnlyMode: { type: Boolean, default: false },
        savingCampaign: { type: Boolean, default: false },
    },
    setup (props, ctx) {
        const route = useRoute();
        const templateId = ref<number|null>(props.templateId);
        const templateName = ref<string|null>(props.defaultName);
        const templateError = computed(() => templateName.value ? null : i18next.t('SURVEYFORMS.EMAIL_MISSING_TEMPLATE_NAME', 'Please name your template.'));
        const senderName = ref(props.defaultSender);
        const subjectText = ref(props.defaultSubject);
        const subjectError = computed(() => subjectText.value ? null : i18next.t('SURVEYFORMS.EMAIL_MISSING_SUBJECT', 'Please give your email a subject.'));
        const buttonText = ref(props.defaultButtonText);
        const hasButton = ref(!!props.defaultButtonText);
        const emailCode = ref(props.defaultEmailCode);
        const embeddedQuestion = ref<QuestionElement|null>(null);

        const optionalVariables = computed(() => {
            const defaultVariables = ['SenderName', 'Action'];
            const variablesFromRecipients = props.variables;
            return [...defaultVariables, ...variablesFromRecipients];
        });

        const usedVariables = computed(() => {
            const foundVariables = [] as string[];
            emailCode.value.split('{{').forEach((part) => {
                if (part.includes('}}')) {
                    // code parts between {{ and }} are considered variables
                    foundVariables.push(part.split('}}')[0]);
                }
            });
            return foundVariables;
        });

        const isActionUsed = computed(() => usedVariables.value.includes('Action'));

        const variableError = computed(() => {
            const missingAction = !usedVariables.value.includes('Action');
            const multipleAction = usedVariables.value.filter(v => v === 'Action').length > 1;
            const notFoundVariables = usedVariables.value.filter(v => !optionalVariables.value.includes(v));
            const hasNotFoundVariables = notFoundVariables.length > 0;
            const missingUsedSender = usedVariables.value.includes('SenderName') && !senderName.value;
            if (!missingAction && !multipleAction && !hasNotFoundVariables && !missingUsedSender) {
                return null;
            }
            let errorMessage = i18next.t('SURVEYFORMS.EMAIL_VARIABLE_ERROR', 'Please make sure to use the proper Variables in order to be able to send the email.');
            if (missingAction) {
                errorMessage += '<br>' + i18next.t('SURVEYFORMS.EMAIL_MISSING_ACTION', 'Action is a required variable.');
            }
            if (multipleAction) {
                errorMessage += '<br>' + i18next.t('SURVEYFORMS.EMAIL_MULTIPLE_ACTION', 'Multiple Action is not possible.');
            }
            if (missingUsedSender) {
                errorMessage += '<br>' + i18next.t('SURVEYFORMS.EMAIL_MISSING_SENDER', 'Sender name added as variable but has no value.');
            }
            if (hasNotFoundVariables) {
                notFoundVariables.forEach((variable) => {
                    errorMessage += '<br>' + i18next.t('SURVEYFORMS.EMAIL_MISSING_VARIABLE', {
                        variableName: variable,
                        defaultValue: 'Variable {{variableName}} is not found.'
                    });
                });
            }
            return errorMessage;
        });

        const canSend = computed(() => {
            return !templateError.value && !subjectError.value && !variableError.value;
        });

        const updateButtonText = (newButtonText: string) => {
            hasButton.value = true;
            buttonText.value = newButtonText;
            embeddedQuestion.value = null;
        };

        const updateQuestion = async (question: QuestionElement) => {
            hasButton.value = false;
            buttonText.value = i18next.t('SURVEYFORMS.EMAIL_BUTTON');
            // Changing same type of embedded questions doesn't re-mount the component without this
            embeddedQuestion.value = null;
            await nextTick();
            embeddedQuestion.value = question;
        };

        const preview = ref();

        const createTemplateCopy = (): NewSurveyEmailTemplate => {
            let guid: string|null = null;
            let html: string|null = null;

            if (embeddedQuestion.value) {
                guid = embeddedQuestion.value.guid;
                html = preview.value.$refs.embeddableQuestion.outerHTML;
            }

            return {
                id: templateId.value ?? void 0,
                datasetId: route.params.id,
                name: templateName.value!,
                variables: usedVariables.value.filter(v => !['Action', 'SenderName'].includes(v)),
                customizationOptions: {
                    subjectText: subjectText.value,
                    senderName: senderName.value,
                    htmlBody: emailCode.value,
                    embeddedQuestionGuid: guid,
                    embeddedCode: html,
                    actionButtonText: guid && html ? null : buttonText.value,
                },
            };
        };

        const { state } = useUserStore();
        const isDark = computed(() => isHexDark(state.primaryColor!));

        const saveAndContinue = () => {
            ctx.emit('save-and-continue', createTemplateCopy());
        };

        const htmlToLong = computed(() => emailCode.value.length > 50_000);

        return {
            preview,
            templateName, senderName, subjectText, emailCode,
            templateError, subjectError, variableError,
            hasButton, buttonText, embeddedQuestion,
            updateButtonText, updateQuestion,
            isActionUsed, optionalVariables,
            canSend,
            isDark, saveAndContinue,
            htmlToLong,
        };
    },
});
</script>
