import {computed, reactive} from 'vue';
import {fieldTypeEnum, UploadedAudienceState} from '@/audience/types';
import i18next from 'i18next';
import excelParser from '@/domain/excel/excelParser';
import BusinessError from '@/domain/errors/BusinessError';

export const uploadState = reactive<UploadedAudienceState>({
    file: null,
    columnMapping: {},
    delimiter: null
});

export const uploadGetters = {
    rowsToUpload: computed(() => {
        if (!uploadState.file) return 0;
        return uploadState.file.sheets[uploadState.file.activeSheet].rows - uploadState.file.sheets[uploadState.file.activeSheet].headerRows;
    }),
    activeSheetDetails: computed(() => {
        if (!uploadState.file) return [];
        return uploadState.file.sheets[uploadState.file.activeSheet];
    }),
    activeSheetIndex: computed(() => {
        if (!uploadState.file) return 0;
        return Object.keys(uploadState.file.sheets).indexOf(uploadState.file.activeSheet);
    }),
};

const storeFileDetails = (response, file) => {
    uploadState.file = {
        name: response.name,
        sheets: response.sheets,
        activeSheet: response.activeSheet,
        file
    };
};

export const uploadActions = {
    uploadFile: async (file) => {
        const response = await excelParser({file: file, guessDimensionTypes: false, defaultType: null, setDateFormatOnRead: true});
        if (!response.activeSheet || !response.sheets || !Object.keys(response.sheets).length) {
            Sentry.addBreadcrumb({
                message: 'Audience excelParser failed',
                data: { response, file }
            });
            throw new BusinessError(i18next.t('ANALYSIS.EMPTY_FILE_ERROR', 'Uploaded file must have at least one not empty sheet.'));
        }
        storeFileDetails(response, file);
    },
    reparseCsv: async (delimiter, file) => {
        const response = await excelParser({ file: file, guessDimensionTypes: false, defaultType: null, setDateFormatOnRead: true, delimiter: delimiter });
        if (!response.activeSheet || !response.sheets || !Object.keys(response.sheets).length) {
            Sentry.addBreadcrumb({
                message: 'Reparse CSV excelParser failed for Audience',
                data: { response, file }
            });
            throw new BusinessError(i18next.t('ANALYSIS.EMPTY_FILE_ERROR', 'Reparsed file must have at least one not empty sheet.'));
        }
        storeFileDetails(response, file);
    },
    resetUploadedFile: () => {
        uploadState.file = null;
        uploadState.columnMapping = [];
        uploadState.delimiter = null;
    },
    setColumnMapping: (mapping) => {
        uploadState.columnMapping = mapping;
    },
    changeSheet(newSheet: string) {
        uploadState.file!.activeSheet = newSheet;
    },
    updateSheets(newSheets: any) {
        uploadState.file!.sheets = newSheets;
    },
    updateDelimiter(delimiter) {
        uploadState.delimiter = delimiter;
    },
    validateFields(index, type) {
        const uploadedFile = uploadState.file;
        if(uploadedFile) {
            const headerRows = uploadedFile.sheets[uploadedFile.activeSheet]['headerRows'];
            const columnData = uploadedFile.sheets[uploadedFile.activeSheet]['columnData'][index].filter((d, i) => i >= headerRows && i <= headerRows + 5);
            if(type === fieldTypeEnum.EMAIL) {
                return columnData.every(email => /\S+@\S+\.\S+/.test(email));
            }
            if(type === fieldTypeEnum.PHONE) {
                return columnData.every(phone => /^\d{1,}$/.test(phone));
            }
            if(type === fieldTypeEnum.DATE) {
                return columnData.every(date => /^(?:(?:19|20)\d{2})(?:\.|-|\/)(?:0[1-9]|1[0-2])(?:\.|-|\/)(?:0[1-9]|[12][0-9]|3[01])(?: (?:[01][0-9]|2[0-3])(?::[0-5][0-9]){1,2})?$/.test(date));
            }
        }
        return true;
    }
};
