<template>
    <div class="p-1 text-right flex justify-center">
        <span
            class="w-3 h-3 rounded-full mr-1 mt-1.5"
            :class="isDisconnected ? 'bg-yellow-500' : 'bg-green-500'"
        />
        <span>
            {{ isDisconnected ? $t('SURVEY.PUBLISH_EMBED_SETTINGS_DISCONNECTED', 'Disconnected') : $t('SURVEY.PUBLISH_EMBED_SETTINGS_CONNECTED', 'Connected') }}
        </span>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        isDisconnected: { type: Boolean, required: true}
    },
});
</script>

