<template>
    <div
        class="link-editor w-64 absolute bg-white rounded shadow text-default z-50 p-2"
        :style="{
            left: '130px',
            top: '-10px',
            transform: 'translate(-50%, -100%)'
        }"
    >
        <z-input
            autofocus
            class="mt-2 mb-2"
            :label="$t('GLOBAL.LINK_EDITOR_URL', 'URL')"
            :model-value="url"
            @update:modelValue="$emit('url-updated', $event)"
            @keyup.enter="focusOnNextInput"
        />
        <z-input
            ref="urlText"
            class="mb-2"
            :label="$t('GLOBAL.LINK_EDITOR_TEXT', 'Display text')"
            :model-value="text"
            @update:modelValue="$emit('text-updated', $event)"
            @keyup.enter="$emit('apply')"
        />
        <div :class="hasLink ? 'flex justify-between' : 'text-center'">
            <z-button
                v-if="hasLink"
                destructive
                variant="secondary"
                @click="$emit('remove')"
            >
                {{ $t('GLOBAL.LINK_EDITOR_REMOVE', 'Remove link') }}
            </z-button>
            <z-button
                variant="secondary"
                :disabled="!url"
                @click="$emit('apply')"
            >
                {{ $t('GLOBAL.LINK_EDITOR_APPLY', 'Apply URL') }}
            </z-button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: 'LinkEditor',
    components: {

    },
    props: {
        text: { type: String, default: null },
        url: { type: String, default: null },
        hasLink: { type: Boolean, default: false },
    },
    setup() {
        const urlText = ref();
        const focusOnNextInput = () => urlText.value.focus();
        return { urlText, focusOnNextInput };
    }
});
</script>
<style scoped>
.link-editor:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: solid 10px transparent;
    border-top-color: var(--color-white);
}
</style>
