<template>
    <div class="html-text-editor flex flex-col">
        <div
            class="relative bg-white h-1 rounded pb-8 border flex-grow"
            :class="disabled ? 'text-neutral-800' : 'text-black'"
            :style="editorStyle"
            @mouseover="hover = true"
            @mouseleave="hover = false"
        >
            <scrollbar
                ref="editorScrollbar"
                log="editor"
                stop-propagation
                class="html-text-editor-scrollbar h-full"
            >
                <iframe
                    v-if="!raw"
                    ref="editorIframe"
                    class="w-full"
                    scrolling="no"
                />
                <div
                    v-else
                    contenteditable="plaintext-only"
                    class="block w-full p-1 outline-none min-h-full"
                    @input="handleEditor"
                >
                    {{ rawHtml }}
                </div>
            </scrollbar>
            <div class="absolute bottom-0 w-full rounded-b bg-neutral-300 h-9 flex justify-between items-center p-2">
                <div class="space-x-3">
                    <z-icon
                        class="text-neutral-800"
                        :class="disabled ? 'cursor-default' : 'cursor-pointer hover:text-black'"
                        :icon="raw ? 'pencil' : 'code'"
                        @click="raw = !raw"
                    />
                    <template v-if="!raw">
                        <z-icon
                            class="text-neutral-800"
                            :class="disabled ? 'cursor-default' : 'cursor-pointer hover:text-black'"
                            icon="bold"
                            @click="command('bold')"
                        />
                        <z-icon
                            class="text-neutral-800"
                            :class="disabled ? 'cursor-default' : 'cursor-pointer hover:text-black'"
                            icon="italic"
                            @click="command('italic')"
                        />
                        <z-icon
                            class="text-neutral-800"
                            :class="disabled ? 'cursor-default' : 'cursor-pointer hover:text-black'"
                            icon="underline"
                            @click="command('underline')"
                        />
                        <template v-if="!noLists">
                            <z-icon
                                class="text-neutral-800"
                                :class="disabled ? 'cursor-default' : 'cursor-pointer hover:text-black'"
                                icon="list"
                                @click="command('insertUnorderedList')"
                            />
                            <z-icon
                                class="text-neutral-800"
                                :class="disabled ? 'cursor-default' : 'cursor-pointer hover:text-black'"
                                icon="list-ol"
                                @click="command('insertOrderedList')"
                            />
                        </template>
                        <template v-if="alignText">
                            <z-icon
                                class="text-neutral-800"
                                :class="disabled ? 'cursor-default' : 'cursor-pointer hover:text-black'"
                                icon="align-left"
                                @click="command('justifyLeft')"
                            />
                            <z-icon
                                class="text-neutral-800"
                                :class="disabled ? 'cursor-default' : 'cursor-pointer hover:text-black'"
                                icon="align-center"
                                @click="command('justifyCenter')"
                            />
                            <z-icon
                                class="text-neutral-800"
                                :class="disabled ? 'cursor-default' : 'cursor-pointer hover:text-black'"
                                icon="align-right"
                                @click="command('justifyRight')"
                            />
                            <z-icon
                                class="text-neutral-800"
                                :class="disabled ? 'cursor-default' : 'cursor-pointer hover:text-black'"
                                icon="align-justify"
                                @click="command('justifyFull')"
                            />
                        </template>
                        <template v-if="!noLinks">
                            <z-icon
                                class="text-neutral-800"
                                :class="disabled ? 'cursor-default' : 'cursor-pointer hover:text-black'"
                                icon="link"
                                @click="toggleLinkMenu"
                            />
                            <link-editor
                                v-if="isLinkMenuActive"
                                v-on-clickaway="toggleLinkMenu"
                                :url="linkUrl || undefined"
                                :text="linkText || undefined"
                                :has-link="isLinkAlready"
                                @url-updated="linkUrl = $event"
                                @text-updated="linkText = $event"
                                @remove="removeLink"
                                @apply="applyLink"
                            />
                        </template>
                    </template>
                </div>
                <slot />
            </div>
        </div>
        <div
            v-if="htmlSize"
            class="text-red-700 text-small text-right"
        >
            <div
                v-if="htmlSize > limit * 0.8"
            >
                {{ htmlSize }}/{{ limit }}
            </div>
            <div
                v-if="htmlSize > limit"
            >
                {{ $t('EMAIL_TEMPLATE_CUSTOMIZATION.CHARACTER_LIMIT_EXCEEDED', { defaultValue: 'HTML exceeds character limit. Please keep HTML shorter than \{\{ limit \}\} characters.', limit: new Intl.NumberFormat().format(limit) }) }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onUnmounted, watch, nextTick } from 'vue';
import { hex2rgba } from '@/utils/color';
import { getBackgroundColor, getPrimaryColor, getTextColor } from '@/utils/theme';
import { directive as onClickaway } from 'vue-clickaway2';
import ZIcon from '@/components/ui/Icon.vue';
import LinkEditor from '@/components/ui/LinkEditor.vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';

export default defineComponent({
    name: 'HtmlTextEditor',
    components: { ZIcon, LinkEditor, Scrollbar },
    directives: { onClickaway },
    props: {
        value: { type: String, default: '' },
        textColor: { type: String, default: getTextColor },
        primaryColor: { type: String, default: getPrimaryColor },
        backgroundColor: { type: String, default: getBackgroundColor },
        alignText: { type: Boolean, default: false },
        noLists: { type: Boolean, default: false },
        noLinks: { type: Boolean, default: false },
        fontSize: { type: String, default: '14px' },
        disabled: { type: Boolean, default: false },
    },
    setup(props, ctx) {
        const focused = ref(false);
        const hover = ref(false);

        const editorIframe = ref();

        const borderColor = computed(() => {
            if (focused.value) return props.primaryColor;
            if (hover.value) return props.textColor;
            return hex2rgba(props.textColor, .5);
        });

        const editorStyle = computed(() => {
            if (!props.disabled) {
                return {
                    'background-color': props.backgroundColor,
                    'color': props.textColor,
                    'border-color': borderColor.value,
                };
            }
            return {
                'background-color': props.backgroundColor,
                'color': '#838da5',
                'border-color': borderColor.value,
            };
        });

        const editorScrollbar = ref();

        const iframeDocument = ref<Document>();

        const calcHeight = () => {
            const scrollbarHeight = editorScrollbar.value!.$el.clientHeight - 16;

            const children = iframeDocument.value!.body.children;
            const childrenHeight = Array.from(children).reduce((heightSum, child) => heightSum + child.scrollHeight, 0);

            const height = scrollbarHeight > childrenHeight ? scrollbarHeight : childrenHeight;

            editorIframe.value.style.height = height + 16 + 'px'; // 16 for top and bottom margins

            // scrollbar's mutation observer doesn't work with iframes, that's why we need to manually redraw
            editorScrollbar.value!.redraw();
        };

        const emitChanges = () => {
            calcHeight();
            htmlSize.value = iframeDocument.value?.body.innerHTML.length || 0;
            ctx.emit('changed', iframeDocument.value?.body.innerHTML);
        };

        const mountIframe = () => {
            iframeDocument.value = editorIframe.value.contentDocument || editorIframe.value.contentWindow.document;
            if (!iframeDocument.value) {
                return;
            }
            // This is the stupidest thing I know but iframe designMode cannot be set without this on Firefox
            iframeDocument.value.open();
            iframeDocument.value.close();
            // Be able to edit iframe content
            iframeDocument.value.designMode = 'on';
            if (props.value !== '') {
                iframeDocument.value.body.innerHTML = props.value;
            }
            iframeDocument.value.body.style.fontFamily = 'sans-serif';
            iframeDocument.value.body.style.fontSize = props.fontSize;
            if (props.disabled) {
                iframeDocument.value.body.style.color = '#838da5';
                iframeDocument.value.designMode = 'off';
            }
            if (!props.disabled) {
                iframeDocument.value.addEventListener('keyup', emitChanges);
            }
            const styleElement = document.createElement('style');
            styleElement.innerText = `
            * {
                max-width: 100%
            }
            `;

            iframeDocument.value.head.appendChild(styleElement);

            calcHeight();
        };

        onMounted(() => {
            mountIframe();
        });

        const unmountIframe = () => {
            iframeDocument.value?.removeEventListener('keyup', emitChanges);
        };

        onUnmounted(() => {
            unmountIframe();
        });

        const command = (cmd, args?: string) => {
            if (!props.disabled) {
                iframeDocument.value?.execCommand(cmd, false, args);
                emitChanges();
            }
        };

        const linkUrl = ref<string|null>(null);
        const linkText = ref<string|null>(null);
        const isLinkAlready = ref(false);
        const isLinkMenuActive = ref(false);

        const toggleLinkMenu = () => {
            if (!props.disabled) {
                if (isLinkMenuActive.value) {
                    linkUrl.value = null;
                    linkText.value = null;
                    isLinkMenuActive.value = false;
                    isLinkAlready.value = false;
                } else {
                    const parentElement = iframeDocument.value?.getSelection()?.focusNode?.parentElement;
                    isLinkAlready.value = parentElement?.nodeType == 1 && parentElement?.tagName.toLowerCase() == 'a'; // element of type html-object/tag && is an anchor tag
                    if (isLinkAlready.value) {
                        const linkElement = parentElement as HTMLAnchorElement;
                        linkUrl.value = linkElement.href;
                        linkText.value = linkElement.textContent;
                    } else {
                        const selectedText = iframeDocument.value?.getSelection()?.getRangeAt(0)?.toString()?.trim() || null;
                        linkUrl.value = selectedText;
                        linkText.value = selectedText;
                    }
                    isLinkMenuActive.value = true;
                }
            }
        };

        const applyLink = () => {
            if (!props.disabled) {
                if (linkUrl.value) {
                    command('createLink', linkUrl.value);
                    const links = iframeDocument.value?.getElementsByTagName('a') || [];
                    for (let i = 0; i < links.length; i++) {
                        // "/" is added to the end of links with createLink, so check if they match regardless of that
                        // and every newly added link will contain either the linkUrl or the given linkText
                        if (
                            links[i].href.replace(/\/$/, '') === linkUrl.value!.replace(/\/$/, '') &&
                                (links[i].textContent === linkUrl.value || links[i].textContent === linkText.value)
                        ) {
                            // setting attribute is not enough to show it in html output
                            links[i].outerHTML = links[i].outerHTML.replace('href', 'target="_blank" href');
                            links[i].setAttribute('target', '_blank');
                            if (linkText.value && links[i].textContent !== linkText.value) {
                                links[i].textContent = linkText.value;
                            }
                        }
                    }
                }
                toggleLinkMenu();
            }
        };

        const removeLink = () => {
            if (!props.disabled) {
                // user might not select the anchor tag (cursor just stands inside it), so we need to select it instead before unlinking
                if (iframeDocument.value?.getSelection()?.getRangeAt(0)?.toString()?.trim().length === 0) {
                    const parentElement = iframeDocument.value?.getSelection()?.focusNode?.parentElement as Node;
                    const range = iframeDocument.value?.createRange();
                    range.selectNodeContents(parentElement);
                    const selection = iframeDocument.value?.getSelection();
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                }
                command('unLink');
                toggleLinkMenu();
            }
        };

        const raw = ref<boolean>(false);
        const rawHtml = ref<string>();
        const htmlSize = ref<number>(props.value.length);

        watch(raw, async (val) => {
            await nextTick();

            if (!val) {
                rawHtml.value = '';
                mountIframe();
            }
            else {
                rawHtml.value = props.value;
                unmountIframe();
            }
        });

        const handleEditor = async (event) => {
            const html = event.target.innerText.trim();
            htmlSize.value = html.length;
            ctx.emit('changed', html);
        };

        const limit = 50_000;

        return {
            editorIframe,
            linkUrl, linkText, isLinkMenuActive, isLinkAlready,
            focused, hover, editorStyle,
            toggleLinkMenu, applyLink, removeLink,
            command,
            raw, rawHtml, handleEditor, htmlSize,
            limit,
            editorScrollbar,
        };
    },
});
</script>

<style scoped lang="less">
/deep/ * {
    max-width: 100%;
}
</style>
