<template>
    <InfoBox
        v-if="infoBoxText"
        :type="emailQuotaPercent == 100 ? 'error' : 'warning'"
    >
        {{ infoBoxText }}
    </InfoBox>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import InfoBox from '@/components/ui/InfoBox.vue';
import { emailUsage, emailQuotaPercent } from './useEmailQuotaInfo';
import PHP_INT_MAX from '@/utils/unlimited';

import i18next from 'i18next';
import globals from '@/utils/globals';

export default defineComponent({
    components: { InfoBox },
    setup() {
        const infoBoxText = computed(() => {
            if (!emailUsage.value && globals.quotaHandlingLimited()) return '';

            if (emailQuotaPercent.value < 80 || emailUsage.value.max_value == PHP_INT_MAX) return '';

            if (emailUsage.value.value != emailUsage.value.max_value) {
                return i18next.t('AUDIENCE.EMAIL_QUOTA_WARNING', {
                    defaultValue: 'Your email quota usage has reached {{percent}}% ({{used}} of {{all}}). This may prevent your scheduled campaigns from being delivered. To ensure delivery, contact your Customer Success Manager and purchase additional quota.',
                    percent: emailQuotaPercent.value,
                    used: emailUsage.value.value,
                    all: emailUsage.value.max_value,
                });
            }

            return i18next.t('AUDIENCE.EMAIL_QUOTA_ERROR', {
                defaultValue: 'Your email quota has been fully used ({{used}} of {{all}}). Your upcoming scheduled campaigns will not be delivered. To ensure delivery contact your customer success manager and purchase additional quota.',
                used: emailUsage.value.value,
                all: emailUsage.value.max_value,
            });
        });

        return {
            infoBoxText,
            emailQuotaPercent,
        };
    }
});
</script>
