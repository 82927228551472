import type { AudienceState, AudienceWithSegments, Field } from '@/audience/types';

import i18next from 'i18next';
import BusinessError from '@/domain/errors/BusinessError';

import { reactive, computed } from 'vue';
import { fieldTypeEnum } from '@/audience/types';
import { segmentState, segmentActions } from '@/audience/store/segment';
import { uploadState, uploadGetters, uploadActions } from '@/audience/store/upload';
import { state as exportState, makeActions as makeExportActions } from '@/audience/store/export';
import {
    editMember,
    getMembers,
    getAudience,
    getAudiences,
    deleteMembers,
    updateAudience,
    deleteAudience,
    createAudienceFromFile,
    createAudienceFromApi,
    getConnectedCampaignsByAudienceId,
    fetchAudiencesWithSegments,
    countMembers
} from '@/audience/api';
import {Campaign} from '@/campaign/types';

const generateRandomHex = (size) => {
    return [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
};

const state = reactive<AudienceState>({
    loading: false,
    audiences: [],
    audience: null,
    audiencesWithSegments: [],
    upload: uploadState,
    members: [],
    selectedMembers: [],
    page: 0,
    segment: segmentState,
    export: exportState,
    campaigns: []
});

export const getters = {
    filterableFields: computed(() => {
        if (!state.audience || !state.audience.fields) return [];
        return state.audience.fields.filter(f => f.type && [fieldTypeEnum.DATE, fieldTypeEnum.CATEGORY, fieldTypeEnum.NUMBER].includes(f.type));
    })
};

export async function getHomeAudiences(polling: boolean = false, force: boolean = false) {
    if (!state.loading || force) {
        state.loading = true;
        state.audiences = await getAudiences().catch(() => []);
        state.loading = false;
    }
    if (polling) {
        //TODO: uncomment when we actually start using this, until then it would result in unnecessary java microservice calls
        return setInterval(() => getHomeAudiences(), 30000);
    }
}

const actions = {
    getHomeAudiences,
    generateRandomHex,
    async getAudience(audienceId: string) {
        try {
            state.audience = await getAudience(audienceId);
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    },
    createDefaultAudience(groupName: string) {
        state.audience = {
            name: groupName + ' ' + i18next.t('DATA_SOURCE_TYPE.AUDIENCE', 'Audience'),
            isPopulated: false,
            hasEmail: false,
            hasPhone: false,
            members: 0,
            fields: [],
        };
    },
    async updateAudienceName(newAudienceName: string, isSetup: boolean) {
        state.audience!.name = newAudienceName;
        // During setup only update audience name in store and save it when audience is created
        if (!isSetup) {
            await updateAudience(state.audience!);
        }
    },
    async updateAudienceFields() {
        await updateAudience(state.audience!);
    },
    addNewField (name: string) {
        const id = generateRandomHex(8);
        // @ts-ignore
        state.audience = {
            ...state.audience,
            fields: [...state.audience?.fields || [], {
                id: id,
                type: null,
                name: name,
                isUnique: false,
                mappings: []
            }]
        };
        return id;
    },
    updateFields (fields: Field[]) {
        if (!state.audience || !state.audience.fields || !Array.isArray(state.audience.fields)) {
            return;
        }
        state.audience = {
            ...state.audience,
            fields: fields
        };
    },
    async createAudience () {
        if (state.audience && state.audience.fields && uploadState.file) {
            const usedFields = state.audience.fields.filter(f => f.type != null && Object.values(fieldTypeEnum).includes(f.type));
            const usedFieldIds = usedFields.map(f => f.id);

            const columnMappingForUsedFields = {};
            Object.keys(uploadState.columnMapping).forEach((key) => {
                if (usedFieldIds.includes(uploadState.columnMapping[key])) {
                    columnMappingForUsedFields[key] = uploadState.columnMapping[key];
                }
            });

            state.audience = await createAudienceFromFile(
                state.audience.name,
                usedFields,
                uploadGetters.activeSheetDetails.value.headerRows,
                columnMappingForUsedFields,
                uploadGetters.activeSheetIndex.value,
                uploadState.file.file,
                uploadState.delimiter
            );
            return state.audience.uid;
        }
        else if (state.audience && state.audience.fields) {
            state.audience = await createAudienceFromApi(
                state.audience.name,
                state.audience.fields,
            );
            return state.audience!.uid;
        }
        else {
            // For now, we need to throw an error if the above is not true, but later there will be non-file based create flow as well
            throw new BusinessError(i18next.t('GENERAL.SOMETHING_WENT_WRONG'));
        }
    },
    async deleteAudience(audienceId: string) {
        await deleteAudience(audienceId);
        state.audiences = state.audiences.filter(a => a.uid !== audienceId);
    },
    async getMembers(audienceId: string, page: number, fieldId?: string, fieldValue?: string): Promise<void> {
        state.members = await getMembers(audienceId, page * 50, fieldId, fieldValue);
    },
    setPage(page: number): void {
        state.page = page;
    },
    setCampaigns(campaigns: Campaign[]): void {
        state.campaigns = campaigns;
    },
    reset () {
        state.loading = false;
        state.audiences = [];
        state.audience = null;
        state.members = [];
        state.selectedMembers = [];
        state.page = 0;
        state.audiencesWithSegments = [];
        state.campaigns = [];
    },
    deleteMembers: async (ids: string[]): Promise<void> => {
        await deleteMembers(state.audience!.uid!, ids);
    },
    toggleSelectedMember: (id: string): void => {
        if (state.selectedMembers.includes(id)) {
            state.selectedMembers = state.selectedMembers.filter(member => member !== id);
        }
        else {
            state.selectedMembers = state.selectedMembers.concat(id);
        }
    },
    toggleAllSelectedMembers: (checked: boolean) => {
        if (checked) {
            state.selectedMembers = [...new Set(state.selectedMembers.concat(state.members.map(member => member.id)))];
        }
        else {
            state.selectedMembers = state.selectedMembers.filter(member => !state.members.some(m => m.id === member));
        }
    },
    resetSelectedMembers: (): void => {
        state.selectedMembers = [];
    },
    editMember: async (id: string, fields: { [fieldId: string]: any }): Promise<void> => {
        await editMember(state.audience!.uid!, id, fields);
    },
    checkIfValueIsUnique: async (fieldId: string, fieldValue: any): Promise<boolean> => {
        const members = await getMembers(state.audience!.uid!, 1, fieldId, fieldValue);

        return members.length === 0;
    },
    async getConnectedCampaigns(audienceId: string) {
        if(state.audience) {
            const connectedCampaigns = await getConnectedCampaignsByAudienceId(audienceId);
            state.audience = {...state.audience, connectedCampaigns: connectedCampaigns};
            segmentActions.setConnectedCampaignOnSegments(segmentState.segments.map(s => ({...s, connectedCampaigns: connectedCampaigns.filter(c => c.segmentId === s.id)})));
        }
    },
    async countAudienceMembers (audienceId: string | null, segmentId: string | null, fromDate: number) {
        return await countMembers(audienceId, segmentId, fromDate);
    }
};

const listAudiencesWithSegments = async (): Promise<AudienceWithSegments[]> => {
    try {
        state.audiencesWithSegments = await fetchAudiencesWithSegments();
        return state.audiencesWithSegments;
    }
    catch (error) {
        Sentry.captureException(error);
        throw error;
    }
};

export default function useAudienceStore() {
    return {
        state,
        getters: {
            ...getters,
            ...uploadGetters
        },
        actions: {
            ...actions,
            ...uploadActions,
            ...segmentActions,
            ...makeExportActions(state),
        },
        listAudiencesWithSegments,
    };
}
