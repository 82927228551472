<template>
    <table :style="'font-size: 14px; line-height: 16px; font-family: ' + answer_font_name + ', Sans-Serif; font-weight: ' + answer_font_weight + '; color: ' + styleOptions.textColor + ';' + border + 'background-color:' + styleOptions.backgroundColor + '; border-collapse:unset; padding:' + styleOptions.marginSize + 'px;'">
        <tr v-if="styleOptions.showTitle">
            <td
                id="titlePlaceholderSC"
                colspan="2"
                class="pb-3"
                :style="'color: ' + styleOptions.textColor + '; font-family: ' + title_font_name + ', Sans-Serif; font-weight: ' + title_font_weight"
            />
        </tr>
        <tr
            v-for="(option, index) in visibleOptions"
            :key="index"
        >
            <td
                colspan="2"
                class="p-1"
            >
                <a
                    target="_blank"
                    :data-zurvey-id="'option-'+index"
                    :style="'text-decoration: none; display: flex; cursor: pointer; color: ' + styleOptions.textColor"
                    :disabled="!isLive"
                    :href="url + '/' + uniqueName + '?' + params + element.guid + '=' + (option.id || index)"
                >
                    <img
                        src="https://zurveyprod.blob.core.windows.net/zurveyprodpub/circle.png"
                        width="15"
                        height="15"
                    >
                    <label
                        style="margin-left: 8px; display: initial;"
                        v-html="option.optionText"
                    />
                </a>
            </td>
        </tr>
        <tr
            v-if="element.has_other_option"
            class="other"
        >
            <td
                colspan="2"
                class="p-1"
            >
                <a
                    target="_blank"
                    data-zurvey-id="option-999"
                    :style="'text-decoration: none; display: flex; cursor: pointer; color: ' + styleOptions.textColor"
                    :disabled="!isLive"
                    :href="url + '/' + uniqueName + '?' + element.guid + '=' + getOtherValue()"
                >
                    <img
                        src="https://zurveyprod.blob.core.windows.net/zurveyprodpub/circle.png"
                        width="15"
                        height="15"
                    >
                    <label style="margin-left: 8px; display: initial;">
                        {{ element.other_option_label }}
                    </label>
                </a>
            </td>
        </tr>
        <tr
            v-if="element.has_dkna_option"
            class="dkna"
        >
            <td
                colspan="2"
                class="p-1"
            >
                <a
                    target="_blank"
                    data-zurvey-id="option-1000"
                    :style="'text-decoration: none; display: flex; cursor: pointer; color: ' + text_color"
                    :disabled="!isLive"
                    :href="url + '/' + uniqueName + '?' + element.guid + '=' + getDknaValue()"
                >
                    <img
                        src="https://zurveyprod.blob.core.windows.net/zurveyprodpub/circle.png"
                        width="15"
                        height="15"
                    >
                    <label style="margin-left: 8px; display: initial;">
                        {{ element.dkna_option_label }}
                    </label>
                </a>
            </td>
        </tr>
        <tr />
        <tr
            v-if="styleOptions.branding"
            :style="'color: #9fa7bc; width: 100%; margin-top: 10px; font-size: 12px; line-height: 16px; font-family: ' + answer_font_name + ', Sans-Serif;'"
        >
            <td style="width: 20px;">
                <img
                    src="https://s3-eu-central-1.amazonaws.com/neticle-images/2019/10/zurvey_16.png"
                    style="margin-bottom: 0; max-width: unset;"
                >
            </td>
            <td>
                {{ $t('EMBEDDED.POWERED_BY', 'powered by') }} <a
                    href="https://zurvey.io"
                    style="text-decoration: none; cursor: pointer;color: #9fa7bc;"
                >Zurvey.io</a>
            </td>
        </tr>
    </table>
</template>

<script lang="ts">
import globals from '@/utils/globals';
import { createNamespacedHelpers } from 'vuex-composition-helpers/dist';
import { computed, nextTick, onMounted, onUpdated, ref } from 'vue';
import hashedKeys from '@/utils/hashedKeys';
import shuffle from 'lodash/shuffle';

export default {
    props: {
        element: { type: Object, default: () => ({}) },
        isLive: { type: Boolean, default: false },
        styleOptions: { type: Object, default: () => ({}) },
        useLinkPlaceholder: { type: Boolean, default: false },
        urlParams: { type: Array as () => Array<{ name: string }>, default: () => ([]) }
    },
    setup(props) {
        const { useState, useGetters } = createNamespacedHelpers('survey');
        const { uniqueName, primary_color, text_color, answer_font_name, answer_font_weight, title_font_name, title_font_weight, hasNewDictionary } = useState(['uniqueName', 'primary_color', 'text_color', 'answer_font_name', 'answer_font_weight', 'title_font_name', 'title_font_weight', 'hasNewDictionary']);
        const { hasActiveLanguages } = useGetters(['hasActiveLanguages']);
        const url = ref(globals.getFillerBaseUrl());
        const params = computed(() => {
            if (props.useLinkPlaceholder) return '@@PARAMS@@';
            let params = hasActiveLanguages.value ? 'lang={{LANG}}&' : '';
            const paramCount = props.urlParams.length;
            if (paramCount > 0) {
                props.urlParams.forEach((p) => {
                    params = params + p.name + '={{' + p.name.toUpperCase() + '}}&';
                });
            }
            return params;
        });

        const getOptionText = (option) => {
            return (typeof option === 'string' || option === null) ? option : option.value;
        };

        const visibleOptions = computed(() => {
            if (props.element.has_random_rotation) {
                const options = props.element.options.map((option, index) => {
                    return { index, optionText: getOptionText(option), id: option.id };
                });
                return shuffle(options);
            } else {
                return props.element.options.map((option, index) => {
                    return { index, optionText: getOptionText(option), id: option.id };
                });
            }
        });

        const border = computed(() => props.styleOptions.borderColor ? ' border: 1px solid' + props.styleOptions.borderColor + '; ' : ' ');

        const updateTitle = async () => {
            const titlePlaceholder = document.getElementById('titlePlaceholderSC');
            await nextTick();

            if (titlePlaceholder) {
                titlePlaceholder.innerHTML = props.element.title;
                const title = titlePlaceholder.getElementsByTagName( 'p' )[0];
                title.style.margin = '0';
            }
        };

        onMounted(() => {
            updateTitle();
        });

        onUpdated(() => {
            updateTitle();
        });

        const getOtherValue = () => hasNewDictionary.value ? hashedKeys.OTHER : 999;
        const getDknaValue = () => hasNewDictionary.value ? hashedKeys.DKNA : 1000;

        return {
            url,
            uniqueName,
            params,
            primary_color,
            text_color,
            answer_font_name,
            answer_font_weight,
            title_font_name,
            title_font_weight,
            visibleOptions,
            border,
            getOtherValue,
            getDknaValue,
        };
    }
};
</script>
