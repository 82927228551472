import JSZip from 'jszip';
import { saveAs } from 'file-saver';

// https://takuti.me/note/javascript-save-svg-as-image/

export default async function exportCharts(fileName: string, charts: any[], width = 1040): Promise<void> {
    const zip = new JSZip();

    const promises = charts.map(async (chart, index) => {
        // Remove html formatting from title or else chart might not be added to zip
        const title = chart.chart.title?.textStr?.replace(/<\/?[^>]+(>|$)/g, '') || ('chart_' + (index + 1));

        const svg = chart.chart.getSVG({ chart: { width } });
        const blob = await getBlob(svg);

        zip.file(title + '.png', blob);
    });

    await Promise.all(promises);

    const pngs = await zip.generateAsync({ type: 'blob' });

    saveAs(pngs, fileName + '.zip');
}

function getBlob(svg): Promise<Blob> {
    return new Promise((resolve) => {
        const blob = new Blob([svg], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);

        const image = new Image();

        image.addEventListener('load', () => {
            URL.revokeObjectURL(url);
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            const context = canvas.getContext('2d');
            context!.drawImage(image, 0, 0);
            canvas.toBlob((blob) => resolve(blob!), 'image/png');
        });

        image.src = url;
    });
}






// When I wrote this code, only god and I knew how it worked. Now, only god knows it!
