<template>
    <div class="flex">
        <div
            v-for="metric in order"
            :key="metric"
            class="flex-grow w-full border-r border-neutral-500 last:border-0"
        >
            <div
                class="flex flex-col items-center justify-center"
                style="aspect-ratio: 1/1;"
            >
                <div class="text-6xl leading-none">
                    {{ +(rates[metric].toFixed(2)) }}%
                </div>
                <div class="text-default text-neutral-800">
                    {{ getRateName(metric) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

import { state } from '../../store';
import { getRateName } from '../../utils';

export default defineComponent({
    setup() {
        const order = ['bounced', 'opened', 'clicked', 'completed'];

        const rates = computed(() => {
            const metrics = Object.values(state.countDistribution).reduce((all, subdivision) => {
                Object.entries(subdivision).forEach(([key,value]) => {
                    all[key] += value;
                });

                return all;
            }, { bounced: 0, clicked: 0, completed: 0, opened: 0, recipients: 0 });

            return {
                bounced: metrics.bounced / metrics.recipients * 100,
                clicked: metrics.clicked / metrics.recipients * 100,
                completed: metrics.completed / metrics.recipients * 100,
                opened: metrics.opened / metrics.recipients * 100,
            };
        });

        return { order, rates, getRateName };
    }
});
</script>
