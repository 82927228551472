<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'EmptyAudienceList',
    components: {

    },
});
</script>
<template>
    <div class="flex flex-col items-center w-1/2 mx-auto mt-4">
        <z-button disabled>
            {{ $t('AUDIENCE.BUTTON_CREATE_NEW_CAMPAIGN', 'Create new campaign') }}
        </z-button>
        <section class="mt-2 text-center text-sm font-light text-neutral-700">
            {{ $t('AUDIENCE.EMPTY_AUDIENCE_LIST_DESCRIPTION', 'You don\'t have a valid Audience containing an Email Address. You can access your Audience on your Home screen.') }}
        </section>
    </div>
</template>
