<script lang="ts">
import type { Campaign } from '@/campaign/types';
import type { PropType } from 'vue';
import type { AudienceWithSegments } from '@/audience/types';

import { toaster } from '@/utils/toaster';
import { VariableType } from '@/campaign/types';
import { ref, defineComponent, toRefs } from 'vue';

import i18next from 'i18next';
import ZSelect from '@/components/ui/Select.vue';

import SurveyStore from '@/survey/store/survey';
import { useCampaigns } from '@/campaign';

function byCampaign (this: Campaign, audience: AudienceWithSegments): boolean {
    return audience.uid === this.audienceId;
}

interface UrlParam {
    guid: string;
    name: string;
}



export default defineComponent({
    name: 'VariablesAndUrlParams',

    components: { ZSelect,  },

    model: {
        prop: 'modelValue',
        event: 'update:modelValue',
    },

    props: {
        modelValue: {
            type: Object as PropType<Campaign>,
            required: true
        },

        audiences: {
            type: Array as PropType<AudienceWithSegments[]>,
            required: true,
        }
    },
    emits: ['update:modelValue', 'show-prev', 'show-next'],

    setup: (props, { emit }) => {
        const audience = props.audiences.find(byCampaign, props.modelValue);
        const { isPopupViewOnly } = toRefs(useCampaigns().state);

        if (!audience) {
            toaster.error(i18next.t('ERRORS.AUDIENCE_NOT_FOUND', 'Audience not found!'));
            throw new ReferenceError(`Audience cannot be found by id (${ props.modelValue.audienceId })!`);
        }

        const fields = audience.fields;
        const fieldCount = fields.length;
        const campaign = { ...props.modelValue };

        const _variables = campaign.variables || [];
        const variableCount = _variables.length;

        // TODO(adam): When implementing editing functionality, we will need to
        // match fields and variables, in case some fields got updated/deleted.

        if (!variableCount) {
            for (let index = 0; index < fieldCount; ++index) {
                const field = fields[index];

                _variables.push({
                    urlUid: null,
                    type: VariableType.Variable,
                    name: field.name,
                    fieldUid: field.id,
                });
            }
        }

        const variables = ref(campaign.variables = _variables);
        const urlParameters: UrlParam[] = SurveyStore.state.urlParameters;

        function setVariableType(uid, newType) {
            variables.value = variables.value.map(v => v.fieldUid === uid ? { ...v, type: newType, urlUid: newType === VariableType.UrlParameter ? urlParameters[0].guid : null } : v);
            campaign.variables = variables.value;
            emit('update:modelValue', campaign);
        }

        function setParamForVariable(uid, paramGuid) {
            variables.value = variables.value.map(v => v.fieldUid === uid ? { ...v, urlUid: paramGuid } : v);
            campaign.variables = variables.value;
            emit('update:modelValue', campaign);
        }

        return {
            audience,
            variables,
            VariableType,
            urlParameters,
            setVariableType,
            setParamForVariable,
            isPopupViewOnly
        };
    }
});
</script>
<template>
    <div class="w-2/3 mx-auto flex flex-col">
        <h1 class="text-xl font-bold self-start">
            {{ $t('AUDIENCE.VARIABLES_TITLE', 'Assign your metadata to Variables and URL Parameters') }}
        </h1>
        <p class="block my-4 text-sm">
            {{ $t('AUDIENCE.VARIABLES_DESCRIPTION', 'Match your Metadatas to use as either a Variable (which you can use in your Email body for a personal touch) or as a URL Parameter (which can also be used in a message but allows targeted questions as Hidden Questions in your survey and you are able to filter these on the dashboard).') }}
        </p>

        <table>
            <thead>
                <th class="text-left w-1/3">
                    {{ $t('CUSTOMER_VIEW_SOURCE_DIMENSION', 'Dimension') }}
                </th>
                <th class="text-left w-1/3">
                    {{ $t('INPUT_API_DIMENSION_TYPE', 'Type') }}
                </th>
                <th class="text-left w-1/3" />
            </thead>
            <tbody>
                <tr
                    v-for="(variable, index) in variables"
                    :key="index"
                >
                    <td class="capitalize">
                        {{ variable.name }}
                    </td>
                    <td>
                        <z-select
                            :disabled="isPopupViewOnly"
                            :model-value="variable.type"
                            @update:modelValue="setVariableType(variable.fieldUid, $event)"
                        >
                            <option
                                selected
                                :value="VariableType.Variable"
                            >
                                {{ $t('AUDIENCE.VARIALBE_TYPE_VARIABLE', 'Variable') }}
                            </option>
                            <option :value="VariableType.UrlParameter">
                                {{ $t('AUDIENCE.VARIALBE_TYPE_URL_PARAM', 'URL Parameter') }}
                            </option>
                        </z-select>
                    </td>
                    <td>
                        <z-select
                            v-show="variable.type === VariableType.UrlParameter"
                            :model-value="variable.urlUid"
                            :disabled="isPopupViewOnly"
                            @update:modelValue="setParamForVariable(variable.fieldUid, $event)"
                        >
                            <option
                                v-for="(param, urlParamIndex) in urlParameters"
                                :key="urlParamIndex"
                                :value="param.guid"
                            >
                                {{ param.name }}
                            </option>
                        </z-select>
                    </td>
                </tr>
            </tbody>
        </table>
        <section class="flex flex-row mx-auto mt-auto">
            <button
                v-if="!isPopupViewOnly"
                class="mx-1 px-3 py-1 rounded border border-primary-700 text-primary-700 text-sm"
                @click="$emit('show-prev')"
            >
                {{ $t('AUDIENCE.BUTTON_BACK', 'Go Back') }}
            </button>
            <z-button
                :disabled="isPopupViewOnly"
                class="mx-1"
                @click="$emit('show-next')"
            >
                {{ $t('BUTTONS.CONTINUE', 'Continue') }}
            </z-button>
        </section>
    </div>
</template>
