<script lang="ts">
import { toaster } from '@/utils/toaster';
import { validateEmails } from '@/utils/emails';
import { ref, defineComponent } from 'vue';

import i18next from 'i18next';
import TagsInputField from '@/components/ui/TagsInputField.vue';


export default defineComponent({
    name: 'TestEmailSettings',

    components: {
        TagsInputField,

    },

    setup: (_, { emit }) => {
        const addresses = ref<string[]>([]);

        return {
            addresses,

            addEmails: (emails: string[]) => {
                for (const email of emails) {
                    if (!addresses.value.includes(email)) {
                        addresses.value.push(email);
                    }
                }
            },

            removeEmail: (email: string) => {
                addresses.value.splice(addresses.value.indexOf(email), 1);
            },

            send: () => {
                if (validateEmails(addresses.value)) {
                    emit('send-test', addresses.value);
                }
                else {
                    toaster.error(i18next.t('SURVEYFORMS.PUBLISH_NOTIFICATION_WRONG_EMAIL_FORMAT', 'Wrong email format(s)'));
                }
            }
        };
    }
});
</script>
<template>
    <div class="w-2/3 mx-auto flex flex-col">
        <h1 class="text-xl font-bold self-start">
            {{ $t('SURVEYFORMS.TEST_EMAIL_TITLE', 'Test Email') }}
        </h1>
        <span class="text-sm my-4">
            {{ $t('SURVEYFORMS.TEST_EMAIL_SUMMARY', 'Enter one or multiple recipient email addresses to send a test email and preview its appearance. We\'ll substitute variables, such as first name” or “last name” with data from a randomly selected audience member in the template. Please note that if you open and complete the survey from the test email, your responses will be recorded under the name of the randomly selected audience member.') }}
        </span>
        <tags-input-field
            :tag-list="addresses"
            tag-type="email"
            placeholder="example@company.com"
            @input="addEmails"
            @removeTag="removeEmail"
        />
        <section class="flex flex-row mx-auto mt-auto">
            <button
                class="mt-auto mx-1 px-3 py-1 rounded border border-primary-700 text-primary-700 text-sm"
                @click="$emit('show-prev')"
            >
                {{ $t('AUDIENCE.BUTTON_BACK', 'Go back') }}
            </button>
            <z-button
                data-test-id="send-test-button"
                :disabled="addresses.length === 0"
                @click="send"
            >
                {{ $t('SURVEYFORMS.EMAIL_SEND_TEST', 'Send Test Email') }}
            </z-button>
        </section>
    </div>
</template>
