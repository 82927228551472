import { RouteConfig } from 'vue-router';
const ConversationInputSetup = () => import('../components/ConversationInputSetup.vue');

const routes: RouteConfig[] = [
    {
        path: ':datasetId',
        redirect: ':datasetId/setup',
    },
    {
        path: ':datasetId/setup',
        component: ConversationInputSetup,
        name: 'conversation-input-setup',
        props: true,
    },
];

export default routes;
