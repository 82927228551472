<template>
    <button
        class="text-default w-full px-6 py-1.5 rounded"
        :class="{
            'text-black bg-white hover:bg-neutral-100': isDark,
            'text-white bg-black hover:bg-neutral-1000': !isDark,
        }"
        @click="onClick"
    >
        <slot />
    </button>
</template>

<script lang="ts">
import useUserStore from '@/user/store';
import { isHexDark } from '@/utils/color';
import { computed, defineComponent } from 'vue';

export default defineComponent({
    setup(_, context) {
        const { state } = useUserStore();

        const isDark = computed(() => isHexDark(state.primaryColor));

        function onClick(event) {
            context.emit('click', event);
        }

        return { onClick, isDark };
    },
});
</script>
