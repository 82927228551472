<template>
    <div class="pb-2 flex flex-col">
        <z-radio
            id="any"
            v-model="selectedOption"
            value="any"
            :label-text="$t('SURVEY.PUBLISH_EMBED_PAGE_VISIBILITY_ANY', 'On any page')"
            @update:modelValue="select($event)"
        />
        <z-radio
            id="custom"
            v-model="selectedOption"
            value="custom"
            :label-text="$t('SURVEY.PUBLISH_EMBED_PAGE_VISIBILITY_SPECIFIC', 'On pages containing') + '...'"
            @update:modelValue="select($event)"
        />
        <template v-if="activeSettings.urls.length > 0">
            <div
                v-for="(url, i) in activeSettings.urls"
                :key="i"
                class="flex items-center my-1"
            >
                <z-input
                    :model-value="url"
                    class="flex-grow"
                    placeholder="eg. /shop"
                    :data-test-id="'url-' + i"
                    @update:modelValue="changeUrl(i, $event)"
                />
                <div class="p-2 flex space-x-2">
                    <icon
                        icon="plus-circle"
                        class="cursor-pointer text-lg"
                        @click="add(i)"
                    />
                    <icon
                        icon="minus-circle"
                        class="cursor-pointer text-lg"
                        @click="remove(i)"
                    />
                </div>
            </div>
        </template>
        <template v-if="activeSettings.type === DisplayTypeEnum.IN_PAGE">
            <z-input
                class="my-3"
                :model-value="activeSettings.querySelector"
                :label="$t('SURVEY.PUBLISH_EMBED_PAGE_VISIBILITY_QUERY_SELECTOR', 'DOM query selector')"
                data-test-id="querySelector"
                placeholder="#id"
                @update:modelValue="changeQuerySelector($event)"
            />
            <p class="text-default my-4">
                {{ $t('SURVEY.PUBLISH_EMBED_PAGE_VISIBILITY_QUERY_SELECTOR_INFO', 'To place a survey in your website, leave a placeholder element in your page with a unique class or id. Your microsurvey will appear in the first match with the query selector. Ids tend to work better since ids are unique per page.') }}
            </p>
        </template>
    </div>
</template>

<script>
import { defineComponent, ref, toRefs, computed, watch } from 'vue';
import Icon from '@/components/ui/Icon.vue';
import useEmbedStore from '@/survey/embed/store/embedStore.ts';
import { DisplayTypeEnum } from '@/survey/embed/store/types';

export default defineComponent({
    components: { Icon },
    setup() {
        const embedStore = useEmbedStore();
        const { activeSettings } = toRefs(embedStore.state);
        const { setUrls, changeSettingsOptions } = embedStore.actions;
        const urlsLength = computed(() => activeSettings.value.urls.length);
        const selectedOption = ref( urlsLength.value === 0 ? 'any' : 'custom');

        function select(value) {
            if (value === 'any') {
                setUrls([]);
            } else {
                setUrls(['']);
            }
            selectedOption.value = value;
        }

        function remove(index) {
            const oldUrls = [...activeSettings.value.urls];
            oldUrls.splice(index, 1);
            setUrls(oldUrls);
        }

        function add(index) {
            const oldUrls = [...activeSettings.value.urls];
            oldUrls.splice(index + 1, 0, '');
            setUrls(oldUrls);
        }

        function changeUrl(index, newValue) {
            const oldUrls = [...activeSettings.value.urls];
            oldUrls.splice(index, 1, newValue);
            setUrls(oldUrls);
        }

        function changeQuerySelector(newValue) {
            changeSettingsOptions('querySelector', newValue);
        }

        watch(urlsLength, (value) => {
            selectedOption.value = value ? 'custom' : 'any';
        });

        return {
            selectedOption,
            activeSettings,
            select,
            remove,
            add,
            changeUrl,
            changeQuerySelector,
            DisplayTypeEnum
        };
    }
});
</script>
