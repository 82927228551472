<script lang="ts">
import type { Campaign } from '@/campaign/types';
import type { Ref, PropType } from 'vue';
import type { SurveyEmailTemplate } from '@/survey/api/types';

import { toaster } from '@/utils/toaster';
import { useSurveyEmailTemplates } from '@/survey/api';
import { ref, defineComponent, toRefs, onBeforeMount, toRef } from 'vue';
import { FontAwesomeIcon } from '@/utils/icons';

import { useCampaigns } from '@/campaign';

import i18next from 'i18next';
import DataTable, { SortDirection } from '@/survey/components/publish/campaign/states/DataTable.vue';
import confirm from '@/utils/confirmPopup';

import Scrollbar from '@/components/ui/Scrollbar.vue';

// NOTE(adam): We can include the user's locale in the formatter, but for now,
// we use the OS's settings.
const formatter = Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
});

const formatVariables = (template: SurveyEmailTemplate): string => {
    return template.variables.join(', ');
};

const formatDateTime = (template: SurveyEmailTemplate, property: keyof SurveyEmailTemplate): string => {
    return (!template[property])
        ? '-'
        : formatter.format(Date.parse(template[property] as string));
};

export default defineComponent({
    name: 'TemplateCustomization',

    components: {
        DataTable,
        FontAwesomeIcon,
        Scrollbar
    },

    model: {
        prop: 'modelValue',
        event: 'update:modelValue',
    },

    props: {
        modelValue: {
            type: Object as PropType<Campaign>,
            required: true
        }
    },
    emits: ['update:modelValue', 'show-next', 'show-prev'],

    setup: (props, { emit }) => {
        const campaign = props.modelValue;
        const { getCampaignsForUserGroup } = useCampaigns();
        const { isPopupViewOnly } = toRefs(useCampaigns().state);
        const selectedTemplate = ref<SurveyEmailTemplate | null>(null);
        const { state, findSurveyEmailTemplates, removeSurveyEmailTemplate } = useSurveyEmailTemplates();

        const campaigns: Ref<Campaign[]> = ref([]);
        const campaignsLoading = ref<boolean>(true);

        // It won't reload the campaign listing if it's already loaded, but
        // just to make sure that they are loaded, we call this:
        onBeforeMount(async () => {
            try {
                const [ templates, newCampaigns ] = await Promise.all([
                    findSurveyEmailTemplates(),
                    getCampaignsForUserGroup(),
                ]);

                campaigns.value = newCampaigns;
                selectedTemplate.value = templates.find(template => template.id == campaign.emailTemplateId) ?? null;
            }
            catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG'));
                Sentry.captureException(e);
            }
            finally {
                campaignsLoading.value = false;
            }
        });

        const dataTableRef = ref();
        const scrollbarRef = ref();

        return {
            dataTableRef,
            scrollbarRef,
            SortDirection,
            formatDateTime,
            formatVariables,
            selectedTemplate,
            templates: toRef(state, 'surveyEmailTemplates'),
            isPopupViewOnly,
            campaignsLoading,

            updateCampaignAndShowNext: () => {
                if (selectedTemplate.value) {
                    const savedEmailTemplateId = campaign.emailTemplateId;

                    campaign.emailTemplateId = selectedTemplate.value.id;
                    emit('update:modelValue', campaign);
                    emit('show-next', { savedEmailTemplateId });
                }
            },

            showNext: () => {
                const savedEmailTemplateId = campaign.emailTemplateId;

                campaign.emailTemplateId = null;
                emit('show-next', { savedEmailTemplateId });
            },

            doesTemplateHaveCampaign: (template: SurveyEmailTemplate) => {
                return campaigns.value.some(campaign => campaign.emailTemplateId == template.id);
            },

            getConnectedCampaigns: (template: SurveyEmailTemplate) => {
                return campaigns.value.find(campaign => campaign.emailTemplateId == template.id)?.name;
            },

            deleteTemplate: async (template: SurveyEmailTemplate) => {
                const confirmed = await confirm.destruction(
                    i18next.t('AUDIENCE.TEMPLATE_DELETION_POPUP_TITLE', 'Deleting template') + ' - ' + template.name,
                    i18next.t('AUDIENCE.TEMPLATE_DELETION_POPUP_BODY', 'Are you sure you would like to delete this template?')
                );
                if (!confirmed) return;
                try {
                    await removeSurveyEmailTemplate(template.id);
                    toaster.success(i18next.t('AUDIENCE.TEMPLATE_DELETED_SUCCESSFULLY', 'Template deleted successfully!'));
                }
                catch (error) {
                    toaster.error(i18next.t('AUDIENCE.ERROR_IN_TEMPLATE_DELETION', 'There was an error while deleting template!'));
                }
            },
        };
    }
});
</script>
<template>
    <div class="flex flex-col space-y-4">
        <scrollbar class="flex-grow flex-shrink h-1 w-full">
            <data-table
                ref="dataTableRef"
                v-model="selectedTemplate"
                :disabled="isPopupViewOnly"
                :labels="[
                    $t('AUDIENCE.TABLE_HEAD_TEMPLATE_NAME_NEW', 'Template Name'),
                    $t('AUDIENCE.TABLE_HEAD_AUDIENCE_CREATED_AT', 'Date of Creation'),
                    $t('AUDIENCE.TABLE_HEAD_TEMPLATE_VARIABLES', 'Variables'),
                    $t('AUDIENCE.TABLE_HEAD_TEMPLATE_CAMPAIGN', 'Campaign'),
                ]"
                :column-widths="['w-3/12', 'w-2/12', 'w-3/12']"

                deletes

                search-by="name"
                sort-by="createdAt"
                :sort-direction="SortDirection.Descending"
                :data-source="templates"
                :column-keys="['name', 'createdAt', 'variables', 'campaign']"

                :formatters="{
                    createdAt: formatDateTime,
                    variables: formatVariables,
                }"
                sticky
                class="w-full"
            >
                <template #default="{ data }">
                    <tr
                        v-for="row in data"
                        :key="row.id"
                        class="relative border-b border-neutral-500"
                        :class="{
                            'bg-active border-b border-primary-700': row === selectedTemplate,
                            'cursor-default text-neutral-800' : isPopupViewOnly,
                            'cursor-pointer' : !isPopupViewOnly,
                        }"
                        @click="!isPopupViewOnly && (selectedTemplate = selectedTemplate === row ? null : row)"
                    >
                        <td class="px-4 py-2 text-sm text-left capitalize">
                            {{ row.name }}
                        </td>
                        <td class="px-4 py-2 text-sm text-left capitalize whitespace-no-wrap">
                            {{ formatDateTime(row, 'createdAt') }}
                        </td>
                        <td class="px-4 py-2 text-sm text-left capitalize">
                            {{ formatVariables(row) }}
                        </td>
                        <td class="px-4 py-2 text-sm text-left capitalize">
                            <div
                                v-if="campaignsLoading"
                                class="bg-neutral-200 animate-pulse"
                            >
                                &nbsp;
                            </div>
                            <span v-else>{{ getConnectedCampaigns(row) }}</span>
                        </td>
                        <td>
                            <font-awesome-icon
                                v-tooltip.top="doesTemplateHaveCampaign(row) ? $t('AUDIENCE.TABLE_TEMPLATE_DELETE_NOT_ALLOWED', 'This template is connected to a campaign.') : null"
                                :icon="['fal', 'trash']"
                                :class="[
                                    (doesTemplateHaveCampaign(row) || isPopupViewOnly)
                                        ? 'fa-icons trash text-neutral-700'
                                        : 'fa-icons trash text-red-500 cursor-pointer'
                                ]"
                                @click.stop="doesTemplateHaveCampaign(row) || isPopupViewOnly ? null : deleteTemplate(row)"
                            />
                        </td>
                    </tr>
                    <tr v-if="!data.length">
                        <td
                            colspan="4"
                            class="px-4 py-2 text-sm text-center capitalize truncate text-neutral-700"
                        >
                            {{ $t('DATATABLE.NO_DATA', 'There is no data to show.') }}
                        </td>
                    </tr>
                </template>
            </data-table>
        </scrollbar>
        <section class="flex flex-row mx-auto mt-auto">
            <button
                v-if="!isPopupViewOnly"
                class="mx-1 px-3 py-1 rounded border border-primary-700 text-primary-700 text-sm"
                @click="$emit('show-prev')"
            >
                {{ $t('AUDIENCE.BUTTON_BACK', 'Go Back') }}
            </button>
            <z-button
                v-show="selectedTemplate || campaignsLoading"
                :disabled="isPopupViewOnly || campaignsLoading"
                class="mx-1"
                @click="updateCampaignAndShowNext"
            >
                {{ $t('AUDIENCE.BUTTON_SELECT_TEMPLATE', 'Select Template') }}
            </z-button>
            <z-button
                v-show="!selectedTemplate && !campaignsLoading"
                :disabled="isPopupViewOnly || campaignsLoading"
                class="mx-1"
                @click="showNext"
            >
                {{ $t('AUDIENCE.BUTTON_CONTINUE_WITH_A_NEW_TEMPLATE', 'Continue with a new new template') }}
            </z-button>
        </section>
    </div>
</template>

<style lang="less" scoped>
.bg-active::after {
    content: '';
    opacity: .12;
    margin-top: 1px;
    @apply block absolute w-full h-full inset-0 bg-primary-700;
}
</style>
