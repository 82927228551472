import { reactive } from 'vue';
import {AudienceSegmentsState, Segment} from '@/audience/types';

import {getMembersBySegmentId, getSegments, deleteSegment} from '@/audience/api';


export const segmentState = reactive<AudienceSegmentsState>({
    segments: [],
    membersPage: 0,
    uid: null,
});

export const segmentActions = {
    getSegments: async (audienceId) => {
        segmentState.segments = await getSegments(audienceId);
    },
    deleteSegment: async (audienceId, segmentId) => {
        await deleteSegment(audienceId, segmentId);
    },
    getSegmentMembers: async (audienceId, segmentId) => {
        const members = await getMembersBySegmentId(audienceId, segmentId, segmentState.membersPage * 50);
        segmentState.segments = segmentState.segments.map(s => s.id === segmentId ? { ...s, members: members } : s );
        segmentState.uid = segmentId;
    },
    setMembersPage(page: number): void {
        segmentState.membersPage = page;
    },
    resetSegmentUid: (): void => {
        segmentState.uid = null;
    },
    setConnectedCampaignOnSegments(segments: Segment[]) {
        segmentState.segments = segments;
    }
};
