<script lang="ts">
import type { Component } from 'vue';
import type { Campaign } from '@/campaign/types';
import type { Ref, PropType } from 'vue';

import { ref, defineComponent } from 'vue';
import type { AudienceWithSegments } from '@/audience/types';
import { setLoading } from '@/utils/loading';

import PopupSubviewSending from '@/survey/components/publish/campaign/popup/views/sending/Default.vue';
import PopupSubviewTestEmailSettings from '@/survey/components/publish/campaign/popup/views/sending/TestEmailSettings.vue';
import PopupSubviewTriggerApiSettings from './sending/TriggerApiSettings.vue';
import i18next from 'i18next';

import { toaster } from '@/utils/toaster';

import { useCampaigns } from '@/campaign';

export default defineComponent({
    name: 'Sending',

    model: {
        prop: 'modelValue',
        event: 'update:modelValue',
    },

    props: {
        modelValue: {
            type: Object as PropType<Campaign>,
            required: true
        },

        audiences: {
            type: Array as PropType<AudienceWithSegments[]>,
            required: true
        },
    },

    emits: ['update:modelValue', 'show-next', 'close', 'show-prev', 'set-schedule'],

    setup: (props, { emit }) => {
        const campaign = props.modelValue;
        const subview: Ref<Component | null> = ref(PopupSubviewSending);
        const { sendCampaignTest } = useCampaigns();

        return {
            subview,

            showTestEmailSettings: () => {
                subview.value = PopupSubviewTestEmailSettings;
            },

            showCampaignNameEditor: () => {
                subview.value = PopupSubviewSending;
            },

            showApiSettings: () => {
                subview.value = PopupSubviewTriggerApiSettings;
            },

            showNext: () => {
                emit('show-next');
            },

            showPrev: () => {
                if (subview.value === PopupSubviewSending) {
                    emit('show-prev');
                }
                else {
                    return subview.value = PopupSubviewSending;
                }
            },

            sendTest: async (emails: string[]) => {
                setLoading(true);
                try {
                    await sendCampaignTest(campaign, emails);
                    subview.value = PopupSubviewSending;
                    setLoading(false);
                    toaster.success(i18next.t('AUDIENCE.CAMPAIGN_POPUP_TEST_EMAIL_SENT', 'Test email(s) sent!'));
                }
                catch (e) {
                    Sentry.captureException(e);
                    setLoading(false);
                    toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                }
            },

            isReminder: useCampaigns().state.reminderWave !== null,
        };
    }
});
</script>
<template>
    <component
        :is="subview"
        :campaign="modelValue"
        :audiences="audiences"
        :is-reminder="isReminder"
        @close="$emit('close')"
        @show-next="showNext"
        @show-prev="showPrev"
        @show-test-email-settings="showTestEmailSettings"
        @send-test="sendTest"
        @show-api-settings="showApiSettings"
        @set-schedule="$emit('set-schedule', $event)"
    />
</template>
