<template>
    <div class="flex flex-col items-center w-1/2 mx-auto mt-4">
        <UpgradeRequestButton
            :feature="planFeatureTypeEnum.CAMPAIGN_EMAILS"
            buttonType="base"
            noIcon
        />
        <section class="mt-2 text-center text-sm font-light text-neutral-700">
            {{ $t('CAMPAIGN_EMAILS.DISABLED_DESCRIPTION', 'Campaign emal feature is not acitvated in your group, to create campaigns please contact your account manager') }}
        </section>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import UpgradeRequestButton from '@/components/common/UpgradeRequestButton.vue';
import planFeatureTypeEnum from '@/domain/planFeatureTypeEnum.js';

export default defineComponent({
    components: { UpgradeRequestButton },
    setup() {
        return { planFeatureTypeEnum };
    }
});
</script>
