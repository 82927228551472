<template>
    <popup
        :title="campaign.name + ' - ' + waveName + ' - ' + $t('CAMPAIGN_METRICS.POPUP_TITLE.METRICS', 'Metrics')"
        is-closable
        has-backdrop
        sticky-header
        @close="$emit('close')"
    >
        <div class="w-5xl space-y-4">
            <template v-if="loading">
                <Loading
                    v-for="index in 2"
                    :key="index"
                    inner-class="h-80"
                />
            </template>
            <template v-else-if="noData">
                <NoData />
            </template>
            <template v-else>
                <metric-rates
                    wave-metrics
                    :campaign="campaign"
                />
                <wave-by-dates :name="waveName" />
            </template>
        </div>
        <template
            #popupActions
        >
            <PopupExportButton
                class="border-r border-neutral-500 mr-2 pr-2"
                :disabled="noData"
                @click="exportData()"
            />
        </template>
    </popup>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref, onUnmounted, computed } from 'vue';
import Popup from '@/components/ui/Popup.vue';
import Loading from '../Loading.vue';
import MetricRates from '../charts/MetricRates.vue';
import WaveByDates from './WaveByDates.vue';
import PopupExportButton from '../PopupExportButton.vue';

import { actions, noData } from '../../store';
import { useRoute } from 'vue-router/composables';
import i18next from 'i18next';
import { toaster } from '@/utils/toaster';
import NoData from '../NoData.vue';
import { Campaign, Wave } from '@/campaign/types';

export default defineComponent({
    components: { Popup, Loading, MetricRates, WaveByDates, PopupExportButton, NoData },
    props: {
        campaign: { type: Object as () => Campaign , required: true },
        wave: { type: Object as () => Wave, required: true }
    },
    setup(props, context) {
        const route = useRoute();
        const loading = ref(false);

        onBeforeMount(async () => {
            try {
                loading.value = true;
                await actions.loadChartData({ datasetId: route.params.id, campaignId: props.campaign.id, waveId: props.wave.id });
            }
            catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG'));
                context.emit('close');
            }
            finally {
                loading.value = false;
            }
        });

        const exportData = async () => {
            try {
                await actions.exportRawData({ datasetId: route.params.id, campaignId: props.campaign.id, waveId: props.wave.id });
                toaster.success(i18next.t('AUDIENCE.CAMPAIGN_EXPORT_UNDER_GENERATION', 'Your export is under generation and will be sent in email.'));
            }
            catch (e: any) {
                if (e.response && e.response.status == 409) {
                    toaster.success(i18next.t('AUDIENCE.CAMPAIGN_EXPORT_UNDER_GENERATION', 'Your export is under generation and will be sent in email.'));
                }
                else {
                    Sentry.captureException(e);
                    toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG'));
                }
            }
        };

        onUnmounted(() => actions.resetCampaignMetricsState());

        const waveName = computed(() => `${i18next.t('AUDIENCE.WAVE', 'Wave')}${props.wave.index + 1}${props.wave.parentWaveId ? ' ' + i18next.t('AUDIENCE.WAVE_REMINDER', 'Reminder') : ''}`);

        return { loading, exportData, noData, waveName };
    }
});
</script>
