export default function(waveType): 'manual'|'api'|'scheduled' {
    let type;

    switch (waveType) {
        case 'manual':
        case 'reminder':
            type = 'manual'; break;
        case 'api':
            type = 'api'; break;
        case 'scheduled':
        case 'scheduled_reminder':
            type = 'scheduled'; break;
    }

    return type;
}
