<template>
    <div class="flex items-center h-6 border border-neutral-700 rounded text-default">
        <div
            v-if="method?.trim()"
            class="bg-neutral-700 text-white px-1 self-stretch flex items-center"
        >
            <div>
                {{ method.toUpperCase() }}
            </div>
        </div>
        <div class="px-1 text-neutral-700 flex-grow">
            {{ url.trim() }}
        </div>
        <button
            class="px-1 text-neutral-700 outline-none"
            @click="copy"
        >
            <icon icon="copy" />
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '@/components/ui/Icon.vue';

import { toaster } from '@/utils/toaster';
import i18next from 'i18next';

export default defineComponent({
    components: { Icon },
    props: {
        method: { type: String, default: '' },
        url: { type: String, required: true }
    },
    setup(props) {
        async function copy() {
            try {
                await navigator.clipboard.writeText(props.url);
                toaster.success(i18next.t('HOME.COPY_SUCCESS', 'Copied to clipboard'));
            }
            catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG'));
            }
        }

        return { copy };
    }
});
</script>
