<template>
    <div>
        <transition
            name="fadeHeight"
            mode="out-in"
        >
            <div class="flex justify-between mt-2">
                <div class="w-1/2 mr-4">
                    <div class="mb-2">
                        <z-input
                            ref="uniqueNameInput"
                            v-model="currentUniqueName"
                            data-test-id="unique-name-input"
                        >
                            <template #label>
                                {{ $t('SURVEYFORMS.PUBLISH_CUSTOM_SURVEY_ID', 'Custom Survey ID') }}
                                <font-awesome-icon
                                    v-tooltip.top="$t('SURVEY.PUBLISH_TOOLTIP_COLLECTORS', 'You can customize your sharing link and make it unique to describe your survey better. E.g.: zurvey.io/customersurvey.')"
                                    class="text-black"
                                    :icon="['fal', 'question-circle']"
                                />
                            </template>
                        </z-input>
                    </div>
                    <div class="mb-4 space-x-2">
                        <z-button
                            data-test-id="save-custom-name"
                            :disabled="currentUniqueName === originalUniqueName"
                            @click="updateUniqueName"
                        >
                            {{ $t('SURVEYFORMS.SAVE_CUSTOM_SURVEY_ID', 'Save Custom Survey ID') }}
                        </z-button>
                        <z-button
                            v-if="currentUniqueName !== originalUniqueName"
                            variant="secondary"
                            @click="resetUpdateUniqueName"
                        >
                            {{ $t('BUTTONS.RESET', 'Reset') }}
                        </z-button>
                    </div>
                    <div class="text-default block w-full pl-2 mb-2 text-black">
                        {{ $t('SURVEYFORMS.PUBLISH_ADDED_URL_PARAMETERS', 'Added URL parameters') }}
                        <font-awesome-icon
                            class="cursor-pointer text-primary-700 float-right mr-2"
                            :icon="['fal', 'plus']"
                            @click="addNewParameter()"
                        />
                    </div>
                    <div
                        v-for="param in parameters"
                        :key="param.guid"
                        class="mb-2"
                    >
                        <div class="flex items-center">
                            <z-input
                                :model-value="param.name"
                                :placeholder="$t('SURVEY.PUBLISH_PARAMETER_PLACEHOLDER', 'eg;source')"
                                :disabled="param.is_answered"
                                @update:modelValue="updateParameterName(param.guid, $event)"
                            />
                            <z-select
                                :model-value="param.type"
                                class="w-1/2 pl-2"
                                :class="{ disabled: param.is_answered }"
                                :disabled="param.is_answered"
                                @update:modelValue="updateParameterType(param.guid, $event)"
                            >
                                <option value="unique">
                                    {{ $t('SURVEY.PUBLISH_PARAMETER_TYPE_UNIQUE', 'Unique') }}
                                </option>
                                <option value="collector">
                                    {{ $t('SURVEY.PUBLISH_PARAMETER_TYPE_COLLECTOR', 'Collector') }}
                                </option>
                            </z-select>
                            <font-awesome-icon
                                v-if="!param.is_answered"
                                class="fa-icon text-red-500 ml-2 cursor-pointer"
                                :icon="['fal', 'trash']"
                                @click="showDeletePopup(param.guid)"
                            />
                        </div>
                        <div class="text-xs text-red-700">
                            {{ getErrorMessage(param) }}
                        </div>
                    </div>
                    <z-button
                        :disabled="!saveable || hasError"
                        @click="saveParameters()"
                    >
                        {{ $t('SURVEY.PUBLISH_URL_PARAMETER_SAVE', 'Save URL Parameters') }}
                    </z-button>
                </div>
                <div class="w-1/2 text-sm">
                    <strong>
                        {{ $t('SURVEYFORMS.PUBLISH_URL_PARAMETERS_DESCRIPTION_TITLE', 'Url parameters') }}
                    </strong>
                    <br><br>
                    <div
                        class="text-left"
                        v-html="$t('SURVEYFORMS.PUBLISH_URL_PARAMETERS_DESCRIPTION', 'With URL parameters you can generate different URLs for the same survey. You can group your responses by <b>Collectors</b>, such as source or you can identify individual respondents by <b>Uniques</b>, like email address or user ID. Every parameter is a new column in the exported Excel output.<br><br>e.g.: s.zurvey.io/customersurvey?<b>source=fb</b>&<b>user-id=123</b>')"
                    />
                </div>
            </div>
        </transition>
        <delete-dimension-popup
            v-if="parameterToDelete"
            :dimension-id="parameterToDelete.guid"
            :dataset-id="datasetId"
            :dataset-type="DatasetTypeEnum.SURVEY"
            :visible="isDeletePopupVisible"
            :title="$t('SURVEY.URL_PARAMETER_USED_TITLE', { defaultValue: 'URL Parameter {{parameterName}} is in use', parameterName: parameterToDelete.name })"
            :default-warning="$t('SURVEY.URL_PARAMETER_USED_DESCRIPTION', 'If you continue with deleting this URL Parameter, some questions\' condition logic might break.')"
            @delete="deleteParameter"
            @close="parameterToDelete = null"
        />
    </div>
</template>

<script lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import { useNamespacedState, useNamespacedActions, useNamespacedGetters } from 'vuex-composition-helpers';
import { FontAwesomeIcon } from '@/utils/icons';
import ZSelect from '@/components/ui/Select.vue';
import i18next from 'i18next';
import { toaster } from '@/utils/toaster';
import { setLoading } from '@/utils/loading';
import DeleteDimensionPopup from '@/survey/components/DeleteDimensionPopup.vue';
import { DatasetTypeEnum } from '@/datasets/store/types';
import confirmPop from '@/utils/confirmPopup/';
import { urlParameterTypes } from '@/survey/store/types';
import * as uuid from 'uuid';

import useEmbedStore from '@/survey/embed/store/embedStore';

export default {
    name: 'UrlCustomization',
    components: {
        DeleteDimensionPopup,
        'font-awesome-icon': FontAwesomeIcon,
        ZSelect,

    },
    setup(_, context) {
        const originalUniqueName = ref<string>();
        const currentUniqueName = ref<string>();
        const parameters = ref<any[]>([]);
        const parameterToDelete = ref();
        const isDeletePopupVisible = ref(false);

        const { uniqueName, urlParameters, datasetId, resumableSurveyFillingGuid, multiFillingRestrictionType } = useNamespacedState('survey', ['uniqueName', 'urlParameters', 'datasetId', 'resumableSurveyFillingGuid', 'multiFillingRestrictionType']);
        const { editableElements } = useNamespacedGetters('survey', ['editableElements']);
        const actions = useNamespacedActions('survey', ['updateParameters', 'deleteParameter', 'updateUniqueName', 'savePublishSettings']);

        const { syncUrlParameters } = useEmbedStore().actions;

        onMounted(() => {
            currentUniqueName.value = uniqueName.value;
            originalUniqueName.value = uniqueName.value;
            parameters.value = [...urlParameters.value];
        });

        watch(uniqueName, (newValue) => {
            originalUniqueName.value = newValue;
            currentUniqueName.value = newValue;
        });

        watch(urlParameters, (newValue) => {
            parameters.value = [...newValue];
        });

        const unavailableUrlParams = ['lang', 'previewLang', 'guid', 'audience_member_id', 'campaign_id', 'wave_id'];

        const saveable = computed(() => {
            return parameters.value.length > 0 && parameters.value.filter(p => p.name === '' || unavailableUrlParams.includes(p.name)).length === 0;
        });

        const uniqueNameInput = ref();

        const updateUniqueName = async () => {
            const isConfirmed = await confirmPop.default(
                i18next.t('GLOBAL.WARNING', 'Warning!'),
                i18next.t('SURVEY.SHARE_CHANGE_UNIQUE_URL', 'If you modify this link, the previous link won\'t function anymore.')
            );
            if (!isConfirmed) {
                currentUniqueName.value = originalUniqueName.value;
                return;
            }

            const regExp = /^[a-zA-Z0-9_-]*$/;
            if (currentUniqueName.value && regExp.test(currentUniqueName.value)) {
                try {
                    await actions.updateUniqueName(currentUniqueName.value);
                    toaster.success(i18next.t('SURVEYFORMS.PUBLISH_SURVEY_UNIQUE_NAME_SAVED', 'Unique survey name successfully saved.'), { timeout: 5000 });
                    originalUniqueName.value = currentUniqueName.value;
                } catch (e: any) {
                    currentUniqueName.value = e.response.data.suggestion;
                    context.emit('uniqueNameChanged', e.response.data.suggestion);
                    uniqueNameInput.value.focus();
                    toaster.error(i18next.t('SURVEYFORMS.PUBLISH_UNIQUE_NAME_ERROR', 'There is already a survey with this name, so we recommended a unique id based on your input, please approve or modify it.'), { timeout: 5000 });
                }
            } else {
                toaster.error(i18next.t('SURVEYFORMS.PUBLISH_SURVEY_UNIQUE_NAME_ERROR', 'Please only use the letters of English alphabet, numbers and underscores.'));
            }
        };

        function resetUpdateUniqueName() {
            currentUniqueName.value = originalUniqueName.value;
        }

        const updateParameterName = (guid, newName) => {
            if (unavailableUrlParams.includes(newName)) {
                toaster.error(i18next.t('SURVEYFORMS.PUBLISH_SURVEY_URL_PARAM_ERROR', {
                    defaultValue: 'The {{param}} URL parameter is not available, it is being used by the system.',
                    param: newName
                }));
            }
            parameters.value = parameters.value.map((p) => {
                if (p.guid !== guid) return p;

                return {
                    ...p,
                    name: newName,
                };
            });
        };

        const updateParameterType = (guid, newType) => {
            parameters.value = parameters.value.map((p) => {
                if (p.guid !== guid) return p;

                return {
                    ...p,
                    type: newType
                };
            });
        };

        const saveParameters = async () => {
            try {
                setLoading(true);
                await actions.updateParameters(parameters.value);

                // check url parameters for resumable filling
                if (parameters.value.find(p => p.guid === resumableSurveyFillingGuid.value)?.type === urlParameterTypes.COLLECTOR) {
                    await actions.savePublishSettings({ resumableSurveyFillingGuid: null });
                }

                if (parameters.value.find(p => p.guid === multiFillingRestrictionType.value)?.type === urlParameterTypes.COLLECTOR) {
                    await actions.savePublishSettings({ multiFillingRestrictionType: 'cookie' });
                }

                // sync url parameter names in embed settings if needed
                await syncUrlParameters(parameters.value);

                toaster.success(i18next.t('SURVEYFORMS.PUBLISH_URL_PARAMETER_SAVED', 'Survey parameters successfully saved.'), { timeout: 5000 });
            }
            catch (e) {
                Sentry.captureException(e);
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG'));
            }
            finally {
                setLoading(false);
            }
        };

        const deleteParameter = async () => {
            try {
                setLoading(true);
                await actions.deleteParameter(parameterToDelete.value.guid);

                // check url parameters for resumable filling
                if (parameterToDelete.value.guid === resumableSurveyFillingGuid.value) {
                    await actions.savePublishSettings({ resumableSurveyFillingGuid: null });
                }

                if (parameterToDelete.value.guid === multiFillingRestrictionType.value) {
                    await actions.savePublishSettings({ multiFillingRestrictionType: 'cookie' });
                }

                // sync url parameter names in embed settings if needed
                await syncUrlParameters(parameters.value);

                toaster.success(i18next.t('SURVEYFORMS.PUBLISH_URL_PARAMETER_DELETED', 'Survey parameter successfully deleted.'), { timeout: 5000 });
                isDeletePopupVisible.value = false;
                parameterToDelete.value = null;
            } catch (e) {
                Sentry.captureException(e);
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
            }
            finally {
                setLoading(false);
            }
        };

        const showDeletePopup = (guid) => {
            parameterToDelete.value = parameters.value.find(p => p.guid === guid);
            const hasDependentCondition = editableElements.value
                .filter(e => e.condition_groups && e.condition_groups.length > 0)
                .some(e => e.condition_groups.some(cg => cg.conditions.some(c => c.conditional_question_guid === guid)))
                .length > 0;

            if ((parameterToDelete.value && parameterToDelete.value.is_answered) || hasDependentCondition ) {
                isDeletePopupVisible.value = true;
            }
        };

        const getErrorMessage = (param) => {
            if (/[ ?&]/.test(param.name)) {
                return i18next.t('SURVEYFORMS.CHARACTERS_NOT_ALLOWED', 'Space, "&" and "?" are not allowed');
            }
        };

        const hasError = computed(() => parameters.value.some(param => !!getErrorMessage(param)));

        const addNewParameter = () => {
            parameters.value = parameters.value.concat({
                guid: uuid.v1(),
                name: '',
                type: urlParameterTypes.UNIQUE,
                is_aswered: false,
                dictionary: [],
            });
        };

        return {
            uniqueNameInput,
            currentUniqueName, updateUniqueName, resetUpdateUniqueName, originalUniqueName,
            parameters, saveable, saveParameters,
            addNewParameter, updateParameterName, updateParameterType, deleteParameter,
            showDeletePopup, isDeletePopupVisible, parameterToDelete, datasetId,
            DatasetTypeEnum,
            getErrorMessage,
            hasError,
        };
    },
};
</script>

<style lang="less" scoped>
    .fadeHeight-enter-active,
    .fadeHeight-leave-active {
        transition: all 0.2s;
        max-height: 230px;
    }
    .fadeHeight-enter,
    .fadeHeight-leave-to
    {
        opacity: 0;
        max-height: 0;
    }
</style>
