import { RouteConfig } from 'vue-router';

import CollectorEditor from '@/survey/components/publish/CollectorEditor.vue';
import CampaignEditor from '@/survey/components/publish/campaign/CampaignEditor.vue';
import EmbedTab from '@/survey/embed/components/EmbedTab.vue';
import WidgetTab from '@/survey/widget/components/WidgetTab.vue';

const routes: RouteConfig[] = [
    {
        path: '/',
        redirect: 'url',
        name: 'publish',
    },
    {
        path: 'url',
        name: 'collector-editor',
        component: CollectorEditor,
    },
    {
        path: 'campaign',
        name: 'campaign-editor',
        component: CampaignEditor,
    },
    {
        path: 'embed',
        name: 'embed-editor',
        component: EmbedTab,
    },
    {
        path: 'widget',
        name: 'widget-editor',
        component: WidgetTab,
    },
];

export default routes;
