import i18next from 'i18next';

const types =  {
    EXCEL: 1,
    SURVEY: 2,
    EMAIL: 3,
    API: 4,
    NMI: 5,
    AUDIENCE: 7,
    WEBSITE_CRAWLER: 8,
    PDF_EXTRACTOR: 9,
    DOMO_INTEGRATION: 10,
    CONVERSATION_INPUT: 11,
};

export default types;

export const getDisplayName = (type) => {
    switch (type) {
    case types.EXCEL:
        return i18next.t('DATA_SOURCE_TYPE.FILE_UPLOAD', 'File upload');
    case types.SURVEY:
        return i18next.t('DATA_SOURCE_TYPE.SURVEY');
    case types.EMAIL:
        return i18next.t('DATA_SOURCE_TYPE.EMAIL', 'Email Connector');
    case types.API:
        return i18next.t('DATA_SOURCE_TYPE.API', 'API Connector');
    case types.NMI:
        return i18next.t('DATA_SOURCE_TYPE.NMI', 'NMI Integration');
    case types.AUDIENCE:
        return i18next.t('DATA_SOURCE_TYPE.AUDIENCE', 'Audience');
    case types.WEBSITE_CRAWLER:
        return i18next.t('DATA_SOURCE_TYPE.WEBSITE_CRAWLER', 'Website Crawler');
    case types.PDF_EXTRACTOR:
        return i18next.t('DATA_SOURCE_TYPE.PDF_EXTRACTOR', 'PDF Extractor');
    case types.DOMO_INTEGRATION:
        return i18next.t('DATA_SOURECE_TYPE.DOMO_INTEGRATION', 'Domo Integration');
    case types.CONVERSATION:
        return i18next.t('DATA_SOURCE_TYPE.CONVERSATION', 'Conversation');
    }
};

export const getColor = (type) => {
    switch (type) {
    case types.EXCEL:
        return '#66d96b';
    case types.SURVEY:
        return '#42b3ff';
    case types.EMAIL:
        return '#eeaa22';
    case types.API:
        return '#f06060';
    case types.NMI:
        return '#007bff';
    case types.WEBSITE_CRAWLER:
        return '#0e7069';
    case types.PDF_EXTRACTOR:
        return '#df9500';
    case types.DOMO_INTEGRATION:
        return '#4edcd4';
    case types.CONVERSATION:
        return '#006EB7';
    }
};
