<template>
    <ZButton
        :disabled="disabled"
        @click="saveSchedules()"
    >
        {{ $t('AUDIENCE.SAVE_SCHEDULE', 'Save schedule') }}
    </ZButton>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import confirmPopup from '@/utils/confirmPopup';

import i18next from 'i18next';

export default defineComponent({
    props: {
        disabled: { type: Boolean, required: true },
        isRecurring: { type: Boolean, required: true },
        allSchedulesAreNotActive: { type: Boolean, required: true },
        notActiveCampaign: { type: Boolean, required: true },
    },
    setup(props, { emit }) {
        async function saveSchedules() {
            let confirmed;

            if (props.isRecurring) {
                if (props.allSchedulesAreNotActive) {
                    confirmed = await confirmPopup.default(
                        i18next.t('AUDIENCE.NO_ACTIVE_SCHEDULES_TITLE', 'No Active Schedules'),
                        i18next.t('AUDIENCE.NO_ACTIVE_RECURRING_SCHEDULES_BODY', 'It looks like your scheduled send is not activated.\nInactive schedules will not be sent out and will not appear in the Campaign List as an upcoming wave.\n\nIf you want your campaign to be sent, activate your schedule by switching on its toggle.'),
                        i18next.t('AUDIENCE.SAVE_ANYWAY', 'Save anyway'),
                        i18next.t('SURVEY_BUILDER.TRANSLATIONS.UNSAVED_CHANGES_POPUP.STAY', 'Stay to edit'),
                        true, // is warning
                        null, // secondary button type
                        true // switch buttons
                    );
                }
                else if (props.notActiveCampaign) {
                    confirmed = await confirmPopup.default(
                        i18next.t('AUDIENCE.CAMPAIGN_ACTIVATED_TITLE', 'Campaign Activated'),
                        i18next.t('AUDIENCE.RECURRING_SCHEDULE_IS_ACTIVE_BODY', 'Your scheduled send is toggled to active. By saving, the campaign will move out of draft state and become active.\nThe next active schedule will appear in the Campaign List as an upcoming wave.'),
                        i18next.t('BUTTONS.SAVE', 'Save'),
                        i18next.t('SURVEY_BUILDER.TRANSLATIONS.UNSAVED_CHANGES_POPUP.STAY', 'Stay to edit'),
                    );
                }
                else {
                    confirmed = true;
                }
            }
            else {
                if (props.allSchedulesAreNotActive) {
                    confirmed = await confirmPopup.default(
                        i18next.t('AUDIENCE.NO_ACTIVE_SCHEDULES_TITLE', 'No Active Schedules'),
                        i18next.t('AUDIENCE.NO_ACTIVE_EXACT_DATE_SCHEDULES_BODY', 'It looks like none of your scheduled sends are toggled to active.\nInactive schedules will not be sent out and will not appear in the Campaign List as an upcoming wave.\n\nIf you want your campaign to be sent, activate at least one schedule by switching on its toggle.'),
                        i18next.t('AUDIENCE.SAVE_ANYWAY', 'Save anyway'),
                        i18next.t('SURVEY_BUILDER.TRANSLATIONS.UNSAVED_CHANGES_POPUP.STAY', 'Stay to edit'),
                        true, // is warning
                        null, // secondary button type
                        true // switch buttons
                    );
                }
                else if (props.notActiveCampaign) {
                    confirmed = await confirmPopup.default(
                        i18next.t('AUDIENCE.CAMPAIGN_ACTIVATED_TITLE', 'Campaign Activated'),
                        i18next.t('AUDIENCE.EXACT_DATE_SCHEDULES_ARE_ACTIVE', 'At least one scheduled send is toggled to active. By saving, the campaign will move out of draft state and become active.\nThe next active schedule will appear in the Campaign List as an upcoming wave.'),
                        i18next.t('BUTTONS.SAVE', 'Save'),
                        i18next.t('SURVEY_BUILDER.TRANSLATIONS.UNSAVED_CHANGES_POPUP.STAY', 'Stay to edit'),
                    );
                }
                else {
                    confirmed = true;
                }
            }

            if (confirmed) emit('save');
        }

        return { saveSchedules };
    }
});
</script>
