<template>
    <popup
        :title="$t('PUBLISH.GENERATE_QR_CODE_POPUP_TITLE', 'Generate QR Code')"
        is-closable
        @close="$emit('close')"
    >
        <div class="w-96">
            <div class="flex flex-col items-center space-y-4 pt-4">
                <h1 class="self-start text-xs font-bold">
                    {{ $t('PUBLISH.GENERATE_QR_CODE_TEXT_TITLE', 'Including URL Parameters') }}
                </h1>
                <p class="text-xs">
                    {{ $t('PUBLISH.GENERATE_QR_CODE_TEXT_BODY', 'Since your survey contains URL parameters, it is important to ensure that all the necessary information is included in the QR codes as well. To accomplish this, you should upload the values of the URL parameters using our predefined template, which already includes the parameter names. By doing so, Zurvey will generate all the QR code versions using these input values.') }}
                </p>
                <z-button
                    variant="secondary"
                    @click="downloadTemplate"
                >
                    <icon
                        icon="download"
                        class="mr-1"
                    />
                    {{ $t('PUBLISH.QR_CODE_DOWNLOAD_TEMPLATE', 'Download template based on your parameters') }}
                </z-button>
                <z-button @click="uploadExcel">
                    {{ $t('PUBLISH.QR_CODE_UPLOAD_EXCEL', 'Upload excel to set up parameters') }}
                </z-button>
            </div>
        </div>
        <input
            ref="file"
            type="file"
            accept=".xls, .xlsx, .csv"
            class="hidden"
            @change="saveFile($event.target.files[0])"
        >
    </popup>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Popup from '@/components/ui/Popup.vue';
import Icon from '@/components/ui/Icon.vue';
import * as XLSX from 'xlsx';
import excelParser from '@/domain/excel/excelParser';
import { useNamespacedMutations } from 'vuex-composition-helpers';
import { useRouter } from 'vue-router/composables';


export default defineComponent({
    components: {
        Popup,
        Icon,

    },
    props: {
        urlParams: { type: Array as () => any[], default: () => [] },
        name: { type: String, default: '' },
    },
    setup(props) {
        const router = useRouter();

        const mutations = useNamespacedMutations('survey/qrCode', ['setTable']);

        function downloadTemplate(): void {
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet([props.urlParams.map(param => param.name)]);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'template');
            XLSX.writeFile(workbook, 'template_' + props.name + '.xlsx');
        }

        const file = ref<HTMLInputElement>();

        function uploadExcel(): void {
            file.value!.click();
        }

        async function saveFile(file: File): Promise<void> {
            mutations.setTable(await excelParser({ file, guessDimensionTypes: false }));
            router.push({ name: 'qr-code/upload' });
        }

        return { file, downloadTemplate, uploadExcel, saveFile };
    },
});
</script>
