<template>
    <div class="p-3">
        <div
            class="inline-flex items-center cursor-pointer p-1 -m-1 space-x-2"
            @click="handleLeaving()"
        >
            <icon
                icon="chevron-left"
                class="text-sm"
            />
            <span class="font-bold">{{ $t('SURVEY.PUBLISH_EMBED_HEADER', 'Embed') }}</span>
        </div>
        <div class="mt-3">
            <z-input
                :model-value="settingsOptions.name"
                :label="$t('SURVEY.PUBLISH_EMBED_SETUP_NAME', 'Embed setup name')"
                data-test-id="setup-name"
                @update:modelValue="changeName($event)"
            />
            <div class="mt-2">
                <label class="pl-2 text-black text-sm">
                    {{ $t('SURVEY.PUBLISH_EMBED_SETUP_DOMAIN', 'Domain') }}
                </label>
                <more-info class="ml-1">
                    {{ $t('SURVEY.PUBLISH_EMBED_SETUP_DOMAIN_TOOLTIP', 'The domain of the website where the survey will be embedded e.g. https://zurvey.io') }}
                </more-info>
                <z-input
                    :model-value="settingsOptions.domain"
                    data-test-id="setup-domain"
                    @update:modelValue="changeDomain($event)"
                />
            </div>
            <div class="mt-4 mb-4">
                <div class="flex justify-between w-2/3 mx-auto">
                    <div
                        class="cursor-pointer"
                        data-test-id="popup-svg"
                        @click="changeType(DisplayTypeEnum.POPUP)"
                    >
                        <popup-svg
                            :embed-type="settingsOptions.type"
                            :width="154"
                            :height="95"
                        />
                        <div class="text-center">
                            {{ $t('SURVEYFORMS.PUBLISH_EMBED_TYPE_POPUP', 'Popup') }}
                        </div>
                    </div>
                    <div
                        class="cursor-pointer"
                        data-test-id="full-svg"
                        @click="changeType(DisplayTypeEnum.FULL_PAGE)"
                    >
                        <full-svg
                            :embed-type="settingsOptions.type"
                            :width="154"
                            :height="95"
                        />
                        <div class="text-center">
                            {{ $t('SURVEYFORMS.PUBLISH_EMBED_TYPE_FULL', 'Full page') }}
                        </div>
                    </div>
                    <div
                        class="cursor-pointer"
                        data-test-id="slider-svg"
                        @click="changeType(DisplayTypeEnum.SLIDER)"
                    >
                        <slider-svg
                            :embed-type="settingsOptions.type"
                            :width="154"
                            :height="95"
                        />
                        <div class="text-center">
                            {{ $t('SURVEYFORMS.PUBLISH_EMBED_TYPE_SLIDER', 'Slider') }}
                        </div>
                    </div>
                </div>
                <div
                    class="mt-1 cursor-pointer flex justify-between w-1/2 mx-auto"
                >
                    <div
                        data-test-id="sidetab-svg"
                        @click="changeType(DisplayTypeEnum.SIDE_TAB)"
                    >
                        <side-tab-svg
                            :embed-type="settingsOptions.type"
                            :width="154"
                            :height="95"
                        />
                        <div class="text-center">
                            {{ $t('SURVEYFORMS.PUBLISH_EMBED_TYPE_SIDE', 'Sidetab') }}
                        </div>
                    </div>
                    <div
                        data-test-id="in-page-svg"
                        @click="changeType(DisplayTypeEnum.IN_PAGE)"
                    >
                        <in-page-popup-svg
                            :embed-type="settingsOptions.type"
                            :width="154"
                            :height="95"
                        />
                        <div class="text-center">
                            {{ $t('SURVEYFORMS.PUBLISH_EMBED_TYPE_IN_PAGE', 'In page') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <accordion
            :text="getText('customization')"
        >
            <embed-customization
                :display-settings="settingsOptions.displaySettings"
                :popup-type="settingsOptions.type"
                @customization-changed="isNew = false"
            />
        </accordion>
        <accordion :text="getText('pageVisibility')">
            <page-visibility />
        </accordion>
        <accordion
            v-if="showUrlParameters"
            :text="getText('urlParameters')"
        >
            <url-parameters />
        </accordion>
        <accordion :text="getText('triggers')">
            <triggers />
        </accordion>
        <accordion :text="getText('targets')">
            <targets />
        </accordion>
        <div class="border-t border-neutral-400 -mb-6 pt-6">
            <info-box
                v-if="warningMessage"
                class="w-full mx-auto my-2"
                type="warning"
            >
                {{ warningMessage }}
            </info-box>
            <info-box
                v-if="!isMultipleFillingOn"
                class="block text-sm mx-auto"
                :style="{ width: 'max-content' }"
                type="warning"
            >
                {{ $t('SURVEY.SINGLE_FILLING_EMBED_WARNING', 'Be aware that in case of Popup, Slider or Sidetab embed types/elements single filling setup is not applicable.') }}
            </info-box>
            <div class="flex mt-6 justify-between">
                <div class="flex">
                    <z-button
                        v-if="selectedId && currentEmbedSettings && currentEmbedSettings.liveSettings && currentEmbedSettings.publishedAt"
                        variant="outline"
                        @click="returnToPreviousState"
                    >
                        {{ $t('SURVEYFORMS.PUBLISH_EMBED_REVERT_TO_PREV_STATE', 'Revert to previous state') }}
                    </z-button>
                    <div
                        v-if="selectedId && currentEmbedSettings && currentEmbedSettings.liveSettings && currentEmbedSettings.publishedAt"
                        class="text-neutral-800 ml-2 mt-1 text-sm"
                    >
                        {{ dayjs(currentEmbedSettings.publishedAt).format('YYYY-MM-DD HH:mm') }}
                    </div>
                </div>
                <div>
                    <z-button
                        class="mx-1"
                        variant="outline"
                        :disabled="!checkIfNameIsValid || !checkIfDomainIsValid"
                        @click="saveToDraft"
                    >
                        {{ $t('SURVEY.PUBLISH_EMBED_SETTINGS_SAVE', 'Save') }}
                    </z-button>
                    <z-button
                        class="mx-1"
                        variant="outline"
                        :disabled="!checkIfSettingsAreValid"
                        @click="save(true)"
                    >
                        {{ $t('SURVEY.PUBLISH_EMBED_PREVIEW', 'Preview') }}
                    </z-button>
                    <z-button
                        class="mx-1"
                        :disabled="!checkIfSettingsAreValid"
                        @click="save()"
                    >
                        {{ $t('SURVEY.PUBLISH_EMBED_SETTINGS_PUBLISH', 'Publish') }}
                    </z-button>
                </div>
            </div>
        </div>
        <embed-preview-popup
            v-if="isPreviewPopupOpen && selectedId != null"
            :id="selectedId"
            :domain="settingsOptions.domain"
            @close="isPreviewPopupOpen = false"
        />
        <embed-activation-popup
            v-if="isActivationPopupOpen"
            :body="$t('SURVEYFORMS.PUBLISH_EMBED_ACTIVATE_POPUP_DESC', 'Currently this embed setting is paused. Would you like to activate it?')"
            @close="activate(false)"
            @activate="activate"
        />
    </div>
</template>

<script lang="ts">
import Icon from '@/components/ui/Icon.vue';
import PopupSvg from '@/components/surveyBuilder/embedSvg/Popup.vue';
import FullSvg from '@/components/surveyBuilder/embedSvg/FullPage.vue';
import SliderSvg from '@/components/surveyBuilder/embedSvg/Slider.vue';
import SideTabSvg from '@/components/surveyBuilder/embedSvg/SideTab.vue';
import { defineComponent, ref, toRefs, computed, watch } from 'vue';
import EmbedCustomization from '@/survey/embed/components/EmbedCustomization.vue';
import PageVisibility from '@/survey/embed/components/PageVisibility.vue';
import UrlParameters from '@/survey/embed/components/URLParameters.vue';
import Triggers from '@/survey/embed/components/Triggers.vue';
import Targets from '@/survey/embed/components/Targets.vue';
import { setLoading } from '@/utils/loading';
import i18next from 'i18next';
import useEmbedStore from '@/survey/embed/store/embedStore';
import { toaster } from '@/utils/toaster';
import Accordion from '@/components/ui/Accordion.vue';
import { useNamespacedState } from 'vuex-composition-helpers';
import EmbedPreviewPopup from '@/survey/embed/components/EmbedPreviewPopup.vue';
import InPagePopupSvg from '@/components/surveyBuilder/embedSvg/InPagePopup.vue';
import { DisplayTypeEnum } from '@/survey/embed/store/types';
import EmbedActivationPopup from '@/survey/embed/components/EmbedActivationPopup.vue';
import confirm from '@/utils/confirmPopup';
import isEqual from 'lodash/isEqual';
import InfoBox from '@/components/ui/InfoBox.vue';
import dayjs from 'dayjs';
import MoreInfo from '@/components/ui/MoreInfo.vue';
import { onBeforeRouteLeave } from 'vue-router/composables';

export default defineComponent({
    components: {
        InPagePopupSvg,
        Accordion,
        EmbedCustomization, Icon, PopupSvg, FullSvg, SliderSvg, SideTabSvg, PageVisibility, Triggers, Targets, UrlParameters,
        EmbedPreviewPopup, EmbedActivationPopup,
        InfoBox, MoreInfo,

    },
    setup(_, ctx) {
        const {
            urlParameters,
            background_color,
            bgImage,
            is_multilang,
            isMultipleFillingOn,
        } = useNamespacedState('survey', ['urlParameters', 'background_color', 'bgImage', 'is_multilang', 'isMultipleFillingOn']);
        const embedStore = useEmbedStore();
        const { activeSettings, selectedId, defaultSettings, surveyEmbedSettings, hasUnsavedChanges } = toRefs(embedStore.state);
        const {
            setActiveSettings,
            changeSettingsOptions,
            updateEmbedSettings,
            createNewSettings,
            returnToPrevState,
            setActivity,
            setHasUnsavedChanges,
        } = embedStore.actions;
        const isPreviewPopupOpen = ref(false);
        const isActivationPopupOpen = ref(false);
        const showPublishPopup = ref(false);

        function changeName(newName) {
            setActiveSettings({ ...activeSettings.value, name: newName });
        }

        function changeDomain(newDomain) {
            setActiveSettings({ ...activeSettings.value, domain: newDomain });
        }

        const currentEmbedSettings = computed(() => surveyEmbedSettings.value.find(ses => ses.id === selectedId.value));

        const isNew = ref(selectedId.value == null);

        async function changeType(newType) {
            if (!isNew.value) {
                const isConfirmed = await confirm.default(
                    i18next.t('GLOBAL.WARNING', 'Warning!'),
                    i18next.t('SURVEYFORMS.PUBLISH_EMBED_CUSTOMIZATION_RESET', 'If you modify the type, the customization settings will reset to default.')
                );
                if (!isConfirmed) {
                    return;
                }
            }

            isNew.value = true;
            setActiveSettings({ ...activeSettings.value, type: newType });

            switch (newType) {
                case DisplayTypeEnum.FULL_PAGE:
                    changeSettingsOptions('displaySettings', {
                        ...defaultSettings.value.displaySettings,
                        margin: '0',
                        backgroundColor: background_color.value,
                        panelColor: (bgImage.value && bgImage.value.panelSettings) ? bgImage.value.panelSettings.bgColor : background_color,
                        backgroundImage: !!bgImage.value
                    });
                    break;
                case DisplayTypeEnum.SLIDER:
                    changeSettingsOptions('displaySettings', {
                        ...defaultSettings.value.displaySettings,
                        margin: '0',
                        direction: 'right',
                        backgroundColor: (bgImage.value && bgImage.value.panelSettings) ? bgImage.value.panelSettings.bgColor : background_color.value
                    });
                    break;
                case DisplayTypeEnum.SIDE_TAB:
                    changeSettingsOptions('displaySettings', {
                        ...defaultSettings.value.displaySettings,
                        buttonText: 'Feedback',
                        backgroundColor: (bgImage.value && bgImage.value.panelSettings) ? bgImage.value.panelSettings.bgColor : background_color.value
                    });
                    break;
                case DisplayTypeEnum.IN_PAGE:
                    changeSettingsOptions('displaySettings', {
                        ...defaultSettings.value.displaySettings,
                        backgroundColor: 'transparent'
                    });
                    break;
                case DisplayTypeEnum.POPUP:
                    changeSettingsOptions('displaySettings', {
                        ...defaultSettings.value.displaySettings,
                        backgroundColor: (bgImage.value && bgImage.value.panelSettings) ? bgImage.value.panelSettings.bgColor : background_color.value
                    });
                    break;
            }
        }

        async function saveToDraft() {
            setLoading(true);
            try {
                if (selectedId.value) {
                    await updateEmbedSettings(false, true);
                } else {
                    await createNewSettings(false, true);
                }
                toaster.success(i18next.t('SURVEY.PUBLISH_EMBED_SETTINGS_SAVE_TO_DARFT', 'Embed settings saved successfully.'));
                setHasUnsavedChanges(false);
            } catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                Sentry.captureException(e);
                Sentry.addBreadcrumb({
                    message: 'Embed settings draft save failed'
                });
            }
            setLoading(false);
        }

        async function save(preview = false) {
            // Only valid settings can reach this point
            if (!preview && currentEmbedSettings.value?.isActive) {
                const confirmed = await confirm.default(
                    i18next.t('SURVEYFORMS.PUBLISH_EMBED_READY_TO_PUBLISH', 'Are you ready to publish?'),
                    i18next.t('SURVEYFORMS.PUBLISH_EMBED_READY_TO_PUBLISH_INFO', 'When publishing, your changes will take effect immediately!'),
                    i18next.t('SURVEY.PUBLISH_EMBED_SETTINGS_PUBLISH', 'Publish'),
                    i18next.t('SURVEYFORMS.PUBLISH_EMBED_SETTINGS_CANCEL', 'Cancel'),
                    true
                );
                if (!confirmed) {
                    return;
                }
            }
            setLoading(true);
            try {
                if (selectedId.value) {
                    await updateEmbedSettings(preview);
                } else {
                    await createNewSettings(preview);
                }
                if (preview) {
                    isPreviewPopupOpen.value = true;
                } else if (currentEmbedSettings.value?.isActive) {
                    ctx.emit('close');
                } else {
                    isActivationPopupOpen.value = true;
                }
                setHasUnsavedChanges(false);
            } catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                Sentry.captureException(e);
                Sentry.addBreadcrumb({
                    message: 'Embed settings save failed'
                });
            }
            showPublishPopup.value = false;
            setLoading(false);
        }

        async function returnToPreviousState() {
            setLoading(true);
            try {
                await returnToPrevState();
                setHasUnsavedChanges(false);
                ctx.emit('close');
            } catch (error) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                Sentry.captureException(error);
                Sentry.addBreadcrumb({
                    message: 'Returning to previous state failed'
                });
            }
            setLoading(false);
        }

        const checkIfNameIsValid = computed(() => activeSettings.value.name && activeSettings.value.name !== '');

        const checkIfDomainIsValid = computed(() => activeSettings.value.domain && activeSettings.value.domain !== '' && (activeSettings.value.domain.includes('https://') || activeSettings.value.domain.includes('http://')));

        const checkIfSettingsAreValid = computed((): boolean => {
            return !(
                (activeSettings.value.type === DisplayTypeEnum.IN_PAGE && (!activeSettings.value.querySelector || activeSettings.value.querySelector === '')) ||
                (activeSettings.value.targets.some(t => t.value == '' || t.value.length === 0)) ||
                (activeSettings.value.triggers.some(t => t.value == '')) ||
                (activeSettings.value.triggers.length === 0) ||
                !checkIfNameIsValid.value ||
                !checkIfDomainIsValid.value
            );
        });
        const warningMessage = computed(() => {
            if (!checkIfNameIsValid.value) {
                return i18next.t('SURVEYFORMS.PUBLISH_EMBED_MISSING_SETUP_NAME', 'Setup name is missing.');
            }
            if (!checkIfDomainIsValid.value) {
                return i18next.t('SURVEYFORMS.PUBLISH_EMBED_MISSING_SETUP_DOMAIN', 'Domain is missing or invalid.');
            }
            if (!checkIfSettingsAreValid.value) {
                return i18next.t('SURVEYFORMS.PUBLISH_EMBED_INVALID_SETTING', 'You have left some defining settings blank, your setup can only be saved as draft.');
            }
            return null;
        });

        const activate = async (activate: boolean = true) => {
            if (activate) {
                try {
                    setLoading(true);
                    await setActivity(selectedId.value!);
                    toaster.success(i18next.t('SURVEY.PUBLISH_EMBED_SETTINGS_SET_TO_ACTIVE', 'Your embedded survey settings were activated successfully.'));
                } catch (e) {
                    toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                    Sentry.captureException(e);
                }
                setLoading(false);
            }
            isActivationPopupOpen.value = false;
            ctx.emit('close');
        };


        watch(activeSettings, () => {
            setHasUnsavedChanges(!isEqual(currentEmbedSettings.value?.draftSettings, activeSettings.value));
        });

        async function handleLeaving(): Promise<boolean> {
            //TODO: If we refact the publish routes (subroutes), this whole unsaved changes logic can be simplified to check route change
            //TODO: Until then, I have to separate to 3 parts (Publish beforeRouteLeave, change Publish tab, EmbedTab)
            if (hasUnsavedChanges.value) {
                const confirmed = await confirm.default(
                    i18next.t('SURVEYFORMS_EMBED_UNSAVED_CHANGES', 'You have unsaved changes'),
                    i18next.t('SURVEYFORMS_EMBED_UNSAVED_CHANGES_LEAVE', 'If you leave now, your changes will be lost. Do you want to save your changes?'),
                    i18next.t('GLOBAL.SAVE_CHANGES', 'Save changes'),
                    i18next.t('GLOBAL.DISCARD_CHANGES', 'Discard changes'),
                    true
                ) as Promise<boolean>;
                if (!confirmed) {
                    ctx.emit('close');
                    return true;
                } else {
                    try {
                        await saveToDraft();
                        toaster.success(i18next.t('SURVEY.PUBLISH_EMBED_SETTINGS_SAVE_TO_DARFT', 'Embed settings saved successfully.'));
                        ctx.emit('close');
                        return true;
                    } catch (e) {
                        toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                        Sentry.captureException(e);
                        Sentry.addBreadcrumb({
                            message: 'Embed settings draft save failed'
                        });
                        return false;
                    }

                }
            } else {
                ctx.emit('close');
                return true;
            }
        }

        function getText(type) {
            let text = '';
            switch (type) {
                case 'customization':
                    text = i18next.t('SURVEYFORMS.PUBLISH_EMBED_SETTINGS_CUSTOMIZATION', 'Customization');
                    if (currentEmbedSettings.value?.publishedAt && !Array.isArray(currentEmbedSettings.value?.liveSettings) && !isEqual(currentEmbedSettings.value?.liveSettings.displaySettings, activeSettings.value.displaySettings)) {
                        text = text.concat('  ', '<span class="text-neutral-600 italic font-normal ml-1">' , i18next.t('SURVEY.PUBLISH_EMBED_SETTINGS_UNPUBLISHED_CHANGES', 'Unpublished changes'), '</span>');
                    }
                    break;
                case 'pageVisibility':
                    text = i18next.t('SURVEYFORMS.PUBLISH_EMBED_PAGE_VISIBILITY', 'Page visibility');
                    text = text.concat(' ', '(', ( activeSettings.value.urls.length ? activeSettings.value.urls.length.toString() : i18next.t('SURVEY.PUBLISH_EMBED_PAGE_VISIBILITY_ANY', 'On any page')), ')');
                    if (currentEmbedSettings.value?.publishedAt && !Array.isArray(currentEmbedSettings.value?.liveSettings) && !isEqual(currentEmbedSettings.value?.liveSettings.urls, activeSettings.value.urls)) {
                        text = text.concat('  ', '<span class="text-neutral-600 italic font-normal ml-1 ml-1">' , i18next.t('SURVEY.PUBLISH_EMBED_SETTINGS_UNPUBLISHED_CHANGES', 'Unpublished changes'), '</span>');
                    }
                    break;
                case 'urlParameters':
                    text = i18next.t('SURVEYFORMS.PUBLISH_EMBED_URL_PARAMS', 'URL Parameters');
                    text = text.concat(' ', '(', activeSettings.value.urlParameters.length.toString(), ')');
                    if (currentEmbedSettings.value?.publishedAt && !Array.isArray(currentEmbedSettings.value?.liveSettings) && !isEqual(currentEmbedSettings.value?.liveSettings.urlParameters, activeSettings.value.urlParameters)) {
                        text = text.concat('  ', '<span class="text-neutral-600 italic font-normal ml-1">' , i18next.t('SURVEY.PUBLISH_EMBED_SETTINGS_UNPUBLISHED_CHANGES', 'Unpublished changes'), '</span>');
                    }
                    break;
                case 'triggers':
                    text = i18next.t('SURVEYFORMS.PUBLISH_EMBED_TRIGGERS', 'Triggers');
                    text = text.concat(' ', '(', activeSettings.value.triggers.length.toString(), ')');
                    if (currentEmbedSettings.value?.publishedAt && !Array.isArray(currentEmbedSettings.value?.liveSettings) && !isEqual(currentEmbedSettings.value?.liveSettings.triggers, activeSettings.value.triggers)) {
                        text = text.concat('  ', '<span class="text-neutral-600 italic font-normal ml-1">' , i18next.t('SURVEY.PUBLISH_EMBED_SETTINGS_UNPUBLISHED_CHANGES', 'Unpublished changes'), '</span>');
                    }
                    break;
                case 'targets':
                    text = i18next.t('SURVEYFORMS.PUBLISH_EMBED_TARGETS', 'Targets');
                    text = text.concat(' ', '(', activeSettings.value.targets.length.toString(), ')');
                    if (currentEmbedSettings.value?.publishedAt && !Array.isArray(currentEmbedSettings.value?.liveSettings) && !isEqual(currentEmbedSettings.value?.liveSettings.targets, activeSettings.value.targets)) {
                        text = text.concat('  ', '<span class="text-neutral-600 italic font-normal ml-1">' , i18next.t('SURVEY.PUBLISH_EMBED_SETTINGS_UNPUBLISHED_CHANGES', 'Unpublished changes'), '</span>');
                    }
                    break;
            }
            return text;
        }

        const showUrlParameters = computed(() => {
            return is_multilang.value || urlParameters.value?.length > 0;
        });

        onBeforeRouteLeave(async (_to, _from, next) => {
            if (await handleLeaving()) {
                next();
            }
        });

        return {
            changeName,
            changeType,
            changeDomain,
            selectedId,
            returnToPreviousState,
            save,
            settingsOptions: activeSettings,
            urlParameters,
            DisplayTypeEnum,
            isPreviewPopupOpen,
            isActivationPopupOpen,
            activate,
            saveToDraft,
            checkIfNameIsValid,
            checkIfDomainIsValid,
            checkIfSettingsAreValid,
            showPublishPopup,
            currentEmbedSettings,
            handleLeaving,
            getText,
            warningMessage,
            showUrlParameters,
            dayjs,
            isMultipleFillingOn,
            isNew,
        };
    }
});
</script>

