import i18next from 'i18next';

export interface EmbedState {
    datasetId?: string,
    surveyEmbedSettings: EmbedSettings[],
    hasUnsavedChanges: boolean,
    activeSettings: SettingOptions,
    selectedId: number|null,
    isActive: boolean,
    defaultSettings: SettingOptions
}

export interface EmbedSettings {
    id: number,
    userGroupId: number,
    datasetId: string,
    liveSettings: SettingOptions|[],
    nextSettings: SettingOptions|[],
    draftSettings: SettingOptions,
    surveyShortCode: string,
    isActive: boolean,
    publishedAt: string|null
}

export interface SettingOptions {
    name: string,
    domain?: string,
    type: DisplayTypeEnum,
    displaySettings: DisplaySettings,
    urls: string[],
    querySelector: string,
    triggers: Trigger[]
    targets: Targets[],
    urlParameters: UrlParam[],
    delay: number
}

export interface DisplaySettings {
    backgroundColor: string,
    textColor: string,
    primaryColor: string,
    showTitle: boolean,
    title: string,
    margin?: string,
    borderRadius: string,
    buttonText?: string,
    shadow: boolean,
    direction?: string,
    backgroundOverlay: boolean,
    closeOnSubmit: boolean,
    removeBranding: boolean,
    removePrivacyPolicy: boolean,
    panelColor?: string,
    backgroundImage?: boolean,
}

export interface Trigger {
    type: TriggerTypeEnum
    value?: string
}

export interface Targets {
    type: TargetTypeEnum,
    variable?: string,
    operator?: OperatorTypeEnum,
    value: any
}

export interface UrlParam {
    urlParam: Parameter,
    source: SourceTypeEnum
    value: string
}

export interface Parameter {
    guid: string,
    name: string
}

export enum SourceTypeEnum {
    URL_QUERY = 'urlQuery',
    COOKIE = 'cookie',
    LOCAL_STORAGE = 'localStorage',
    GTM = 'gtm',
    ZURVEY = 'zurvey',
    CONSTANT = 'constant',
}

export enum TriggerTypeEnum {
    ON_PAGE_VISIT = 'pageVisit',
    ON_CLICK = 'click',
    ON_SCROLL = 'scroll',
    ON_IDLING_USER = 'idlingUser',
    ON_EXIT = 'exit',
    ON_GTM_EVENT = 'gtm',
    ON_ZURVEY_EVENT = 'zurvey',
}
export enum DisplayTypeEnum {
    POPUP = 'popup',
    FULL_PAGE = 'full',
    SLIDER = 'slider',
    SIDE_TAB = 'sidetab',
    IN_PAGE = 'inPage'
}

export enum TargetTypeEnum {
    DEVICE = 'device',
    OS = 'os',
    BROWSER = 'browser',
    VISITOR = 'visitor',
    VISITOR_PERCENTAGE = 'visitorPercentage',
    TIME = 'time',
    COOKIE = 'cookie',
    GTM = 'gtm',
    ZURVEY = 'zurvey',
}

export const SourceTypeText = {
    [SourceTypeEnum.URL_QUERY]: () => i18next.t('SURVEYFORMS_EMBED_URL_SOURCE_TYPE_URL', 'URL query'),
    [SourceTypeEnum.COOKIE]: () => i18next.t('SURVEYFORMS_EMBED_URL_SOURCE_TYPE_COOKIE', 'Cookie'),
    [SourceTypeEnum.LOCAL_STORAGE]: () => i18next.t('SURVEYFORMS_EMBED_URL_SOURCE_TYPE_LOCAL_STORAGE', 'Local storage'),
    [SourceTypeEnum.GTM]: () => i18next.t('SURVEYFORMS_EMBED_TARGET_TYPE_GTM', 'GTM variable'),
    [SourceTypeEnum.ZURVEY]: () => i18next.t('SURVEYFORMS_EMBED_TARGET_TYPE_ZURVEY', 'Zurvey variable'),
    [SourceTypeEnum.CONSTANT]: () => i18next.t('SURVEYFORMS_EMBED_URL_SOURCE_TYPE_CONSTANT', 'Constant'),
};

export const TargetTypeText = {
    [TargetTypeEnum.DEVICE]: () => i18next.t('SURVEYFORMS_EMBED_TARGET_TYPE_DEVICE', 'Device'),
    [TargetTypeEnum.OS]: () => i18next.t('SURVEYFORMS_EMBED_TARGET_TYPE_OS', 'Operating System'),
    [TargetTypeEnum.BROWSER]: () => i18next.t('SURVEYFORMS_EMBED_TARGET_TYPE_BROWSER', 'Browser'),
    [TargetTypeEnum.VISITOR]: () => i18next.t('SURVEYFORMS_EMBED_TARGET_TYPE_VISITOR', 'Visitor Segment'),
    [TargetTypeEnum.VISITOR_PERCENTAGE]: () => i18next.t('SURVEYFORMS_EMBED_TARGET_TYPE_PERCENTAGE', 'Percentage of visitors'),
    [TargetTypeEnum.TIME]: () => i18next.t('SURVEYFORMS_EMBED_TARGET_TYPE_TIME', 'Time'),
    [TargetTypeEnum.COOKIE]: () => i18next.t('SURVEYFORMS_EMBED_TARGET_TYPE_COOKIE', 'Cookie variable'),
    [TargetTypeEnum.GTM]: () => i18next.t('SURVEYFORMS_EMBED_TARGET_TYPE_GTM', 'GTM variable'),
    [TargetTypeEnum.ZURVEY]: () => i18next.t('SURVEYFORMS_EMBED_TARGET_TYPE_ZURVEY', 'Zurvey variable')
};

export enum OperatorTypeEnum {
    CONTAINS = 'contains',
    EQ = 'eq',
    NOT_EQ = 'neq',
    NOT_CONTAINS = 'notContains',
    ONE_OF = 'oneOf',
    NONE_OF = 'noneOf',
}

export const OperatorTypeText = {
    [OperatorTypeEnum.CONTAINS]: () => i18next.t('SURVEYFORMS.CONDITION_OPERATOR_CONTAINS'),
    [OperatorTypeEnum.NOT_CONTAINS]: () => i18next.t('SURVEYFORMS_EMBED_OPERATOR_NOT_CONTAINS', 'Not contains'),
    [OperatorTypeEnum.EQ]: () => i18next.t('SURVEYFORMS.CONDITION_OPERATOR_EQ'),
    [OperatorTypeEnum.NOT_EQ]: () => i18next.t('SURVEYFORMS.CONDITION_OPERATOR_NEQ'),
    [OperatorTypeEnum.ONE_OF]: () => i18next.t('SURVEYFORMS.CONDITION_OPERATOR_ONE_OF'),
    [OperatorTypeEnum.NONE_OF]: () => i18next.t('SURVEYFORMS.CONDITION_OPERATOR_NONE_OF'),
};

export enum DeviceTypeEnum {
    MOBILE = 'mobile',
    TABLET = 'tablet',
    DESKTOP = 'desktop',
}

export const DeviceTypeText = {
    [DeviceTypeEnum.MOBILE]: () => i18next.t('SURVEYFORMS_EMBED_DEVICE_TYPE_MOBILE', 'Mobile'),
    [DeviceTypeEnum.TABLET]: () => i18next.t('SURVEYFORMS_EMBED_DEVICE_TYPE_TABLET', 'Tablet'),
    [DeviceTypeEnum.DESKTOP]: () => i18next.t('SURVEYFORMS_EMBED_DEVICE_TYPE_DESKTOP', 'Desktop')
};

export enum OSTypeEnum {
    WINDOWS = 'Windows',
    OSX = 'macOS',
    LINUX = 'Linux',
    ANDROID = 'Android',
    IOS = 'IOS',
    OTHER = 'other',
}

export enum BrowserTypeEnum {
    CHROME = 'Chrome',
    FIREFOX = 'Firefox',
    SAFARI = 'Safari',
    INTERNET_EXPLORER = 'Internet Explorer',
    EDGE = 'Edge',
    OTHER = 'other',
}

export enum VisitorTypeEnum {
    NEW = 'new',
    RETURNING = 'returning'
}

export const VisitorTypeText = {
    [VisitorTypeEnum.NEW]: () => i18next.t('SURVEYFORMS_EMBED_VISITOR_TYPE_NEW', 'New'),
    [VisitorTypeEnum.RETURNING]: () => i18next.t('SURVEYFORMS_EMBED_VISITOR_TYPE_RETURNING', 'Returning'),
};

export enum TimeTypeEnum {
    ONCE_SESSION = 'onceSession',
    ONCE_DAY = 'onceDay',
    ONCE_WEEK = 'onceWeek',
    ONCE_MONTH = 'onceMonth',
}

export const TimeTypeText = {
    [TimeTypeEnum.ONCE_DAY]: () => i18next.t('SURVEYFORMS_EMBED_TIME_TYPE_ONCE_DAY', 'Once a day'),
    [TimeTypeEnum.ONCE_MONTH]: () => i18next.t('SURVEYFORMS_EMBED_TIME_TYPE_ONCE_MONTH', 'Once a month'),
    [TimeTypeEnum.ONCE_SESSION]: () => i18next.t('SURVEYFORMS_EMBED_TIME_TYPE_ONCE_SESSION', 'Once a session'),
    [TimeTypeEnum.ONCE_WEEK]: () => i18next.t('SURVEYFORMS_EMBED_TIME_TYPE_ONCE_WEEK', 'Once a week'),
};

export const embedTypeText = {
    [DisplayTypeEnum.POPUP]: () => i18next.t('SURVEYFORMS.PUBLISH_EMBED_TYPE_POPUP', 'Popup'),
    [DisplayTypeEnum.FULL_PAGE]: () => i18next.t('SURVEYFORMS.PUBLISH_EMBED_TYPE_FULL', 'Full page'),
    [DisplayTypeEnum.SLIDER]: () => i18next.t('SURVEYFORMS.PUBLISH_EMBED_TYPE_SLIDER', 'Slider'),
    [DisplayTypeEnum.SIDE_TAB]: () => i18next.t('SURVEYFORMS.PUBLISH_EMBED_TYPE_SIDE', 'Sidetab'),
    [DisplayTypeEnum.IN_PAGE]: () => i18next.t('SURVEYFORMS.PUBLISH_EMBED_TYPE_IN_PAGE', 'In page'),
};

