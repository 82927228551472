<script lang="ts">
import type { Campaign } from '@/campaign/types';
import type { PropType } from 'vue';

import { toaster } from '@/utils/toaster';
import { useCampaigns } from '@/campaign';
import { useSurveyEmailTemplates } from '@/survey/api';
import { NewSurveyEmailTemplate, SurveyEmailTemplate } from '@/survey/api/types';
import { ref, defineComponent, computed, toRefs } from 'vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers/dist';
const { useState: useSurveyState } = createNamespacedHelpers('survey');
import useUserStore from '@/user/store';
import i18next from 'i18next';
import EmailTemplateCustomization from '@/survey/components/publish/email/EmailTemplateCustomization.vue';
import { VariableType } from '@/campaign/types';
import striptags from 'striptags';

function byCampaign (this: Campaign, template: SurveyEmailTemplate): boolean {
    return template.id === this.emailTemplateId;
}

export default defineComponent({
    name: 'EmailCustomization',

    components: { EmailTemplateCustomization },

    model: {
        prop: 'modelValue',
        event: 'update:modelValue',
    },

    props: {
        modelValue: {
            type: Object as PropType<Campaign>,
            required: true
        },

        /**
         * We use this (with the campaign's id and actual emailTemplateId) to
         * see if we should use create a new template, or to update an existing
         * one later when the user decides to save.
         */
        savedEmailTemplateId: {
            type: Number as PropType<number | null>,
            default: null,
        },
    },
    emits: ['show-prev', 'show-next', 'campaign-saved'],

    setup: (props, ctx) => {
        const { title: surveyTitle } = useSurveyState(['title']);
        const { groupName } = useUserStore().state;
        const defaultName = ref<string | null>(null);
        const { isPopupViewOnly } = toRefs(useCampaigns().state);
        const defaultButtonText = ref<string|null>(i18next.t('SURVEYFORMS.EMAIL_BUTTON'));
        const defaultEmbeddedQuestionGuid = ref<string|null>(null);
        const defaultSubject = ref(`${ i18next.t('SURVEYFORMS.EMAIL_SUBJECT') }: ${ striptags(surveyTitle.value) }`);
        const defaultSender = ref('Zurvey');
        const defaultEmailCode = ref(
            '<div>' + i18next.t('SURVEYFORMS.EMAIL_GREETING') + '</div>' +
            '<div>' + i18next.t('SURVEYFORMS.EMAIL_MESSAGE') + '</div>' +
            '<div>' + '{{Action}}' + '</div>' +
            '<div>' + i18next.t('SURVEYFORMS.EMAIL_THANKS') + '</div>' +
            '<div><b>' + groupName + '</b></div>'
        );

        const variableNames = computed(() => props.modelValue.variables?.filter(v => v.type === VariableType.Variable).map(v => v.name));
        const campaign = props.modelValue;
        const { state, createSurveyEmailTemplateNow, updateSurveyEmailTemplateNow } = useSurveyEmailTemplates();

        // NOTE(adam): We use a plain veriable, because we can't mutate the
        // original prop (eslint will argue with us if we do).
        let savedEmailTemplateId = props.savedEmailTemplateId;

        // NOTE(adam): There are several cases we need to consider here:
        // 1. We opened this step directly from the edit dropdown.
        //    - savedEmailTemplateId is null
        //    - campaign.emailTemplateId is filled
        //    - campaign.id is not 0 (campaign exists)
        //
        // 2. The campaign exists, and we want to select a different template for it
        //    - savedEmailTemplateId is not null (the template id as it's saved now in the db)
        //    - campaign.emailTemplateId is filled with the new template's id (either null when selecting the default template, or the id of the template)
        //    - campaign.id is not 0 (campaign exists)
        //
        // 3. We are creating an entirely new campaign, for which there is no saved template id yet.
        //    - savedEmailTemplateId is null
        //    - campaign.emailTemplateId is filled with the new template's id (either null when selecting the default template, or the id of the template)
        //    - campaign.id IS 0 (campaign does not exist yet)
        //
        // Based on all of the above, savedEmailTemplateId should only be overwritten if:
        // - The campaign exists
        // - AND the campaign's emailTemplateId is filled
        // - AND the savedEmailTemplateId is null
        //
        // The only case when the campaign.emailTemplateId and the savedEmailTemplateId
        // is null, is when we are creating a new campaign, and the user selected the default
        // template. That is why the campaign exists check is needed.
        if (campaign.id && campaign.emailTemplateId && !savedEmailTemplateId) {
            savedEmailTemplateId = campaign.emailTemplateId;
        }

        // NOTE(adam): Survey email tempaltes are being loaded in the
        // CampaignEditor now, to reduce the number of possible race conditions
        // (also, it seems like they are bound to the user group id, so they are
        // better queried once).

        let template = state.surveyEmailTemplates.find(byCampaign, campaign) as NewSurveyEmailTemplate|SurveyEmailTemplate;

        if (!template) {
            template = {
                id: 0,
                name: campaign.name,
                variables: [],
                datasetId: campaign.datasetId,
                customizationOptions: {
                    embeddedCode: '',
                    subjectText: defaultSubject.value,
                    senderName: defaultSender.value,
                    actionButtonText: defaultButtonText.value,
                    embeddedQuestionGuid: defaultEmbeddedQuestionGuid.value,
                    htmlBody: defaultEmailCode.value,
                },
            };
        } else {
            template = { ...template };
        }

        if (!savedEmailTemplateId || savedEmailTemplateId != props.modelValue.emailTemplateId) {
            const nameWithoutNumber = template.name.replace(/[\d\s]+$/, '');
            let count = 2;

            while (state.surveyEmailTemplates.some(t => t.name.toLowerCase().trim() === template.name.toLowerCase().trim())) {
                template.name = nameWithoutNumber + ' ' + (count++).toString();
            }
        }

        defaultName.value = template.name;
        defaultSubject.value = template.customizationOptions.subjectText;
        defaultSender.value = template.customizationOptions.senderName || defaultSender.value;
        defaultButtonText.value = template.customizationOptions.actionButtonText;
        defaultEmbeddedQuestionGuid.value = template.customizationOptions.embeddedQuestionGuid;
        defaultEmailCode.value = template.customizationOptions.htmlBody;

        const { createNewCampaign, updateExistingCampaign } = useCampaigns();

        const savingCampaign = ref(false);

        return {
            templateId: template.id,
            defaultName,
            defaultSender,
            defaultSubject,
            defaultEmailCode,
            defaultButtonText,
            defaultEmbeddedQuestionGuid,
            variableNames,
            isPopupViewOnly,
            savingCampaign,

            saveCampaign: async (template: NewSurveyEmailTemplate) => {
                try {
                    savingCampaign.value = true;
                    let templateCopy: SurveyEmailTemplate;

                    // NOTE(adam): The template should only be updated if:
                    // 1. The template exists
                    // 2. The campaign has THIS template associated with it (it's
                    //    possible to select another existing template to copy, in
                    //    which case we need to copy and create a new template).
                    // 3. The campaign already exists. If it does not, we have to create
                    (template.id && campaign.id && savedEmailTemplateId === template.id)
                        ? templateCopy = await updateSurveyEmailTemplateNow(template)
                        : templateCopy = await createSurveyEmailTemplateNow(template);

                    savedEmailTemplateId = templateCopy.id;
                    campaign.emailTemplateId = templateCopy.id;

                    const updatedCampaign = (!campaign.id)
                        ? await createNewCampaign(campaign)
                        : await updateExistingCampaign(campaign);

                    if (!campaign.id) {
                        toaster.success(i18next.t('AUDIENCE.CAMPAIGN_SAVED_AS_DRAFT', 'Campaign saved as draft.'));
                    }
                    else {
                        toaster.success(i18next.t('AUDIENCE.CAMPAIGN_UPDATED', 'Campaign updated.'));
                    }

                    ctx.emit('campaign-saved', updatedCampaign);
                    ctx.emit('show-next');
                }
                catch (error) {
                    toaster.error(i18next.t('AUDIENCE.CAMPAIGN_SAVE_FAILED', 'Failed to save campaign!'));
                    Sentry.captureException(error);
                }
                finally {
                    savingCampaign.value = false;
                }
            },
        };
    }
});
</script>
<template>
    <email-template-customization
        :template-id="templateId"
        :default-name="defaultName || undefined"
        :default-sender="defaultSender"
        :default-subject="defaultSubject"
        :default-email-code="defaultEmailCode"
        :default-button-text="defaultButtonText || undefined"
        :default-embedded-question-guid="defaultEmbeddedQuestionGuid || undefined"
        :variables="variableNames"
        :is-view-only-mode="isPopupViewOnly"
        class="w-full"
        :saving-campaign="savingCampaign"
        @send="saveCampaign"
        @save-and-continue="saveCampaign"
        @show-prev="$emit('show-prev')"
    />
</template>
