<template>
    <div class="p-4">
        <div class="rounded h-24 overflow-hidden">
            <logo
                :url="logo_file_url"
                class="h-24"
            />
        </div>
        <p
            data-test-id="msg-before-action"
            class="html-paragraph text-black leading-6 mt-0 text-left"
            v-html="msgBeforeAction"
        />
        <template v-if="hasAction">
            <div
                v-if="hasButton"
                data-test-id="action-button"
                class="mx-auto my-8 p-0 text-center w-full"
            >
                <z-button :style="'background-color:' + primary_color + '; border-color:' + primary_color">
                    {{ buttonText }}
                </z-button>
            </div>
            <div
                v-else-if="embeddedQuestion"
                ref="embeddableQuestion"
                class="my-4"
            >
                <component
                    :is="embedComponent"
                    :element="embeddedQuestion"
                    :is-live="true"
                    :use-link-placeholder="true"
                    :style-options="styleOptions"
                />
            </div>
            <div
                v-else-if="embeddedCode"
                v-html="embeddedCode"
            />
        </template>
        <p
            data-test-id="msg-after-action"
            class="html-paragraph text-black leading-6 text-left"
            v-html="msgAfterAction"
        />
    </div>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import Logo from '@/components/surveyFiller/Logo.vue';
import elementTypes from '@/survey/store/utils/elementTypeEnum';
import EmbeddableScale from '@/survey/widget/components/elements/EmbeddableScale.vue';
import EmbeddableSingleChoice from '@/survey/widget/components/elements/EmbeddableSingleChoice.vue';
import EmbeddableRating from '@/survey/widget/components/elements/EmbeddableRating.vue';
import EmbeddableButtons from '@/survey/widget/components/elements/EmbeddableButtons.vue';
import EmbeddableVerticalButtons from '@/survey/widget/components/elements/EmbeddableVerticalButtons.vue';
import {useNamespacedState} from 'vuex-composition-helpers';
import {QuestionElement} from '@/survey/api/types';


export default defineComponent({
    components: { Logo, EmbeddableScale, EmbeddableSingleChoice, EmbeddableRating, EmbeddableButtons, EmbeddableVerticalButtons,  },
    props: {
        htmlBody: { type: String, required: true },
        hasButton: { type: Boolean, required: true },
        buttonText: { type: String, default: null },
        senderName: { type: String, default: null },
        embeddedQuestion: { type: Object as () => QuestionElement, default: null },
        embeddedCode: { type: String, default: null },
    },
    setup (props) {
        const { logo_file_url, primary_color } = useNamespacedState('survey', ['logo_file_url', 'primary_color']);

        const embedComponent = computed(() => {
            if(props.embeddedQuestion) {
                if (props.embeddedQuestion.type === elementTypes.RADIO) {
                    return 'embeddable-single-choice';
                }
                if (props.embeddedQuestion.type === elementTypes.RATING) {
                    return 'embeddable-rating';
                }
                if ([elementTypes.CSAT, elementTypes.CES_5_SCALE, elementTypes.CES_7_SCALE].includes(props.embeddedQuestion.type)) {
                    return 'embeddable-buttons';
                }
                if (props.embeddedQuestion.type !== elementTypes.SCALE && props.embeddedQuestion.type !== elementTypes.NPS) {
                    Sentry.captureException(new Error('Invalid element selected for embed: ' + props.embeddedQuestion.type));
                    return '';
                }
                if (props.embeddedQuestion.display_type !== 'value') {
                    return 'embeddable-scale';
                }
                if (props.embeddedQuestion.display_direction === 'vertical') {
                    return 'embeddable-vertical-buttons';
                }
            }
            return null;
        });

        const styleOptions = computed(() => {
            const options = {
                textColor: '#282828',
                backgroundColor: 'transparent',
                iconColor: primary_color.value,
                showLabels: false,
                showTitle: true,
                iconSpacing: 0,
            };
            if (
                props.embeddedQuestion
                && (
                    [elementTypes.CSAT, elementTypes.CES_5_SCALE, elementTypes.CES_7_SCALE].includes(props.embeddedQuestion.type)
                    || props.embeddedQuestion.display_type === 'value'
                )
            ) {
                options.showLabels = true;
                options.iconSpacing = 8;
            }
            return options;
        });

        const hasAction = computed(() => props.htmlBody.includes('{{Action}}'));
        const htmlBody = computed(() => props.htmlBody.replace('{{SenderName}}', props.senderName));
        const msgBeforeAction = computed(() => {
            return hasAction.value ? htmlBody.value.split('{{Action}}')[0] : htmlBody.value;
        });
        const msgAfterAction = computed(() => {
            return hasAction.value ? htmlBody.value.split('{{Action}}')[1] : '';
        });

        return {
            logo_file_url, primary_color,
            embedComponent, styleOptions,
            msgBeforeAction, msgAfterAction, hasAction,
        };
    },
});
</script>
<style scoped lang="less">
    .html-paragraph ::v-deep a {
        text-decoration: underline;
        color: var(--color-primary-700);
    }
    .html-paragraph ::v-deep ol {
        display: block;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
    }
    .html-paragraph ::v-deep ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
    }

    /deep/ * {
        max-width: 100%;
    };
</style>
