import excelParser from '@/domain/excel/excelParser';
import * as api from '../api';
import * as datasetApi from '@/dashboard/api/datasetApi';
import * as datasetsApi from '@/datasets/api/';

export default {
    uploadFile: async ({ commit }, { file, noVerbatimColumn }) => {
        const response = await excelParser({ file, noVerbatimColumn });

        commit('saveFile', { response, file });
    },
    reparseCsv: async ({ commit }, { file, delimiter }) => {
        const response = await excelParser({ file, delimiter });

        commit('saveFile', { response, file });
    },
    getDatasetLastAnalysisAt: async ({ commit }, id) => {
        const dataset = await datasetsApi.getDataset(id);

        commit('setLastAnalysisAt', dataset.last_analysis_at);
    },
    getDataset: async ({ commit }, id) => {
        const dataset = await datasetsApi.getDataset(id);

        commit('setColumns', dataset.dimension_definitions);
    },
    getWebsiteCrawlerId: async ({ commit }, id) => {
        const crawlerData = await api.getWebCrawler(id);

        commit('setId', crawlerData.id);
        commit('setOriginalUploadedColumns', crawlerData.columns);
    },
    getWebsiteCrawler: async ({ commit }, datasetId) => {
        const crawlerData = await api.getWebCrawler(datasetId);

        commit('setName', crawlerData.name);
        commit('setId', crawlerData.id);
        commit('setAnalysisSettings', crawlerData.semanticSettings);
        commit('setSelectedCustomLabelIds', crawlerData.customLabelIds);
        commit('setOriginalCustomLabelIds', crawlerData.customLabelIds);
        commit('setColumns', crawlerData.columns);
    },
    getPreviewData: async ({ commit }, id) => {
        const previewData = await api.getPreviewData(id);

        commit('setActiveSheet', 'active');
        commit('setSheets', {
            active: {
                columnData: Object.values(previewData.dimensions).map(dimension => previewData.records.map(record => record[dimension.id])),
                columnNames: Object.values(previewData.dimensions).map(col => col.name),
                columnTypes: Object.values(previewData.dimensions).map(col => col.type),
                columnIds: Object.values(previewData.dimensions).map(col => col.id),
                headerRows: 0,
                rows: previewData.records.length,
            }
        });
    },
    updateWebsiteCrawler: async ({ state }) => {
        await api.updateWebsiteCrawler(state.id, {
            name: state.name,
            accent: state.analysisSettings.accent,
            lowercase: state.analysisSettings.lowercase,
            topicRecognition: state.analysisSettings.topicRecognition,
            textSplitting: state.analysisSettings.textSplitting,
            languageId: state.analysisSettings.languageId,
            customLabelIds: state.selectedCustomLabelIds,
        });
    },
    updateColumns: async({ state }, datasetId) => {
        const sheet = state.sheets.active;
        const columns = sheet.columnIds.map((id, index) => ({
            guid: id,
            name: sheet.columnNames[index],
            type: sheet.columnTypes[index],
            isMeta: false,
        }));
        await api.updateColumns(datasetId, columns);
    },
    reanalyze: async(_, datasetId) => {
        await datasetsApi.reanalyze(datasetId);
    },
    clQuickReanalyze: async ({ state, getters }, { datasetId, updatedCls }) => {
        const customLabelIds = [...new Set([...state.selectedCustomLabelIds, ...updatedCls])];

        await datasetApi.customLabelReanalyses(datasetId, 'content', customLabelIds, getters.deletedCustomLabelIds, null);
    },
    append: async({ state }, { mode, delimiter }) => {
        const data = {
            sheetName: state.activeSheet,
            headerRows: state.sheets[state.activeSheet].headerRows,
            rows: state.sheets[state.activeSheet].rows,
            columnPairs: state.columns.reduce((pairs, column) => {
                if (mode === 'content') {
                    pairs[column.id] = Object.entries(state.configs[state.activeSheet]).find(([, value]: [key: string, value: any]) => value.id === column.id)?.[0] ?? null;
                } else {
                    // In append URL case web crawler columns do not have ids, but in that case the id will be the column number
                    pairs[column.columnNumber] = Object.entries(state.configs[state.activeSheet]).find(([, value]: [key: string, value: any]) => value.columnNumber === column.columnNumber)?.[0] ?? null;
                }
                return pairs;
            }, {}),
            file: state.file,
            delimiter: delimiter
        };

        if (mode === 'content') {
            await api.appendContent(state.id, data);
        }
        else {
            await api.appendUrl(state.id, data);
        }
    },
};
