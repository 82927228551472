import { computed, reactive } from 'vue';

import * as campaignMetricsApi from '../api';

import { Filter } from '@/dashboard/common/types/filterInterface';

import { CountDistribution, DateDistribution } from './types';
import { ReminderType } from '@/campaign/types';

interface CampaignMetricsState {
    filters: Array<Filter>,
    chartsLoading: boolean,
    countDistribution: CountDistribution,
    dateDistribution: DateDistribution,
}

interface GetDistributionPayload {
    datasetId: string,
    campaignId?: string,
    waveId?: string,
    metricType?: ReminderType,
    filters?: Filter[]
}

const getDefaultState = (): CampaignMetricsState => ({
    filters: [],
    chartsLoading: false,
    countDistribution: {},
    dateDistribution: {},
});

export const state = reactive<CampaignMetricsState>(getDefaultState());

export const noData = computed(() => Object.keys(state.countDistribution).length == 0 || Object.keys(state.dateDistribution).length == 0);

export const actions = {
    async exportRawData({ datasetId, campaignId, waveId, metricType, filters }: GetDistributionPayload): Promise<void> {
        await campaignMetricsApi.exportRawData({ datasetId, campaignId, waveId, metricType, filters });
    },
    async getDistributionCount({ datasetId, campaignId, waveId }: GetDistributionPayload): Promise<void> {
        state.countDistribution = await campaignMetricsApi.getCountDistribution({
            datasetId,
            filters: state.filters,
            campaignId,
            waveId,
        });
    },
    async getDateDistribution({ datasetId, campaignId, waveId }: GetDistributionPayload): Promise<void> {
        state.dateDistribution = await campaignMetricsApi.getDateDistribution({
            datasetId,
            filters: state.filters,
            campaignId,
            waveId,
        });
    },
    async loadChartData({ datasetId, campaignId, waveId }: GetDistributionPayload): Promise<void> {
        await Promise.all([
            actions.getDistributionCount({ datasetId, campaignId, waveId }),
            actions.getDateDistribution({ datasetId, campaignId, waveId }),
        ]);
    },
    setChartsLoading(isLoading): void {
        state.chartsLoading = isLoading;
    },
    setFilters(filters: Array<Filter>): void {
        state.filters = filters;
    },
    setWaveFilter(waveFilter: Filter): void {
        if (state.filters.some(f => f.dimensionId === 'wave_id')) {
            state.filters = state.filters.map(f => {
                if (f.dimensionId !== 'wave_id') return f;

                return waveFilter;
            });
        }
        else {
            state.filters = state.filters.concat(waveFilter);
        }
    },
    deleteWaveFilter(): void {
        state.filters = state.filters.filter(f => f.dimensionId !== 'wave_id');
    },
    resetCampaignMetricsState(): void {
        Object.entries(getDefaultState()).forEach(([key,value]) => state[key] = value);
    }
};
