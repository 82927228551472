<template>
    <div>
        <svg
            :width="width"
            :height="height"
            viewBox="0 0 154 95"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="0.5"
                width="153"
                height="94"
                rx="7.5"
                fill="white"
                :stroke="embedType === 'inPage' ? 'var(--color-primary-700)': '#D8DCE8'"
            />
            <path
                d="M43.1719 68.6016L44.4609 71.1992L47.3125 71.6094C47.8203 71.6875 48.0352 72.3125 47.6641 72.6836L45.5938 74.6758L46.082 77.5078C46.1602 78.0156 45.6328 78.4062 45.1641 78.1719L42.625 76.8438L40.0664 78.1914C39.5977 78.4258 39.0703 78.0352 39.1484 77.5273L39.6367 74.6953L37.5664 72.6836C37.1953 72.3125 37.4102 71.6875 37.918 71.6094L40.7695 71.1992L42.0586 68.6016C42.293 68.1328 42.957 68.1523 43.1719 68.6016Z"
                :fill="embedType === 'inPage' ? 'var(--color-primary-700)': '#9FA7BC'"
            />
            <path
                d="M60.1719 68.6016L61.4609 71.1992L64.3125 71.6094C64.8203 71.6875 65.0352 72.3125 64.6641 72.6836L62.5938 74.6758L63.082 77.5078C63.1602 78.0156 62.6328 78.4062 62.1641 78.1719L59.625 76.8438L57.0664 78.1914C56.5977 78.4258 56.0703 78.0352 56.1484 77.5273L56.6367 74.6953L54.5664 72.6836C54.1953 72.3125 54.4102 71.6875 54.918 71.6094L57.7695 71.1992L59.0586 68.6016C59.293 68.1328 59.957 68.1523 60.1719 68.6016Z"
                :fill="embedType === 'inPage' ? 'var(--color-primary-700)': '#9FA7BC'"
            />
            <path
                d="M77.1719 68.6016L78.4609 71.1992L81.3125 71.6094C81.8203 71.6875 82.0352 72.3125 81.6641 72.6836L79.5938 74.6758L80.082 77.5078C80.1602 78.0156 79.6328 78.4062 79.1641 78.1719L76.625 76.8438L74.0664 78.1914C73.5977 78.4258 73.0703 78.0352 73.1484 77.5273L73.6367 74.6953L71.5664 72.6836C71.1953 72.3125 71.4102 71.6875 71.918 71.6094L74.7695 71.1992L76.0586 68.6016C76.293 68.1328 76.957 68.1523 77.1719 68.6016Z"
                :fill="embedType === 'inPage' ? 'var(--color-primary-700)': '#9FA7BC'"
            />
            <path
                d="M94.1719 68.6016L95.4609 71.1992L98.3125 71.6094C98.8203 71.6875 99.0352 72.3125 98.6641 72.6836L96.5938 74.6758L97.082 77.5078C97.1602 78.0156 96.6328 78.4062 96.1641 78.1719L93.625 76.8438L91.0664 78.1914C90.5977 78.4258 90.0703 78.0352 90.1484 77.5273L90.6367 74.6953L88.5664 72.6836C88.1953 72.3125 88.4102 71.6875 88.918 71.6094L91.7695 71.1992L93.0586 68.6016C93.293 68.1328 93.957 68.1523 94.1719 68.6016Z"
                fill="#D8DCE8"
            />
            <path
                d="M111.172 68.6016L112.461 71.1992L115.312 71.6094C115.82 71.6875 116.035 72.3125 115.664 72.6836L113.594 74.6758L114.082 77.5078C114.16 78.0156 113.633 78.4062 113.164 78.1719L110.625 76.8438L108.066 78.1914C107.598 78.4258 107.07 78.0352 107.148 77.5273L107.637 74.6953L105.566 72.6836C105.195 72.3125 105.41 71.6875 105.918 71.6094L108.77 71.1992L110.059 68.6016C110.293 68.1328 110.957 68.1523 111.172 68.6016Z"
                fill="#D8DCE8"
            />
            <rect
                x="37"
                y="60"
                width="24"
                height="3"
                fill="#424454"
            />
            <rect
                x="37"
                y="8"
                width="76"
                height="3"
                :fill="embedType === 'inPage' ? 'var(--color-primary-700)': '#BEC5D7'"
            />
            <rect
                x="37"
                y="17"
                width="73"
                height="3"
                :fill="embedType === 'inPage' ? 'var(--color-primary-700)': '#BEC5D7'"
            />
            <rect
                x="37"
                y="26"
                width="76"
                height="3"
                :fill="embedType === 'inPage' ? 'var(--color-primary-700)': '#BEC5D7'"
            />
            <rect
                x="37"
                y="35"
                width="75"
                height="3"
                :fill="embedType === 'inPage' ? 'var(--color-primary-700)': '#BEC5D7'"
            />
            <rect
                x="37"
                y="44"
                width="73"
                height="3"
                :fill="embedType === 'inPage' ? 'var(--color-primary-700)': '#BEC5D7'"
            />
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        embedType: { type: String, default: null },
        width: { type: Number, default: 210 },
        height: { type: Number, default: 138 },
    },
};
</script>

<style scoped>

</style>
