<template>
    <div>
        <label
            v-if="$slots.label || label"
            :style="{color: textColor}"
            class="text-default block w-full mb-2"
        >
            <slot name="label">
                {{ label }}
            </slot>
            <slot name="more-info" />
        </label>
        <div
            class="relative h-8 w-full border rounded placeholder-neutral-700"
            :class="{ 'border-red-500': !!errorMessage || hasError, 'opacity-75': disabled }"
            :style="active ? activeBoxStyle : (hover ? hoverBoxStyle : (disabled ? disabledBoxStyle : baseBoxStyle))"
            @mouseover="hover = true"
            @mouseleave="hover = false"
            @focus="active = true"
            @focusout="active = false"
        >
            <font-awesome-icon
                class="absolute mr-1 mt-1 w-6 h-6 right-0"
                :icon="['fal', 'angle-down']"
                :class="{'opacity-75': disabled}"
                @click="onclick"
            />
            <select
                ref="select"
                :data-test-id="dataTestId"
                :value="modelValue"
                v-bind="$attrs"
                class="truncate absolute appearance-none left-0 w-full p-2 pr-4 text-default rounded disabled:opacity-75 disabled:cursor-not-allowed border-none outline-none"
                :style="{color: disabled ? hex2rgba(textColor, .5) : textColor}"
                style="background: none;"
                :disabled="disabled"
                @change="change"
            >
                <slot>
                    <option
                        v-for="(option, i) in options"
                        :key="i"
                        class="text-black"
                        :class="{ 'hidden': option.hidden }"
                        :value="option.value ?? option"
                        :disabled="!!option.disabled"
                    >
                        {{ option.text || option }}
                    </option>
                </slot>
            </select>
        </div>
        <div
            v-if="isOptional"
            class="text-default text-neutral-700 text-right"
        >
            ({{ $t('GLOBAL.OPTIONAL', 'optional') }})
        </div>
        <span
            v-if="$slots.errorMessage || errorMessage"
            class="text-xs text-red-500"
        >
            <slot name="errorMessage">
                {{ errorMessage }}
            </slot>
        </span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { FontAwesomeIcon } from '@/utils/icons';
import { getBackgroundColor, getPrimaryColor, getTextColor } from '@/utils/theme';
import { hex2rgba } from '@/utils/color';

export default defineComponent({
    name: 'ZSelect',
    components: { FontAwesomeIcon },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue',
    },
    props:  {
        label: { type: String, default: null },
        errorMessage: { type: String, default: null },
        isOptional: { type: Boolean, default: false },
        options: { type: Array as () => Array<string>|Array<{ hidden?: boolean, disabled?: boolean, text: string, value: any }>, default: () => [] },
        modelValue: { type: [String, Number, Boolean], default: null },
        textColor: { type: String, default: getTextColor },
        primaryColor: { type: String, default: getPrimaryColor },
        backgroundColor: { type: String, default: getBackgroundColor },
        dataTestId: { type: String, default: null },
        disabled: { type: Boolean, default: false },
        hasError: { type: Boolean, default: false },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            hover: false,
            active: false
        };
    },
    computed: {
        activeBoxStyle() {
            return {
                'background-color': this.backgroundColor,
                'color': this.textColor,
                'border-color': this.primaryColor,
            };
        },
        hoverBoxStyle() {
            return {
                'background-color': this.backgroundColor,
                'color': this.textColor,
                'border-color': this.textColor,
            };
        },
        baseBoxStyle() {
            return {
                'background-color': this.backgroundColor,
                'color': this.textColor,
                'border-color': hex2rgba(this.textColor, .5),
            };
        },
        disabledBoxStyle() {
            return {
                'background-color': this.backgroundColor,
                'color': this.textColor,
                'border-color': hex2rgba(this.textColor, .3),
            };
        },
    },
    methods: {
        change(event) {
            this.$emit('update:modelValue', event.target.value);
        },
        onclick() {
            // @ts-ignore
            this.$refs.select.click();
        },
        hex2rgba,
    },
});
</script>
