import i18next from 'i18next';
import planFeatureTypeEnum, { getDisplayName as getPlanFeatureName } from '@/domain/planFeatureTypeEnum';

const statuses = {
    TEXT_ANALYSIS: 'text_analysis',
    EXCEL_GENERATION: 'excel_generation',
    FINISHED: 'finished',
    DRAFT: 'draft',
    ACTIVE: 'active',
    PAUSED: 'paused',
};

const quotaIssues = {
    DATASET_TRANSLATION: planFeatureTypeEnum.DATASET_TRANSLATION,
    TEXT_ANALYSIS: planFeatureTypeEnum.TEXT_ANALYSIS,
    FILE_VERBATIM: planFeatureTypeEnum.FILE_UPLOAD_VERBATIM,
    NMI_RECORD: planFeatureTypeEnum.NMI_RECORD,
    API_VERBATIM: planFeatureTypeEnum.API_VERBATIM,
    API_RESPONSE: planFeatureTypeEnum.API_RESPONSE,
    CONVERSATION_RECORD: planFeatureTypeEnum.CONVERSATION_RECORD,
    CONVERSATION_VERBATIM: planFeatureTypeEnum.CONVERSATION_VERBATIM,
};

export const getDisplayName = (status, quotaIssue) => {
    if (quotaIssue) {
        const paused = i18next.t('DATA_SOURCE_STATUS.PROCESSING_PAUSED', 'Processing Paused');
        switch (quotaIssue) {
            case quotaIssues.DATASET_TRANSLATION:
                return paused + ': ' + i18next.t('DATASET_TRANSLATION_QUOTA_ISSUE', 'Translation Quota Exhausted');
            case quotaIssues.TEXT_ANALYSIS:
                return paused + ': ' + i18next.t('TEXT_ANALYSIS_QUOTA_ISSUE', 'Text Analysis Quota Exhausted');
            case quotaIssues.FILE_VERBATIM:
                return paused + ': ' + i18next.t('FILE_VERBATIM_QUOTA_ISSUE', 'File Verbatim Quota Exhausted');
            case quotaIssues.NMI_RECORD:
                return paused + ': ' + i18next.t('NMI_RECORD_QUOTA_ISSUE', 'NMI Record Quota Exhausted');
            case quotaIssues.API_VERBATIM:
                return paused + ': ' + i18next.t('API_VERBATIM_QUOTA_ISSUE', 'API Verbatim Quota Exhausted');
            case quotaIssues.API_RESPONSE:
                return paused + ': ' + i18next.t('API_RESPONSE_QUOTA_ISSUE', 'API Response Quota Exhausted');
            case quotaIssues.CONVERSATION_RECORD:
                return paused + ': ' + i18next.t('CONVERSATION_INPUT.QUOTA_ISSUE', 'Conversation Input Record Quota Exhausted');
            case quotaIssues.CONVERSATION_VERBATIM:
                return paused + ': ' + i18next.t('CONVERSATION_INPUT.QUOTA_ISSUE', 'Conversation Input Verbatim Quota Exhausted');
        }
    }

    switch (status) {
        case statuses.TEXT_ANALYSIS:
            return i18next.t('DATA_SOURCE_STATUS.TEXT_ANALYSIS');
        case statuses.EXCEL_GENERATION:
            return i18next.t('DATA_SOURCE_STATUS.EXCEL_GENERATION');
        case statuses.FINISHED:
            return i18next.t('DATA_SOURCE_STATUS.FINISHED');
        case statuses.DRAFT:
            return i18next.t('DATA_SOURCE_STATUS.DRAFT');
        case statuses.ACTIVE:
            return i18next.t('DATA_SOURCE_STATUS.ACTIVE');
        case statuses.PAUSED:
            return i18next.t('DATA_SOURCE_STATUS.PAUSED');
    }
};

export const getQuotaIssueTooltip = (quotaIssue, amount) => {
    if (quotaIssue == quotaIssues.DATASET_TRANSLATION) {
        return i18next.t('DATASET_TRANSLATION_QUOTA_ISSUE_TOOLTIP', {
            amount: amount,
            defaultValue: 'Next record requires {{amount}} characters to translate.'
        });
    }

    return i18next.t('DATASET_VERBATIM_QUOTA_ISSUE_TOOLTIP', {
        amount: amount,
        quotaType: getPlanFeatureName(quotaIssue),
        defaultValue: 'Next record requires {{amount}} {{quotaType}} quota to analyze.'
    });
};

export default statuses;
