<script lang="ts">
import type { Campaign } from '@/campaign/types';
import type { PropType } from 'vue';
import type { Segment, AudienceWithSegments } from '@/audience/types';

import { ref, computed, defineComponent, toRefs } from 'vue';

import ZSelect from '@/components/ui/Select.vue';
import DataTable, { SortDirection } from '@/survey/components/publish/campaign/states/DataTable.vue';
import { VariableType } from '@/campaign/types';
import { useCampaigns } from '@/campaign';

const EMPTY_SEGMENT_LIST: Segment[] = [];

// NOTE(adam): We can include the user's locale in the formatter, but for now,
// we use the OS's settings.
const formatter = Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
});

const numberFormatter = Intl.NumberFormat('default', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

const formatNumber = (segment: Segment, property: keyof Segment): string => {
    return numberFormatter.format(segment[property] as number);
};

const formatType = (segment: Segment): string => {
    return `${ segment.type![0].toUpperCase() }${ segment.type!.slice(1).toLowerCase() }`;
};

const formatDateTime = (segment: Segment, property: keyof Segment): string => {
    return (!segment[property])
        ? '-'
        : formatter.format(Date.parse(segment[property] as string));
};

function audienceById (this: string, audience: AudienceWithSegments): boolean {
    return this == audience.uid;
}

function segmentById (this: string, segment: Segment): boolean {
    return this == segment.id;
}

function onlyAudiencesWithSegments (audience: AudienceWithSegments): boolean {
    return !!audience.segments.length;
}

export default defineComponent({
    name: 'SegmentList',

    components: { ZSelect, DataTable,  },

    props: {
        campaign: {
            required: true,
            type: Object as PropType<Campaign>,
        },

        audiences: {
            type: Array as PropType<AudienceWithSegments[]>,
            required: true,
        }
    },

    emits: ['updateSegment', 'show-next', 'show-prev'],

    setup: ({ campaign, audiences }, { emit }) => {
        const { isPopupViewOnly } = toRefs(useCampaigns().state);
        const selectedSegment = ref<Segment | null>(null);

        const audiencesWithSegments = computed(() => {
            return audiences.filter(onlyAudiencesWithSegments);
        });

        const selectedAudienceIndex = ref<number>((audiencesWithSegments.value.length) ? 0 : -1);

        const selectedAudience = computed(() => {
            return (selectedAudienceIndex.value < 0) ? null : audiencesWithSegments.value[selectedAudienceIndex.value];
        });

        const segments = computed(() => {
            return selectedAudience.value?.segments || EMPTY_SEGMENT_LIST;
        });

        if (campaign.audienceId) {
            selectedAudienceIndex.value = audiencesWithSegments.value.findIndex(audienceById, campaign.audienceId) ?? -1;
        }

        if (campaign.segmentId) {
            selectedSegment.value = segments.value.find(segmentById, campaign.segmentId) ?? null;
        }

        function updateCampaignAndShowNext() {
            if (selectedAudience.value && selectedSegment.value) {
                emit('show-next', {
                    segmentUid: selectedSegment.value.id,
                    audienceUid: selectedAudience.value.uid,
                    variables: campaign.variables!.length === 0 ? selectedAudience.value?.fields.map(f => ({
                        urlUid: null,
                        type: VariableType.Variable,
                        name: f.name,
                        fieldUid: f.id,
                    })) : campaign.variables
                });
            }
        }

        return {
            segments,
            formatType,
            formatNumber,
            SortDirection,
            formatDateTime,
            selectedSegment,
            selectedAudience,
            selectedAudienceIndex,
            audiencesWithSegments,
            updateCampaignAndShowNext,
            isPopupViewOnly
        };
    }
});
</script>
<template>
    <div class="flex flex-col">
        <data-table
            v-model="selectedSegment"
            :disabled="isPopupViewOnly"
            :labels="[
                $t('AUDIENCE.TABLE_HEAD_SEGMENT_NAME', 'Segment Name'),
                $t('AUDIENCE.TABLE_HEAD_SEGMENT_TYPE', 'Type'),
                $t('AUDIENCE.TABLE_HEAD_SEGMENT_MERMBER_COUNT', 'Audience Members'),
                $t('AUDIENCE.TABLE_HEAD_SEGMENT_CREATED_AT', 'Date of Creation'),
                $t('AUDIENCE.TABLE_HEAD_SEGMENT_UPDATED_AT', 'Last Updated'),
            ]"
            :column-widths="['w-1/3']"

            :formatters="{
                type: formatType,
                memberCount: formatNumber,
                createdAt: formatDateTime,
                updatedAt: formatDateTime,
            }"

            use-key="id"
            search-by="name"
            sort-by="createdAt"
            :sort-direction="SortDirection.Descending"
            :search-label="$t('AUDIENCE.SEARCH_SEGMENT_BY_NAME', 'Search segments by name')"
            :data-source="segments"
            :column-keys="['name', 'type', 'memberCount', 'createdAt', 'updatedAt']"
        >
            <template #after-search>
                <section class="flex flex-col flex-shrink-0 w-1/4 pl-4 ml-auto">
                    <label class="font-bold text-sm">{{ $t('AUDIENCE.CHOOSE_AUDIENCE_LABEL', 'Audience') }}</label>
                    <z-select
                        :disabled="isPopupViewOnly"
                        :model-value="selectedAudienceIndex"
                        @update:modelValue="selectedAudienceIndex = parseInt($event)"
                    >
                        <option
                            disabled
                            selected
                            :value="-1"
                        >
                            -- {{ $t('AUDIENCE.BUTTON_SELECT_AUDIENCE', 'Select audience') }} --
                        </option>
                        <option
                            v-for="audience in audiencesWithSegments"
                            :key="audience.uid"
                            :value="audiencesWithSegments.findIndex( a => a.uid === audience.uid)"
                        >
                            {{ audience.name }}
                        </option>
                    </z-select>
                </section>
            </template>
        </data-table>
        <section class="flex flex-row mx-auto mt-auto">
            <button
                v-if="!isPopupViewOnly"
                class="mx-1 px-3 py-1 rounded border border-primary-700 text-primary-700 text-sm"
                @click="$emit('show-prev')"
            >
                {{ $t('AUDIENCE.BUTTON_BACK', 'Go Back') }}
            </button>
            <z-button
                class="mx-1"
                :disabled="!selectedSegment || isPopupViewOnly"
                @click="updateCampaignAndShowNext"
            >
                {{ $t('AUDIENCE.BUTTON_SELECT_SEGMENT', 'Select Segment') }}
            </z-button>
        </section>
    </div>
</template>
