<template>
    <div class="p-3">
        <table class="text-sm w-full mt-3">
            <tr class="border-b-2 border-neutral-700">
                <th class="py-1 px-3 text-left w-1/2">
                    {{ $t('SURVEY.PUBLISH_EMBED_SETTINGS_TITLE', 'Title') }}
                </th>
                <th class="py-1 text-left w-1/8">
                    {{ $t('SURVEY.PUBLISH_EMBED_SETTINGS_TYPE', 'Type') }}
                </th>
                <th class="py-1 text-left w-1/3">
                    {{ $t('SURVEY.PUBLISH_EMBED_SETTINGS_PUBLISHED', 'Published') }}
                </th>
                <th class="p-1 text-right">
                    <connectedness-marker :is-disconnected="isDisconnected" />
                </th>
            </tr>
            <tr
                v-for="setting in embedSettings"
                :key="setting.id"
                class="border-b-2 border-neutral-400 last:border-b-0 hover:bg-neutral-200"
            >
                <td
                    class="py-3 px-3 cursor-pointer w-1/2 items-center"
                    data-test-id="settings-name"
                    @click="select(setting.draftSettings, setting.isActive , setting.id)"
                >
                    <div class="flex items-center">
                        <div @click.prevent.stop="checkActivity(setting)">
                            <z-toggle
                                class="mr-0"
                                :model-value="setting.isActive"
                                :disabled="!setting.isActive"
                            />
                        </div>
                        <div class="flex flex-grow">
                            {{
                                (JSON.stringify(setting.liveSettings) !== JSON.stringify(setting.draftSettings) && setting.draftSettings.name.length > 12) ?
                                    setting.draftSettings.name.substr(0, 12) + '...' :
                                    setting.draftSettings.name
                            }}
                            <div
                                v-if="setting.publishedAt && JSON.stringify(setting.liveSettings) !== JSON.stringify(setting.draftSettings)"
                                class="text-neutral-600"
                            >
                                ({{ $t('SURVEY.PUBLISH_EMBED_SETTINGS_UNPUBLISHED_CHANGES', 'Unpublished changes') }})
                            </div>
                        </div>
                    </div>
                </td>
                <td class="py-2 w-1/8">
                    <div class="mt-1">
                        {{ embedTypeText[setting.draftSettings.type]() }}
                    </div>
                </td>
                <td class="py-2 w-1/3">
                    <div class="flex">
                        <div class="mt-1 mr-1">
                            {{ setting.publishedAt ? dayjs(setting.publishedAt).format('YYYY-MM-DD HH:mm') : $t('SURVEY.PUBLISH_EMBED_SETTINGS_DRAFT', 'Draft') }}
                        </div>
                        <div
                            v-if="setting.publishedAt"
                            class="text-xs px-2 py-1 bg-neutral-400 text-neutral-1000 rounded mr-5"
                        >
                            {{ setting.isActive ? $t('SURVEY.PUBLISH_EMBED_SETTINGS_ACTIVE', 'Active') : $t('SURVEY.PUBLISH_EMBED_SETTINGS_PAUSED', 'Paused') }}
                        </div>
                    </div>
                </td>
                <td class="py-3 px-4">
                    <div class="flex space-x-2 justify-end">
                        <icon
                            icon="copy"
                            class="cursor-pointer my-1"
                            @click="copySettings(setting.id)"
                        />
                        <icon
                            icon="trash"
                            class="text-red-700 cursor-pointer my-1"
                            @click="deleteSettings(setting.id)"
                        />
                    </div>
                </td>
            </tr>
        </table>
        <embed-activation-popup
            v-if="isActivationPopupOpen"
            :body="$t('SURVEYFORMS.PUBLISH_EMBED_NO_LIVE_SETTINGS', 'You haven\'t saved this embed\'s settings yet. If you decide to activate this embed, we will publish these settings for you.')"
            @close="closeWithoutActivation"
            @activate="activate"
        />
    </div>
</template>

<script lang="ts">
import Icon from '@/components/ui/Icon.vue';
import { defineComponent, ref, computed } from 'vue';
import { useNamespacedState } from 'vuex-composition-helpers';
import useEmbedStore from '@/survey/embed/store/embedStore';
import { EmbedSettings, embedTypeText, DisplayTypeEnum, SettingOptions } from '@/survey/embed/store/types';
import { setLoading } from '@/utils/loading';
import i18next from 'i18next';
import { toaster } from '@/utils/toaster';
import confirm from '@/utils/confirmPopup';
import EmbedActivationPopup from '@/survey/embed/components/EmbedActivationPopup.vue';
import ConnectednessMarker from '@/survey/embed/components/ConnectednessMarker.vue';
import dayjs from 'dayjs';
import confirmPopup from '@/utils/confirmPopup';

export default defineComponent({
    components: { Icon, EmbedActivationPopup, ConnectednessMarker },
    props: {
        embedSettings: { type: Array as () => EmbedSettings[], default: () => ([]) },
        isDisconnected: { type: Boolean, required: true }
    },
    setup(props, ctx) {
        const surveyState = useNamespacedState('survey', ['isMultipleFillingOn']);
        const embedStore = useEmbedStore();
        const { copy, deleteSettings: deleteEmbedSettings, setActiveSettings, setActivity } = embedStore.actions;

        async function copySettings(id) {
            setLoading(true);
            const settings = props.embedSettings.find(s => s.id === id);
            try {
                await copy(settings);
            }
            catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                Sentry.captureException(e);
                Sentry.addBreadcrumb({
                    message: 'Embed settings copy failed',
                    settings
                });
            }
            setLoading(false);
        }

        async function deleteSettings(id) {
            try {
                const confirmed = await confirm.destruction(
                    i18next.t('SURVEY.PUBLISH_EMBED_SETTINGS_DELETE_CONFIRM_TITLE', 'Delete survey embed settings'),
                    i18next.t('GLOBAL.ARE_YOU_SURE', 'Are you sure?')
                );
                if (confirmed) {
                    try {
                        setLoading(true);
                        await deleteEmbedSettings(id);
                        toaster.success(i18next.t('SURVEY.PUBLISH_EMBED_SETTINGS_DELETE_POPUP', 'Your embedded survey settings deleted successfully!'));
                    } catch (e) {
                        return;
                    }
                } else {
                    return;
                }
            }
            catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                Sentry.captureException(e);
                Sentry.addBreadcrumb({
                    message: 'Embed settings copy failed',
                    id
                });
            }
            setLoading(false);
        }

        function select(selected, isActive, id) {
            setActiveSettings(selected, isActive, id);
            ctx.emit('select');
        }

        const isActivationPopupOpen = ref(false);
        const embedSettingToActivate = ref<EmbedSettings|null>(null);

        const toggleActivity = async (setting: EmbedSettings, isActive: boolean = false) => {
            try {
                setLoading(true);
                await setActivity(setting.id);
                if (isActive) {
                    toaster.success(i18next.t('SURVEY.PUBLISH_EMBED_SETTINGS_SET_TO_PAUSE', 'Your embedded survey settings were paused successfully.'));
                } else {
                    toaster.success(i18next.t('SURVEY.PUBLISH_EMBED_SETTINGS_SET_TO_ACTIVE', 'Your embedded survey settings were activated successfully.'));
                    if (
                        !surveyState.isMultipleFillingOn.value
                        && [DisplayTypeEnum.POPUP, DisplayTypeEnum.SIDE_TAB, DisplayTypeEnum.SLIDER].includes((setting.liveSettings as SettingOptions).type)
                    ) {
                        confirmPopup.notification(
                            i18next.t('SURVEY.SINGLE_FILLING_EMBED_WARNING_TITLE', 'Single filling and embed'),
                            i18next.t('SURVEY.SINGLE_FILLING_EMBED_WARNING', 'Be aware that in case of Popup, Slider or Sidetab embed types/elements single filling setup is not applicable.'),
                            null,
                            null,
                            true
                        );
                    }
                }
            }
            catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                Sentry.captureException(e);
                Sentry.addBreadcrumb({
                    message: 'Embed settings activity change failed',
                    id: setting.id
                });
            }
            finally {
                setLoading(false);
                embedSettingToActivate.value = null;
            }
        };

        const { status } = useNamespacedState('survey', ['status']);

        const isSurveyActive = computed(() => {
            return parseInt(status.value) === 1 || status.value === true;
        });

        const checkActivity = (settings: EmbedSettings) => {
            if (!isSurveyActive.value && !settings.isActive) {
                toaster.error(i18next.t('SURVEY.PUBLISH_EMBED_INACTIVE_SURVEY_WARNING', 'This survey is paused. In order to activate the embed, you have to activate the survey first.'));
                return;
            }
            if (!settings.isActive && (
                (typeof settings.liveSettings === 'object' && !Array.isArray(settings.liveSettings) && !settings.liveSettings.domain) ||
                (typeof settings.nextSettings === 'object' && !Array.isArray(settings.nextSettings) && !settings.nextSettings.domain)
            )) {
                toaster.error(i18next.t('SURVEYFORMS.PUBLISH_EMBED_MISSING_SETUP_DOMAIN', 'Domain is missing or invalid.'));
                return;
            }
            embedSettingToActivate.value = settings;
            if (!settings.isActive && Array.isArray(settings.liveSettings) && settings.liveSettings.length === 0) {
                isActivationPopupOpen.value = true;
            } else {
                toggleActivity(settings, settings.isActive);
            }
        };

        const activate = async () => {
            isActivationPopupOpen.value = false;
            await toggleActivity(embedSettingToActivate.value!);
        };

        const closeWithoutActivation = () => {
            isActivationPopupOpen.value = false;
            embedSettingToActivate.value = null;
        };

        return {
            copySettings,
            deleteSettings,
            select,
            toggleActivity,
            checkActivity,
            activate,
            closeWithoutActivation,
            isActivationPopupOpen,
            embedTypeText,
            dayjs,
        };
    }
});
</script>

<style lang="less" scoped>
/deep/ .toggle-container label {
    cursor: pointer !important;
}
</style>
