import i18next from 'i18next';

export interface Campaign {
    id?: string
    datasetId: string
    audienceId: string | null
    userGroupId?: number
    name: string
    segmentId: string | null
    emailTemplateId: number | null

    isActive: boolean
    sendingInProgress: boolean
    creatorId?: number
    editorId?: number
    createdAt?: string
    updatedAt?: string | null
    lastSentAt?: string | null
    lastSubmittedAt?: string | null
    deletedAt?: string | null

    variables: null | Variable[]
    waves?: Wave[]

    recipientCount?: number
    completedCount?: number

    sendOnlyNewMembers?: boolean
    recipients?: string
    schedules: Schedule[]
}
export interface Variable {
    urlUid: null | string // url parameter id
    fieldUid: string // audience field id
    name: string
    type: VariableType
}

export enum VariableType {
    Variable = 'variable',
    UrlParameter = 'urlParameter',
}

export interface CampaignState {
    isPopupViewOnly: boolean,
    reminderWave: ParentWave | null,
    reminderRecipientCount: number,
    campaignsForDatasetId: Array<Campaign>,
    firstLoadingCampaigns: boolean,
}

export interface Wave {
    campaignId: string;
    completedCount: number;
    created_at: string;
    datasetId: string;
    deletedAt: null | string;
    finishedAt: null | string;
    id: string;
    recipientCount: number;
    sentRecipientCount: number;
    segmentId: string | null,
    audienceId: string | null,
    type: WaveType;
    updated_at: string;
    waveSentAt: string;
    recipients?: string;
    lastSubmittedAt?: string;
    parentWaveId: null | number;
    reminderType: null | ReminderType;
    index: number;
}

export interface ParentWave {
    campaignId: string;
    waveId: string;
    segmentId: string | null,
    audienceId: string | null,
    index: number;
    reminderType: ReminderType | null;
}

export type MappedWave = Wave & {
    index: number,
    recipients: string,
    reminders: MappedWave[],
    isScheduled?: boolean,
    isRecurring?: boolean,
};

export type WaveType = 'api'|'manual'|'scheduled'|'reminder'|'scheduled_reminder';

export type ReminderType = 'bounced'|'not_opened'|'not_clicked'|'not_completed';

export const ReminderType = {
    BOUNCED: 'bounced',
    NOT_OPENED: 'not_opened',
    NOT_CLICKED: 'not_clicked',
    NOT_COMPLETED: 'not_completed'
};

export interface Schedule {
    id?: number;
    tempid?: number;
    campaignId: string;
    datasetId: string;
    scheduleType: 'specific'|'recurring';
    scheduleSettings: ScheduleSettings;
    reminderType: string|null;
    parentWaveId: string|null;
    nextSendAt: string|null;
    createdAt: string|null;
    updatedAt: string|null;
}

export interface ScheduleSettings {
    year: number|null;
    month: number|null;
    day: number;
    hours: number;
    minutes: number;
    timeZone: string;
    periodType: 'monthly'|'weekly'|'daily'|null;
    isLast: boolean;
    isActive: boolean;
}

export const getReminderTypeDisplayName = (type) => {
    switch (type) {
        case ReminderType.BOUNCED:
            return i18next.t('AUDIENCE.WAVE_REMINDER_TYPE_BOUNCED', 'Bounced emails');
        case ReminderType.NOT_OPENED:
            return i18next.t('AUDIENCE.WAVE_REMINDER_TYPE_NOT_OPENED', 'Non-openers');
        case ReminderType.NOT_CLICKED:
            return i18next.t('AUDIENCE.WAVE_REMINDER_TYPE_NOT_CLICKED', 'No Click (CTR)');
        case ReminderType.NOT_COMPLETED:
            return i18next.t('AUDIENCE.WAVE_REMINDER_TYPE_NOT_COMPLETED', 'Non-completers');
        default:
            return '-';
    }
};
