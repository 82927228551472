<template>
    <div
        class="grid"
        :style="{
            'grid-template-columns': 'subgrid',
            'grid-column-start': 'span 7',
        }"
    >
        <!-- campaign stuff -->
        <section
            class="grid items-center border-b border-neutral-500"
            :style="{
                'grid-template-columns': 'subgrid',
                'grid-column-start': 'span 7',
            }"
        >
            <div class="border-r border-neutral-500 self-stretch flex items-center">
                <Toggle
                    :modelValue="campaign.isActive"
                    @update:modelValue="$emit('set-is-active', $event)"
                />
            </div>
            <div class="flex items-center space-x-2">
                <div class="w-4">
                    <Icon
                        v-if="hasWaves"
                        :icon="open ? 'chevron-up' : 'chevron-down'"
                        class="select-none cursor-pointer"
                        @click="open = !open"
                    />
                </div>
                <div>
                    {{ campaign.name }}
                    <b v-if="mappedWaves.length">({{ mappedWaves.length }})</b>
                </div>
            </div>
            <div>{{ campaign.recipients }}</div>
            <div class="space-x-2 whitespace-no-wrap">
                <TypeIcon
                    v-for="sendType in campaign.sending"
                    :key="`${sendType.type}-${sendType.active}`"
                    :type="sendType.type"
                    :solid="sendType.active"
                />
            </div>
            <div class="whitespace-no-wrap">
                {{ campaign.lastSubmittedAt ? dayjs(campaign.lastSubmittedAt).format('YYYY.MM.DD. HH:mm') : '-' }}
            </div>
            <div>
                {{ campaign.completedCount }} / {{ campaign.recipientCount }} ({{ Math.round((campaign.completedCount || 0) / (campaign.recipientCount || 1) * 100) }}%)
            </div>
            <div>
                <Action
                    :has-waves="campaign.waves.length > 0"
                    @click="action($event)"
                />
            </div>
        </section>
        <template v-if="open">
            <!-- waves stuff -->
            <WaveListItem
                v-for="wave in mappedWaves"
                :key="wave.id"
                :campaign="campaign"
                :wave="wave"
                @wave-action="$emit('wave-action', $event)"
            />
            <!-- scheduled campaign stuff -->
            <ScheduledCampaign
                v-if="scheduledCampaign"
                :scheduledCampaign="scheduledCampaign"
                :recipients="campaign.recipients || '-'"
                @wave-action="$emit('wave-action', $event)"
            />
        </template>
        <!-- popup -->
        <CampaignMetricsPopup
            v-if="campaignMetricsPopupOpen"
            :campaign="campaign"
            @close="campaignMetricsPopupOpen = false"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import Toggle from '@/common/components/design-system/Toggle.vue';
import Icon from '@/components/ui/Icon.vue';
import TypeIcon from './CampaignTypeIcon.vue';
import Action from './CampaignListItemAction.vue';
import WaveListItem from './WaveListItem.vue';
import ScheduledCampaign from './ScheduledCampaign.vue';
import CampaignMetricsPopup from '@/dashboard/campaign-metrics/components/CampaignMetricsPopup/CampaignMetricsPopup.vue';
import { CampaignAction, MappedCampaign } from './CampaignList.vue';
import { Schedule, Wave } from '@/campaign/types';
import dayjs from 'dayjs';

export type MappedWave = Wave & { index: number, reminders: Wave[], schedules: Schedule[] };

export default defineComponent({
    components: {
        Toggle,
        Icon,
        TypeIcon,
        Action,
        WaveListItem,
        ScheduledCampaign,
        CampaignMetricsPopup,
    },
    props: {
        campaign: { type: Object as () => MappedCampaign, required: true },
    },
    emits: ['set-is-active', 'action', 'wave-action'],
    setup(props, context) {
        const open = ref(false);
        const campaignMetricsPopupOpen = ref(false);

        function action(action: string) {
            if (action == 'open-campaign-metrics') {
                campaignMetricsPopupOpen.value = true;
                return;
            }
            const payload: CampaignAction = {
                type: action,
                id: props.campaign.id!
            };

            context.emit('action', payload);
        }

        const mappedWaves = computed<MappedWave[]>(() => {
            return props.campaign.waves
                .filter(w => !w.parentWaveId)
                .map((w, index) => {
                    return {
                        ...w,
                        index,
                        reminders: props.campaign.waves.filter(r => r.parentWaveId == +w.id),
                        schedules: props.campaign.schedules
                            .filter(s => s.parentWaveId == w.id)
                            .filter(s => s.nextSendAt != null)
                            // @ts-ignore at this point nextSendAt is not null
                            .sort((s1, s2) => s1.nextSendAt < s2.nextSendAt ? -1 : 1)
                            .reduce<Schedule[]>((acc, s) => {
                                if (!acc.some(a => s.reminderType == a.reminderType)) {
                                    acc.push(s);
                                }
                                return acc;
                            }, []),
                    };
                });
        });

        const scheduledCampaign = computed(() => {
            return props.campaign.schedules
                .filter((schedule) => !schedule.parentWaveId)
                .filter((schedule) => schedule.nextSendAt != null)
                .sort(((s1, s2) => {
                    if (s1.nextSendAt == s2.nextSendAt) return 0;
                    // @ts-ignore null values are filtered out at this point
                    return s1.nextSendAt < s2.nextSendAt ? -1 : 1;
                }))
                .at(0);
        });

        const hasWaves = computed(() => {
            return props.campaign.waves.length > 0 || scheduledCampaign.value;
        });

        return { open, action, campaignMetricsPopupOpen, mappedWaves, scheduledCampaign, hasWaves };
    },
    methods: { dayjs },
});
</script>

<style scoped>
section.grid > div {
    @apply py-4 px-2
}
</style>
