<template>
    <z-icon
        icon="download"
        :class="{
            'cursor-pointer': !disabled,
            'cursor-not-allowed text-neutral-700': disabled
        }"
        @click="!disabled && $emit('click', $event)"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ZIcon from '@/components/ui/Icon.vue';

export default defineComponent({
    components: { ZIcon },
    props: {
        disabled: { type: Boolean, default: false },
    },
    emits: ['click']
});
</script>
