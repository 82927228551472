<template>
    <div
        class="dropdown-container"
        @scroll.stop
        @wheel.stop
        @mousewheel.stop
    >
        <div
            v-if="showDropDown"
            class="overlay"
            @click="toggleDropDown(true)"
        />
        <div @click="toggleDropDown()">
            <div
                ref="icon"
                class="icon-container"
                data-test-id="icon"
            >
                <slot name="icon" />
            </div>
            <div
                v-if="showDropDown && !disabled"
                ref="dropDown"
                class="dropdown-box-container"
                data-test-id="box"
                :style="dropdownPosition"
            >
                <scrollbar
                    class="h-full p-1 bg-bg rounded"
                    stop-propagation
                >
                    <slot name="box" />
                </scrollbar>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {nextTick, ref, computed, defineComponent} from 'vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';

export default defineComponent({
    name: 'DropDown',
    components: {
        Scrollbar,
    },
    props: {
        shouldCloseOnClick: { type: Boolean, default: true },
        directionX: { type: String as () => 'left'|'right', default: 'right', validator: (val: string): boolean => ['left', 'right'].includes(val) },
        directionY: { type: String as () => 'top'|'bottom', default: 'bottom', validator: (val: string): boolean => ['top', 'bottom'].includes(val) },
        disabled: { type: Boolean, default: false },
    },
    setup(props) {
        const showDropDown = ref(false);
        const top = ref(0);
        const left = ref(0);
        const dropDown = ref<HTMLDivElement>();
        const icon = ref<HTMLDivElement>();

        const toggleDropDown = async (forceClose = false) => {
            top.value = 0;
            left.value = 0;

            if (props.shouldCloseOnClick || forceClose) {
                showDropDown.value = !showDropDown.value;
            } else {
                showDropDown.value = true;
            }

            await nextTick();
            calculatePosition();
            await nextTick();
            calculateHeight();
        };

        const calculatePosition = (): void => {
            if (!dropDown.value || !icon.value) return;

            dropDown.value.style.height = 'unset';

            const rectInfo = dropDown.value.getBoundingClientRect();
            const iconRectInfo = icon.value.getBoundingClientRect();
            const bottomDistance = window.innerHeight - (iconRectInfo.bottom + rectInfo.height);
            const rightDistance = window.innerWidth - (iconRectInfo.left + rectInfo.width);

            if (props.directionX === 'left' || rightDistance <= 10) {
                left.value  = iconRectInfo.right - rectInfo.width;
            }
            else {
                left.value  = iconRectInfo.left;
            }

            if (props.directionY === 'top' || bottomDistance <= 10) {
                top.value = iconRectInfo.top - rectInfo.height;
            }
            else {
                top.value = iconRectInfo.bottom;
            }
        };

        const calculateHeight = (): void => {
            if (!dropDown.value || !icon.value) return;

            dropDown.value.style.height = 'unset';

            const rect = dropDown.value.getBoundingClientRect();

            if (rect.top < 0) {
                top.value = 10;
                dropDown.value.style.height = (rect.height + rect.top - 10) + 'px';
            }
            if (rect.bottom > window.innerHeight) {
                dropDown.value.style.height = (rect.height - (rect.bottom - window.innerHeight) - 10) + 'px';
            }
        };

        const dropdownPosition = computed(() => {
            const position: { left?: string, top?: string } = {};
            if (left.value) {
                position.left = left.value + 'px';
            }
            if (top.value) {
                position.top = top.value + 'px';
            }
            return position;
        });

        return { showDropDown, toggleDropDown, dropdownPosition, icon, dropDown };
    }
});
</script>
<style scoped>
    .overlay {
        background-color: transparent;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        position: fixed;
        z-index: 40;
        overflow: hidden;
    }
    .icon-container {
        position: relative;
        padding: 2px;
        cursor: pointer;
    }
    .dropdown-container {
        width: max-content;
    }
    .dropdown-box-container {
        box-shadow: 0 6px 18px 0 rgba(131, 146, 165, 0.5);
        outline: 2px solid transparent;
        outline-offset: 2px;
        position: fixed;
        z-index: 50;
        width: max-content;
        border-radius: 0.25rem
    }
</style>
