<script lang="ts">
import type { Campaign } from '@/campaign/types';
import type { PropType } from 'vue';
import type { AudienceWithSegments } from '@/audience/types';
import { ReminderType, getReminderTypeDisplayName } from '@/campaign/types';
import type { Button, EmitCallback } from '@/survey/components/publish/campaign/popup/views/common/SelectableButtonList.vue';

import { ref, computed, defineComponent, onBeforeMount } from 'vue';

import i18next from 'i18next';
import SelectableButtonList from '@/survey/components/publish/campaign/popup/views/common/SelectableButtonList.vue';
import { useCampaigns } from '@/campaign';

import * as CampaignMetricsApi from '@/dashboard/campaign-metrics/api';
import { useNamespacedState } from 'vuex-composition-helpers';

export default defineComponent({
    name: 'ReminderFor',
    components: { SelectableButtonList },
    props: {
        campaign: { type: Object as PropType<Campaign>, required: true },
        audiences: { type: Array as PropType<AudienceWithSegments[]>, required: true },
    },
    emits: ['set-reminder-type'],
    setup: (_, { emit }) => {
        const surveyState = useNamespacedState('survey', ['datasetId']);
        const { state } = useCampaigns();
        const selectedButtonIndex = ref(-1);
        const selectedButton = computed(() => targetButtons.value[selectedButtonIndex.value]);

        function checkRecipients(reminderType): boolean {
            switch (reminderType) {
            case ReminderType.BOUNCED:
                return countDistribution.value.bounced > 0;
            case ReminderType.NOT_CLICKED:
                return countDistribution.value.recipients - countDistribution.value.clicked > 0;
            case ReminderType.NOT_COMPLETED:
                return countDistribution.value.recipients - countDistribution.value.completed > 0;
            case ReminderType.NOT_OPENED:
                return countDistribution.value.recipients - countDistribution.value.opened > 0;
            default:
                return false;
            }
        }

        const footNote = i18next.t('AUDIENCE.CAMPAIGN_POPUP.NO_RECIPIENTS_BY_METRIC', 'No recipients defined by this metric');

        const targetButtons = computed<Button[]>(() => [
            {
                icon: 'envelope-open',
                title: getReminderTypeDisplayName(ReminderType.NOT_OPENED),
                summary: i18next.t('AUDIENCE.CAMPAIGN_POPUP_REMINDERS_NON_OPENERS_DESCRIPTION', 'Send reminders to recipients who haven’t opened their survey invitation emails.'),
                action: (emit: EmitCallback) => emit('set-reminder-type' , ReminderType.NOT_OPENED),
                isDisabled: () => !checkRecipients(ReminderType.NOT_OPENED),
                footnote: !checkRecipients(ReminderType.NOT_OPENED) ? footNote : undefined
            },
            {
                icon: 'arrow-pointer',
                title: getReminderTypeDisplayName(ReminderType.NOT_CLICKED),
                summary: i18next.t('AUDIENCE.CAMPAIGN_POPUP_REMINDERS_NO_CLICK_DESCRIPTION', 'Send reminders to recipients who opened the invitation but didn\'t click the survey link.'),
                action: (emit: EmitCallback) => emit('set-reminder-type' , ReminderType.NOT_CLICKED),
                isDisabled: () => !checkRecipients(ReminderType.NOT_CLICKED),
                footnote: !checkRecipients(ReminderType.NOT_CLICKED) ? footNote : undefined
            },
            {
                icon: 'ban',
                title: getReminderTypeDisplayName(ReminderType.BOUNCED),
                summary: i18next.t('AUDIENCE.CAMPAIGN_POPUP_REMINDERS_BOUNCED_DESCRIPTION', 'Resend to recipients whose initial email couldn\'t be delivered.'),
                action: (emit: EmitCallback) => emit('set-reminder-type' , ReminderType.BOUNCED),
                isDisabled: () => !checkRecipients(ReminderType.BOUNCED),
                footnote: !checkRecipients(ReminderType.BOUNCED) ? footNote : undefined
            },
            {
                icon: 'file-check',
                title: getReminderTypeDisplayName(ReminderType.NOT_COMPLETED),
                summary: i18next.t('AUDIENCE.CAMPAIGN_POPUP_REMINDERS_NON_COMPLETERS_DESCRIPTION', 'Send reminders to recipients who didn\'t finish the survey.'),
                action: (emit: EmitCallback) => emit('set-reminder-type' , ReminderType.NOT_COMPLETED),
                isDisabled: () => !checkRecipients(ReminderType.NOT_COMPLETED),
                footnote: !checkRecipients(ReminderType.NOT_COMPLETED) ? footNote : undefined
            }
        ]);

        const countDistributionLoading = ref(false);
        const countDistribution = ref({
            recipients: 0,
            bounced: 0,
            opened: 0,
            clicked: 0,
            completed: 0,
        });

        onBeforeMount(async () => {
            try {
                if (!state.reminderWave) throw new Error('no reminder wave');

                countDistributionLoading.value = true;
                const distributions = await CampaignMetricsApi.getCountDistribution({
                    datasetId: surveyState.datasetId.value,
                    campaignId: state.reminderWave.campaignId,
                    waveId: state.reminderWave.waveId,
                    filters: []
                });

                // if getCountDistribution returns an empty array, this won't give an error and it won't be true neither
                if (distributions[state.reminderWave.waveId]) {
                    countDistribution.value = distributions[state.reminderWave.waveId];
                }
            }
            catch (e) {
                Sentry.captureException(e);
            }
            finally {
                countDistributionLoading.value = false;
            }
        });

        return {
            targetButtons,
            selectedButton,
            selectedButtonIndex,
            // @ts-ignore
            callAction: (button: Button) => button.action(emit),

            selectButton: (index: number) => {
                selectedButtonIndex.value = index;

                switch (index) {
                case 0:
                    useCampaigns().setReminderRecipientCount(countDistribution.value.recipients - countDistribution.value.opened);
                    break;
                case 1:
                    useCampaigns().setReminderRecipientCount(countDistribution.value.recipients - countDistribution.value.clicked);
                    break;
                case 2:
                    useCampaigns().setReminderRecipientCount(countDistribution.value.bounced);
                    break;
                case 3:
                    useCampaigns().setReminderRecipientCount(countDistribution.value.recipients - countDistribution.value.completed);
                    break;
                }
            },
            countDistributionLoading
        };
    },
});
</script>
<template>
    <div class="flex flex-col">
        <main class="flex-grow flex flex-col items-center mt-4 mx-32">
            <h1 class="mx-2 text-xl font-bold self-start">
                {{ $t('AUDIENCE.CAMPAIGN_POPUP_SELECT_REMINDER_TARGET_HEADER', 'Define the recipients of your Reminder Wave') }}
            </h1>
            <div
                class="mx-2 self-start"
                v-html="$t('AUDIENCE.CAMPAIGN_POPUP_SELECT_REMINDER_TARGET_DESCRIPTION', 'Choose the segment of your original recipients who will receive this reminder wave.<br>Select only one option.')"
            />
            <div class="w-full">
                <selectable-button-list
                    :campaign="campaign"
                    :audiences="audiences"
                    :buttons="targetButtons"
                    :selected-button-index="selectedButtonIndex"
                    :loading="countDistributionLoading"
                    @select-index="selectButton"
                />
            </div>
            <div class="flex-grow mt-4 flex items-end">
                <z-button
                    :disabled="selectedButtonIndex === -1"
                    @click="callAction(selectedButton)"
                >
                    {{ $t('BUTTONS.CONTINUE', 'Continue') }}
                </z-button>
            </div>
        </main>
    </div>
</template>
