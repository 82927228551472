import { reactive } from 'vue';
import { ExportAudienceState, ExportMode, AudienceState } from '../types';
import * as api from '../api';
import wait from '@/utils/wait';
import { saveAs } from 'file-saver';

export const state = reactive<ExportAudienceState>({
    exportPopupOpen: false,
    exportMode: null,
    exports: null,
});

export const makeActions = (rootState: AudienceState ) => ({
    setExportPopupOpen: (val: boolean): void => {
        state.exportPopupOpen = val;
    },
    setExportMode: (mode: ExportMode): void => {
        state.exportMode = mode;
    },
    generateExport: async (): Promise<void> => {
        await api.createAudienceExport(rootState.audience!.uid!, {
            segmentId: rootState.segment.uid || undefined,
            memberIds: rootState.selectedMembers.length > 0 ? rootState.selectedMembers : undefined
        });
    },
    getExports: async (): Promise<void> => {
        state.exports = await api.getAudienceExports(rootState.audience!.uid!);

        while (state.exportPopupOpen && state.exports.some(e => !e.finishedAt)) {
            await wait(1);
            state.exports = await api.getAudienceExports(rootState.audience!.uid!);
        }
    },
    deleteExport: async (exportId: number): Promise<void> => {
        await api.deleteExport(rootState.audience!.uid!, exportId);
    },
    downloadExport: async (exportId: number) => {
        const { data, filename } = await api.downloadExport(rootState.audience!.uid!, exportId);

        saveAs(data, filename);
    },
    resetExportState: (): void => {
        state.exportPopupOpen = false;
        state.exportMode = null;
        state.exports = null;
    },
});
