import {api} from '@/api/index.js';
import {DatasetAlert} from '@/dataset-alerting/common/types/datasetAlertInterface';

export const saveDatasetAlert = async (datasetAlert: DatasetAlert, datasetId: string) => {
    const { data } = await api.post('datasets/' + datasetId + '/alerts', datasetAlert);
    return data;
};

export const updateDatasetAlert = async (datasetAlert: DatasetAlert, datasetId: string) => {
    const { data } = await api.put('datasets/' + datasetId + '/alerts/' + datasetAlert.id, datasetAlert);
    return data;
};

export const getDatasetAlerts = async (datasetId: string) => {
    const { data } = await api.get('datasets/' + datasetId + '/alerts');
    return data;
};

export const deleteDatasetAlert = async (datasetId: string, id: number) => {
    const { data } = await api.delete('datasets/' + datasetId + '/alerts/' + id);
    return data;
};

export const getAlertNamesForDimension = async (datasetId: string, dimensionId: string) => {
    const { data } = await api.get(`/datasets/${datasetId}/alerts/${dimensionId}/names`);
    return data;
};
