<template>
    <popup
        :title="$t('PUBLISH.GENERATE_ENCODED_URLS_POPUP_TITLE', 'Generate Encoded URLs')"
        is-closable
        @close="$emit('close')"
    >
        <div class="w-96">
            <div class="flex flex-col items-center space-y-4">
                <p class="text-sm">
                    {{ $t('PUBLISH.GENERATE_ENCODED_URL_BODY_LINE_1', 'Ensure the security of your survey by sharing it via encoded links. This prevents URL parameters and URL parameter values from being visible.') }}<br>
                    {{ $t('PUBLISH.GENERATE_ENCODED_URL_BODY_LINE_2', 'To generate these encoded links:') }}
                </p>
                <ol class="list-decimal ml-2 pl-4 text-sm">
                    <li>{{ $t('PUBLISH.GENERATE_ENCODED_URL_BODY_LIST_1', 'Download our provided template, which includes all necessary parameter names.') }}</li>
                    <li>{{ $t('PUBLISH.GENERATE_ENCODED_URL_BODY_LIST_2', 'Fill in the parameter values in the template.') }}</li>
                    <li>{{ $t('PUBLISH.GENERATE_ENCODED_URL_BODY_LIST_3', 'Upload the completed template.') }}</li>
                </ol>
                <p class="text-sm">
                    {{ $t('PUBLISH.GENERATE_ENCODED_URL_BODY_LINE_3', 'Zurvey will then create the encoded survey URLs based on your input.') }}
                </p>
                <z-button
                    variant="secondary"
                    @click="downloadTemplate"
                >
                    <icon
                        icon="download"
                        class="mr-1"
                    />
                    {{ $t('PUBLISH.QR_CODE_DOWNLOAD_TEMPLATE', 'Download template based on your parameters') }}
                </z-button>
                <z-button @click="uploadExcel">
                    {{ $t('PUBLISH.QR_CODE_UPLOAD_EXCEL', 'Upload excel to set up parameters') }}
                </z-button>
            </div>
        </div>
        <input
            ref="file"
            type="file"
            accept=".xls, .xlsx, .csv"
            class="hidden"
            @change="saveFile($event.target.files[0])"
        >
    </popup>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Popup from '@/components/ui/Popup.vue';
import Icon from '@/components/ui/Icon.vue';
import * as XLSX from 'xlsx';
import excelParser from '@/domain/excel/excelParser';
import { useNamespacedMutations } from 'vuex-composition-helpers';
import { useRouter } from 'vue-router/composables';


export default defineComponent({
    components: {
        Popup,
        Icon
    },
    props: {
        urlParams: { type: Array as () => any[], default: () => [] },
        name: { type: String, default: '' },
    },
    setup(props) {
        const router = useRouter();

        const mutations = useNamespacedMutations('survey/encodedUrl', ['setTable', 'setFile']);

        function downloadTemplate(): void {
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet([props.urlParams.map(param => param.name)]);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'template');
            XLSX.writeFile(workbook, 'template_' + props.name + '.xlsx');
        }

        const file = ref<HTMLInputElement>();

        function uploadExcel(): void {
            file.value!.click();
        }

        async function saveFile(file: File): Promise<void> {
            mutations.setFile(file);
            mutations.setTable(await excelParser({ file, guessDimensionTypes: false }));
            router.push({ name: 'encoded-url/upload' });
        }

        return { file, downloadTemplate, uploadExcel, saveFile };
    },
});
</script>
