<template>
    <div>
        <svg
            :width="width"
            :height="height"
            viewBox="0 0 212 138"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="0.5"
                width="210.33"
                height="136.334"
                rx="7.5"
                :fill="embedType === 'sidetab' ? 'var(--color-primary-700)': '#F2F3F8'"
                stroke="#D8DCE8"
            />
            <g filter="url(#filter3_d)">
                <rect
                    x="131"
                    y="29"
                    width="67"
                    height="80"
                    rx="4"
                    fill="white"
                />
            </g>
            <path
                d="M139.047 63.2812L138.031 65.3594L135.734 65.6875C135.328 65.75 135.172 66.25 135.469 66.5469L137.109 68.1562L136.719 70.4219C136.656 70.8281 137.094 71.1406 137.453 70.9531L139.5 69.875L141.531 70.9531C141.891 71.1406 142.328 70.8281 142.266 70.4219L141.875 68.1562L143.516 66.5469C143.812 66.25 143.656 65.75 143.25 65.6875L140.969 65.3594L139.938 63.2812C139.766 62.9219 139.234 62.9062 139.047 63.2812Z"
                :fill="embedType === 'sidetab' ? 'var(--color-primary-700)': '#9FA7BC'"
            />
            <path
                d="M151.797 63.2812L150.781 65.3594L148.484 65.6875C148.078 65.75 147.922 66.25 148.219 66.5469L149.859 68.1562L149.469 70.4219C149.406 70.8281 149.844 71.1406 150.203 70.9531L152.25 69.875L154.281 70.9531C154.641 71.1406 155.078 70.8281 155.016 70.4219L154.625 68.1562L156.266 66.5469C156.562 66.25 156.406 65.75 156 65.6875L153.719 65.3594L152.688 63.2812C152.516 62.9219 151.984 62.9062 151.797 63.2812Z"
                :fill="embedType === 'sidetab' ? 'var(--color-primary-700)': '#9FA7BC'"
            />
            <path
                d="M164.547 63.2812L163.531 65.3594L161.234 65.6875C160.828 65.75 160.672 66.25 160.969 66.5469L162.609 68.1562L162.219 70.4219C162.156 70.8281 162.594 71.1406 162.953 70.9531L165 69.875L167.031 70.9531C167.391 71.1406 167.828 70.8281 167.766 70.4219L167.375 68.1562L169.016 66.5469C169.312 66.25 169.156 65.75 168.75 65.6875L166.469 65.3594L165.438 63.2812C165.266 62.9219 164.734 62.9062 164.547 63.2812Z"
                :fill="embedType === 'sidetab' ? 'var(--color-primary-700)': '#9FA7BC'"
            />
            <path
                d="M177.297 63.2812L176.281 65.3594L173.984 65.6875C173.578 65.75 173.422 66.25 173.719 66.5469L175.359 68.1562L174.969 70.4219C174.906 70.8281 175.344 71.1406 175.703 70.9531L177.75 69.875L179.781 70.9531C180.141 71.1406 180.578 70.8281 180.516 70.4219L180.125 68.1562L181.766 66.5469C182.062 66.25 181.906 65.75 181.5 65.6875L179.219 65.3594L178.188 63.2812C178.016 62.9219 177.484 62.9062 177.297 63.2812Z"
                fill="#D8DCE8"
            />
            <path
                d="M190.047 63.2812L189.031 65.3594L186.734 65.6875C186.328 65.75 186.172 66.25 186.469 66.5469L188.109 68.1562L187.719 70.4219C187.656 70.8281 188.094 71.1406 188.453 70.9531L190.5 69.875L192.531 70.9531C192.891 71.1406 193.328 70.8281 193.266 70.4219L192.875 68.1562L194.516 66.5469C194.812 66.25 194.656 65.75 194.25 65.6875L191.969 65.3594L190.938 63.2812C190.766 62.9219 190.234 62.9062 190.047 63.2812Z"
                fill="#D8DCE8"
            />
            <rect
                x="135"
                y="53"
                width="18"
                height="3"
                fill="#424454"
            />
            <rect
                x="155.25"
                y="93"
                width="19.5"
                height="8"
                rx="4"
                :fill="embedType === 'sidetab' ? 'var(--color-primary-700)': '#9FA7BC'"
            />
            <rect
                x="160.5"
                y="96"
                width="9"
                height="2"
                fill="white"
            />
            <path
                d="M210.5 51V50.5H210H206C203.515 50.5 201.5 52.5147 201.5 55V83C201.5 85.4853 203.515 87.5 206 87.5H210H210.5V87V51Z"
                fill="white"
                stroke="#D8DCE8"
            />
            <defs>
                <filter
                    id="filter3_d"
                    x="117"
                    y="19"
                    width="95"
                    height="108"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.513726 0 0 0 0 0.572549 0 0 0 0 0.647059 0 0 0 0.45 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        embedType: { type: String, default: null },
        width: { type: Number, default: 210 },
        height: { type: Number, default: 138 },
    },
};
</script>

<style scoped>

</style>
