<template>
    <popup
        :title="$t('SURVEYFORMS.PUBLISH_EMBED_ACTIVATION_POPUP_TITLE', 'Embed setting activation')"
        :is-closable="true"
        data-test-id="embed-activation-popup"
        width-class="w-128"
        @close="$emit('close')"
    >
        <div class="text-sm mb-4">
            {{ body }}
        </div>
        <div class="mt-4 flex justify-center space-x-4">
            <z-button
                variant="secondary"
                data-test-id="not-activate-button"
                @click="$emit('close')"
            >
                {{ $t('SURVEYFORMS.PUBLISH_EMBED_ACTIVATE_POPUP_NON_ACTIVATE', 'Keep it paused') }}
            </z-button>
            <z-button
                data-test-id="activate-button"
                @click="$emit('activate')"
            >
                {{ $t('SURVEYFORMS.PUBLISH_EMBED_ACTIVATE_POPUP_ACTIVATE', 'Activate it') }}
            </z-button>
        </div>
    </popup>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Popup from '@/components/ui/Popup.vue';


export default defineComponent({
    components: { Popup,  },
    props: {
        body: { type: String, required: true }
    }
});
</script>

