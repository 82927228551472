<template>
    <div class="relative shadow rounded bg-white p-4">
        <all-rates v-if="chartType === 'all'" />
        <template v-else>
            <rates-by-wave
                v-if="campaignMetrics"
                ref="rates"
                :waves="waves"
            />
            <wave-rates
                v-else-if="waveMetrics"
                ref="rates"
            />
            <rates-by-campaigns
                v-else
                ref="rates"
            />
        </template>
        <div class="absolute top-0 right-0 mr-3 mt-3 space-x-2">
            <z-icon
                icon="gauge-simple-high"
                class="cursor-pointer"
                :class="{
                    'text-primary-700': chartType === 'all'
                }"
                @click="chartType = 'all'"
            />
            <z-icon
                icon="chart-pie"
                class="cursor-pointer"
                :class="{
                    'text-primary-700': chartType === 'per-campaign'
                }"
                @click="chartType = 'per-campaign'"
            />
            <z-icon
                v-if="chartType === 'per-campaign'"
                icon="download"
                class="cursor-pointer border-l border-neutral-500 pl-2"
                @click="downloadCharts"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import AllRates from './AllRates.vue';
import RatesByWave from '../CampaignMetricsPopup/RatesByWave.vue';
import RatesByCampaigns from './RatesByCampaigns.vue';
import WaveRates from '../WaveMetricsPopup/WaveRates.vue';
import ZIcon from '@/components/ui/Icon.vue';
import { MappedWave } from '../CampaignMetricsPopup/CampaignMetricsPopup.vue';

export default defineComponent({
    components: {
        AllRates,
        RatesByWave,
        RatesByCampaigns,
        WaveRates,
        ZIcon,
    },
    props: {
        campaignMetrics: { type: Boolean, default: false },
        waveMetrics: { type: Boolean, default: false },
        waves: { type: Array as () => Array<MappedWave>, default: null }
    },
    setup() {
        const chartType = ref<'all'|'per-campaign'>('all');
        const rates = ref();

        const downloadCharts = () => {
            rates.value.downloadCharts();
        };

        return { chartType, downloadCharts, rates };
    }
});
</script>
