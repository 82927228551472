<template>
    <div class="flex justify-center">
        <chart
            ref="chartRef"
            :options="chartOptions"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { Chart } from 'highcharts-vue';
import { getLegend, getRateName } from '../../utils';
import { createColorScale } from '@/utils/color';
import useUserStore from '@/user/store';

export default defineComponent({
    components: { Chart },
    props: {
        metric: { type: String, required: true },
        rate: { type: Number, required: true },
        recipients: { type: Number, required: true },
    },
    setup(props) {
        const chartRef = ref();
        const { state: userState } = useUserStore();
        const colors = computed(() => createColorScale(userState.primaryColor, 2));

        const chartOptions = computed(() => ({
            chart: {
                type: 'pie',
                backgroundColor: null,
                width: 200,
                height: 300,
            },
            legend: { enabled: false },
            exporting: { enabled: false },
            tooltip: { enabled: false },
            plotOptions: {
                series: {
                    dataLabels: {
                        formatter(this: any) {
                            if (this.y == 0) return undefined;

                            return `${this.point.name}<br><span class="whitespace-no-wrap">${this.y} (${+(this.y / this.point.recipients * 100).toFixed(2)}%)</span>`;
                        },
                        alignTo: 'plotEdges',
                        useHTML: true,
                    }
                }
            },
            title: {
                text: getRateName(props.metric),
                style: {
                    'color': 'var(--color-neutral-800)',
                    'font-size': '12px',
                },
                align: 'center',
                verticalAlign: 'bottom',
            },
            series: [
                {
                    colors: colors.value,
                    data: [
                        { name: getLegend(props.metric), y: props.rate, recipients: props.recipients },
                        { name: getLegend(`not-${props.metric}`), y: props.recipients - props.rate, recipients: props.recipients },
                    ]
                }
            ],
            credits: {
                enabled: false,
            }
        }));

        return { chartOptions, chartRef };
    },
});
</script>
