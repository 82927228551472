<template>
    <dropdown-menu>
        <template #icon>
            <z-icon icon="ellipsis-h" />
        </template>
        <template #box>
            <div class="-m-1 text-black">
                <template v-if="isScheduled">
                    <button @click="$emit('click', 'schedule')">
                        {{ $t('AUDIENCE.EDIT', 'Edit') }}
                    </button>
                </template>
                <template v-else>
                    <button @click="$emit('click', 'metrics')">
                        {{ isReminder ? $t('AUDIENCE.WAVE_LIST_ITEM_REMINDER_METRICS', 'Reminder Metrics') : $t('AUDIENCE.WAVE_LIST_ITEM_METRICS', 'Wave Metrics') }}
                    </button>
                    <!-- reminder cannot have reminder -->
                    <button
                        v-if="!isReminder"
                        @click="$emit('click', 'reminder')"
                    >
                        {{ $t('AUDIENCE.WAVE_LIST_ITEM_REMINDER', 'New Reminder') }}
                    </button>
                </template>
            </div>
        </template>
    </dropdown-menu>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import DropdownMenu from '@/components/ui/DropdownMenu.vue';
import ZIcon from '@/components/ui/Icon.vue';

export default defineComponent({
    components: {
        DropdownMenu,
        ZIcon
    },
    props: {
        isReminder: { type: Boolean, required: true },
        isScheduled: { type: Boolean, default: false },
    },
});
</script>

<style scoped>
button {
    @apply block w-full text-left p-2;
}
button:hover {
    @apply bg-primary-300;
}
</style>
