<template>
    <popup
        :title="$t('PUBLISH.QR_CODE_READY_POPUP_TITLE', 'QR Codes are ready')"
        is-closable
        dont-capitalize-title
        @close="$emit('close')"
    >
        <div class="w-96 space-y-8">
            <p class="text-xs">
                {{ $t('PUBLISH.QR_CODE_READY_POPUP_BODY', 'You can download the QR codes in a compressed archive. The file names will indicate the survey name and parameter values, which will make it easier to distinguish between the different QR codes.') }}
            </p>
            <div class="flex justify-center">
                <z-button @click="download">
                    {{ $t('PUBLISH.QR_CODE_DOWNLOAD_QR_CODES', 'Download QR Codes') }}
                </z-button>
            </div>
        </div>
    </popup>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import Popup from '@/components/ui/Popup.vue';
import { useNamespacedState } from 'vuex-composition-helpers';


import QRCode from 'qrcode';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default defineComponent({
    components: {
        Popup,

    },
    props: {
        link: { type: String, required: true },
        name: { type: String, required: true },
        benchmarkLanguage: { type: String, default: '' },
        langs: { type: Array as () => Array<string>, default: () => [] },
    },
    setup(props) {
        const state = useNamespacedState('survey/qrCode', ['linkData']);

        const dataUrl = ref();

        onMounted(async () => {
            dataUrl.value = await QRCode.toDataURL(props.link + '?' + new URLSearchParams(state.linkData.value[0]));
        });

        async function download(): Promise<void> {
            const zip = new JSZip();

            const linkData = state.linkData.value.reduce((links, data) => {
                if (props.langs.length > 0) {
                    links.push({...data, lang: props.benchmarkLanguage});
                }
                else {
                    links.push({...data});
                }

                // if props.langs is an empty array, no additional links will be added
                props.langs.forEach(lang => {
                    links.push({
                        ...data,
                        lang
                    });
                });

                return links;

            }, []);

            const codes: string[] = await Promise.all(linkData.map(data => QRCode.toDataURL(props.link + '?' + new URLSearchParams(data))));

            linkData.forEach((data, index) => {
                zip.file(props.name + '_' + Object.values(data).join('_') + '.png', codes[index].split('base64,')[1], { base64: true });
            });

            saveAs(await zip.generateAsync({ type: 'blob' }), props.name + '.zip');
        }

        return { dataUrl, download };
    },
});
</script>
