import axios from 'axios';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import {setLoading, isLoading} from '@/utils/loading';
import theme from '@/utils/theme';
import i18next from 'i18next';
import api from '@/api';
import useSubscriptionStore from '@/subscription/store';
import useUserStore from '@/user/store';
import useCommonStore from '@/dashboard/common/store/commonStore';
import { toaster } from '@/utils/toaster';

Vue.use(VueRouter);

import AuthRoutes from '@/user/routes';

const HomeRoot = () => import('@/home/HomeRoot.vue');
import HomeRoutes from '@/home/router';

const SettingsRoot = () => import('@/settings/SettingsRoot.vue');
import SettingsRoutes from '@/settings/router';

const AdminRoot = () => import('@/admin/AdminRoot.vue');
import AdminRoutes from '@/admin/router';

const CustomLabelsRoot = () => import('@/custom-labels/CustomLabelsRoot.vue');
import CustomLabelsRoutes from '@/custom-labels/router';

const SurveyBuilderRoot = () => import('@/survey/SurveyBuilderRoot.vue');
import SurveyBuilderRoutes from '@/survey/router';

const DashboardRoot = () => import('@/dashboard/DashboardRoot.vue');
import DashboardRoutes from '@/dashboard/routes';

const EmailConnectorRoot = () => import('@/email-connector/EmailConnectorRoot.vue');
import EmailConnectorRoutes from '@/email-connector/router';

const ExcelAnalysisRoot = () => import('@/excel-analysis/ExcelAnalysisRoot.vue');
import ExcelAnalysisRoutes from '@/excel-analysis/router';

const InputAPIRoot = () => import('@/input-api/InputAPIRoot.vue');
import InputAPIRoutes from '@/input-api/router';

const NMIIntegrationRoot = () => import('@/nmi-integration/NMIIntegrationRoot.vue');
import NMIIntegrationRoutes from '@/nmi-integration/router';

const AudienceRoot = () => import('@/audience/AudienceRoot.vue');
import AudienceRoutes from '@/audience/router';

const PrintDashboardApp = () => import('@/dashboard/print/PrintDashboardApp.vue');

const UnavailableDashboard = () => import('@/dashboard/UnavailableDashboard.vue');

const EmbedPreviewPage = () => import('@/embed/EmbedPreviewPage.vue');

const WebsiteCrawlerRoot = () => import('@/website-crawler/WebsiteCrawlerRoot.vue');
import WebsiteCrawlerRoutes from '@/website-crawler/routes';

const PdfExtractorRoot = () => import('@/pdf-extractor/PdfExtractorRoot.vue');
import PdfExtractorRoutes from '@/pdf-extractor/routes';

const DomoIntegrationRoot = () => import('@/domo-integration/DomoIntegrationRoot.vue');
import DomoIntegrationRoutes from '@/domo-integration/routes';

const ConversationInputRoot = () => import('@/conversation-input/ConversationInputRoot.vue');
import ConversationInputRoutes from '@/conversation-input/routes';

const routes: RouteConfig[] = [
    ...AuthRoutes,
    {
        path: '/',
        component: HomeRoot,
        children: HomeRoutes
    },
    {
        path: '/settings',
        component: SettingsRoot,
        children: SettingsRoutes
    },
    {
        path: '/admin',
        component: AdminRoot,
        children: AdminRoutes
    },
    {
        path: '/custom-labels',
        component: CustomLabelsRoot,
        children: CustomLabelsRoutes
    },
    {
        path: '/survey',
        component: SurveyBuilderRoot,
        children: SurveyBuilderRoutes
    },
    {
        path: '/email-connector',
        component: EmailConnectorRoot,
        children: EmailConnectorRoutes
    },
    {
        path: '/api-connector',
        component: InputAPIRoot,
        children: InputAPIRoutes
    },
    {
        path: '/nmi-integration',
        component: NMIIntegrationRoot,
        children: NMIIntegrationRoutes
    },
    {
        path: '/audience',
        component: AudienceRoot,
        children: AudienceRoutes
    },
    {
        path: '/dashboard',
        component: DashboardRoot,
        children: DashboardRoutes,
        meta: { isGuestAllowed: true },
        beforeEnter: async (to, from, next) => {
            if (!isLoading.value) { // this will fire on every redirect, only set loading once
                setLoading(true);
            }

            // load dashboard with filters if set
            if (to.query.filters) {
                useCommonStore().actions.setQuery({filters: to.query.filters});
            }

            let isDashboardAvailableWithIps = true;
            if (to.query.t) {
                useCommonStore().actions.setQuery({t: to.query.t});
                isDashboardAvailableWithIps = await useCommonStore().actions.checkIps(to.params.datasetId);
            }

            if(!isDashboardAvailableWithIps) {
                return next('/unavailable-dashboard');
            }

            if (!useUserStore().state.hasAccess && to.name == 'unavailableDashboard') {
                return next();
            }

            try { // try with default access token, normal user
                await useCommonStore().actions.init(to.params.datasetId);

                if (useCommonStore().state.dataset.is_archived) {
                    setLoading(false);
                    return next('/');
                }

                return next({ query: useCommonStore().state.query });
            } catch (e) {
                if ((axios.isAxiosError(e) && e?.response?.status !== 403) || !to.query.t) {
                    // If we didn't fail because of a access or there's no share token
                    if (axios.isAxiosError(e) && e?.response?.status === 403) {
                        toaster.error(i18next.t('DASHBOARD.NO_ACCESS_FOR_DATASET', 'Access denied for dataset'));
                    } else {
                        toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                    }
                    return next('/');
                }
            }

            try { // try to override normal user from token
                const accessToken = await api.auth.exchangeSharedToken(to.params.datasetId, to.query.t);
                api.setAccessToken(accessToken);
                await useCommonStore().actions.init(to.params.datasetId);
                next({ query: { t: to.query.t } });
            } catch (e) {
                toaster.error(i18next.t('DASHBOARD.NO_ACCESS_FOR_DATASET', 'Access denied for dataset'));
                next('/');
            }
        }
    },
    {
        path: '/unavailable-dashboard',
        name: 'unavailableDashboard',
        component: UnavailableDashboard,
    },
    {
        path: '/excel-analysis',
        component: ExcelAnalysisRoot,
        children: ExcelAnalysisRoutes
    },
    {
        path: '/print/:datasetId',
        component: PrintDashboardApp,
    },
    {
        path: '/embed-preview',
        name: 'embed-preview',
        component: EmbedPreviewPage,
    },
    {
        path: '/website-crawler',
        component: WebsiteCrawlerRoot,
        children: WebsiteCrawlerRoutes,
    },
    {
        path: '/pdf-extractor',
        component: PdfExtractorRoot,
        children: PdfExtractorRoutes,
    },
    {
        path: '/domo-integration',
        component: DomoIntegrationRoot,
        children: DomoIntegrationRoutes,
    },
    {
        path: '/conversation-input',
        component: ConversationInputRoot,
        children: ConversationInputRoutes,
    },
    {
        path: '*',
        redirect: '/'
    },
];

const router = new VueRouter({
    routes,
    mode: 'history',
    base: '/',
});

router.beforeEach(async (to, _, next) => {
    if (to.name === 'embed-preview') {
        return next();
    }

    const { state, actions } = useUserStore();

    if (!state.isLoggedIn) {
        await actions.initLoggedInUser();
    }

    if (!state.isLoggedIn && to.matched.some(m => m.meta.isGuestAllowed)) {
        await actions.initGuestUser(to.params.datasetId, to.query.t as string);
    }

    theme(state.primaryColor, state.isDarkMode);

    const rootLoading = document.getElementById('root-loading');
    if (rootLoading) {
        rootLoading.remove();
    }

    if (!state.hasAccess) {
        return to.name ===  'unavailableDashboard' ? next() : next( {name: 'unavailableDashboard'});
    }

    if (!state.isLoggedIn && !to.meta?.isAnonymous) {
        return await actions.login();
    } else if (to.meta?.isAnonymous) {
        return state.isLoggedIn ? next('/') : next();
    }

    setLoading(true);
    await i18next.changeLanguage(state.langCode);
    try {
        await useSubscriptionStore().actions.init(false);
    }
    catch (e) {
        Sentry.captureException(e);
    }
    setLoading(false);
    next();
});

export default router;
