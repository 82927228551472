<template>
    <v-select
        v-model="multiSelectValue"
        :options="options"
        label="text"
        :reduce="option => option.value"
        :multiple="true"
    >
        <template #selected-option-container="{ option, deselect }">
            <z-tag
                class="self-center mr-0.5"
                deletable
                @remove="() => deselect(option)"
            >
                {{ option.text }}
            </z-tag>
        </template>
    </v-select>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import 'vue-select/dist/vue-select.css';
import VSelect from 'vue-select';

export default defineComponent({
    components: { VSelect },
    model: { prop: 'modelValue', event: 'update:modelValue' },
    props: {
        modelValue: { type: Array as () => Array<number|string>, default: () => [] },
        options: { type: Array as () => Array<Record<any, string>|{ value: number|string, text: string }>,required: true, },
    },
    emits: ['update:modelValue'],
    setup(props, ctx) {
        const multiSelectValue = computed({
            get: () => {
                if (!props.modelValue) {
                    return [];
                }
                return props.modelValue;
            },
            set: newValue => ctx.emit('update:modelValue', newValue),
        });
        return {
            multiSelectValue,
        };
    }
});
</script>
<style scoped lang="less">
/deep/ .vs__dropdown-toggle{
    padding: 0px;
    border-radius: 4px;
    border: 1px solid var(--color-neutral-800);
    height: 32px;
}
/deep/ .vs__dropdown-option--highlight {
    color: var(--color-bg);
    background-color: var(--color-primary-700);
}
/deep/ .vs__selected-options {
    font-size: 12px;
}
/deep/ .vs__selected {
    color: var(--color-bg);
    background-color: var(--color-primary-700);
    border-radius: 4px;
    padding: 1px;
    border: none;
    margin: 2px;
    overflow: hidden;
}

/deep/ .vs__selected .vs__deselect {
    fill: var(--color-bg);
}
/deep/ .vs__dropdown-menu {
    box-shadow: none;
}
/deep/ .vs__open-indicator{
    display: none;
}
</style>
