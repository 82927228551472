<template>
    <dropdown-menu>
        <template #icon>
            <z-icon icon="ellipsis-h" />
        </template>
        <template #box>
            <div class="-m-1">
                <button
                    v-if="hasWaves"
                    @click="$emit('click', 'open-campaign-metrics')"
                >
                    {{ $t('CAMPAIGN_METRICS.LIST_ITEM.CAMPAIGN_METRICS', 'Campaign metrics') }}
                </button>
                <button @click="$emit('click', 'edit')">
                    {{ $t('AUDIENCE.CAMPAIGN_LIST_ITEM_EDIT', 'Edit') }}
                </button>
                <button @click="$emit('click', 'customization')">
                    {{ $t('AUDIENCE.CAMPAIGN_LIST_ITEM_TEMPLATE_CUSTOMIZATION', 'Template Customization') }}
                </button>
                <button @click="$emit('click', 'summary')">
                    {{ $t('AUDIENCE.CAMPAIGN_LIST_ITEM_TEMPLATE_SUMMARY', 'Summary') }}
                </button>
                <button @click="$emit('click', 'sending')">
                    {{ $t('AUDIENCE.CAMPAIGN_LIST_ITEM_TEMPLATE_SENDING', 'Sending') }}
                </button>
                <button
                    :disabled="hasWaves"
                    :class="{
                        'text-red-700 hover:bg-red-200': !hasWaves,
                        'text-neutral-700 hover:bg-transparent cursor-default': hasWaves
                    }"
                    @click="$emit('click', 'delete')"
                >
                    {{ $t('AUDIENCE.CAMPAIGN_LIST_ITEM_TEMPLATE_DELETE', 'Delete') }}
                </button>
            </div>
        </template>
    </dropdown-menu>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import DropdownMenu from '@/components/ui/DropdownMenu.vue';
import ZIcon from '@/components/ui/Icon.vue';

export default defineComponent({
    components: {
        DropdownMenu,
        ZIcon,
    },
    props: {
        hasWaves: { type: Boolean, default: false },
    },
});
</script>

<style scoped>
button {
    @apply block w-full text-left p-2;
}
button:hover {
    @apply bg-primary-300;
}
</style>
