// K.I.S.S - Keep It Stupid Simple

import type { Campaign } from '@/campaign/types';

import { reactive } from 'vue';
import * as campaignApi from './api';
import { CampaignState } from '@/campaign/types';

function getDefaultState(): CampaignState {
    return {
        isPopupViewOnly: false,
        reminderWave: null,
        reminderRecipientCount: 0,
        firstLoadingCampaigns: true,
        campaignsForDatasetId: [],
    };
}

const state = reactive<CampaignState>(getDefaultState());

const setPopupViewOnlyMode = (isViewOnly) => {
    state.isPopupViewOnly = isViewOnly;
};

const setReminderWave = (wave) => {
    state.reminderWave = wave;
};

const setReminderRecipientCount = (count: number) => {
    state.reminderRecipientCount = count;
};

function setFirstLoadingCampaigns(isLoading: boolean): void {
    state.firstLoadingCampaigns = isLoading;
}

function setCampaignsForDatasetId(campaigns: Array<Campaign>): void {
    state.campaignsForDatasetId = campaigns;
}

const resetState = () => {
    Object.entries(getDefaultState()).forEach(([key, value]) => {
        state[key] = value;
    });
};

// formerly listCampaignsForUserGroup
const getCampaignsForUserGroup = async (): Promise<Campaign[]> => {
    const campaigns = await campaignApi.fetchAllCampaigns();

    return campaigns;
};

// formerly listCampaignsByDatasetId
const getCampaignsByDatasetId = async (datasetId: string): Promise<Campaign[]> => {
    const campaigns = await campaignApi.fetchCampaignsByDatasetId(datasetId);
    state.campaignsForDatasetId = campaigns;

    return campaigns;
};

const removeCampaign = async (campaignId: string): Promise<void> => {
    await campaignApi.deleteCampaignById(campaignId);
    state.campaignsForDatasetId = state.campaignsForDatasetId.filter(c => c.id != campaignId);
};

const initEmptyCampaign = (datasetId: string): Campaign => ({
    datasetId: datasetId,
    audienceId: null,
    name: '',
    segmentId: null,
    emailTemplateId: null,
    isActive: false,
    variables: [],
    sendingInProgress: false,
    schedules: [],
});

const createNewCampaign = async (campaign: Campaign): Promise<Campaign> => {
    const result = await campaignApi.createCampaign(campaign);

    state.campaignsForDatasetId = state.campaignsForDatasetId.concat(result);

    return result;
};

const updateExistingCampaign = async (campaign: Campaign): Promise<Campaign> => {
    const result = await campaignApi.updateCampaign(campaign);

    state.campaignsForDatasetId = state.campaignsForDatasetId.map(c => c.id == campaign.id ? campaign : c);

    return result;
};

const sendCampaign = async (campaign: Campaign, recipientCount = 0) => {
    if (! campaign.id) {
        throw new ReferenceError('Campaign has not been saved yet');
    }

    campaign.isActive = true;
    campaign.recipientCount = recipientCount;
    campaign.lastSentAt = new Date().toISOString();

    await updateExistingCampaign(campaign);

    const result = await campaignApi.sendCampaign(campaign.id);

    return result;
};

const sendCampaignTest = async (campaign: Campaign, emails: string[]): Promise<void> => {
    if (! campaign.id) {
        throw new ReferenceError('Campaign has not been saved yet');
    }

    await campaignApi.sendCampaignTest(campaign.id, emails);
};

const sendCampaignReminder = async (campaign: Campaign, parentWaveId: string, reminderType: string): Promise<void> => {
    if (!campaign.id) {
        throw new ReferenceError('Campaign has not been saved yet');
    }

    await campaignApi.sendCampaignReminder(campaign.id, parentWaveId, reminderType);
};

export const useCampaigns = () => {
    return {
        state,
        sendCampaign,
        removeCampaign,
        sendCampaignTest,
        sendCampaignReminder,
        initEmptyCampaign,
        createNewCampaign,
        updateExistingCampaign,
        getCampaignsByDatasetId,
        getCampaignsForUserGroup,
        setPopupViewOnlyMode,
        setReminderWave,
        setReminderRecipientCount,
        setFirstLoadingCampaigns,
        setCampaignsForDatasetId,
        resetState,
    };
};
