<template>
    <section class="space-y-1">
        <div class="text-xs">
            {{ $t('PUBLISH.SHARE_SURVEY', 'Share Survey') }}
        </div>
        <div class="flex space-x-1.5">
            <share-button
                :disabled="urlParamCount === 0"
                @click="showEncodedUrlPopup = true"
            >
                {{ $t('PUBLISH.ENCODED_URL', 'Encoded URL') }}
            </share-button>
            <share-button @click="openQrPopup">
                {{ $t('PUBLISH.QR_CODE', 'QR Code') }}
            </share-button>
            <share-button>
                <a
                    :href="'https://www.facebook.com/sharer/sharer.php?u=' + basicUrl"
                    target="popup"
                >
                    Facebook
                </a>
            </share-button>
            <share-button>
                <a
                    :href="'https://www.linkedin.com/shareArticle?mini=true&url=' + basicUrl"
                    target="popup"
                >
                    LinkedIn
                </a>
            </share-button>
            <share-button>
                <a
                    :href="'https://twitter.com/intent/tweet?text=' + basicUrl"
                    target="popup"
                >
                    Twitter
                </a>
            </share-button>
        </div>
        <no-url-param-qr-code-popup
            v-if="showNoUrlParamQrCodePopup"
            :link="basicUrl"
            :name="title"
            :benchmark-language="benchmarkLanguage"
            :langs="langs"
            @close="showNoUrlParamQrCodePopup = false"
        />
        <with-url-param-qr-code-popup
            v-if="showWithUrlParamQrCodePopup"
            :url-params="urlParams"
            :name="title"
            @close="showWithUrlParamQrCodePopup = false"
        />
        <download-with-url-param-qr-code-popup
            v-if="linkData.length > 0"
            :link="basicUrl"
            :name="title"
            :benchmark-language="benchmarkLanguage"
            :langs="langs"
            @close="setLinkData([])"
        />
        <with-url-param-encode-popup
            v-if="showEncodedUrlPopup"
            :url-params="urlParams"
            :name="title"
            @close="showEncodedUrlPopup = false"
        />
        <download-encoded-url-popup
            v-if="encodedUrlResponse !== null"
            :name="title"
            @close="setEncodedUrlResponse(null)"
        />
    </section>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import ShareButton from './ShareButton.vue';
import NoUrlParamQrCodePopup from './NoUrlParamQrCodePopup.vue';
import WithUrlParamQrCodePopup from './WithUrlParamQrCodePopup.vue';
import DownloadWithUrlParamQrCodePopup from './DownloadWithUrlParamQrCodePopup.vue';
import WithUrlParamEncodePopup from './WithUrlParamEncodePopup.vue';
import DownloadEncodedUrlPopup from './DownloadEncodedUrlPopup.vue';

import globals from '@/utils/globals';
import { useNamespacedMutations, useNamespacedState } from 'vuex-composition-helpers';
import { getNicknameFromId } from '@/domain/analysisLanguageEnum';

export default defineComponent({
    components: {
        ShareButton,
        NoUrlParamQrCodePopup,
        WithUrlParamQrCodePopup,
        DownloadWithUrlParamQrCodePopup,
        WithUrlParamEncodePopup,
        DownloadEncodedUrlPopup,
    },
    props: {
        uniqueName: { type: String, default: '' },
        title: { type: String, default: '' },
        langs: { type: Array as () => Array<string>, default: () => [] },
        urlParams: { type: Array, default: () => [] }
    },
    setup(props) {
        const state = useNamespacedState('survey/qrCode', ['linkData']);
        const mutations = useNamespacedMutations('survey/qrCode', ['setLinkData']);

        const surveyState = useNamespacedState('survey', ['language_id']);
        const benchmarkLanguage = computed(() => getNicknameFromId(surveyState.language_id.value));

        const basicUrl = computed(() => globals.getFillerBaseUrl() + '/' + props.uniqueName);

        const urlParamCount = computed(() => props.urlParams.length);

        const showNoUrlParamQrCodePopup = ref(false);
        const showWithUrlParamQrCodePopup = ref(false);

        const openQrPopup = () => {
            if (urlParamCount.value === 0) {
                showNoUrlParamQrCodePopup.value = true;
            }
            else {
                showWithUrlParamQrCodePopup.value = true;
            }
        };

        const showEncodedUrlPopup = ref(false);
        
        const encodedUrlState = useNamespacedState('survey/encodedUrl', ['response']);
        const encodedUrlMutations = useNamespacedMutations('survey/encodedUrl', ['setResponse']);

        return {
            basicUrl,
            showNoUrlParamQrCodePopup,
            showWithUrlParamQrCodePopup,
            openQrPopup,
            linkData: state.linkData,
            setLinkData: mutations.setLinkData,
            benchmarkLanguage,
            urlParamCount,
            showEncodedUrlPopup,
            encodedUrlResponse: encodedUrlState.response,
            setEncodedUrlResponse: encodedUrlMutations.setResponse
        };
    },
});
</script>

