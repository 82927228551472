import {api} from '@/api/index.js';
import {Filter, FilterTypeEnum, OperatorTypeEnum} from '@/dashboard/common/types/filterInterface';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import { DashboardSettings } from '../common/types/commonInterface';
dayjs.extend(utc);
dayjs.extend(isoWeek);

export const logDashboardVisit = async (id: string) => {
    await api.post('/portal/dashboard/log-visit', { id });
};

export const getDashboardSettings = async (id: string) => {
    const { data } = await api.get(`/portal/dashboard/settings/${id}`);
    return data;
};

export const setDashboardSettings = async (id: string, dashboardSettings: DashboardSettings) => {
    const { data } = await api.put(`portal/dashboard/settings/${id}`, {
        filter_sets: dashboardSettings.filter_sets,
        pinned: dashboardSettings.pinned,
        hidden: dashboardSettings.hidden,
        series: dashboardSettings.series,
        metadata_dimensions: dashboardSettings.metadata_dimensions,
        labelOrderBy: dashboardSettings.labelOrderBy
    });
    return data;
};

export enum TextUnitLabelOrder {
    position = 'position',
    category = 'category',
    name = 'name'
}

export const getExcludedLabels = async (datasetId: string|null = null) => {
    const { data } = await api.get('/portal/dashboard/excluded-labels?datasetId=' + datasetId);
    return data;
};

// dashboardDatasetId is always filled when called from dashboard, null if not => it's for caching on backend
// datasetId however is null when the exclusion is user group-level, not dataset-level
export const excludeLabel = async (labelId: number, labelName: string, datasetId: string|null, dashboardDatasetId: string|null = null) => {
    await api.post('/portal/dashboard/excluded-labels', {labelId, labelName, datasetId, dashboardDatasetId});
};

export const includeLabels = async (labelIds: number[], dashboardDatasetId: string|null = null) => {
    const {data} = await api.post('/portal/dashboard/excluded-labels/delete', {labelIds, dashboardDatasetId});
    return data;
};

export const getDimensionDistribution = async (datasetId: string, dimensionId: string, filters: Filter[] = []) => {
    const { data } = await api.post(`records/${datasetId}/aggregations/distribution/${dimensionId}`, {filters});
    return data;
};

export const getPolarityDistribution = async (datasetId: string, dimensionId: string, filters: Filter[][] = []) => {
    const { data } = await api.post(`records/${datasetId}/aggregations/polarityDistribution/${dimensionId}`, {filters});
    return data;
};

export const getLabelDistribution = async (datasetId: string, dimensionId: string, filters: Filter[] = []) => {
    const { data } = await api.post(`records/${datasetId}/aggregations/labelDistribution/${dimensionId}`, {filters});
    return data;
};

export const getResponseCounts = async (datasetId: string, filters: Filter[] = []) => {
    const { data } = await api.post(`/records/${datasetId}/aggregations/notNullAnswersByDimension`, {filters});
    return data;
};

export const getSemanticTimeSeries = async (datasetId: string, dimensionId: string, filters: Filter[] = []) => {
    const { data } = await api.post(`/records/${datasetId}/textAnalysis/${dimensionId}/semanticTimeSeries`, {filters});
    return data;
};

export const getCategorySemanticStats = async (datasetId: string, dimensionId: string, filters: Filter[] = []) => {
    const { data } = await api.post(`/records/${datasetId}/textAnalysis/${dimensionId}/categorySemanticStats`, {filters});
    return data;
};

export const getAllEntities = async (datasetId: string, dimensionId: string, filters: Filter[][] = []) => {
    const { data } = await api.post(`/records/${datasetId}/textAnalysis/${dimensionId}/allEntities`, {filters});
    return data;
};

export const getTopEntities = async (datasetId: string, dimensionId: string, filters: Filter[] = []) => {
    const { data } = await api.post(`/records/${datasetId}/textAnalysis/${dimensionId}/topEntities`, {filters});
    return data;
};

export const getMentions = async (datasetId: string, dimensionId: string, filters: Filter[] = [], labels): Promise<{ [key: number]: {posMention: string, negMention: string, neuMention: string} }> => {
    const { data } = await api.post(`/records/${datasetId}/textAnalysis/${dimensionId}/allEntities/mentions`, {filters, labels});
    return data;
};

export const getRelationMentions = async (datasetId: string, dimensionId: string, filters: Filter[] = []): Promise<{id: string, name: string, relatedEntityId: string, relatedEntityName: string, posMention: string, negMention: string, neuMention: string}[]> => {
    const { data } = await api.post(`/records/${datasetId}/textAnalysis/${dimensionId}/entityRelations/mentions`, {filters});
    return data;
};

export const getPhrases = async (datasetId: string, dimensionId: string, filters: Filter[] = []) => {
    const { data } = await api.post(`/records/${datasetId}/textAnalysis/${dimensionId}/phrases`, {filters});
    return data;
};

export const getEntityRelations = async (datasetId: string, dimensionId: string, filters: Filter[] = []) => {
    const { data } = await api.post(`/records/${datasetId}/textAnalysis/${dimensionId}/entityRelations`, {filters});
    return data;
};

export const getPhraseRelations = async (datasetId: string, dimensionId: string, entityIds: number[], filters: Filter[] = []) => {
    const { data } = await api.post(`/records/${datasetId}/textAnalysis/${dimensionId}/phraseRelations`, { filters, ids: entityIds});
    return data;
};

export const getTopDrivers = async (datasetId: string, dimensionId: string, filters: Filter[] = []) => {
    const { data } = await api.post(`/records/${datasetId}/textAnalysis/${dimensionId}/topPhraseRelationsWithGroups`, { filters });
    return data;
};

export const getTopAllEntityTimeSeries = async (datasetId: string, dimensionId: string, filters: Filter[] = [], topEntities: any[]) => {
    const { data } = await api.post(`/records/${datasetId}/textAnalysis/${dimensionId}/topAllEntityTimeSeries`, { filters, topEntities });
    return data;
};

export const getTopAllEntityCorrelations = async (datasetId: string, dimensionId: string, filters: Filter[] = [], topEntities: any[]) => {
    const { data } = await api.post(`/records/${datasetId}/textAnalysis/${dimensionId}/topAllEntityCorrelations`, { filters, topEntities });
    return data;
};

export const getExampleVerbatim = async (datasetId: string, dimensionId: string) => {
    const { data } = await api.get(`/records/${datasetId}/textUnits/${dimensionId}/example`);
    return data;
};
export const getTextUnits = async (
    datasetId: string,
    dimensionId: string,
    searchQuery: string,
    sortColumn: string,
    sortDirection: string,
    limit: number,
    lastTextUnitId: string|null = null,
    lastTextUnitOi: number|string|null = null, // it's a number but on TextUnit type it's string so i leave it like this for now
    filters: Filter[] = []
) => {
    const filterToApply = [...filters];
    if (searchQuery.length > 0) {
        filterToApply.push({
            dimensionId,
            isMultiSelect: false,
            type: FilterTypeEnum.text,
            operator: OperatorTypeEnum.contains,
            value: searchQuery
        });
    }
    const { data } = await api.post(`/records/${datasetId}/textUnits/${dimensionId}`, {
        searchQuery, sortColumn, sortDirection, limit, lastTextUnitId, lastTextUnitOi, filters: filterToApply
    });

    return data.map(tu => ({ ...tu, entities: JSON.parse(tu.entities) }));
};

export const exportTextUnits = async (
    datasetId: string,
    dimensionId: string,
    searchQuery: string,
    filters: Filter[] = []
) => {
    const filterToApply = [...filters];
    if (searchQuery.length > 0) {
        filterToApply.push({
            dimensionId,
            isMultiSelect: false,
            type: FilterTypeEnum.text,
            operator: OperatorTypeEnum.contains,
            value: searchQuery
        });
    }
    const { data } = await api.post(`/records/${datasetId}/textUnits/${dimensionId}/export`, {
        searchQuery, filters: filterToApply
    }, {responseType: 'blob'});
    return data;
};

export const customLabelReanalyses = async (datasetId: string, dimensionId: string, customLabelIds: number[], removedClIds: number[] = [], from: number|null = null) => {
    const { data } = await api.post('/customLabelReanalyses', {
        datasetId, dimensionId, customLabelIds, removedClIds, from
    });
    return data;
};

export const getCustomLabelReanalyses = async (datasetId: string) => {
    const { data } = await api.get('/customLabelReanalyses/dataset/' + datasetId);

    return data;
};

export const getTopRatedTopics = async (datasetId: string, dimensionId: string, onlyCls: boolean, filters: Filter[][] = [[]]) => {
    const { data } = await api.post(`/records/${datasetId}/textAnalysis/${dimensionId}/topLabelsBasedOnRatings`, {onlyCls, filters});
    return data;
};

export const searchInNonUniqueIdentifier = async (datasetId: string, dimensionId: string, identifier: string) => {
    const { data } = await api.get(`/records/${datasetId}/filters/nonUniqueIdentifier/${dimensionId}/search?identifier=${identifier}`);
    return data;
};

export const getSemanticIndividualMarkers = async (datasetId: string, dimensionId: string, nonUniqueDimensionId: string,  filters: Filter[][] = [[]]) => {
    const { data } = await api.post(`/records/${datasetId}/textAnalysis/${dimensionId}/topNonUniqueIdentifiers/${nonUniqueDimensionId}/polarity`, {filters});
    return data;
};

export const getSemanticIndividualMarkersByFrequency = async (datasetId: string, dimensionId: string, nonUniqueDimensionId: string,  filters: Filter[][] = [[]]) => {
    const { data } = await api.post(`/records/${datasetId}/textAnalysis/${dimensionId}/topNonUniqueIdentifiers/${nonUniqueDimensionId}/freq`, {filters});
    return data;
};
