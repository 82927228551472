<template>
    <table
        style="border-collapse: unset;"
        :style="outerTableStyle"
    >
        <tr v-if="styleOptions.showTitle">
            <td
                id="titlePlaceholderVertical"
                :style="headerStyle"
                colspan="2"
            />
        </tr>
        <tr>
            <td colspan="2">
                <table :style="bodyTableStyle">
                    <tr
                        v-for="range in element.ranges"
                        :key="range.weight"
                    >
                        <td :style="tdStyle">
                            <a
                                target="_blank"
                                :style="anchorStyle"
                                :data-zurvey-id="'option-'+range.weight"
                                :disabled="!isLive"
                                :href="url + '/' + uniqueName + '?' + params + element.guid + '=' + range.weight"
                            >
                                {{ getDisplayedLabel(range) }}
                            </a>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr v-if="styleOptions.branding">
            <td
                colspan="2"
                style="vertical-align: bottom; line-height: 16px;"
            >
                <table
                    :style="'color: #9fa7bc; width: 100%; margin-top: 10px; font-size: 12px; line-height: 16px; font-family: ' + answer_font_name + ', Sans-Serif;'"
                >
                    <tr>
                        <td style="width: 20px;">
                            <img
                                src="https://s3-eu-central-1.amazonaws.com/neticle-images/2019/10/zurvey_16.png"
                                style="margin-bottom: 0; max-width: unset;"
                            >
                        </td>
                        <td>
                            <a
                                href="https://zurvey.io"
                                style="text-decoration: none; cursor: pointer; color: #9fa7bc;"
                            >
                                {{ $t('EMBEDDED.POWERED_BY', 'powered by') }} Zurvey.io
                            </a>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</template>

<script lang="ts">
import globals from '@/utils/globals';
import { createNamespacedHelpers } from 'vuex-composition-helpers/dist';
import { computed, defineComponent, nextTick, onMounted, onUpdated } from 'vue';
import { StyleValue } from 'vue/types/jsx';

interface StyleOptions {
    color?: string,
    backgroundColor?: string,
    textColor?: string,
    borderColor?: string,
    scaleButtonColor?: string,
    scaleBorderColor?: string,
    type?: string,
    borderRadius?: number,
    iconSpacing?: number,
    marginSize?: number,
    boxShadow?: string,
    backgroundOverlay?: string,
    closeOnSubmit?: boolean,
    branding?: boolean,
    showPrivacyPolicy?: boolean,
    showLabels?: boolean,
    showTitle?: boolean,
}

export default defineComponent({
    props: {
        element: { type: Object, default: () => ({}) },
        isLive: { type: Boolean, default: false },
        styleOptions: { type: Object as () => StyleOptions, default: () => ({}) },
        useLinkPlaceholder: { type: Boolean, default: false },
        urlParams: { type: Array as () => Array<{ name: string }>, default: () => ([]) }
    },
    setup(props) {
        const { useState, useGetters } = createNamespacedHelpers('survey');
        const { uniqueName, primary_color, background_color, text_color, answer_font_name, answer_font_weight, title_font_name, title_font_weight } = useState(['uniqueName', 'primary_color', 'background_color', 'text_color', 'answer_font_name', 'answer_font_weight', 'title_font_name', 'title_font_weight']);
        const { hasActiveLanguages } = useGetters(['hasActiveLanguages']);
        const url = globals.getFillerBaseUrl();
        const params = computed(() => {
            if (props.useLinkPlaceholder) return '@@PARAMS@@';
            let params = hasActiveLanguages.value ? 'lang={{LANG}}&' : '';
            const paramCount = props.urlParams.length;
            if (paramCount > 0) {
                props.urlParams.forEach(p => {
                    params = params + p.name + '={{' + p.name.toUpperCase() + '}}&';
                });
            }
            return params;
        });

        const textColor = computed(() => props.styleOptions.textColor || text_color.value);

        const headerStyle = computed(() => {
            return {
                fontSize: '14px',
                lineHeight: '16px',
                color: textColor.value,
                fontFamily: title_font_name.value + ', Sans-Serif',
                fontWeight: title_font_weight.value,
                padding: '0 0 10px'
            };
        });

        const bodyTableStyle = computed<StyleValue>(() => {
            return {
                height: '32px',
                margin: '0',
                borderCollapse: 'separate',
                borderSpacing: props.styleOptions.iconSpacing ? '0 ' + (props.styleOptions.iconSpacing + 'px') : '0',
                marginTop: props.styleOptions.iconSpacing ? ('-' + props.styleOptions.iconSpacing + 'px') : '0',
                textAlign: 'center',
                verticalAlign: 'middle',
                fontFamily: answer_font_name.value + ', Sans-Serif',
                fontWeight: answer_font_weight.value,
                tableLayout: 'fixed',
            };
        });

        const outerTableStyle = computed(() => {
            return {
                color: props.styleOptions.textColor || text_color.value,
                padding: props.styleOptions.marginSize + 'px',
                border: props.styleOptions.borderColor ? '1px solid' + props.styleOptions.borderColor : 'unset',
                backgroundColor: props.styleOptions.backgroundColor || background_color.value,
            };
        });

        const anchorStyle = computed(() => {
            return {
                width: '100%',
                height: '100%',
                fontSize: '14px',
                textDecoration: 'none',
                cursor: 'pointer',
                color: textColor.value,
                padding: props.styleOptions.iconSpacing ? '0' : '0 8px'
            };
        });

        const tdStyle = computed(() => {
            return {
                width: '20%',
                backgroundColor: props.styleOptions.scaleButtonColor || props.styleOptions.backgroundColor || background_color.value,
                padding:  '8px 14px',
                borderRadius: '4px',
                border : '1px solid ' + (props.styleOptions.scaleBorderColor || primary_color.value),
                verticalAlign: 'middle',
            };
        });

        const getDisplayedLabel = (range: {range: string, weight: number}) => {
            if (props.element.display_type === 'number' || !props.styleOptions.showLabels) {
                return range.weight;
            }
            return !range.range || range.range === '' ? range.weight : range.range;
        };

        const updateTitle = async () => {
            const titlePlaceholder = document.getElementById('titlePlaceholderVertical');
            await nextTick();

            if (titlePlaceholder) {
                titlePlaceholder.innerHTML = props.element.title;
                const title = titlePlaceholder.getElementsByTagName( 'p' )[0];
                title.style.margin = '0';
            }
        };

        onMounted(() => {
            updateTitle();
        });

        onUpdated(() => {
            updateTitle();
        });

        return {
            url, uniqueName, params, textColor, answer_font_name, getDisplayedLabel,
            tdStyle, headerStyle, bodyTableStyle, outerTableStyle, anchorStyle,
        };
    }
});
</script>
