<template>
    <popup
        :title="$t('PUBLISH.QR_CODE_POPUP_TITLE', 'Download QR Code')"
        is-closable
        @close="$emit('close')"
    >
        <div class="w-64 space-y-4">
            <main class="flex justify-center">
                <img
                    v-if="dataUrl"
                    :src="dataUrl"
                >
            </main>
            <div class="flex justify-center">
                <z-button @click="download">
                    {{ $t('GLOBAL.DOWNLOAD', 'Download') }}
                </z-button>
            </div>
        </div>
    </popup>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import Popup from '@/components/ui/Popup.vue';
import QRCode from 'qrcode';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';


export default defineComponent({
    components: {
        Popup,

    },
    props: {
        link: { type: String, required: true },
        name: { type: String, required: true },
        benchmarkLanguage: { type: String, default: '' },
        langs: { type: Array as () => Array<string>, default: () => [] }
    },
    setup(props) {
        const dataUrl = ref();

        onMounted(async () => {
            dataUrl.value = await QRCode.toDataURL(props.link);
        });

        const download = async () => {
            if (props.langs.length === 0) {
                saveAs(dataUrl.value, props.name + '.png');
            }
            else {
                const codes = await Promise.all(props.langs.map(lang => QRCode.toDataURL(props.link + '?lang=' + lang)));

                const zip = new JSZip();

                zip.file(props.name + '_' + props.benchmarkLanguage + '.png', dataUrl.value.split('base64,')[1], {base64: true});

                codes.forEach((code, index) => {
                    zip.file(props.name + '_' + props.langs[index] + '.png', code.split('base64,')[1], {base64: true});
                });

                saveAs(await zip.generateAsync({ type: 'blob' }), props.name + '.zip');
            }
        };

        return { dataUrl, download };
    },
});
</script>
