<template>
    <div>
        <div
            v-for="(language, index) in languages"
            :key="index"
            class="mb-2 flex"
        >
            <div class="w-24 py-2 mr-2 text-sm flex-shrink-0">
                {{ language.name }}
            </div>
            <div class="flex-grow overflow-hidden">
                <copy-container
                    :text-to-copy="language.url"
                />
            </div>
        </div>
        <div class="float-right mt-2">
            <z-button @click="exportUrls">
                {{ $t('SURVEYFORMS_EMAIL_EXPORT', 'Export .xlsx') }}
            </z-button>
        </div>
    </div>
</template>

<script lang="ts">
import CopyContainer from './CopyContainer.vue';
import { defineComponent, computed } from 'vue';
import { getDisplayNameFromNickname, getNicknameFromId } from '@/domain/analysisLanguageEnum';
import i18next from 'i18next';
import { setLoading } from '@/utils/loading';
import excelWriter from '@/utils/excelWriter';
import { toaster } from '@/utils/toaster';


export default defineComponent({
    components: { CopyContainer,  },
    props: {
        baseUrl: { type: String, required: true },
        benchmarkLangId: { type: Number, required: true },
        langCodes: { type: Array as () => Array<string>, required: true },
    },
    emits: ['click'],
    setup(props) {
        const concatChar = computed(() => props.baseUrl.includes('?') ? '&' : '?');

        const getLanguageFromCode = (langCode: string, isBenchmark: boolean = false) => {
            const baseFlag = isBenchmark ? (' (' + i18next.t('SURVEY.BASE_LANGUAGE', 'base') + ')') : '';
            return {
                name: getDisplayNameFromNickname(langCode) + baseFlag,
                url: props.baseUrl + concatChar.value + 'lang=' + langCode.toUpperCase()
            };
        };

        const languages = computed(() => {
            const benchmarkLanguage = getLanguageFromCode(getNicknameFromId(props.benchmarkLangId), true);
            const translationLanguages = props.langCodes.map((langCode) => getLanguageFromCode(langCode));
            return [...[benchmarkLanguage], ...translationLanguages];
        });

        const exportUrls = async () => {
            setLoading(true);
            const excelHeaders = [i18next.t('DASHBOARD.FILTER_TYPE_LANGUAGE', 'Language'), 'URL'];
            const excelData = languages.value.map(l => [l.name, l.url]);
            try {
                await excelWriter({ fileName: 'lang_url_export.xlsx', headers: excelHeaders, data: { 'Data': excelData } });
            } catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
            }
            setLoading(false);
        };

        return { languages, exportUrls };
    },
});
</script>
